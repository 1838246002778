import { makeStyles } from '@material-ui/core/styles';

const tabContainerStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',

    [theme.breakpoints.down('sm')]: {
     // marginTop: '75px',
      '& .MuiAppBar-root': {
        display: 'none'
      }
    }
  },
  tabContainer: {
    padding: '0',
    margin: '0 auto',
    maxWidth: '1200px',

    [theme.breakpoints.only("md")]: {
      maxWidth: "960px"
    },

    '& .MuiBox-root': {
      padding: 0
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset'
    }
  },

}));

export default tabContainerStyles;