import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const AntSwitch = withStyles(theme => ({
  root: {
    width: 35,
    height: 18,
    padding: 0,
    marginRight: '5px',
    display: 'flex'
  },
  switchBase: {
    padding: '3px',
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
    '&:hover': {
      backgroundColor: 'initial !important'
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

