// export const notificationTypes={
//     CREATE_REQUEST_GIVER : "create_request_giver",
//     CREATE_REQUEST_RECEIVER : "create_request_receiver",
//     ACCEPT_REQUEST_GIVER : "accept_request_giver",
//     ACCEPT_REQUEST_RECEIVER : "accept_request_receiver",
//     CONFIRM_REQUEST_GIVER : "confirm_request_giver",
//     CONFIRM_REQUEST_RECEIVER : "confirm_request_receiver",
//     ITEM_GIVEN_GIVER : "item_given_giver",
//     ITEM_GIVEN_RECEIVER : "item_given_receiver",
//     ITEM_NOT_GIVEN_GIVER : "item_not_given_giver",
//     ITEM_NOT_GIVEN_RECEIVER : "item_not_given_receiver",
// }

export const notificationActions = {
  MARK_AS_READ: 'mark_as_read',
  MARK_AS_UNREAD: 'mark_as_unread',
  MARK_AS_SEEN: 'mark_as_seen',
  MARK_AS_UNSEEN: 'mark_as_unseen',
  MARK_AS_ACTIVE: 'mark_as_active',
  MARK_AS_INACTIVE: 'mark_as_inactive',
  MARK_ALL_AS_READ: 'mark_all_as_read',
  MARK_ALL_AS_UNREAD: 'mark_all_as_unread',
  MARK_ALL_AS_SEEN: 'mark_all_as_seen',
  MARK_ALL_AS_UNSEEN: 'mark_all_as_unseen',
  MARK_ALL_AS_ACTIVE: 'mark_all_as_active',
  MARK_ALL_AS_INACTIVE: 'mark_all_as_inactive',
};

export const UNREAD_NOTIFICATION_INTERVAL = 300 * 1000; // 5min in milliseconds (ms)
