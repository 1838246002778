import { HorizontalAds } from "../components/ui/ads/HorizontalAds";

export function getHorizontalAds() {
  switch (window.countryCode) {
    case "IN":
      return [
        '<a href="https://www.dpbolvw.net/click-100148901-12710170" target="_blank"><img src="https://www.ftjcfx.com/image-100148901-12710170" width="468" height="60" alt="Project Management Certification" border="0"/></a>',
        '<a href="https://www.dpbolvw.net/click-100148901-12710181" target="_blank"><img src="https://www.ftjcfx.com/image-100148901-12710181" width="468" height="60" alt="Six Sigma Certification" border="0"/></a>',
        '<a href="https://www.jdoqocy.com/click-100148901-12710033" target="_blank"><img src="https://www.tqlkg.com/image-100148901-12710033" width="468" height="60" alt="Online Certifications" border="0"/></a>',
        '<a href="https://www.anrdoezrs.net/click-100148901-12710032" target="_blank"><img src="https://www.lduhtrp.net/image-100148901-12710032" width="468" height="60" alt="Aromatherapy Certification" border="0"/></a>', 
        `<a href="https://tracking.vcommission.com/aff_c?offer_id=44&aff_id=101997&file_id=196274" target="_blank"><img src="https://media.vcommission.com/brand/files/vcm/44/Makemytrip_CPS_Dear_Dal-Roti_We'll_Be_Right_Back_728x90.jpg" width="728" height="90" border="0" /></a><img src="https://tracking.vcommission.com/aff_i?offer_id=44&file_id=196274&aff_id=101997" width="0" height="0" style="position:absolute;visibility:hidden;" border="0" />`,
        `<a href="https://tracking.vcommission.com/aff_c?offer_id=44&aff_id=101997&file_id=196263" target="_blank"><img src="https://media.vcommission.com/brand/files/vcm/44/Makemytrip_CPS_Dear_Zoom_Classes_We'll_Be_Right_Back_728x90.jpg" width="728" height="90" border="0" /></a><img src="https://tracking.vcommission.com/aff_i?offer_id=44&file_id=196263&aff_id=101997" width="0" height="0" style="position:absolute;visibility:hidden;" border="0" />`,
        `<a href="https://tracking.vcommission.com/aff_c?offer_id=44&aff_id=101997&file_id=196252" target="_blank"><img src="https://media.vcommission.com/brand/files/vcm/44/Makemytrip_CPS_Dear_Roz_ki_chik_chik_we'll_Be_Right_Back_728x90.jpg" width="728" height="90" border="0" /></a><img src="https://tracking.vcommission.com/aff_i?offer_id=44&file_id=196252&aff_id=101997" width="0" height="0" style="position:absolute;visibility:hidden;" border="0" />`
      ];

    case "GB":
      return [
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=wedding_registry&banner=0JFTAXXPGSJTVYG56GR2&f=ifr&linkID=49c322d9445ef8fe6d70773d94547645&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ez&f=ifr&linkID=8f19d48085e91e8f07c6ddb58df4a29d&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=piv&banner=0CQ53D02W107RHR3JVR2&f=ifr&linkID=bc30c5d1051df44794467c00fc5e239a&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=26&l=ur1&category=audible&banner=0N66TFHVHKE58MRWWHG2&f=ifr&linkID=8467d7540d19c00c51da1ca99f867d3a&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="468" height="60" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=amazon_music_bounty&banner=1S7QPRHBCK6W9HRPHV82&f=ifr&linkID=e2950dbd085e3be8ed1e19bae46afd5c&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=amazon_student&banner=159PP9MQ6JYSJZKDC6G2&f=ifr&linkID=aed1c67cb14937f03d1a481156dd0316&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=kindle_unlimited&banner=0FQBXJPYRKBARVC5VAR2&f=ifr&linkID=1120373d7026b5b11e1a1d5aff63baef&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=amazon_business&banner=1VCYTD9FQ2WQDVK6AVG2&f=ifr&linkID=4dcd42cc4bd4a45b18a8ac9b682c113b&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=rainbowsforhope_handmade&banner=1MPT4SNZGPQY3205T282&f=ifr&linkID=f95ec57b4741c098f101f5cba014e37e&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=endless&banner=0SMT47VQQNF212JA3AR2&f=ifr&linkID=7569db1d67533ef994c8a52825f38214&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>'
      ];

    case "US":
    default:
      return [
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=booksteach&banner=15NZ7M2AY053NEPSW202&f=ifr&linkID=8d5e44299b37c67c91c40e5fc769cd1c&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=kindle&banner=0FYSGBRNTHMCPW1BX682&f=ifr&linkID=764aaff5e856fd595b56e5bcc7380a8a&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=amazonmom&banner=1Z1SYMKHY3SN1KJYFW02&f=ifr&linkID=21b2662b7ff8723ac5e6f702fb7fae29&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=288&l=ur1&category=amzn_biz_bounty&banner=1CBSC7EGBAJ74Q2EWK02&f=ifr&linkID=1e2d6dd2eda67b11887a8c422d5ba101&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="320" height="50" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=audibleromance&banner=0WJ95B1Z3W66WEKQ7782&f=ifr&linkID=caf0ee53f89d1a07c4c91cff31effcb9&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=freetime&banner=077WJQZH4MJF57F3F482&f=ifr&linkID=8ce633ff8d3d0ad92d3c2eaa9536788c&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primemusic&banner=1RY2Z0V0AAZV4RSZEXG2&f=ifr&linkID=ddf4baa7056b3f6d6dbf5627238547c3&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primeent&banner=0KRPDR35SJEJY56XVF82&f=ifr&linkID=2f3a69acbbebf0887425e2af71662f47&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primeent&banner=06GDZC4PE16KAQ62F782&f=ifr&linkID=35f9a42ac2b7b13db157238e84b30777&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>'
      ];
  }
}

export function getVerticalAds() {
  switch (window.countryCode) {
    case "IN":
      return [
        '<a href="https://www.tkqlhce.com/click-100148901-12772503" target="_blank"><img src="https://www.ftjcfx.com/image-100148901-12772503" width="160" height="600" alt="ExpertRating Typing Certification" border="0"/></a>',
        `<a href="https://tracking.vcommission.com/aff_c?offer_id=44&aff_id=101997&file_id=196273" target="_blank"><img src="https://media.vcommission.com/brand/files/vcm/44/Makemytrip_CPS_Dear_Dal-Roti_We'll_Be_Right_Back_160x600.jpg" width="160" height="600" border="0" /></a><img src="https://tracking.vcommission.com/aff_i?offer_id=44&file_id=196273&aff_id=101997" width="0" height="0" style="position:absolute;visibility:hidden;" border="0" />`,
        `<a href="https://tracking.vcommission.com/aff_c?offer_id=44&aff_id=101997&file_id=196264" target="_blank"><img src="https://media.vcommission.com/brand/files/vcm/44/Makemytrip_CPS_Dear_Zoom_Classes_We'll_Be_Right_Back_160x600.jpg" width="160" height="600" border="0" /></a><img src="https://tracking.vcommission.com/aff_i?offer_id=44&file_id=196264&aff_id=101997" width="0" height="0" style="position:absolute;visibility:hidden;" border="0" />`,
        `<a href="https://tracking.vcommission.com/aff_c?offer_id=44&aff_id=101997&file_id=196244" target="_blank"><img src="https://media.vcommission.com/brand/files/vcm/44/Makemytrip_CPS_Dear_Roz_ki_chik_chik_we'll_Be_Right_Back_160x600.jpg" width="160" height="600" border="0" /></a><img src="https://tracking.vcommission.com/aff_i?offer_id=44&file_id=196244&aff_id=101997" width="0" height="0" style="position:absolute;visibility:hidden;" border="0" />`
      ];

    case "GB":
      return [
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=14&l=ur1&category=fresh&banner=1NJ6Q5SYAK15CTXVG482&f=ifr&linkID=21ec791c4c46f19b8717ad9044e6e765&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=14&l=ur1&category=piv&banner=0BW4N9Q1ZEC3DG9B8M82&f=ifr&linkID=da98157bbef856f0dc34b5e9fbd82a43&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=14&l=ur1&category=audible&banner=1R2KC5X7BCE8KCW6RJR2&f=ifr&linkID=836fe96eb6840c7ab58aea7bc1b2b70e&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=14&l=ur1&category=amazon_music_bounty&banner=1EG2FDPE02KM84DS6202&f=ifr&linkID=16a66034bf83140ad283c32867e42d01&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=14&l=ur1&category=amazon_student&banner=1W42ZS1E10516NZZ3PG2&f=ifr&linkID=f640e4afcddf563eb33fe48b2e172f1e&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=14&l=ur1&category=fireforkidsunlimitedv2&banner=04N6TPGXEGSMP4GKHE82&f=ifr&linkID=a601c76b3aa13bc4e7f6eb32b7a6d45e&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=14&l=ur1&category=kindle_unlimited&banner=03HMBK98EVP93S95T2R2&f=ifr&linkID=d7d94a8581f9298083ec4d428beefafd&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=14&l=ur1&category=amazon_business&banner=0R6MXZKXD3KZ83SR6A02&f=ifr&linkID=6fa15f1ac06d9ecc5b1d13870d932f4a&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=14&l=ur1&category=rainbowsforhope_handmade&banner=0J24S4VMC854YECWX2G2&f=ifr&linkID=72065b373b60f2063cda87e38717e76c&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=14&l=ur1&category=endless&banner=0WAY20WMEA92X5XNDW82&f=ifr&linkID=e91542066d59139b3107b21ae1bef1f7&t=isupportkindeeds-21&tracking_id=isupportkindeeds-21" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>'
      ];

    case "US":
    default:
      return [
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=14&l=ur1&category=booksteach&banner=01W6KZG4QZMD00EKF982&f=ifr&linkID=deb7a5504ac9e6464ee5d8e8541c09b4&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=29&l=ur1&category=mostwishforitem&banner=16FJBX4VDZHER5Y2EMG2&f=ifr&linkID=99d4e724e80a2c21834bdcbc7aaef511&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="120" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=14&l=ur1&category=amazon_smp_fallback&banner=16R0A9ZBB6PJYQ2MVA02&f=ifr&linkID=88a66093bd8f493fd6178bd42df76929&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=14&l=ur1&category=amazonmom&banner=0VP4HBA4KTSJPQEJNDR2&f=ifr&linkID=e580562a06f1971afdc63ebca1ebef7b&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=49&l=ur1&category=amzn_biz_bounty&banner=01391AD863E0ABYDTXG2&f=ifr&linkID=520df91221c8df2d3231650ca4269825&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="300" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=14&l=ur1&category=freetime&banner=1RAR1MZV15CFJPACD002&f=ifr&linkID=eadf8cd4d4ad9685d94e6ba7f608aebd&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
        '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=14&l=ur1&category=primemusic&banner=1P22PZRP7AVGHE6KQH82&f=ifr&linkID=c0f1c2319bd5e76293fc68691ae4d929&t=kindeedsputth-20&tracking_id=kindeedsputth-20" width="160" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>'
      ];
  }
}

export function getLargeRectangleAds() {
  switch (window.countryCode) {
    case "IN":
      return [
        '<a href="https://www.amazon.in/tryprime?tag=icarekindeeds-21" target="_blank"><img src="/assets/ads/h2.jpg" border="0"/></a>',
        '<a href="https://www.amazon.in/tryab?tag=icarekindeeds-21" target="_blank"><img src="/assets/ads/h3.jpg" border="0"/></a>',
        '<a href="https://www.amazon.in/dp/B077S5CVBQ/?ref=assoc_tag_sept19?actioncode=AINOTH066082819002X&tag=icarekindeeds-21" target="_blank"><img src="/assets/ads/h1.jpg" border="0"/></a>'
      ];
    
    default:
      return getHorizontalAds();
  }
}
