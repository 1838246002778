import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import { navigate } from "@reach/router";
import { userApi } from "../../api";
import { stripeApi } from "../../api";
import { apiHelper } from '../../helpers';
import { useStoreState } from "easy-peasy";
import { utilHelper } from "./../../helpers/utilHelper";
import tabContainerStyles from "./../../styles/tabContainer.styles";
import { useConfirmation } from "../../ConfirmationService";
import { useStoreActions } from "./../../hooks";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Container from '@material-ui/core/Container';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  StripeTextFieldNumber,
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
} from '../ui/StripeInput';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InputAdornment from '@material-ui/core/InputAdornment';
import Chip from "@material-ui/core/Chip";
import Avatar from '@material-ui/core/Avatar';

const GetStyles = makeStyles(theme => ({
  contributeWrapper: {
    minHeight: '666px',

    [theme.breakpoints.down("md")]: {
      minHeight: "840px"
    },
  },
  container: {
    display: 'flex',
    width: '100%',
    minWidth: '650px',
    maxWidth: '1200px',
    margin: '4rem auto 2rem',

    [theme.breakpoints.only("md")]: {
      maxWidth: "960px"
    },

    '& .MuiIconButton-root': {
      display: 'none'
    },

    '& .MuiToggleButton-root': {
      border: '1px solid #8d8d8d26',
      borderRadius: 'unset',
      margin: '0.2rem',
      color: "inherit",

      '&:hover': {
        backgroundColor: '#22B11F'
      }
    },

    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: '#22B11F',
      color: "#fff"
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
      paddingLeft: '1rem',
      flexDirection: 'column',
      margin: '4rem 0 2rem',
      paddingTop: '2rem',

      '& .MuiIconButton-root': {
        display: 'flex'
      },

      '& > .MuiBox-root': {
        margin: '0 !important',

        '& img': {
          display: 'none'
        }
      },

      '& .MuiTypography-h1': {
        fontSize: '21px !important',
      },

      '& .MuiTypography-body1': {
        marginBottom: '0 !important'
      }
    }
  },
  subDiv: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1',

    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
  },
  contributeTo: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  toggleBtnGroup: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
}));

const helperTextStyles = makeStyles(theme => ({
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: "#EB933C"
    }
  }
}));

// stripeApi.getLocation().then()

const stripeKey = () => {
  return new Promise(async (resolve, reject) => {
    let [err, response] = await stripeApi.getSettings()
    if (err) return reject(err)
    resolve(loadStripe(response.STRIPE_PUBLISHABLE_KEY))
  })
}
// const stripePromise = stripeKey()

const CheckoutForm = (props) => {
  const stripe = useStripe()
  const elements = useElements()

  const [cardState, setCardState] = useState({
    creditCardNumberComplete: false,
    expirationDateComplete: false,
    cvcComplete: false,
    cardNameError: false,
    cardNumberError: false,
    expiredError: false,
    cvcError: false,
    formComplete: false,
    name: "",
    nameError: false,
    buttonDisabled: false
  })
  const user = useStoreState(state => state.auth.user)
  const isLoggedIn = useStoreState(state => state.auth.isLoggedIn)
  const showAlert = useStoreActions(actions => actions.alert.showAlert)
  const confirm = useConfirmation().bind(this)
  const openDialog = ({ variant, title, description }) => {
    confirm({ variant, title, description, catchOnCancel: true }).then().catch()
  }

  const onElementChange = (field, errorField) => ({
    complete,
    error = { message: null }
  }) => {
    const cstate = { ...cardState };
    if (field) {
      cstate[field] = complete;
      cstate[errorField] = error.message;
    }
    cstate.formComplete = (cstate.creditCardNumberComplete &&
      cstate.cvcComplete &&
      cstate.expirationDateComplete &&
      cstate.name.length > 5)
    setCardState(cstate)
  };

  const handleSubscription = async (event) => {
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: cardState.name
      },
    });

    if (result && result.error) {
      // showAlert({ message: `Payment Error - ${result.error.message}`, open: true, severity: "error" })
      openDialog({ variant: "info", title: "Payment Error", description: result.error.message })
      return
    }

    if (result && result.paymentMethod && result.paymentMethod.id) {
      let [err, response] = await stripeApi.createSubscription(result.paymentMethod.id, props.amount * 100, props.currency, props.frequency)
      if (err) {
        // return showAlert({ message: `Payment Error - ${err}`, open: true, severity: "error" })
        return openDialog({ variant: "info", title: "Payment Error", description: `Payment Error - ${err}` })
      }

      showAlert({ message: "Subscription Successful", open: true, severity: "success" })
      props.setPaymentStatus({
        amount: props.amount * 100,
        currency: props.currency,
        paymentId: result.paymentMethod.id,
        status: "succeeded",
        name: cardState.name,
        email: user.email,
        frequency: "monthly"
      })

    }
  }

  const handleSubmit = async (event) => {
    setCardState({ ...cardState, buttonDisabled: true })
    await handleSubmitAction(event)
    setCardState({ ...cardState, buttonDisabled: false })
  }

  const handleSubmitAction = async (event) => {
    event.preventDefault()
    if (cardState.name.length <= 5) setCardState({ ...cardState, nameError: "Invalid Name" })
    if (!cardState.formComplete) return showAlert("Please provide the card details")

    if (props.frequency === "monthly") {
      return handleSubscription(event)
    }

    let [err, response] = await stripeApi.createPaymentIntent(props.amount * 100, props.currency, props.frequency)
    if (err) {
      // return showAlert({message: `${err}`, open: true, severity: "error"})
      return openDialog({ variant: "info", title: "Payment Error", description: err })
    }
    if (!(response.client_secret)) {
      // return showAlert({message: "Internal error: Could not get client_secret", open: true, severity: "error"})
      return openDialog({ variant: "info", title: "Payment Error", description: "Internal error: Could not get client_secret" })
    }

    // TODO: Make it blur and disable Payment button

    const result = await stripe.confirmCardPayment(response.client_secret, {
      receipt_email: user.email,
      payment_method: {
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: cardState.name
        }
      },
    });

    if (result && result.paymentIntent && result.paymentIntent.status === "succeeded") {
      showAlert({ message: "Payment Successful", open: true, severity: "success" })
      props.setPaymentStatus({
        amount: result.paymentIntent.amount,
        currency: result.paymentIntent.currency,
        paymentId: result.paymentIntent.id,
        status: "succeeded",
        name: cardState.name,
        email: result.paymentIntent.receipt_email
      })
      let ret = await stripeApi.paymentSuccess(result.paymentIntent.id, result.paymentIntent.amount, result.paymentIntent.currency, cardState.name)
    }
    else if (result && result.error) {
      // showAlert({ message: `Payment Error - ${result.error.message}`, open: true, severity: "error" })
      openDialog({ variant: "info", title: "Payment Error", description: result.error.message })
    }

  }

  return (
    <form onSubmit={handleSubmit}>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StripeTextFieldNumber
              error={Boolean(cardState.cardNumberError)}
              helperText={cardState.cardNumberError}
              onChange={onElementChange(
                'creditCardNumberComplete',
                'cardNumberError'
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <StripeTextFieldExpiry
              error={Boolean(cardState.expiredError)}
              helperText={cardState.expiredError}
              onChange={onElementChange(
                'expirationDateComplete',
                'expiredError'
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <StripeTextFieldCVC
              error={Boolean(cardState.cvcError)}
              helperText={cardState.cvcError}
              onChange={onElementChange(
                'cvcComplete',
                'cvcError'
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Name on card"
              fullWidth
              InputProps={{
                style: {
                  fontSize: "14px"
                }
              }}
              error={Boolean(cardState.nameError)}
              helperText={cardState.nameError}
              onChange={event => {
                let name = event.target.value
                setCardState({
                  ...cardState,
                  name: name,
                  nameError: name.length > 5 ? "" : cardState.nameError,
                  formComplete: cardState.creditCardNumberComplete && cardState.cvcComplete && cardState.expirationDateComplete && name.length > 5
                })
              }}
            />
          </Grid>
        </Grid>
        <Button variant='contained' color='primary' onClick={handleSubmit} type='submit' style={{ marginTop: '2rem', width: 'max-content' }} disabled={cardState.buttonDisabled}>Proceed with payment</Button>
      </Box>
    </form>
  )
}

function Stripe(props) {
  const [stripe, setStripe] = React.useState(null);
  const stripePromise = loadStripe(props.stripeKey)

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [{ cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap" }]
      }}
    >
      {props.children}
    </Elements>
  );
}

const cmap = {
  "usd": "$",
  "gbp": "£",
  "eur": "€",
  "inr": "₹"
}

export const Contribute = () => {
  const [location, setLocation] = React.useState({ countryCode: window.countryCode, ready: window.countryCode ? true : false })
  const isLoggedIn = useStoreState(state => state.auth.isLoggedIn)
  const showAlert = useStoreActions(actions => actions.alert.showAlert)
  const [psettings, setPsettings] = React.useState({ ready: false })
  const classes = GetStyles();

  React.useEffect(() => {
    const f = () => setLocation({ countryCode: window.countryCode, ready: true })
    const ps = async () => {
      let [err, response] = await stripeApi.getSettings()
      setPsettings({ ...response, ready: true })
    }
    ps().then()
    // Wait for 1 sec, for countryCode to appear
    if (location.ready === false)
      setTimeout(f, 1000)
  }, []) // run only once

  React.useEffect(() => {
    if (!isLoggedIn) {
      showAlert({ message: `Please Register/Sign In to continue`, open: true, severity: "info" })
      navigate("/register")
    }
  }, []) // run only once

  return (
    <div className={classes.contributeWrapper}>
      {
        location.ready && psettings.ready
          ? <ContributeInner {...psettings} />
          : null
      }
    </div>
  );
}

export const ContributeInner = (props) => {
  const isEU = (cc) => {
    // Obtained from https://www.europeancuisines.com/Europe-European-Two-Letter-Country-Code-Abbreviations
    euCodes = ["AL", "AD", "AM", "AT", "BY", "BE", "BA", "BG", "CH", "CY", "CZ", "DE", "DK", "EE", "ES", "FO",
      "FI", "FR", "GE", "GI", "GR", "HU", "HR", "IE", "IS", "IT", "LT", "LU", "LV", "MC", "MK", "MT", "NO", "NL",
      "PO", "PT", "RO", "RU", "SE", "SI", "SK", "SM", "TR", "UA", "VA"]
    return euCodes.includes(cc) ? true : false
  }
  let curr = 'usd'
  const pusd = apiHelper.safeJsonParse(props.USD_PAYMENT_CHOICES) || ["5", "10", "15", "20"]
  const pinr = apiHelper.safeJsonParse(props.INR_PAYMENT_CHOICES) || ["1000", "2000", "3000", "5000"]
  const min_usd = (props.MIN_USD_VALUE) || 3
  const min_inr = (props.MIN_INR_VALUE) || 500
  if (window.countryCode) {
    if (window.countryCode === "IN") curr = "inr"
    else if (window.countryCode === "GB") curr = "gbp"
    else if (isEU(window.countryCode)) curr = "eur"
    else curr = "usd"
  }
  const [paymentStatus, setPaymentStatus] = React.useState({ status: "" })
  const [currency, setCurrency] = React.useState(curr)
  const [amount, setAmount] = React.useState(curr === "inr" ? pinr[0] : pusd[0])
  const [custom, setCustom] = React.useState('');
  const [frequency, setFrequency] = React.useState('once');
  const [customError, setCustomError] = React.useState('')
  const classes = GetStyles();
  const helperTextClasses = helperTextStyles()

  const currencies = [
    <ToggleButton key={0} value='usd'>USD ($)</ToggleButton>,
    <ToggleButton key={1} value='gbp'>GBP (£)</ToggleButton>,
    <ToggleButton key={2} value='eur'>Euro (€)</ToggleButton>,
    <ToggleButton key={3} value='inr'>INR (₹)</ToggleButton>,
  ];

  var amounts;
  if (currency !== "inr") {
    amounts = [
      <ToggleButton key={0} value={pusd[0]}>{cmap[currency]}{pusd[0]}</ToggleButton>,
      <ToggleButton key={1} value={pusd[1]}>{cmap[currency]}{pusd[1]}</ToggleButton>,
      <ToggleButton key={2} value={pusd[2]}>{cmap[currency]}{pusd[2]}</ToggleButton>,
      <ToggleButton key={3} value={pusd[3]}>{cmap[currency]}{pusd[3]}</ToggleButton>,
      <ToggleButton key={4} value='custom'>Custom</ToggleButton>,
    ];
  }
  else {
    amounts = [
      <ToggleButton key={0} value={pinr[0]}>{cmap[currency]}{pinr[0]}</ToggleButton>,
      <ToggleButton key={1} value={pinr[1]}>{cmap[currency]}{pinr[1]}</ToggleButton>,
      <ToggleButton key={2} value={pinr[2]}>{cmap[currency]}{pinr[2]}</ToggleButton>,
      <ToggleButton key={3} value={pinr[3]}>{cmap[currency]}{pinr[3]}</ToggleButton>,
      <ToggleButton key={4} value='custom'>Custom</ToggleButton>,
    ];
  }

  if (paymentStatus.status === "succeeded") {
    return (
      <section className={classes.container}>
        <Box className={classes.subDiv} mr={4}>
          <Typography variant='h1' component='div' style={{ fontSize: '30px' }}>
            Your help keeps us going!
          </Typography>
          <Typography variant='body1' component='div' style={{ margin: '2rem 0' }}>
            We appreciate your support to keep this platform free for all who wish to share mindfully to help communities locally and globally.
            <br />
            Please <a href="mailto:feedback@kindeeds.com">contact us</a> if you have any questions. Thank you!
          </Typography>
          <img src='assets/images/contribute.svg' width='100%' height='382px'></img>
        </Box>
        <Box className={classes.subDiv} ml={4}>
          <Typography variant='h1' component='div' className={classes.contributeTo} style={{ fontSize: '30px' }}>
            Thank you for your contribution!
          </Typography>
          <Typography variant='body1' component='div' style={{ margin: '2rem 0' }}>
            {/* Your donation contribution has been successful. We have received
            <Chip
              size="small"
              label={paymentStatus.currency.toUpperCase() + " " + (paymentStatus.amount / 100)}
              avatar={<Avatar>{cmap[paymentStatus.currency]}</Avatar>}
              color="secondary"
            />
              with a payment id
            <Chip
              size="small"
              label={paymentStatus.paymentId}
            /> */}

            <Typography variant='body1' component='div' style={{ margin: '2rem 0' }}>
              Your generosity in helping communities worldwide is appreciated.
              {/* <i>Note: Your contribution amount includes payment processing feeds that Stripe charges</i> */}
            </Typography>

            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell><Typography variant='subtitle1' style={{ fontSize: '12px' }} component='div'>Amount</Typography></TableCell>
                  <TableCell><Typography variant='subtitle1' style={{ fontSize: '12px' }} component='div'>{paymentStatus.currency.toUpperCase()} {paymentStatus.amount / 100}</Typography></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><Typography variant='subtitle1' style={{ fontSize: '12px' }} component='div'>Payment Id</Typography></TableCell>
                  <TableCell><Typography variant='subtitle1' style={{ fontSize: '12px' }} component='div'>{paymentStatus.paymentId}</Typography></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><Typography variant='subtitle1' style={{ fontSize: '12px' }} component='div'>Receipt Email</Typography></TableCell>
                  <TableCell><Typography variant='subtitle1' style={{ fontSize: '12px' }} component='div'>{paymentStatus.email}</Typography></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><Typography variant='subtitle1' style={{ fontSize: '12px' }} component='div'>Name as in Card</Typography></TableCell>
                  <TableCell><Typography variant='subtitle1' style={{ fontSize: '12px' }} component='div'>{paymentStatus.name}</Typography></TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Typography variant='body1' component='div' style={{ margin: '2rem 0' }}>
              You will receive a receipt from us with the above transaction details. If you don't receive it, please let us
              know at <a href="mailto:feedback@kindeeds.com">feedback@kindeeds.com</a>.
            </Typography>

          </Typography>
        </Box>
      </section>
    )
  }

  return (
    <section className={classes.container}>
      <Box className={classes.subDiv} mr={4}>
        <Typography variant='h1' component='div' style={{ fontSize: '30px' }}>
          Your help keeps us going!
        </Typography>
        <Typography variant='body1' component='div' style={{ margin: '2rem 0' }}>
          We appreciate your support to keep this platform free for all who wish to share mindfully to help communities locally and globally.
          <br />
          <br />
          Please <a href="mailto:feedback@kindeeds.com">contact us</a> if you have any questions. Thank you!
        </Typography>
        <img src='assets/images/contribute.svg' width='100%' height='382px'></img>
      </Box>
      <Box className={classes.subDiv} ml={4} pt={4}>
        <Typography variant='h5' component='div' style={{ fontWeight: '600', margin: '2rem 0 0.5rem 0' }}>
          Choose the currency and amount
        </Typography>

        <ToggleButtonGroup className={classes.toggleBtnGroup} size="small" value={currency} style={{ margin: '0 0 2rem 0' }}
          exclusive onChange={(e, c) => {
            if (c === null) return
            setCurrency(c)
            setAmount(c === "inr" ? pinr[0] : pusd[0])
          }}>
          {currencies}
        </ToggleButtonGroup>

        <ToggleButtonGroup className={classes.toggleBtnGroup} size="small" value={amount} exclusive
          onChange={(e, a) => {
            if (a === null) return
            setAmount(a)
            setCustomError(`Please consider a minimum of ${cmap[currency]}${currency === "inr" ? min_inr : min_usd}.`)
            setCustom('')
          }}>
          {amounts}
        </ToggleButtonGroup>

        <TextField style={{ marginTop: '1rem' }} type="text" value={custom} exclusive label='Custom amount'
          InputProps={{
            // inputProps: { min: 0.1, max: 100000 },
            startAdornment: <InputAdornment position="start">{cmap[currency]}</InputAdornment>,
          }}
          inputProps={{ pattern: "[0-9]" }}
          fullWidth
          error={Boolean(customError.length)}
          helperText={customError}
          FormHelperTextProps={{ classes: helperTextClasses }}
          style={{ display: amount === "custom" ? "block" : "none", fontFamily: "Open Sans", marginTop: '1rem' }}
          onChange={e => e.target.value >= 1 && setCustom(e.target.value)}
        />

        <Typography variant='h5' component='div' style={{ fontWeight: '600', margin: '2rem 0 0.5rem 0' }}>
          Frequency
        </Typography>

        <ToggleButtonGroup className={classes.toggleBtnGroup} size="small" value={frequency} exclusive
          onChange={(e, f) => f && setFrequency(f)}>
          <ToggleButton key={0} value='once'>One time</ToggleButton>,
          {/* <ToggleButton key={1} value='monthly'>Monthly</ToggleButton> */}
        </ToggleButtonGroup>

        <Container style={{ padding: 0 }}>
          <Box ml={-2} my={4} padding='1rem !important'>
            <Stripe stripeKey={props.STRIPE_PUBLISHABLE_KEY}>
              <CheckoutForm amount={amount === "custom" ? custom : amount} currency={currency} frequency={frequency} setPaymentStatus={setPaymentStatus} />
              {/* <img src='assets/images/powered_by_stripe.png' style={{ margin: '1rem 0 0 0', float: "right" }}></img> */}
              <img src='assets/images/powered_by_stripe1.png' style={{ margin: '1rem 0 0 0', float: "right" }}></img>
            </Stripe>
          </Box>
        </Container>
      </Box>
    </section>
  );
}

export const ContributeList = () => {
}
