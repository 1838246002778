import React, { Component, useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import CustomStyles from './../../../styles/CustomStyles.styles';
import tabContainerStyles from './../../../styles/tabContainer.styles';
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Card
} from '@material-ui/core';
import { CommonLeftBlock } from './commonLeftBlock';

const setpassword = makeStyles(theme => ({
  root: {
    marginBottom: '-1.5rem',
  },
  card: {
    boxSizing: 'border-box',
    padding: '15px',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    backgroundColor: "transparent",
    boxShadow: "none !important",
    minHeight: '900px',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: "#ffffff",
      minHeight: 'unset',
      height: 'unset'
    },
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '460px',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem'
    }
  },
  title1: {
    color: '#505050',
    fontSize: '30px',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    }
  },
}));




const SetPassword = () => {
  const classes = setpassword();
  const classesTabContainer = tabContainerStyles();
  //const classes = CustomStyles();

  return (
    <div className={classesTabContainer.root}>
      <div className={classesTabContainer.tabContainer}></div>
      <React.Fragment>
        <Grid className={classes.root} container>
          <CommonLeftBlock></CommonLeftBlock>

          <Grid item sm={12} xs={12} md={6} style={{ backgroundColor: 'rgba(73,73,73,7%)' }}>
            <Card className={classes.card} style={{ width: '100%' }}>
              <section className={classes.loginContainer}>
                <Typography component='div' className={classes.title1} >Reset Password</Typography><br />
                <Typography component='div'>Reset Password link has been sent to your registered email address.</Typography>
              </section>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
}

export { SetPassword };