import React from 'react';
import { navigate } from '@reach/router';
import { Helmet } from "react-helmet";
//import  useInterval  from './../../hooks/useInterval';
import { Hidden, Typography, Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles (theme =>({
  root: {
    minHeight: '500px',
  },
  header: {
    display: 'none',
  },

  headerActions: {
    display: 'none',
  },
  headerActions112: {
    display: 'none',
  },
  text: {
    fontSize:"27px", color: '#383838', fontWeight: '600', marginTop: '30px', lineHeight: '50px',
    [theme.breakpoints.down("sm")]: {
      fontSize:"21px", marginTop: '30px', lineHeight: '28px',
    },  
  },
  topBanner: {
    [theme.breakpoints.down("sm")]: {
      width: '100%'
    },
  },
  MuiAppBar: {
    display: 'none !important'
  },
  overrides: {
    root: {
      MuiAppBar: {
        display: 'none !important'
      },
    },
    
  },
 

}));

function Downtime()  {
 
  const classes = useStyles();

  return (

    <div className={classes.root}>
        {/* <Helmet title="Kindeeds - Downtime" /> */}
        <Helmet>
          <title>Downtime</title>
        </Helmet>

        <div style={{ margin: "auto", width: "100%", textAlign: "center", fontWeight: "bold", fontSize:"15px"}}>
            <img src="/assets/images/Maintenance.svg" className={classes.topBanner} />
            <div className={classes.text} style={{}}>Sorry, we are out for a coffee.
            <br/>Be Right Back!</div>
        </div>

        {/* <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>404 Not Found</Typography>
            </Grid>
        </Grid> */}
        {/*<Typography variant="subtitle2" gutterBottom>Returning home in {count} ... </Typography> */}
    </div>
  )
};


export default Downtime
// export { Downtime };
