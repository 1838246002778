import React from 'react';
export const NotificationContext = React.createContext();

export const NotificationContextProvider = (props) => {
  const [unreadNotificationCount, setUnreadNotificationCount] =
    React.useState(0);
  return (
    <NotificationContext.Provider
      value={{ unreadNotificationCount, setUnreadNotificationCount }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};
