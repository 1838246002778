import {apiHelper} from '../helpers';
import {httpMethodTypes} from "../constants/httpMethodTypes";
import {appSettings} from "../constants/appSettings";

import axios from 'axios'

export const imageApi = {
    imageUpload,
    imageDelete,
    profileImageUpload

};
const BASE_URL =  appSettings.BaseURL;

function imageUpload(requestDataBlk, successCallBack, errorCallBack)
{
    const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.POST, true, requestDataBlk)
    delete requestOptions.headers["Content-Type"];
    delete  requestOptions.headers["Accept"];
    var data = new FormData();
    data.append("docfile", requestDataBlk);
    requestOptions.body = data;
    const url = BASE_URL + 'api/core/media/upload/';
    apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
}

function imageDelete(fileName, successCallBack, errorCallBack)
{
    const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.DELETE, true)
    const url = BASE_URL + 'api/core/media/delete/?file_path='+fileName;
    apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
}

function profileImageUpload(file, successCallBack)
{
    const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.POST, true)
    delete requestOptions.headers["Content-Type"];
    delete  requestOptions.headers["Accept"];
    var data = new FormData();
    data.append("docfile", file);
    requestOptions.body = data;
    const url = BASE_URL + 'api/user/account/avatar/upload/';
    apiHelper.sendRequest(url, requestOptions, successCallBack);
}