import { useState, useEffect } from 'react';
import { throttle } from 'lodash';

const defaultOptions = {
  throttle: 100,
};

let supportsPassive = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    get: () => {
      supportsPassive = true;
      return supportsPassive;
    },
  });
  window.addEventListener('testPassive', null, opts);
  window.removeEventListener('testPassive', null, opts);
} catch (e) {}

const getPosition = () => ({
  x: window.pageXOffset,
  y: window.pageYOffset,
});

const useWindowScrollPosition = options => {
  const opts = Object.assign({}, defaultOptions, options);

  const [position, setPosition] = useState(getPosition());

  useEffect(() => {
    const handleScroll = throttle(() => {
      setPosition(getPosition());
    }, opts.throttle);

    window.addEventListener(
      'scroll',
      handleScroll,
      supportsPassive ? { passive: true } : false
    );

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [opts.throttle]);

  return position;
};

export { useWindowScrollPosition };
