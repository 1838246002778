import React, { useEffect, useState } from 'react';
import { AntTab, AntTabs } from './AntTabs.js';
import Typography from "@material-ui/core/Typography/Typography";
import AccountSettingsStyles from '../../styles/AccountSettings.styles';
import Box from '@material-ui/core/Box';
import PropTypes from "prop-types";
import PersonalInfo from "./my/PersonalInfo";
import AccountInfo from "./my/AccountInfo";
import Preferences from "./my/Preferences";
import { makeStyles } from '@material-ui/core';
import { HorizontalAds } from './ads/HorizontalAds.js';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            style={{ border: 'none', minHeight: '400px' }}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#fff',

        [theme.breakpoints.down('sm')]: {
            marginTop: '75px',

            '& .MuiAppBar-root': {
                display: 'none'
            }
        }
    },
    tabContainer: {
        padding: '0',
        margin: '0 auto',
        maxWidth: '1200px',

        [theme.breakpoints.only("md")]: {
            maxWidth: "960px"
        },

        '& .MuiBox-root': {
            padding: 0
        },

        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset'
        }
    },

}));

export const UserProfile = () => {
    const [value, setValue] = React.useState(0),
        classes = AccountSettingsStyles(),
        classesLocal = useStyles(),
        handleChange = (event, newValue) => {
            setValue(newValue);
        };

    return (
        <div className={classesLocal.root}>
            <div className={classesLocal.tabContainer}></div>
            <Box display='flex'>
                <div className={classes.container}>
                    <AntTabs value={value} onChange={handleChange} variant="scrollable" scrollButtons='on' aria-label="ant example">
                        <AntTab label="About me" />
                        <AntTab label="My Preferences" />
                        <AntTab label="Account" />
                    </AntTabs>
                    <TabPanel value={value} index={0}>
                        <PersonalInfo />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Preferences />
                    </TabPanel>
                    {/*<TabPanel value={value} index={2}>
                    <Box className={classes.communications}>
                        <Box display='flex' alignItems='center'>
                            <Typography variant='subtitle2' component='div'>
                                Receive SMS Notification?</Typography>
                            <RadioGroup aria-label="days" name="days" value={value2} onChange={handleRadio1Change}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end" />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="end" />
                            </RadioGroup>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <Typography variant='subtitle2' component='div'>
                                Receive email from platform?</Typography>
                            <RadioGroup aria-label="days" name="days" value={value2} onChange={handleRadio1Change}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end" />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="end" />
                            </RadioGroup>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <Typography variant='subtitle2' component='div'>
                                Received promotions from platform? </Typography>
                            <RadioGroup aria-label="days" name="days" value={value2} onChange={handleRadio1Change}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end" />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="end" />
                            </RadioGroup>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <Typography variant='subtitle2' component='div'>
                                Received promotions from platform? </Typography>
                            <RadioGroup aria-label="days" name="days" value={value2} onChange={handleRadio1Change}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end" />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="end" />
                            </RadioGroup>
                        </Box>
                        <Box className={classes.buttonContainer}>
                            <Button variant='contained' color='primary'>Update</Button>
                            <Button variant='outlined' color='secondary' style={{ marginRight: '1rem' }}>Cancel</Button>
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Box className={classes.communications}>
                        <Box display='flex' alignItems='center'>
                            <Typography variant='subtitle2' component='div'>
                                Activity Snapshot is visible </Typography>
                            <RadioGroup aria-label="days" name="days" value={value2} onChange={handleRadio1Change}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end" />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="end" />
                            </RadioGroup>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <Typography variant='subtitle2' component='div'>
                                Make my Profile Public </Typography>
                            <RadioGroup aria-label="days" name="days" value={value2} onChange={handleRadio1Change}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end" />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="end" />
                            </RadioGroup>
                        </Box>
                        <Box mt={4}>
                            <Typography variant='subtitle1' component='div'>Read our <a href=''>Privacy policy</a> here.</Typography>
                        </Box>
                        <Box className={classes.buttonContainer}>
                            <Button variant='contained' color='primary'>Update</Button>
                            <Button variant='outlined' color='secondary' style={{ marginRight: '1rem' }}>Cancel</Button>
                        </Box>
                    </Box>
                </TabPanel>*/}
                    <TabPanel value={value} index={2}>
                        <AccountInfo />
                    </TabPanel>
                </div>
                {/* <section className={classes.advertisement} style={{ marginTop: '1rem' }}><Box width='100%' height='100%'> <VerticalAds></VerticalAds> </Box> </section> */}
            </Box>
            <Box width='100%'> <HorizontalAds></HorizontalAds> </Box>
        </div>
    );
}
