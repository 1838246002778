import React, { useState } from 'react';
import { navigate } from '@reach/router';
import {Helmet} from "react-helmet";
import Grid from '@material-ui/core/Grid'; 
//import  useInterval  from './../../hooks/useInterval';

import 'typeface-roboto';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
  },
});

export default function NotFound403  (RouteComponentProps)  {
  const [count, setCount] = useState(5);

  // useInterval(
  //   () => {
  //     if (count <= 0) {
  //       navigate('/');
  //       return;
  //     }
  //     setCount(count - 1);
  //   },
  //   count >= 0 ? 1000 : null
  // );

  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Helmet title="Not Found" />

        <div style={{ margin: "auto", width: "100%", textAlign: "center", top: "20%", position: "absolute", fontWeight: "bold", fontSize:"15px"}}>
            <span style={{fontSize:"200px",color:"#bf5959"}}>403</span>
            <br/> <span style={{fontSize:"25px",}}>ACCESS FORBIDDEN</span>
            <br /><br /> Go back to <a href="/">Home</a> Page
        </div>

        {/* <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>404 Not Found</Typography>
            </Grid>
        </Grid> */}
                {/* <Typography variant="subtitle2" gutterBottom>Returning home in {count} ... </Typography> */}
    </div>
  )
};

export { NotFound403 };
