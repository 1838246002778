import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { makeStyles } from "@material-ui/core";
import { userApi } from '../../api';
import { utilHelper } from './../../helpers/utilHelper';
import ReadMoreAndLess from 'react-read-more-less';
import MyGivesStyles from '../../styles/MyGives.styles';

const acceptBtnStyle = makeStyles(theme => ({
    btn: {
        border: "1px solid #717171",
        margin: '0px 5px',
        color: '#717171',
        '&:hover': {
            backgroundColor: '#22B11F',
            color: '#ffffff',
            borderColor: '#22B11F',
        }
    }
}));

const RequestListItem = (props) => {
    const localClasses = MyGivesStyles(),
        userObject = {
            name: null,
            user_avatar: null,
            city: null,
            user_rating: 0
        },
        [giveUser, setUser] = useState(userObject),
        getButtonText = (status) => {
            switch (status) {
                case 'requested':
                    return !props.acceptedResponse ? 'Accept' : (props.canDecline ? 'Decline' : 'In queue');
                case 'rejected':
                    return 'Declined';
                case 'not given':
                    return 'Not given';
                case 'confirmed':
                    return 'Confirmed';
                case 'completed':
                    return 'Given';
            }
        };

    const acceptBtnClasses = acceptBtnStyle();
    useEffect(() => {
        let userHash = props.item.r_user_hash
        userApi.getUserSummaryByHash(userHash, function (response) {
            if (response) {
                let receiverData = { ...giveUser }
                receiverData.name = response[0].display_name
                receiverData.city = response[0].u_city
                receiverData.user_avatar = response[0].user_avatar
                receiverData.user_rating = response[0].user_rating;
                setUser(receiverData)
            }
        });
    }, []);

    const handleClicked = (req_id, uid, u_name) => {
        props.setUser_Id(uid)
        props.setRequest_Id(req_id)
        props.setReqName(u_name)
        props.handleClick(req_id, uid, u_name);
        return ('')
    }

    return (
        <>
            <Grid container className={localClasses.requestContainer}>
                <Grid item sm={2} xs={12}>

                    {giveUser.user_avatar && giveUser.user_avatar !== 'null' ?
                        <Avatar style={{ height: '60px', width: '60px' }} src={giveUser.user_avatar && giveUser.user_avatar} />
                        :
                        <Avatar style={{ height: '60px', width: '60px' }}><PersonOutlineIcon /></Avatar>
                    }
                </Grid>
                <Grid item sm={7} xs={12} px={1}>

                    <Typography variant='h3' component='div'>{giveUser.name && utilHelper.toCamelCase(giveUser.name)}</Typography>
                    <Box display='flex' my={1}>
                        <Typography variant='subtitle2' component='div'>For {props.item.for_whom}</Typography>
                        <Typography variant='subtitle1' style={{ alignSelf: 'center', marginLeft: '10px' }} component='div'>{props.item.requested_date} | {giveUser.city && giveUser.city}</Typography>
                    </Box>
                    <Typography variant='subtitle2' component='div'>
                        <ReadMoreAndLess
                            charLimit={140}
                            // className="read-more-content"
                            readMoreText=" View More"
                            readLessText=" View Less"> {props.item.message}</ReadMoreAndLess>
                    </Typography>

                </Grid>
                <Grid item sm={2} xs={12}>
                    {props.item.b_status === 'accepted' ? <Button size="small" variant='outlined' disabled={true} className={acceptBtnClasses.btn}>In play</Button> :
                        <Button variant='outlined' size="small" onClick={() => handleClicked(props.request_id, props.user_id, giveUser.name)}
                            disabled={props.item.r_status !== "requested" || props.acceptedResponse} className={acceptBtnClasses.btn}>
                            {getButtonText(props.item.r_status)}</Button>}
                </Grid>
            </Grid>

            {/* 
        <Grid item xs={2}>
            {giveUser.user_avatar && giveUser.user_avatar !== 'null' ?
                <Avatar style={{ height: '60px', width: '60px' }} src={giveUser.user_avatar && giveUser.user_avatar} />
            :
                <Avatar style={{ height: '60px', width: '60px' }}><PersonOutlineIcon /></Avatar>
            }

        </Grid>
        <Grid item xs={7}>
            <section style={{ display: 'flex' }}>
                <Typography variant='h6' component='div'>{giveUser.name && utilHelper.toCamelCase(giveUser.name)}</Typography>
                <Typography variant='subtitle1' style={{ alignSelf: 'center', marginLeft: '10px' }} component='div'>{props.item.requested_date} | {giveUser.city && giveUser.city}</Typography>
            </section>
            <Typography variant='subtitle1' component='div'>For {props.item.for_whom}</Typography>
            <Typography variant='subtitle2' component='div'>
                <ReadMoreAndLess
                    charLimit={140}
                    // className="read-more-content"
                    readMoreText=" Show More"
                    readLessText=" Show Less"
                >
                    {props.item.message}
                </ReadMoreAndLess>
            </Typography>
        </Grid>
        <Grid xs={3}>
            <Button variant='outlined' color='primary' style={{ float: 'right', marginTop: '10px' }} onClick={() => handleClicked(props.request_id,props.user_id,giveUser.name)} disabled={props.item.r_status!=="requested" ? true : false } >Accept</Button>
        </Grid> */}
        </>
    );
}
export { RequestListItem };