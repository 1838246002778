import React from 'react';
import { fade } from '@material-ui/core/styles';
import theme from '../../styles/theme'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import TextField from '@material-ui/core/TextField';

export default function StripeInput(props) {
  const {
    component: Component,
    inputRef,
    'aria-invalid': ariaInvalid,
    'aria-describedby': ariaDescribeBy,
    defaultValue,
    required,
    onKeyDown,
    onKeyUp,
    readOnly,
    autoComplete,
    autoFocus,
    type,
    name,
    rows,
    ...other
  } = props;

  const [mountNode, setMountNode] = React.useState(null);

  React.useImperativeHandle(
    inputRef,
    () => ({
      focus: () => mountNode.focus()
    }),
    [mountNode]
  );

  return (
    <Component
      onReady={setMountNode}
      options={{
        style: {
          base: {
            color: theme.palette.text.primary,
            fontSize: `${theme.typography.fontSize}px`,
            fontFamily: theme.typography.fontFamily,
            '::placeholder': {
              color: fade(theme.palette.text.primary, 0.42)
            }
          },
          invalid: {
            color: theme.palette.text.primary
          }
        }
      }}
      {...other}
    />
  );
}

function StripeTextField(props) {
  const { InputLabelProps, stripeElement, InputProps, ...other } = props;

  return (
    <TextField
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          component: stripeElement,
        },
        inputComponent: StripeInput
      }}
      {...other}
    />
  );
}

export function StripeTextFieldNumber(props) {
  return (
    <StripeTextField
      label='Credit Card Number'
      stripeElement={CardNumberElement}
      {...props}
    />
  );
}

export function StripeTextFieldExpiry(props) {
  return (
    <StripeTextField
      label='Expires'
      stripeElement={CardExpiryElement}
      {...props}
    />
  );
}

export function StripeTextFieldCVC(props) {
  return (
    <StripeTextField
      label='CVC'
      stripeElement={CardCvcElement}
      {...props}
    />
  );
}
