import React from 'react';

import { RouteProvider } from './components/providers/RouteProvider';
import AppLoader from './components/screens/AppLoader';
import { StoreProvider } from 'easy-peasy';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

import { ConfirmationServiceProvider } from './ConfirmationService';
import { NotificationContextProvider } from './hooks/notificationContext';

export const UserContext = React.createContext();
export const SecondContext = React.createContext();

export default function App() {
  return (
    // <Container maxWidth='md'>
    //   <ConfirmationServiceProvider>
    //     <UserContext.Provider value={'Kalmesh'}>
    //       <SecondContext.Provider value="Second Contect data">
    //         <RouteProvider />
    //       </SecondContext.Provider>
    //     </UserContext.Provider>
    //   </ConfirmationServiceProvider>
    // </Container>
    <StoreProvider store={store}>
      {/* <Provider store={store}> */}
      <PersistGate loading={<AppLoader />} persistor={persistor}>
        <ConfirmationServiceProvider>
          <NotificationContextProvider>
            <RouteProvider />
          </NotificationContextProvider>
        </ConfirmationServiceProvider>
      </PersistGate>
      {/* </Provider> */}
    </StoreProvider>
  );
}
