import React from 'react';
import { navigate } from '@reach/router';
import { useStoreActions, useStoreState } from './../../../hooks';
import { makeStyles } from '@material-ui/core';
import { userApi } from "../../../api";
import LoadingIcon from "../../ui/loadingIcon";

const LoginStyles = makeStyles(theme => ({
    loginContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '1rem'
    }
}));
const VerifyEmail = (props) => {
    const classes = LoginStyles(),
        updateVerifyEmailSent = useStoreActions((actions) => actions.auth.updateVerifyEmailSent),
        isLoggedIn = useStoreState(state => state.auth.isLoggedIn);

    React.useEffect(() => {
        userApi.verifyEmail(props.token, handleSuccessCall, handleErrorCall);
    }, []);

    const handleSuccessCall = () => {
        updateVerifyEmailSent({ isVerifyEmailSent: true });

        if (isLoggedIn) {
            navigate("/dashboard", { state: { verifyEmailData: { isError: false, errorCode: undefined } }, replace: true });
        } else {
            navigate('/login', { state: { verifyEmailData: { isError: false, errorCode: undefined } }, replace: true });
        }
    },
        handleErrorCall = (resp) => {
            updateVerifyEmailSent({ isVerifyEmailSent: true });

            if (isLoggedIn) {
                navigate("/dashboard", { state: { verifyEmailData: { isError: true, errorCode: resp.response.status } }, replace: true });
            } else {
                navigate("/login", { state: { verifyEmailData: { isError: true, errorCode: resp.response.status }, replace: true } });
            }
        };

    return (
        <section className={classes.loginContainer} style={{ margin: "0 auto" }}>
            <LoadingIcon loadingMsg="verifying ..." />
        </section>
    );
}

export { VerifyEmail };