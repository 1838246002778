import React from 'react';
import { useStoreActions, useStoreState } from './../../hooks';
import { ItemImagesContainer } from '../../components/ui/ItemImagesContainer';
import { ItemDetails } from '../../components/ui/ItemDetails';
import { GoodReadDetails } from '../../components/ui/GoodReadDetails';
import { appSettings } from './../../constants/appSettings';
import ReactPhoneInput from 'react-phone-input-mui';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import throttle from 'lodash/throttle';
import {
  CircularProgress, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, withStyles,
  Button, Box, Grid, Typography, Chip, TextField, FormControlLabel, Checkbox, Select, InputLabel, MenuItem, FormControl, FormGroup, Collapse, Table, TableBody, TableRow, TableCell, FormHelperText, Dialog, makeStyles, IconButton
} from '@material-ui/core';
import { itemApi, genericApi, userApi } from '../../api';
import LoadingIcon from '../ui/loadingIcon';
import { utilHelper } from './../../helpers/utilHelper';
import { AntSwitch } from './../ui/AntSwitch';
import { RequestListItem } from './RequestListItem';
import { Alert, Autocomplete, Rating } from '@material-ui/lab';
import { LargeRectangleAds } from '../ui/ads/LargeRectangleAds';
import { navigate } from '@reach/router';
import MyRequestStyles from './../../styles/MyRequest.styles';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { FormikTextField } from '../ui/FormikTextField';
import { FormikSearchTextField } from '../ui/FormikSearchTextField';
import { getCountryCode, getPostCodeFormat, getPhoneNumber } from '../../utils/countrylist.js';
import { GetStylesLocal, HtmlTooltip } from './../../styles/addressCommon.styles';
import { getCountryCallingCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import MyGivesStyles from "./../../styles/MyGives.styles.js";

const BASE_URL = appSettings.BaseURL;

const useStylesOpacity = makeStyles((theme) => ({
  opacity: {
    margin: "1rem auto",
    opacity: "0.1",
  },
  noOpacity: {
    margin: "1rem auto",
  },

  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  setSection: {
    padding: "4px",
    width: "77px",
    backgroundColor: theme.palette.primary.secondary,
    borderRadius: "0 0px 20px 0px",
    margin: "5px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  prevNextContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px",
    visibility: "hidden",
    zIndex: 2,

    '& svg': {
      mixBlendMode: 'difference'
    }
  },
  helperTextRight: {
    display: 'flex',
    alignSelf: 'flex-end'
  }
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


const MyRequest = (props) => {
  const classes = MyRequestStyles(),
    localClasses = MyGivesStyles(),
    getClasees = GetStylesLocal(),
    [displayIndex, setDisplayIndex] = React.useState(0),
    [shareUrl, setShareUrl] = React.useState(''),
    isLoggedIn = useStoreState((state) => state.auth.isLoggedIn),
    [isSaveToProfile, setSaveToProfile] = React.useState(false),
    [item, setItem] = React.useState(null),
    [Request_msg, setRequestMsg] = React.useState(''),
    [itemDetails, setItemDetails] = React.useState([]),
    user = useStoreState((state) => state.auth.user),
    updateUser = useStoreActions((actions) => actions.auth.updateUser),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    [loadingCity, setLoadingCity] = React.useState(false),
    [inputValue, setInputValue] = React.useState(''),
    [options, setOptions] = React.useState([]),
    [RequestList, setRequestList] = React.useState([]),
    [extraInfo, setExtraInfo] = React.useState([]),
    [isDeliveryPage, setDeliveryPage] = React.useState(props.location.search === '?isDeliveryPage=true'),
    [isEditable, setEditable] = React.useState(false),
    [isRequestId, setRequestId] = React.useState(),
    [isUserId, setUserId] = React.useState(),
    [requestUserName, setRequestUserName] = React.useState(''),
    [request_id, setRequest_Id] = React.useState(),
    [user_id, setUser_Id] = React.useState(),
    [reqName, setReqName] = React.useState(''),
    [loading, setLoading] = React.useState(false),
    [loadingExtraInfo, setLoadingExtraInfo] = React.useState(false),
    [isPhoneUpdated, setPhoneUpdated] = React.useState(false),
    [requestLoading, setRequestLoading] = React.useState(false),
    [waitingImg, setWaitingImg] = React.useState(false),
    emptyUser = {
      fullName: '',
      displayName: '',
      city: '',
      area: '',
      userAvatar: '',
      phone: '',
      email: null,
    },
    [userDetails, setUserDetails] = React.useState(emptyUser),
    [state, setState] = React.useState({
      checkedA: false,
      checkedB: false,
    }),
    [checkState, setCheckState] = React.useState({
      checkA: false,
      checkB: false,
      checkC: false,
      checkD: false,
    }),
    emptyPickupLoc1 = {
      name: '',
      street: '',
      city: '',
      state: '',
      country: '',
      zipcode: ''
    },
    [pickUpLoc1, setPickUpLoc1] = React.useState(emptyPickupLoc1),
    [phone, setPhone] = React.useState(user?.mobile),
    [from_time1, setFromTime1] = React.useState(-1),
    [to_time1, setToTime1] = React.useState(-1),
    [canDecline, setCanDecline] = React.useState(false),
    [bundleSubmitted, setBundleSubmitted] = React.useState(false),
    [acceptedResponse, setAcceptedResponse] = React.useState(''),
    [isSubmitted, setIsSubmitted] = React.useState(false),
    [error, setError] = React.useState(false),
    [errorMsg, setErrorMsg] = React.useState(false),
    [isDayError, setDayError] = React.useState(false),
    [isTimeDirty, setTimeDirty] = React.useState(false),
    [isCommError, setCommError] = React.useState(false),
    [userHash, setUserHash] = React.useState("");

  const PickHoursTime = (props) => {
    const [value, setValue] = React.useState(props.value);
    const options = [
      { value: -1, label: 'Select' },
      { value: 1, label: '1 AM' },
      { value: 2, label: '2 AM' },
      { value: 3, label: '3 AM' },
      { value: 4, label: '4 AM' },
      { value: 5, label: '5 AM' },
      { value: 6, label: '6 AM' },
      { value: 7, label: '7 AM' },
      { value: 8, label: '8 AM' },
      { value: 9, label: '9 AM' },
      { value: 10, label: '10 AM' },
      { value: 11, label: '11 AM' },
      { value: 12, label: '12 Noon' },
      { value: 13, label: '1 PM' },
      { value: 14, label: '2 PM' },
      { value: 15, label: '3 PM' },
      { value: 16, label: '4 PM' },
      { value: 17, label: '5 PM' },
      { value: 18, label: '6 PM' },
      { value: 19, label: '7 PM' },
      { value: 20, label: '8 PM' },
      { value: 21, label: '9 PM' },
      { value: 22, label: '10 PM' },
      { value: 23, label: '11 PM' },
      { value: 0, label: '12 Midnight' },
    ];
    let initialValue = props.initialValue !== -1 && props.initialValue <= 23 ? props.initialValue : -1;
    let optionList;
    if (props.leblId == "to" && initialValue == 23) {
      optionList = <MenuItem value={options[24].value}>
        {options[24].label}
      </MenuItem>;
    } else {
      optionList = options.map((option) => {
        if (option.value > initialValue)
          return <MenuItem value={option.value}>
            {option.label}
          </MenuItem>;
      })
      if (props.leblId == "to" && initialValue !== 0) {
        optionList.push(<MenuItem value={options[24].value}>
          {options[24].label}
        </MenuItem>)
      }
    }
    return (<Select
      labelId={props.leblId}
      value={value}
      onClose={() => setTimeDirty(true)}
      onChange={(e) => {
        setValue(e.target.value);
        props.setValue(e.target.value);
        if (props.leblId == "from" && e.target.value < 23) {
          setToTime1(e.target.value + 1)
        } else if (props.leblId == "from" && e.target.value == 23) {
          setToTime1(0)
        }
      }}
      disabled={props.isDisabled}>
      {optionList}
    </Select>);
  };

  const updateItemObject = (item) => {
    let nemItem = {
      ...item,
      itemTitle: [],
      itemImage: [],
      category: [],
      condition: [],
      id: [],
    };
    if (item && item.title) {
      let keys = Object.keys(item.title);
      keys.map(function (itemTitle, i) {
        nemItem.id[i] = itemTitle;
        nemItem.itemTitle[i] = item.title[itemTitle];
        let img = item.images[itemTitle].startsWith('http')
          ? item.images[itemTitle]
          : appSettings.MEDIA_URL + item.images[itemTitle];
        nemItem.itemImage[i] = img;
        nemItem.itemImage[i] = nemItem.itemImage[i].replace('\\', '/');
        nemItem.itemImage[i] = nemItem.itemImage[i].replace('\\', '/');
        let cat_condition = item.cat_condition[itemTitle].split('_');
        nemItem.category[i] = cat_condition[0];
        nemItem.condition[i] = cat_condition[1];
      });
    }
    return nemItem;
  }

  const getItemsDetails = (item) => {
    itemApi.getItemsDetails(item.bundle_id, function (response) {
      if (response) {
        getExtraInfo(response.Items[0].item_id);

        let newrItemDeatilsesponse = [];
        for (let i = 0; i < response.Items.length; i++) {
          newrItemDeatilsesponse[response.Items[i].item_id] = response.Items[i];
        }
        setItemDetails(newrItemDeatilsesponse);
        setShareUrl(
          BASE_URL +
          'get/details/books/' +
          utilHelper.slugify(response.Items[0].i_name) +
          '/' +
          item.bundle_id
        );
        if (response.can_decline) {
          setCanDecline(true);
        } else {
          setCanDecline(false);
        }
      }

      setRequestLoading(true);

      itemApi.getRequestsByBundleId(
        item.bundle_id,
        function (response) {
          setRequestLoading(false);
          if (response) {
            const isSubmitted =
              item &&
                (item.b_status === 'accepted' ||
                  item.b_status === 'confirmed' ||
                  item.b_status === 'given' ||
                  item.b_status === 'pending_delivery')
                ? true
                : false;
            setBundleSubmitted(isSubmitted);

            response &&
              response.map((item, i) => {
                if (item.b_status == 'accepted') {
                  setAcceptedResponse(item);
                  getUserDetails(item.r_user_hash);
                }
                else if (item.b_status === 'given') {
                  setUserHash(item.r_user_hash);
                }
              });
            setRequestList(response);
            setRequestMsg(response[0].message);

            if (response[0].b_status === 'confirmed') {
              setItem({ ...item, prefer_pickup_loc: response[0].prefer_pickup_loc, display_name: response[0].display_name, conf_message: response[0].conf_message });
            }
          }
        },
        function () {
          setRequestLoading(false);
          setWaitingImg(true);
        }
      );
    });
  }

  React.useEffect(() => {
    if (isLoggedIn) {
      userApi.getLoggedInUserDetails(function (resp) {
        if (resp) {
          setPickUpLoc1({
            name: resp[0].full_name || '',
            city: resp[0].u_city || '',
            state: resp[0].u_state || '',
            country: resp[0].u_country || '',
            street: resp[0].area || '',
            zipcode: resp[0].zipcode || ''
          });

          updateUser({ user: resp[0] });

          setEditable(!resp[0].u_city);
        }
      });

      itemApi.getMyGiveItemByBundle(
        props['item-id'],
        function (response) {
          if (response.bundle && response.bundle.title) {
            let nemItem = updateItemObject(response.bundle);
            setItem(nemItem);
            getItemsDetails(nemItem);
          }
        });
    } else {
      navigate('/login', { state: { redirectUrl: window.location.pathname } });
    }

    return () => {
      setWaitingImg(false);
    };
  }, []);

  const count = RequestList.length,
    handleCheckChange = (name) => (event) => {
      const checkState1 = { ...checkState };
      checkState1[name] = event.target.checked
      setCheckState(checkState1);
      if (checkState1['checkA'] || checkState1['checkB']) {
        setDayError(false);
      } else {
        setDayError(true);
      }
    },
    handleClick = (rid, uid, requestUserName) => {
      setRequestId(rid);
      setUserId(uid);
      setRequestUserName(requestUserName);
      {
        isDeliveryPage === true ? setDeliveryPage(false) : setDeliveryPage(true);
      }
    };

  const handleNoResponseClick = (bundle_id, request_id) => {
    setLoading(true);
    itemApi.updateBundleRejectRequest(
      bundle_id,
      request_id,
      function (response) {
        setLoading(false);
        if (response) {
          window.location.reload(false);
        } else {
          showAlert({ message: 'Error', open: true, severity: 'error' });
          return false;
        }
      },
      function () {
        setLoading(false);
      }
    );
  };

  const handleClickOK = () => {
    const requestDataBlock = {
      accept_request_id: item.accept_request_id,
      bundle_id: item.bundle_id,
      user_id: item.r_user_hash,
    };
    setLoading(true);
    itemApi.setBundleConfirmed(
      requestDataBlock,
      function (response) {
        setLoading(false);
        if (response) {
          navigate('/my-gives/3');
        } else {
          showAlert({ message: 'Error', open: true, severity: 'error' });
          return false;
        }
      },
      function () {
        setLoading(false);
      }
    );
  };

  const addAdrressToObj = (values) => {
    let countryCode = getCountryCode(values.country);
    // setPickUpLoc1({ ...values });
    if (isSaveToProfile) {
      updateProfileAddress({
        full_name: values.name,
        u_city: values.city,
        u_state: values.state,
        u_country: values.country,
        zipcode: values.zipcode,
        country_code: countryCode,
        area: values.street
      });
    }
    setEditable(false);
    setPickUpLoc1(values);
  };

  let newRequest,
    DayAvail1;

  const handleCheckBox = () => {
    if (checkState.checkA === true && checkState.checkB === true) {
      DayAvail1 = [
        { id: 0, value: 'weekdays' },
        { id: 1, value: 'weekends' },
      ];
      setDayError(false);
    } else if (checkState.checkA == true && checkState.checkB === false) {
      DayAvail1 = [{ id: 0, value: 'weekdays' }];
      setDayError(false);
    } else if (checkState.checkA == false && checkState.checkB === true) {
      DayAvail1 = [{ id: 0, value: 'weekends' }];
      setDayError(false);
    } else {
      // showAlert({
      //   message: 'Please choose your preferred available days for pickup.',
      //   open: true,
      //   severity: 'warning',
      // });
      setDayError(true);
      setLoading(false);
      return false;
    }

    return true;
  };

  let pref_mod_com = {
    email: null,
    mobile: null,
  };

  const formRef = React.useRef();

  const SavePickup = async (e) => {
    let isError = false;
    setLoading(true);
    await formRef.current.submitForm();
    if (Object.keys(formRef?.current?.errors)?.length) {
      isError = true;
      setEditable(true);
    }
    if (pickUpLoc1.city) {
      e.preventDefault();
      const isTrue = handleCheckBox();
      if (!isTrue) {
        isError = true
      }
      if (state.checkedA === true) {
        pref_mod_com.email = user.email;
      }
      if (state.checkedB) {
        if (user.mobile) {
          pref_mod_com.mobile = user.mobile;
        }
        else {
          setError(true);
          setErrorMsg("Please update phone number.")
          isError = true;
        }
      }
      if (from_time1 === -1 || to_time1 === -1) {
        setTimeDirty(true);
        isError = true;
      }
      if (state.checkedA == false && state.checkedB == false) {
        setCommError(true);
        isError = true;
      }
      if (pickUpLoc1.length <= 0) {
        showAlert({
          message: 'Please enter at least one pickup address details.',
          open: true,
          severity: 'warning',
        });
        setLoading(false);
        return false;
      }
      if (isError) {
        setLoading(false);
        return false;
      } else {
        const requestDataBlock = {
          request_id: isRequestId,
          bundle_id: item.bundle_id,
          user_id: isUserId,
          pickup_loc_1: {
            addr: {
              name: pickUpLoc1.name,
              street: pickUpLoc1.street,
              city: pickUpLoc1?.city,
              state: pickUpLoc1?.state,
              country: pickUpLoc1?.country,
              zipcode: pickUpLoc1.zipcode,
            },
            day_avail: DayAvail1,
            from_time: from_time1,
            to_time: to_time1,
          },
          prefer_mode_comm: pref_mod_com
        };
        itemApi.updateRequest(
          requestDataBlock,
          function (response) {
            if (response) {
              setIsSubmitted(true);
              setLoading(false);
              return;
            }
          },
          function (error) {
            const errorMsg = error?.response ? error?.response.data?.detail : "Error while confirming the request. Please try again later."
            setLoading(false);
            setIsSubmitted(false);
            showAlert({
              message: errorMsg,
              open: true,
              severity: 'warning',
            });
            return false;
          }
        );
      }
    } else {
      setLoading(false);
      showAlert({
        message: 'Please enter pickup address details before confirming.',
        open: true,
        severity: 'warning',
      });
      return false;
    }
  };

  const fetch = React.useMemo(
    () =>
      throttle((input, callback) => {
        if (!input) {
          return callback([]);
        }
        if (input.input.length < 4) {
          return;
        }

        setLoadingCity(true);

        genericApi.getCitiesByGeoLocations(input.input, function (response) {
          return callback(response);
        });
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);

        setLoadingCity(false);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const checkPhone = (e) => {
    let countryCode = getCountryCode(user?.u_country)?.toLowerCase();
    let phone = e ? parsePhoneNumberFromString(e, countryCode) : "";
    if (phone && phone.nationalNumber?.length > 4) {
      setErrorMsg("")
      setError(false);
      return true;
    }
    setErrorMsg("Please enter valid phone number.")
    setError(true);
    return false;
  }

  const updatePhone = (e) => {
    if (!checkPhone(userDetails.phone)) {
      return;
    }
    userApi.updateLoggedInUserProfile({ ...user, mobile: parsePhoneNumberFromString(userDetails.phone, countryCode).nationalNumber }, function (resp) {
      if (resp) {
        setPhoneUpdated(true);
        setPhone(resp.mobile);
        updateUser({ user: resp });
        showAlert({ message: 'You have successfully updated phone to profile.', open: true, severity: 'success' });
        setError(false);
      } else {
        showAlert({ message: 'There is a failure in updating phone to profile.', open: true, severity: 'error' });
        setError(true);
        setErrorMsg("")
      }
    });
  },
    updateProfileAddress = (values) => {
      values.mobile = user.country_code !== values.country_code ? null : user.mobile;

      userApi.updateLoggedInUserProfile({ ...user, ...values }, function (resp) {
        let storeUserCopy = { ...user, ...values };
        if (resp) {
          updateUser({ user: storeUserCopy });
          showAlert({ message: 'You have successfully updated address to profile.', open: true, severity: 'success' });
        } else {
          showAlert({ message: 'There is a failure in updating address to profile.', open: true, severity: 'error' });
        }
      });
    }

  const handleStateChange = (names) => (event) => {
    if (names == "checkedB" && !event.target.checked) {
      setError(false);
    }
    const state1 = { ...state };
    state1[names] = event.target.checked;
    setState(state1);
    if (state1['checkedA'] || state1['checkedB']) {
      setCommError(false);
    } else {
      setCommError(true);
    }
  },
    getExtraInfo = (itemId) => {
      setLoadingExtraInfo(true);
      itemApi.getItemExtraInfo(itemId, function (response) {
        if (response) {
          setExtraInfo(response);
          setLoadingExtraInfo(false);
        }
      });
    };

  const getProductDetails = () => {
    return (
      <Grid item xs={6} className={classes.product}>
        <ItemImagesContainer
          itemDetails={itemDetails}
          titles={item.itemTitle}
          item={item}
          shareUrl={shareUrl}
          getExtraInfo={getExtraInfo}
          displayIndex={displayIndex}
          setDisplayIndex={setDisplayIndex}
        ></ItemImagesContainer>
        <Box mt={4}>
          <ItemDetails
            itemDetails={itemDetails}
            displayIndex={displayIndex}
            item={item}
          ></ItemDetails>
        </Box>
        {extraInfo.authorName && (
          <Box mt={4}>
            <GoodReadDetails
              extraInfo={extraInfo}
              loading={loadingExtraInfo}
            ></GoodReadDetails>
          </Box>
        )}
      </Grid>
    );
  };

  const getDeliveryDetails = () => {
    return (
      <>
        <Typography
          variant='h1'
          style={{ marginBottom: '2rem' }}
          component='div'
        >
          {item.b_status === 'pending_delivery'
            ? `The pick up location and time slot has been confirmed by ${acceptedResponse.display_name}`
            : `You have accepted ${acceptedResponse.display_name}'s request.`
          }
        </Typography>
        <Grid container style={{ marginBottom: "3rem" }}>
          <Grid item xs={12}>
            <Typography
              variant='subtitle'
              component='div'
              style={{ marginBottom: '1rem', fontWeight: '600' }}>
              Your preferences have been shared as below:
                    </Typography>
            {acceptedResponse && acceptedResponse.pickup_loc_1 &&
              <section>
                <section style={{ alignSelf: "center" }}>
                  <section className={getClasees.wrapper}>
                    <Grid item xs={8} className={getClasees.marpad}
                      style={{
                        backgroundColor: "aliceblue",
                        borderRadius: "4px",
                        padding: "1rem !important"
                      }}
                    >
                      <Typography variant='subtitle2'
                        component='div'
                        style={{ fontWeight: 600 }}>
                        Address
                    </Typography>
                      <Typography variant='subtitle2' component='div'>
                        {acceptedResponse.pickup_loc_1?.addr?.name}
                      </Typography>
                      <Typography variant='subtitle2' component='div'>
                        {acceptedResponse.pickup_loc_1?.addr?.street}
                      </Typography>
                      <Typography variant='subtitle2' component='div'>
                        {acceptedResponse.pickup_loc_1?.addr?.city}, {acceptedResponse.pickup_loc_1?.addr?.state}, {acceptedResponse.pickup_loc_1?.addr?.country}
                      </Typography>
                      <Typography variant='subtitle2' component='div'>
                        {acceptedResponse.pickup_loc_1?.addr?.zip}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className={getClasees.marpad}
                      style={{
                        backgroundColor: "aliceblue",
                        borderRadius: "4px",
                        padding: "1rem !important"
                      }}
                    >
                      <Typography
                        variant='subtitle2'
                        component='div'
                        style={{ fontWeight: 600 }}
                      >
                        Day {" "}
                      </Typography>
                      <Typography variant='subtitle2' component='div'>
                        {acceptedResponse.pickup_loc_1?.day_avail?.map((value, key) => {
                          return <Typography variant='subtitle2' component='div'>{value["value"] + "  "}</Typography>;
                        })}
                      </Typography>
                      {acceptedResponse?.pickup_loc_1?.from_time != -1 &&
                        <>
                          <Typography
                            variant='subtitle2'
                            component='div'
                            style={{ fontWeight: 600, marginTop: '10px' }}
                          >
                            Time
                        </Typography>
                          <Typography variant='subtitle2' component='div'>
                            {utilHelper.getTimeValue(
                              acceptedResponse?.pickup_loc_1?.from_time
                            ) == "0 AM" ? "12 Midnight" : utilHelper.getTimeValue(
                              acceptedResponse?.pickup_loc_1?.from_time
                            )}{' '}
                            to{' '}
                            {utilHelper.getTimeValue(
                              acceptedResponse?.pickup_loc_1?.to_time
                            ) == "0 AM" ? "12 Midnight" : utilHelper.getTimeValue(
                              acceptedResponse?.pickup_loc_1?.to_time
                            )}{' '}
                          </Typography>
                        </>
                      }
                    </Grid>
                  </section>
                </section>
              </section>}
          </Grid>
        </Grid>
        {acceptedResponse &&
          acceptedResponse.prefer_mode_comm && (acceptedResponse.prefer_mode_comm.mobile || acceptedResponse.prefer_mode_comm.email) && <Grid container style={{ alignSelf: "center", marginBottom: "3rem" }}>
            <Grid item xs={12}>
              <Typography variant='subtitle' component='div' style={{ marginBottom: "1rem", fontWeight: '600' }}>
                Preferred mode of communication:
            </Typography>
              <section className={getClasees.wrapper}>
                {acceptedResponse.prefer_mode_comm.email && (
                  <Grid item xs={6} style={{ paddingLeft: '0px !important' }}>
                    <Typography
                      variant='h4'
                      component='div'
                      style={{
                        display: 'flex',
                        flex: '1 1',
                        fontWeight: 600
                      }}>
                      <p style={{ marginTop: '7px' }}>Email:</p>{' '}
                      <Chip
                        label={acceptedResponse.prefer_mode_comm.email}
                        style={{
                          marginLeft: '10px',
                          backgroundColor: 'aliceblue',
                          fontWeight: '500',
                          fontSize: '14px',
                          width: '80%'
                        }}
                      />
                    </Typography></Grid>
                )}
                {acceptedResponse.prefer_mode_comm.mobile && (
                  <Grid item xs={6} style={{ paddingLeft: '0px !important' }}>
                    <Typography
                      variant='h4'
                      component='div'
                      style={{
                        display: 'flex',
                        flex: '1 1',
                        fontWeight: 600
                      }}
                    >
                      <p style={{ marginTop: '7px' }}>Phone:</p>{' '}
                      <Chip
                        label={
                          (acceptedResponse?.r_country ? getPhoneNumber(acceptedResponse.prefer_mode_comm.mobile, acceptedResponse?.r_country) : acceptedResponse.prefer_mode_comm.mobile)

                        }
                        style={{
                          marginLeft: '10px',
                          backgroundColor: 'aliceblue',
                          fontWeight: '500',
                          fontSize: '14px'
                        }}
                      />
                    </Typography>
                  </Grid>
                )}
              </section>
            </Grid>
          </Grid>}
        <Grid container style={{ alignSelf: "center", marginBottom: "3rem" }}>
          <Grid
            item
            xs={12}>
            <Alert
              severity='info'
              style={{ marginBottom: "2rem", fontSize: '14px', fontWeight: '500' }}>
              If {acceptedResponse.display_name} does not respond within 24 hours, you
            can decline this request or continue waiting. Please ensure you have other requests to choose from if you decline.
          </Alert>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ marginBottom: "2rem" }}
            align='right'>
            <Button
              variant='contained'
              color='primary'
              disabled={loading || !canDecline}
              onClick={() => {
                handleNoResponseClick(
                  acceptedResponse.bundle_id,
                  acceptedResponse.request_id
                );
              }}>Decline</Button>
          </Grid>
        </Grid>
      </>
    )
  }

  const schema = yup.object().shape({
    name: yup.string().trim().required('Please enter name.').nullable(),
    street: yup.string().required('Please enter street address.').nullable(),
    city: yup.string().required('Please enter city.').nullable(),
    zipcode: yup.string().nullable().test({
      name: 'isZipCodeValid',
      test: function (value) {
        if (value) {
          const patt = getPostCodeFormat(user.u_country);
          let res = !(new RegExp('^' + patt + '$', 'i').test(value));
          if (patt && res) {
            return this.createError({
              message: `Please enter a valid zipcode.`,
              path: 'zipcode', // Fieldname
            })
          }
          else {
            return true;
          }
        } else {
          return true;
        }
      }
    }).nullable(),
  }),
    handleOpenGivenModel = () => {
      getuserDetails();
      setGivenModel(true);
    },
    [openGivenModel, setGivenModel] = React.useState(false),
    [additionalnfo, setAdditionalInfo] = React.useState(""),
    [ratingValue, setRatingValue] = React.useState(""),
    [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false),
    [feedbackMessage, setFeedbackMessage] = React.useState(""),
    [isReload, setIsReload] = React.useState(false),
    local = useStylesOpacity(),
    submitReceiverRating = () => {
      if (ratingValue) {
        setLoading(true);
        const reqBlk = {
          get_ratings: ratingValue,
          give_experience: additionalnfo,
          bundle_id: item.bundle_id,
        };
        let bundleId = item.bundle_id;
        itemApi.submitFeedback(bundleId, reqBlk, function (response) {
          if (response) {
            setLoading(false);
            setFeedbackSubmitted(true);
            setIsReload(true);
            setFeedbackMessage("Thanks for your feedback. Your request is completed.");
          }
        });
      } else {
        // handleCloseGiverModel();
        showAlert({
          message: "Please provide ratings.",
          open: true,
          severity: "warning",
        });
      }
    },
    getuserDetails = () => {
      userApi.getUserSummaryByHash(userHash, function (response) {
        if (response) {
          setLoading(false);
          let receiverData = { ...user };
          receiverData.name = response[0].display_name;
          receiverData.city = response[0].u_city;
          receiverData.user_avatar = response[0].user_avatar;
          setUser(receiverData);
        }
      });
    };

  return (
    <Box
      display='flex'
      flexDirection='column'
      bgcolor='#fff'
      className={classes.container}>

      <Grid container style={{ margin: '2rem 0' }}>
        {item && getProductDetails()}
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h5' component='div'>
              View details
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>{item && getProductDetails()}</ExpansionPanelDetails>
        </ExpansionPanel>

        {/*Requests Page*/}
        {isDeliveryPage === false ? (
          <Grid item xs={6}>
            {requestLoading && (
              <div>
                <LoadingIcon />
              </div>
            )}
            {bundleSubmitted && acceptedResponse && !requestLoading && (
              getDeliveryDetails()
            )}
            {bundleSubmitted === false ? (
              <>
                {count && count > 0 ? (
                  <Typography
                    variant='h1'
                    style={{ paddingBottom: '10px' }}
                    component='div'
                  >
                    {count} {count == 1 ? 'Request' : 'Requests'}
                  </Typography>
                ) : waitingImg && !requestLoading ? (
                  <><div
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#fbfdf8',
                      padding: '35px 10px',
                    }}>
                    <img
                      alt='waiting'
                      width='70%'
                      height='273px'
                      src='/assets/images/waiting.svg' />
                  </div>
                    <Alert severity='info'
                      style={{ marginTop: "20px", marginBottom: "20px" }}>
                      Waiting for requests.
                  </Alert>
                  </>
                ) : (
                      ''
                    )}

              </>

            ) : (
                ''
              )}
            {bundleSubmitted && item.b_status == 'confirmed' &&
              <Grid item xs={12}>
                {/* || props.r_status === 'confirmed' */}
                <Typography
                  variant='h1'
                  style={{ marginBottom: '3rem' }}
                  component='div'>
                  Your pickup has been confirmed by {item.prefer_pickup_loc.addr.name}.
                </Typography>
                <Typography
                  variant='subtitle2'
                  component='div'
                  style={{ marginBottom: '3rem' }}>
                  Meeting point has been fixed.
                </Typography>
                <Grid container style={{ marginBottom: "3rem" }}>
                  <Grid item xs={12}>
                    <section>
                      <section style={{ alignSelf: "center" }}>
                        <Typography variant='subtitle' component='div' style={{ marginBottom: "1rem", fontWeight: '600' }}>
                          Preferred pick up address:
                    </Typography>
                        {item.prefer_pickup_loc && <section className={getClasees.wrapper}>
                          <Grid item xs={6} className={getClasees.marpad}
                            style={{
                              backgroundColor: "aliceblue",
                              borderRadius: "4px",
                              padding: "1rem !important"
                            }}
                          >
                            <Typography
                              variant='subtitle2'
                              component='div'
                              style={{ fontWeight: 600 }}
                            >
                              Address {" "}
                            </Typography>
                            <Typography variant='subtitle2' component='div'>
                              {item.prefer_pickup_loc.addr.name}
                            </Typography>
                            <Typography variant='subtitle2' component='div'>
                              {item.prefer_pickup_loc.addr.street}
                            </Typography>
                            <Typography variant='subtitle2' component='div'>
                              {item.prefer_pickup_loc?.addr?.city}, {item.prefer_pickup_loc?.addr?.state}, {item.prefer_pickup_loc?.addr?.country}
                            </Typography>
                            <Typography variant='subtitle2' component='div'>
                              {item.prefer_pickup_loc?.zip}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} className={getClasees.marpad}
                            style={{
                              backgroundColor: "aliceblue",
                              borderRadius: "4px",
                              padding: "1rem !important"
                            }}
                          >
                            <Typography
                              variant='subtitle2'
                              component='div'
                              style={{ fontWeight: 600 }}
                            >
                              Day {" "}
                            </Typography>
                            <Typography variant='subtitle2' component='div'>
                              {item.prefer_pickup_loc?.day_avail?.map((value, key) => {
                                return <Typography variant='subtitle2' component='div'>{value["value"] + "  "}</Typography>;
                              })}
                            </Typography>
                            {item.prefer_pickup_loc?.from_time != -1 &&
                              <>
                                <Typography
                                  variant='subtitle2'
                                  component='div'
                                  style={{ fontWeight: 600, marginTop: '10px' }}
                                >
                                  Time
                                </Typography>
                                <Typography variant='subtitle2' component='div'>
                                  {utilHelper.getTimeValue(
                                    item.prefer_pickup_loc?.from_time
                                  ) == "0 AM" ? "12 Midnight" : utilHelper.getTimeValue(
                                    item.prefer_pickup_loc?.from_time
                                  )}{' '}
                                   to{' '}
                                  {utilHelper.getTimeValue(
                                    item.prefer_pickup_loc?.to_time
                                  ) == "0 AM" ? "12 Midnight" : utilHelper.getTimeValue(
                                    item.prefer_pickup_loc?.to_time
                                  )}{' '}
                                </Typography>
                              </>}
                          </Grid>
                        </section>}
                      </section>
                    </section>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} >
                    <section style={{ alignSelf: "center" }}>
                      <Typography variant='subtitle' component='span' style={{ fontWeight: '600' }}>
                        Message from {item.r_display_name && item.r_display_name} : {" "}
                      </Typography>
                      <Typography variant='subtitle2' component='span'>
                        {item.conf_message && item.conf_message}
                      </Typography>
                    </section>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <div
                    className={classes.wrapper}
                    style={{
                      float: 'right',
                      marginTop: '32px',
                      marginBottom: '1rem',
                    }}>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={loading}
                      onClick={() => {
                        handleClickOK();
                      }}>OK, Got it</Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            }

            {bundleSubmitted &&
              item.b_status == 'given' &&
              <>

                <Grid item xs={12}>
                  {/* || props.r_status === 'confirmed' */}
                  <Typography
                    variant='h1'
                    style={{ marginBottom: '3rem' }}
                    component='div'
                  >
                    You have gifted this item to{' '}
                    {item.r_display_name && item.r_display_name}.
                  </Typography>
                  <Grid container style={{ marginBottom: "3rem" }}>
                    <Grid item xs={12}>
                      <Typography variant='subtitle' component='span' style={{ fontWeight: 600 }}>
                        Message from {item.r_display_name && item.r_display_name} : {" "}
                      </Typography>
                      <Typography variant='subtitle2' component='span'>
                        {Request_msg && Request_msg}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginBottom: "3rem" }}>
                    <Grid item xs={12}>
                      <section style={{ marginBottom: "3rem" }}>
                        <section style={{ alignSelf: "center" }}>
                          <section className={getClasees.wrapper}>
                            <Grid item xs={8} className={getClasees.marpad}
                              style={{
                                backgroundColor: "aliceblue",
                                borderRadius: "4px",
                                padding: "1rem !important"
                              }}
                            >
                              <Typography variant='subtitle2'
                                component='div'
                                style={{ fontWeight: 600 }}>
                                Address
                            </Typography>
                              <Typography variant='subtitle2' component='div'>
                                {item?.prefer_pickup_loc?.addr?.name}
                              </Typography>
                              <Typography variant='subtitle2' component='div'>
                                {item?.prefer_pickup_loc?.addr?.street}
                              </Typography>
                              <Typography variant='subtitle2' component='div'>
                                {item?.prefer_pickup_loc?.addr?.city}, {item?.prefer_pickup_loc?.addr?.state}, {item?.prefer_pickup_loc?.addr?.country}
                              </Typography>
                              <Typography variant='subtitle2' component='div'>
                                {item?.prefer_pickup_loc?.addr?.zip}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} className={getClasees.marpad}
                              style={{
                                backgroundColor: "aliceblue",
                                borderRadius: "4px",
                                padding: "1rem !important"
                              }}
                            >
                              <Typography
                                variant='subtitle2'
                                component='div'
                                style={{ fontWeight: 600 }}
                              >
                                Day {" "}
                              </Typography>
                              <Typography variant='subtitle2' component='div'>
                                {item?.prefer_pickup_loc?.day_avail?.map((value, key) => {
                                  return <Typography variant='subtitle2' component='div'>{value["value"] + "  "}</Typography>;
                                })}
                              </Typography>
                              {item?.prefer_pickup_loc?.from_time != -1 && <>
                                <Typography
                                  variant='subtitle2'
                                  component='div'
                                  style={{ fontWeight: 600, marginTop: '10px' }}
                                >
                                  Time
                              </Typography>
                                <Typography variant='subtitle2' component='div'>
                                  {utilHelper.getTimeValue(
                                    item?.prefer_pickup_loc?.from_time
                                  ) == "0 AM" ? "12 Midnight" : utilHelper.getTimeValue(
                                    item?.prefer_pickup_loc?.from_time
                                  )}{' '}
                                 to{' '}
                                  {utilHelper.getTimeValue(
                                    item?.prefer_pickup_loc?.to_time
                                  ) == "0 AM" ? "12 Midnight" : utilHelper.getTimeValue(
                                    item?.prefer_pickup_loc?.to_time
                                  )}{' '}
                                </Typography>
                              </>}
                            </Grid>
                          </section>
                        </section>
                      </section>
                      {item.get_ratings > 0 ? <section style={{
                        alignSelf: "center", maxWidth: '100%',
                        width: 'fit-content'
                      }}>
                        <Typography variant='subtitle'
                          component='div' style={{ fontWeight: 600, marginBottom: "1rem" }}>
                          Your Feedback:
                       </Typography>
                        <section
                          style={{
                            padding: "1rem",
                            backgroundColor: "aliceblue",
                          }}
                        >
                          <Table size='small'>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography variant='subtitle2' component='div' style={{ fontSize: '14px' }}>
                                    {/* Rating given to requestor */}
                                 Rating:
                                </Typography>
                                </TableCell>
                                <TableCell>
                                  <Rating
                                    name='disabled'
                                    style={{ fontSize: '18px' }}
                                    value={+item.get_ratings}
                                    disabled
                                  />
                                </TableCell>
                              </TableRow>
                              {item.give_experience &&
                                <TableRow>
                                  <TableCell>
                                    <Typography variant='subtitle2' component='div' style={{ fontSize: '14px' }}>
                                      Comments:
                              </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant='subtitle2' component='div' ><q>{item.give_experience}</q></Typography>
                                  </TableCell>
                                </TableRow>}
                            </TableBody>
                          </Table>
                        </section>
                      </section> : (
                          <Box>
                            <Button
                              variant='contained'
                              color='primary'
                              onClick={handleOpenGivenModel}
                            >
                              Rate {item.r_display_name}
                            </Button>
                          </Box>
                        )}
                    </Grid>
                  </Grid>
                </Grid>


                <Dialog
                  aria-labelledby='customized-dialog-title'
                  open={openGivenModel}>
                  <DialogTitle
                    id='customized-dialog-title'
                    style={{
                      padding: '0',
                    }}>
                    Rate the receiver
            </DialogTitle>
                  <DialogContent
                    style={{
                      padding: '16px 0 0',
                    }}>
                    <div>
                      We hope that you have enjoyed the overall experience. Please
                      rate the receiver to help the community.
              </div>
                    {user && user.name && (
                      <div style={{ display: 'flex', flexDirection: 'row', padding: '16px 0 0' }}>
                        <Avatar
                          className={classes.avatar}
                          src={user.user_avatar ? user.user_avatar : ""}></Avatar>
                        <div style={{ marginLeft: '5px' }}>
                          <b>{user && user.name}</b>
                          <br />
                          <span>{user && user.city}</span>
                        </div>
                      </div>
                    )}
                    <Typography
                      style={{
                        padding: '16px 0 0 ',
                      }}>How would you rate receiver ? <br />
                      <Rating
                        name='simple-controlled'
                        value={+ratingValue}
                        onChange={(event, newValue) => {
                          setRatingValue(newValue);
                        }} />
                    </Typography>
                  </DialogContent>
                  <TextField
                    multiline
                    label='Review (Optional)'
                    placeholder='Describe your experience'
                    rows='3'
                    name='additionalInfo'
                    inputProps={{ maxLength: 500 }}
                    onChange={(e) => {
                      setAdditionalInfo(e.target.value);
                    }}
                    style={{
                      margin: '0 0 32px',
                    }}
                    inputProps={{ maxLength: 200 }}
                    FormHelperTextProps={{ classes: { root: local.helperTextRight } }}
                    helperText={`${additionalnfo ? additionalnfo.length : 0}/200`}

                  />
                  {
                    !feedbackSubmitted
                      ? <DialogActions>
                        <Button
                          variant='contained'
                          onClick={() => {
                            setIsReload(false);
                            setFeedbackSubmitted(true);
                            setFeedbackMessage("Ok, no problem. You can rate them later under Completed Requests.");
                          }}
                          color=''
                        >
                          Cancel
                  </Button>
                        <div className={local.wrapper}>
                          <Button
                            variant='contained'
                            onClick={submitReceiverRating}
                            color='primary'
                            disabled={loading}
                          >
                            Submit
                    </Button>
                          {loading && (
                            <CircularProgress
                              size={24}
                              className={local.buttonProgress}
                            />
                          )}
                        </div>
                      </DialogActions>
                      : <div className={localClasses.feedbackWrapper}>
                        <p>{feedbackMessage}</p>
                        <Button
                          variant='contained'
                          onClick={() => {
                            if (isReload === true) {
                              window.location.reload();
                            }
                            setFeedbackSubmitted(false);
                            setRatingValue("")
                            setFeedbackMessage("");
                            setAdditionalInfo("");
                            setGivenModel(false);
                          }
                          }
                          color='primary'>
                          OK
                    </Button>
                      </div>
                  }
                </Dialog>
              </>
            }

            {bundleSubmitted === false && RequestList && (
              <Box width='100%'>
                {RequestList.map((item, i) => {
                  newRequest = { ...item };
                  let [date, time] = newRequest.created_time.split('T');
                  return (
                    <>
                      <RequestListItem
                        item={newRequest}
                        key={i}
                        handleClick={handleClick}
                        setUser_Id={setUser_Id}
                        setRequest_Id={setRequest_Id}
                        setReqName={setReqName}
                        request_id={newRequest.request_id}
                        user_id={newRequest.user_id}
                        display_name={newRequest.display_name}
                      />
                    </>
                  );
                })}
              </Box>
            )}
            <Box width='100%' display='none'>
              <LargeRectangleAds></LargeRectangleAds>
            </Box>
          </Grid>

        ) : (item &&
          <Grid item xs={6}>
            <>
              {!isSubmitted ? (
                <>
                  <Typography
                    variant='h1'
                    style={{ marginBottom: '1rem' }}
                    component='div'>
                    Give Item to {utilHelper.toCamelCase(requestUserName)}{' '}
                  </Typography>

                  <Typography variant='subtitle2' component='div'>
                    Please share your pickup address and available time slots :{' '}
                    {''}
                    <HtmlTooltip
                      title={
                        'Please make sure that you select a safe or public place for pickup. Follow safety rules when meeting for pick up.'
                      }>
                      <InfoIcon style={{ color: '#2196f3' }}></InfoIcon>
                    </HtmlTooltip>
                  </Typography>

                  <section className={classes.addPickupAddress}>
                    <Formik
                      innerRef={formRef}
                      enableReinitialize
                      initialValues={{
                        name: pickUpLoc1.name,
                        street: pickUpLoc1.street,
                        city: pickUpLoc1.city,
                        state: pickUpLoc1.state,
                        country: pickUpLoc1.country,
                        zipcode: pickUpLoc1.zipcode,
                      }}
                      validationSchema={schema}
                      onSubmit={async (
                        values
                      ) => {
                        try {
                          addAdrressToObj(values);
                        } catch ({ error }) {
                          showAlert({ message: 'Error', open: true, severity: 'error' });
                        }
                      }}
                      render={({ submitForm, values, resetForm }) => (
                        <Form style={{ width: '100%' }}>
                          {!isEditable ? <EditIcon className={classes.closeButton}
                            style={{ fontSize: '1.2rem' }}
                            onClick={() => {
                              setEditable(true);
                            }}></EditIcon> : <CloseIcon className={classes.closeButton}
                              onClick={(e) => {
                                resetForm();
                                setEditable(false);
                              }} />}
                          <FormGroup>
                            <Box mt={2} display='flex'>
                              <Field
                                fullWidth
                                type='text'
                                className={classes.field}
                                label='Name'
                                name='name'
                                component={FormikTextField}
                                disabled={!isEditable}
                                value={pickUpLoc1.name} />
                              <Field
                                fullWidth
                                type='text'
                                label='Street Address'
                                name='street'
                                value={pickUpLoc1.street}
                                component={FormikTextField}
                                disabled={!isEditable}
                                className={classes.field} />
                            </Box>
                            <Box mt={2} display='flex'>
                              <Autocomplete
                                id='city-search_id'
                                className={classes.field}
                                getOptionLabel={(option) => {
                                  const address = typeof option === 'string' ? option.split(',') : option.city.split(',');
                                  return address[0].trim();
                                }}
                                filterOptions={(x) => x}
                                options={options}
                                autoComplete='new-city'
                                autoSelect={true}
                                includeInputInList
                                disableOpenOnFocus
                                loading={loadingCity}
                                getOptionSelected={(option, value) => value?.city == option?.city}
                                value={pickUpLoc1.city}
                                onChange={(event, value) => {
                                  if (value) {
                                    const address = value.city.split(',');

                                    setPickUpLoc1({
                                      ...values,
                                      city: address[0],
                                      state: address[1].trim(),
                                      country: address[2].trim()
                                    });
                                  } else {
                                    setPickUpLoc1({
                                      ...values,
                                      city: ''
                                    });
                                  }
                                }}
                                onInputChange={(event, newValue) => {
                                  setInputValue(newValue);
                                }}
                                renderInput={(params) => (
                                  <Field
                                    fullWidth
                                    {...params}
                                    label='City'
                                    name='city'
                                    component={FormikSearchTextField}
                                    disabled={!isEditable}
                                    className={classes.mobileFormLabel}
                                  />
                                )}
                                renderOption={(option) => {
                                  return (
                                    <Grid container alignItems='center'>
                                      <Grid item xs>
                                        <Typography variant='body2' color='textSecondary'>
                                          <span countryCode={option.country_code}>
                                            {option.city}
                                          </span>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  );
                                }}
                              />
                              <TextField
                                fullWidth
                                style={{ flex: '1 1', margin: '0 20px' }}
                                className={!isEditable && classes.field}
                                type='text'
                                label='State'
                                name='state'
                                value={pickUpLoc1.state}
                                disabled={true} />
                            </Box>
                            <Box mt={2} display='flex'>
                              <TextField
                                style={{ flex: '1 1', margin: '0 20px' }}
                                className={!isEditable && classes.field}
                                fullWidth
                                type='text'
                                label='Country'
                                name='country'
                                value={pickUpLoc1.country}
                                disabled={true} />
                              <Field
                                fullWidth
                                className={classes.field}
                                type='text'
                                label='Zipcode'
                                name='zipcode'
                                component={FormikTextField}
                                disabled={!isEditable}
                                defaultValue={pickUpLoc1.zipcode}
                                value={pickUpLoc1.zipcode} />
                            </Box>
                            <Collapse in={isEditable}>
                              <Box mt={2} mx={2} display='flex' color='#9e9e9e' justifyContent='space-between'>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        setSaveToProfile(e.target.checked);
                                      }}
                                      value='saveToProfile' />}
                                  label='Save to profile' />
                                <IconButton color="primary" aria-label="done"
                                  onClick={(e) => {
                                    // let countryCode = getCountryCode(values.country);

                                    // setPickUpLoc1({ ...values });

                                    // if (isSaveToProfile) {
                                    //   updateProfileAddress({
                                    //     full_name: values.name,
                                    //     u_city: values.city,
                                    //     u_state: values.state,
                                    //     u_country: values.country,
                                    //     zipcode: values.zipcode,
                                    //     country_code: countryCode,
                                    //     area: values.street
                                    //   });
                                    // }
                                    // setEditable(false);
                                    submitForm()
                                  }}
                                >
                                  <DoneOutlinedIcon />
                                </IconButton>

                              </Box>
                            </Collapse>
                          </FormGroup>
                        </Form>
                      )}
                    />
                  </section>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography
                        style={{ margin: '0 0 10px 0' }}
                        variant='subtitle2'
                        component='div'>
                        Day
                          </Typography>
                      <FormControl
                        component='fieldset'
                        className={classes.formControl}
                        error={isDayError}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkState.checkA}
                                onChange={handleCheckChange('checkA')}
                                value='checkA' />
                            }
                            label='Weekdays'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkState.checkB}
                                onChange={handleCheckChange('checkB')}
                                value='checkB' />
                            }
                            label='Weekends'
                          />
                        </FormGroup>
                        {isDayError &&
                          <FormHelperText>Please choose your preferred available days for pickup.</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        style={{ margin: '0rem 0 10px 0' }}
                        variant='subtitle2'
                        component='div'>
                        Time
                          </Typography>
                      <Box display='flex'>
                        <FormControl
                          style={{ margin: '0 5px', width: '75%' }}>
                          <InputLabel id='from'>From</InputLabel>
                          <PickHoursTime
                            value={from_time1}
                            setValue={setFromTime1}
                            leblId='from'
                          />
                        </FormControl>

                        <FormControl
                          style={{ margin: '0 5px', width: '75%' }}>
                          <InputLabel id='to'>To</InputLabel>
                          <PickHoursTime
                            value={to_time1}
                            setValue={setToTime1}
                            leblId='to'
                            initialValue={from_time1}
                            isDisabled={from_time1 != -1 ? false : true}
                          />
                        </FormControl>
                      </Box>
                      {isTimeDirty && (from_time1 == -1 || to_time1 == -1) &&
                        <FormHelperText style={{ color: '#ff1744' }}>
                          Please select an available time slot.</FormHelperText>}
                    </Grid>
                  </Grid>
                  <Box mt={4}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant='subtitle2'
                      component='div'
                      style={{ marginRight: '5px' }}>
                      Preferred mode of communication :
                          </Typography>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <AntSwitch
                            checked={state.checkedA}
                            onChange={handleStateChange('checkedA')}
                            value='checkedA' />
                        }
                        label='Email'
                      />
                      <FormControlLabel
                        control={
                          <AntSwitch
                            checked={state.checkedB}
                            onChange={handleStateChange('checkedB')}
                            value='checkedB'
                          />
                        }
                        label='Phone'
                      />
                    </FormGroup>
                  </Box>

                  <Collapse in={state.checkedA || state.checkedB}>
                    <Box mt={1} display='flex' width='100%'>
                      {state.checkedA === true ? (
                        user.email ? (
                          <Typography
                            variant='subtitle2'
                            component='div'
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignItems: 'end',
                            }}
                          >
                            <Chip
                              label={user.email}
                              style={{
                                margin: '10px 0',
                                backgroundColor: 'aliceblue',
                              }}
                            />
                          </Typography>
                        ) : (
                            alert('Email is Not Provided')
                          )

                      ) : ('')}
                      {state.checkedB === true ? (
                        user.mobile && user.mobile.length > 3 ? (
                          <Typography
                            variant='subtitle2'
                            component='div'
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <Chip
                              label={
                                (user?.u_country ? getPhoneNumber(user.mobile, user?.u_country) : user.mobile)}
                              // label={user.mobile}
                              style={{
                                margin: '10px',
                                backgroundColor: 'aliceblue',
                              }}
                            />
                          </Typography>
                        ) : isPhoneUpdated === false ? (
                          <Box
                            style={{
                              marginLeft: '12px',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-end',
                            }}>
                            <ReactPhoneInput
                              value={userDetails.phone}
                              defaultCountry={getCountryCode(user?.u_country)?.toLowerCase()}
                              country={getCountryCode(user?.u_country)?.toLowerCase()}
                              countryCodeEditable={false}
                              onChange={(e, data) => {
                                checkPhone(e);
                                setUserDetails({ ...userDetails, phone: e });
                              }}
                              searchPlaceholder='Phone'
                              placeholder='Phone Number'
                              label='Phone Number'
                              component={TextField}
                              inputExtraProps={{
                                margin: 'normal',
                                autoComplete: 'phone',
                                name: 'Phone',
                                required: true,
                                autoFocus: true,
                                error: error,
                                helperText: error && errorMsg,
                              }}
                              enableSearchField={true}
                            />
                            <Typography
                              onClick={updatePhone}
                              color='primary'
                              style={{ cursor: 'pointer' }}
                            >
                              Update
                              </Typography>
                          </Box>
                        ) : phone && phone != 1 ? (
                          <Typography
                            variant='subtitle2'
                            component='div'
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <Chip
                              label={
                                (user?.u_country ? getPhoneNumber(user.mobile, user?.u_country) : user.mobile)
                              }
                              // label={user.mobile}
                              style={{
                                marginBottom: '5px',
                                marginLeft: '10px',
                                backgroundColor: 'aliceblue',
                              }}
                            />
                          </Typography>
                        ) : (
                                ''
                              )
                      ) : (
                          ''
                        )}
                    </Box>
                  </Collapse>
                  {isCommError &&
                    <FormHelperText style={{ color: '#ff1744' }}>
                      Please select at least one mode of communication.</FormHelperText>}
                  <Typography
                    variant='subtitle1'
                    style={{ marginTop: '3rem', fontSize: '13px' }}
                    component='div'>
                    By giving this item, I accept that I have read,
                        understood and agree to abide by the{' '}
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <ul
                            style={{ margin: '0 3px', padding: '6px 12px' }}
                          >
                            <li>I am the owner of this item.</li>
                            <li>
                              I agree to wait at least 24 hours before
                              accepting requests so the entire community can
                              view the item.
                                </li>
                            <li>
                              I agree to honor my give and complete the give
                              with the accepted requestor safely.
                                </li>
                          </ul>
                        </React.Fragment>
                      }>
                      <span>
                        {/* <InfoIcon style={{ fontSize: '1rem' }}></InfoIcon>{' '} */}
                        <span style={{ color: '#2196f3' }}>
                          Community Guidelines
                            </span>
                      </span>
                    </HtmlTooltip>
                        ,{' '}
                    <a
                      href='/termsofuse'
                      target='_new'>
                      Terms of Use{' '}
                    </a>{' '}
                        and{' '}
                    <a href='/privacypolicy' target='_new'>
                      Privacy Policy.
                        </a>
                  </Typography>
                  <Box display='flex' flexDirection='row-reverse'>
                    <div className={classes.wrapper}>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={SavePickup}
                        disabled={loading}>
                        Confirm
                          </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    <div className={classes.wrapper}>
                      <Button
                        variant='outlined'
                        color='secondary'
                        style={{ marginRight: '1rem' }}
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        Cancel
                          </Button>
                    </div>
                  </Box>
                </>
              ) : (
                  <Typography
                    variant='h4'
                    component='div'
                    style={{
                      margin: '2rem 0',
                      color: '#22B11F',
                      textAlign: 'center',
                    }}>
                    <p>You have successfully accepted this request from the
                        requestor.</p>
                    <p>
                      {' '}
                    We will notify you once the requestor confirms the pickup
                    address.
                  </p>
                  </Typography>
                )}
            </>
            <Box width='100%' display='none'>
              <LargeRectangleAds></LargeRectangleAds>{' '}
            </Box>
          </Grid>
          )}
      </Grid>
    </Box >
  );
};
export { MyRequest };