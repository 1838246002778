import {
  createStore,
  Action,
  action,
  createTypedHooks,
} from 'easy-peasy';
import { persistReducer, persistStore } from 'redux-persist';
//import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import localforage from 'localforage';
//  import { union, difference } from 'lodash';
import pkg from './../package.json';

//import models from './components/models/models';

interface AuthModel {
  token: string | null;
  user: any | null;
  isLoggedIn: boolean;
  isVerifyEmailSent: boolean;
  isJustRegistered: boolean;
  logout: Action<AuthModel>;
  login: Action<AuthModel, { token: string; user: object }>;
  updateUser: Action<AuthModel, { user: object }>;
  updateVerifyEmailSent: Action<AuthModel, { isVerifyEmailSent: boolean }>;
  updateJustRegistered: Action<AuthModel, { isJustRegistered: boolean }>;
}

interface SnackbarModel {
  message: string | null;
  open: boolean | false;
  severity: string | 'info';
  showAlert: Action<SnackbarModel, { message: string; open: boolean; severity: string }>;
}

interface NetworkModel {
  fetching: boolean;
  setFetching: Action<NetworkModel, { fetching: boolean }>;
}

interface ViewModel {
  page: string | null;
  index: number | 0;
  setPage: Action<ViewModel, { index: number; page: string }>;
}

const models: StoreModel = {
  auth: {
    token: null,
    user: null,
    isLoggedIn: false,
    isVerifyEmailSent: false,
    isJustRegistered: false,
    logout: action(state => {
      state.token = null;
      state.user = null;
      state.isLoggedIn = false;
      localStorage.removeItem("token");
    }),
    login: action((state, { token, user }) => {
      state.token = token;
      state.user = user;
      state.isLoggedIn = true;
      localStorage.setItem('token', token);
    }),
    updateUser: action((state, { user }) => {
      state.user = user;
      if (user == null) {
        state.token = null;
        state.user = null;
        state.isLoggedIn = false;
        localStorage.removeItem("token");
      }
    }),
    updateVerifyEmailSent: action((state, { isVerifyEmailSent }) => {
      state.isVerifyEmailSent = isVerifyEmailSent;
    }),
    updateJustRegistered: action((state, { isJustRegistered }) => {
      state.isJustRegistered = isJustRegistered;
    })
  },

  alert: {
    message: null,
    open: false,
    severity: 'info',
    showAlert: action((state, { message, open, severity }) => {
      if (open) {
        state.message = message;
        state.severity = severity;
      }
      state.open = open;
    }),
  },

  view: {
    page: null,
    index: 0,
    setPage: action((state, { index, page }) => {
      state.page = page;
      state.index = index;
    }),
  },
  network: {
    fetching: false,
    setFetching: action((state, { fetching }) => {
      state.fetching = fetching;
      //        state.fetching = false;
    }),
  },
};

export type StoreModel = {
  auth: AuthModel;
  view: ViewModel;
  network: NetworkModel;
  alert: SnackbarModel;
};

const persistConfig = {
  key: pkg.name,
  storage: localforage,
  // Setting this to "autoMergeLevel2" allows us to shallow merge
  // the top two levels of our store, everything else will be
  // overwritten by the incoming persisted fields, delete cache to resync
  // stateReconciler: autoMergeLevel2,
  serialize: process.env.NODE_ENV !== 'development',
  deserialize: process.env.NODE_ENV !== 'development',
  debug: process.env.NODE_ENV === 'development',
};

const config = {
  reducerEnhancer: (reducer: any) => {
    return persistReducer(persistConfig, reducer);
  },
  devTools: process.env.NODE_ENV === 'development',
};


const { useStoreActions, useStoreState, useStoreDispatch } = createTypedHooks<StoreModel>();

export { useStoreActions, useStoreState, useStoreDispatch };

const store = createStore(models, config);

const persistor = persistStore(store);

export { store, persistor };