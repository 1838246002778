import React from 'react'
import BundleItem from './BundleItem';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import GiveBooksStyles from './../../../styles/GiveBooks.styles';
// import Carousel from "react-material-ui-carousel";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


function BundleContainer(props) {
  const classes = GiveBooksStyles();
  const bookList = props.itemList;
  const responsive = {
      desktop: {
        breakpoint: { max: 5000, min: 800 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
        partialVisibilityGutter: 70,
      },
      tablet: {
        breakpoint: { max: 800, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
  };

    return (
        <Grid item xs={12} className={classes.bundleContainer}>
            {
                props.bundleLoading==true ?
                    <div style={{textAlign: 'center'}}>
                        <img width="50" src="/assets/images/loading-cgk.gif"/>
                        <div style={{fontSize:"10px"}}>Loading items...</div>
                    </div> : ''
            }
              {bookList && bookList.map(function (book, index) {
                return (
                  // <Carousel autoPlay={false}  responsive={responsive} partialVisible={false}> 
                  <Box ml={2} style={{margin: '3px'}}> 
                    <BundleItem item={book} key={index} itemUpdate={props.updateItem} itemEdit={props.editItem} setBundleId={props.setBundleId}  setItemId={props.setItemId}  setBundleList={props.setBundleList} clearForm={props.clearForm} />
                  </Box>
                  // </Carousel>
                )
              })}
          {/* {bookList && bookList.length >0 &&
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h5' component='div'>Set of {bookList.length}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
              { bookList.map(function (book, index) {
                  return <BundleItem item={book} key={index} itemUpdate={props.updateItem} itemEdit={props.editItem} setBundleId={props.setBundleId}  setItemId={props.setItemId}  setBundleList={props.setBundleList} clearForm={props.clearForm} />
                })}
            </ExpansionPanelDetails>
            </ExpansionPanel>
          } */}
        </Grid>
    )
}

export default BundleContainer
