import React from 'react'
import { makeStyles, Typography, Box, ButtonGroup, Button, Grid, Hidden, Tabs, Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { HorizontalAds } from "../../ui/ads/HorizontalAds";
import { useStoreState } from './../../../hooks';
import { navigate } from '@reach/router';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    header: {
        background: '#F2FFF1',
        paddingTop: '1rem',
        display: 'none'
    },
    bg: {
        [theme.breakpoints.down('sm')]: {
            background: '#F2FFF1',

            '&:nth-child(odd)': {
                background: '#fff',
            },

            '&:nth-child(even)': {
                background: '#fff',
            },

            '& .makeStyles-header-1': {
                paddingTop: 0
            },

            '& header': {
                boxShadow: 'unset',

                '& .MuiIconButton-root': {
                    display: 'none'
                },

                '& .makeStyles-headerActions-21': {
                    display: 'block'
                }
            }
        }
    },
    container: {
        display: 'flex',
        padding: '4rem 0',
        margin: '0 auto',
        maxWidth: '1200px',
        minWidth: '650px',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            minWidth: 'unset',
            padding: '1rem'
        },

        [theme.breakpoints.only("md")]: {
            maxWidth: "960px"
        },

        '& section:first-child': {
            margin: '4rem 0 1rem 0',
            padding: '0 1rem',

            [theme.breakpoints.down('sm')]: {
                margin: '1rem 0',
                padding: 0
            }
        },


        '& section': {
            width: '50%',

            [theme.breakpoints.down('sm')]: {
                width: 'unset'
            }
        }
    },

    titleText: {
        color: '#231C07',
        fontSize: '28px',
        fontWeight: 'bold',
        letterSpacing: '0',
        lineHeight: '1.4',
        marginTop: '35px',
        marginBottom: '75px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '17px',
            marginBottom: '30px',
        }

    },

    primaryText: {
        color: '#22B11F',
    },

    stepTitle: {
        color: '#22B11F',
        fontSize: '24px',
        fontWeight: 'bold',
        letterSpacing: '0.11px',
        margin: '0'
    },

    stepSubTitle: {
        color: '#717171',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        margin: '0',
        lineHeight: '2'
    },

    stepContent: {
        color: '#717171',
        fontSize: '16px',
        letterSpacing: '0.15px',
        marginTop: '20px',
        textAlign: 'justify',
        marginBottom: '50px'
    },

    topBanner: {
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
        }
    },

    imgFluid: {
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        }
    },

    imgHolder: {
        [theme.breakpoints.down('sm')]: {
            width: '335px',
            height: '181px'
        }
    },

    imgsvgs: {
        padding: '0 20px',
        [theme.breakpoints.between('md','lg')]: {
            width: '546px'
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100vw'
        },
        [theme.breakpoints.between('sm','md')]: {
            width: '460px'
        },
    },

    mbSteps: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            paddingBottom: '20px',
        }

    },

    stepPadding: {
        [theme.breakpoints.down('sm')]: {
            padding: '15px 20px 0',
        }
    },

    banner: {
        background: `url('assets/images/banner.svg') center /contain no-repeat`,
        height: '80%',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '426px'
        }
    },

    flexItem: {
        flex: '1 1',
        margin: '1rem'
    },

    MuiTabTextColorPrimary: {
        color: '#fff !important',
        backgroundColor: '#231C07 !important',
    },

    customTabs: {
        height: 'auto',
        width: '100%',
        justifyContent: 'center',
    },
    customTabsItem: {
        color: '#525252',
        fontSize: '14px',
        backgroundColor: '#EFEFEF !important',

        [theme.breakpoints.down("sm")]: {
            fontSize: '22px',
        },


        'customTabsItem:first-child': {
            borderTopLeftRadius: '4px !important',
            borderBottomLeftRadius: '4px !important'
        },

        'customTabsItem:last-child': {
            borderTopRightRadius: '4px !important',
            borderBottomRightRadius: '4px !important'
        },

        // '& span': {
        //     fontWeight: 'bold !important',
        // }

    },
    active: {
        backgroundColor: '#231C07 !important',
        color: '#fff !important'
    },

    MuiTabRoot: {
        '& .Mui-selected': {
            backgroundColor: '#231C07 !important'
        }
    },
    MuiTabs: {
        root: {
            textTransform: 'none',
            '&$selected': {
                backgroundColor: '#231C07 !important'
            }
        },
    },
    stepsContainer: {
        display: 'flex',

        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            alignItems: 'center',
            padding: '15px 20px 0'
        }
    },
    stepImageHolder: {
        [theme.breakpoints.down("sm")]: {
            order: 2,
            marginTop: '20px'
        },
    },
    stepNumberHolder: {
        [theme.breakpoints.down("sm")]: {
            order: 1
        }
    },
    stepDescriptionHolder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        [theme.breakpoints.down("sm")]: {
            order: 3
        }
    },
    contenWrapper: {
    },
    marginLeft: {
        marginLeft: '33px',
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0
        }
    },
    marginRight: {
        marginRight: '33px',
        [theme.breakpoints.down("sm")]: {
            marginRight: 0
        }
    },
    sectionWrapper: {
        marginTop: '80px',
        [theme.breakpoints.down("sm")]: {
            marginTop: '40px'
        }
    },
    joinNowSectionWrapper: {
        marginTop: '80px', 
        marginBottom: '80px', 
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            marginTop: '40px'
        }
    }


}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function LearnMore() {
    const classes = useStyles(),
        [value, setValue] = React.useState(0),
        user = useStoreState(state => state.auth.user),
        handleChange = (event, newValue) => {
            setValue(newValue);
        },
        handleChangeIndex = (index) => {
            setValue(index);
        };

    const createButton= ( { type } ) => {
        const label= type && type === 'get' ? 'Get Now' : 'Give Now';
        return (
            <Typography
                variant='h5'
                style={{ fontSize: "16px", cursor: "pointer" }}
                component='div'
                onClick={(e) => {
                    if(type === 'get') {
                        navigate("/get");
                    }
                    else if (user) {
                        navigate("/give/books");
                    } else {
                        navigate("/login");
                    }
                }}
                color="primary"
            >
                { label }
            </Typography>  
        )
    };
    
    return (
        <section className={classes.root}>
            <HorizontalAds></HorizontalAds>
            <hr style={{
                position: 'relative',
                top: '32px',
                border: '1px solid #efefef',
                marginTop: '16px',
            }} />
            <ButtonGroup className={classes.customTabs} disableElevation variant="contained">
                <Button className={`${value === 0 ? classes.active : ''} ${classes.customTabsItem}`} onClick={() => handleChangeIndex(0)}>
                    How to Give
                </Button>
                <Button className={`${value === 1 ? classes.active : ''} ${classes.customTabsItem}`} onClick={() => handleChangeIndex(1)}>
                    How to Get
                </Button>
            </ButtonGroup>
            <SwipeableViews
                axis={'x-reverse'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0}>
                    <div>

                        <section className={classes.sectionWrapper}>
                            <div className={classes.stepsContainer}>
                                <div className={classes.stepImageHolder}>
                                    <img src="/assets/images/learnmore/category.svg" className={classes.imgsvgs} alt="" />
                                </div>
                                <div className={`${classes.stepNumberHolder} ${classes.marginLeft} ${classes.marginRight}`}>
                                    <img src="/assets/images/learnmore/steps.svg" className={classes.imgFluid} alt="" />
                                </div>
                                <div className={classes.stepDescriptionHolder}>
                                    <div className={classes.contenWrapper}>
                                        <div>
                                            <h4 className={classes.stepTitle}>Choose your category</h4>
                                            <h6 className={classes.stepSubTitle}>From Home</h6>
                                        </div>
                                        <p className={classes.stepContent}>
                                            Register, Verify your email, Sign-in and voila, you are ready to give.
                                            Currently, we support Books and new categories are being added soon.
                                            Let us know which ones you care about most to be added first.
                                        </p>
                                    </div>
                                    
                                    { createButton( { type: 'give' } ) }
                                </div>
                            </div>
                        </section>

                        <section className={classes.sectionWrapper}>
                            <div className={classes.stepsContainer}>
                                <div className={classes.stepNumberHolder}>
                                    <img src="/assets/images/learnmore/step2.svg" className={classes.imgFluid} alt="" />
                                </div>

                                <div className={`${classes.stepDescriptionHolder} ${classes.marginLeft} ${classes.marginRight}`}>
                                    <div className={classes.contenWrapper}>
                                        <div>
                                            <h4 className={classes.stepTitle}>Simplified Giving. Search. Upload.</h4>
                                            <h6 className={classes.stepSubTitle}>From Give</h6>
                                        </div>
                                        <p className={classes.stepContent}>
                                            We have made giving easy. Search for your book by author or title.
                                            Confirm its condition and type. Upload up to 4 images. Submit. You can also give
                                            up to five books at once.
                                        </p>
                                    </div>
                                    
                                    { createButton( { type: 'give' } ) } 
                                </div>
                                
                                <div className={classes.stepImageHolder}>
                                    <img src="/assets/images/learnmore/upload-images.svg" style={{}} className={classes.imgsvgs} alt="" />
                                </div>
                            </div>
                        </section>

                        <section className={classes.sectionWrapper}>
                            <div className={classes.stepsContainer}>
                                <div className={classes.stepImageHolder}>
                                    <img src="/assets/images/learnmore/picup-loaction.svg" className={classes.imgsvgs} alt="" />
                                </div>
                                <div className={`${classes.stepNumberHolder} ${classes.marginLeft} ${classes.marginRight}`}>
                                    <img src="/assets/images/learnmore/step3.svg" className={classes.imgFluid} alt="" />
                                </div>
                                <div className={classes.stepDescriptionHolder}>
                                    <div className={classes.contenWrapper}>
                                        <div>
                                            <h4 className={classes.stepTitle}>Accept a Request. Specify where and when to meet</h4>
                                            <h6 className={classes.stepSubTitle}>From MyGives</h6>
                                        </div>
                                        <p className={classes.stepContent}>
                                            No more emailing back and forth. View all your requests in one place by location and need.
                                            Accept any request.
                                            Tell the requestor where and when to pick up the item.
                                        </p>
                                    </div>
                                    
                                    { createButton( { type: 'give' } ) } 
                                </div>
                            </div>
                        </section>                

                        <section className={classes.sectionWrapper}>
                            <div className={classes.stepsContainer}>
                                <div className={classes.stepNumberHolder}>
                                    <img src="/assets/images/learnmore/step4.svg" className={classes.imgFluid} alt="" />
                                </div>

                                <div className={`${classes.stepDescriptionHolder} ${classes.marginLeft} ${classes.marginRight}`}>
                                    <div className={classes.contenWrapper}>
                                        <div>
                                            <h4 className={classes.stepTitle}>Meet the receiver and Give the item</h4>
                                            <h6 className={classes.stepSubTitle}>at your convenience</h6>
                                        </div>
                                        <p className={classes.stepContent}>
                                            Receiver will confirm your specified location and time slot.
                                            Acknowledge it. Sit back and relax. If the receiver fails to show,
                                            you can always choose a different requestor. Always practice
                                            safety measures when meeting people for a pickup by choosing
                                            safe public areas for a handoff.
                                        </p>
                                    </div>
                                    
                                    { createButton( { type: 'give' } ) }  
                                </div>
                                
                                <div className={classes.stepImageHolder}>
                                    <img src="/assets/images/learnmore/Parkmeet-1.svg" style={{}} className={classes.imgsvgs} alt="" />
                                </div>
                            </div>
                        </section>    
                    
                        <section className={classes.sectionWrapper}>
                            <div className={classes.stepsContainer}>
                                <div className={classes.stepImageHolder}>
                                    <img src="/assets/images/learnmore/Rating.svg" className={classes.imgsvgs} alt="" />
                                </div>
                                <div className={`${classes.stepNumberHolder} ${classes.marginLeft} ${classes.marginRight}`}>
                                    <img src="/assets/images/learnmore/step5.svg" className={classes.imgFluid} alt="" />
                                </div>
                                <div className={classes.stepDescriptionHolder}>
                                    <div className={classes.contenWrapper}>
                                        <div>
                                            <h4 className={classes.stepTitle}>Rate the receiver</h4>
                                            <h6 className={classes.stepSubTitle}>Share your experience</h6>
                                        </div>
                                        
                                        <p className={classes.stepContent}>
                                            Build our community as a trusted place to give and get. By rating and sharing,
                                            you can help others learn from your experience as they give.
                                        </p>
                                    </div>
                                    
                                    { createButton( { type: 'give' } ) } 
                                </div>
                            </div>
                        </section>

                        <section className={classes.joinNowSectionWrapper}>
                            {
                                !user && <Box my={4}>
                                    <Button variant="contained" color="primary" onClick={() => navigate('/register')} >Join Now!</Button>
                                </Box>
                            }
                        </section>
                   
                    </div>

                </TabPanel>

                <TabPanel value={value} index={1}>
                    <div>

                        <section className={classes.sectionWrapper}>
                            <div className={classes.stepsContainer}>
                                <div className={classes.stepImageHolder}>
                                    <img src="/assets/images/learnmore/browse-item.svg" className={classes.imgsvgs} alt="" />
                                </div>
                                <div className={`${classes.stepNumberHolder} ${classes.marginLeft} ${classes.marginRight}`}>
                                    <img src="/assets/images/learnmore/steps.svg" className={classes.imgFluid} alt="" />
                                </div>
                                <div className={classes.stepDescriptionHolder}>
                                    <div className={classes.contenWrapper}>
                                        <div>
                                            <h4 className={classes.stepTitle}>Browse the items, make a request</h4>
                                            <h6 className={classes.stepSubTitle}>From Get</h6>
                                        </div>
                                        <p className={classes.stepContent}>
                                            Search for items being gifted in your neighborhood, city, state or country. Filter on condition and/or availability.
                                            (Remember to set your city and neighborhood in settings or when you filter) Request a book you like.
                                            Share with the giver why you would like it.
                                        </p>
                                    </div>
                                    
                                   { createButton( { type:'get' }) } 
                                </div>
                            </div>
                        </section>

                        <section className={classes.sectionWrapper}>
                            <div className={classes.stepsContainer}>
                                <div className={classes.stepNumberHolder}>
                                    <img src="/assets/images/learnmore/step2.svg" className={classes.imgFluid} alt="" />
                                </div>

                                <div className={`${classes.stepDescriptionHolder} ${classes.marginLeft} ${classes.marginRight}`}>
                                    <div className={classes.contenWrapper}>
                                        <div>
                                            <h4 className={classes.stepTitle}>Wait for approval from giver</h4>
                                            <h6 className={classes.stepSubTitle}>From MyGets</h6>
                                        </div>
                                        <p className={classes.stepContent}>
                                            Once request is made, the giver has 24 hours to choose from the
                                            many requests based on reasons shared, queue, or proximity to
                                            their location. If they accept your request, confirm the pickup
                                            time and location they specify. If not, try another book!
                                        </p>
                                    </div>
                                    
                                    { createButton( { type:'get' }) }
                                </div>
                                
                                <div className={classes.stepImageHolder}>
                                    <img src="/assets/images/learnmore/wait.svg" style={{}} className={classes.imgsvgs} alt="" />
                                </div>
                            </div>
                        </section>

                        <section className={classes.sectionWrapper}>
                            <div className={classes.stepsContainer}>
                                <div className={classes.stepImageHolder}>
                                    <img src="/assets/images/learnmore/Parkmeet-1.svg" className={classes.imgsvgs} alt="" />
                                </div>
                                <div className={`${classes.stepNumberHolder} ${classes.marginLeft} ${classes.marginRight}`}>
                                    <img src="/assets/images/learnmore/step3.svg" className={classes.imgFluid} alt="" />
                                </div>
                                <div className={classes.stepDescriptionHolder}>
                                    <div className={classes.contenWrapper}>
                                        <div>
                                            <h4 className={classes.stepTitle}>Meet the giver and receive the item</h4>
                                            <h6 className={classes.stepSubTitle}>at your convenience</h6>
                                        </div>
                                        <p className={classes.stepContent}>
                                            Confirm you will be at the specified location, and share a
                                            convenient time from the time slots giver shares. Once giver
                                            acknowledges your message, make sure you show up on time
                                            to pick up the book. Always practice safety measures when
                                            meeting someone or going to a new location.
                                        </p>
                                    </div>

                                    { createButton( { type:'get' }) }
                                </div>
                            </div>
                        </section>

                        <section className={classes.sectionWrapper}>
                            <div className={classes.stepsContainer}>
                                <div className={classes.stepNumberHolder}>
                                    <img src="/assets/images/learnmore/step4.svg" className={classes.imgFluid} alt="" />
                                </div>

                                <div className={`${classes.stepDescriptionHolder} ${classes.marginLeft} ${classes.marginRight}`}>
                                    <div className={classes.contenWrapper}>
                                        <div>
                                            <h4 className={classes.stepTitle}>Rate the giver</h4>
                                            <h6 className={classes.stepSubTitle}>Share your experience</h6>
                                        </div>
                                        <p className={classes.stepContent}>
                                            Build our community as a trusted place to give and get. Share
                                            your experience and rate the giver and confirm the condition of
                                            the item met the details given. This will help others learn from
                                            your experience and flag any issues with givers.
                                        </p>
                                    </div>
                                
                                    { createButton( { type:'get' }) } 
                                </div>
                            
                            <div className={classes.stepImageHolder}>
                                <img src="/assets/images/learnmore/Rating.svg" style={{}} className={classes.imgsvgs} alt="" />
                            </div>
                        </div>
                        </section>                       

                        <section className={classes.sectionWrapper}>
                            <div className={classes.stepsContainer}>
                                <div className={classes.stepImageHolder}>
                                    <img src="/assets/images/learnmore/give-once.svg" className={classes.imgsvgs} alt="" />
                                </div>
                                <div className={`${classes.stepNumberHolder} ${classes.marginLeft} ${classes.marginRight}`}>
                                    <img src="/assets/images/learnmore/step5.svg" className={classes.imgFluid} alt="" />
                                </div>
                                <div className={classes.stepDescriptionHolder}>
                                    <div className={classes.contenWrapper}>
                                        <div>
                                            <h4 className={classes.stepTitle}>Pay it forward by giving at least once or spreading the word.</h4>
                                            <h6 className={classes.stepSubTitle}>To keep the community going</h6>
                                        </div>
                                        
                                        <p className={classes.stepContent}>
                                            Like what we do? Support us to keep this platform free for all.
                                            Spread the word on social media. Give if you can. Help someone else get something.
                                        </p>
                                    </div>
                                    
                                    <Typography
                                        variant='h5'
                                        style={{
                                            fontSize: "16px",
                                            cursor: "pointer",
                                        }}
                                        component='div'
                                        onClick={(e) => {
                                            navigate("/get");
                                        }}
                                        color="primary"
                                    >
                                        Get Now{" "}
                                    </Typography>  
                                </div>
                            </div>

                        </section>
                        
                        <section className={classes.joinNowSectionWrapper}>
                        {
                            !user && <Box my={4}>
                                <Button variant="contained" color="primary" onClick={() => navigate('/register')} >Join Now!</Button>
                            </Box>
                        }
                    </section>

                    </div>

                </TabPanel>

            </SwipeableViews>
        </section >
    );
}


export default LearnMore
