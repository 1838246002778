import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { Helmet } from "react-helmet";
import Grid from '@material-ui/core/Grid';
//import  useInterval  from './../../hooks/useInterval';

import 'typeface-roboto';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '500px',
    margin: '30px auto 0',
    width: '50%',
    textAlign: 'center',
    fontSize: '22px',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      margin: '100px auto 0',
      width: '100%',
    },

  },
}));



export default function NotFound(RouteComponentProps) {
  const [count, setCount] = useState(5);

  // useInterval(
  //   () => {
  //     if (count <= 0) {
  //       navigate('/');
  //       return;
  //     }
  //     setCount(count - 1);
  //   },
  //   count >= 0 ? 1000 : null
  // );

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet title="Not Found" />

      <div>
        <img src="./assets/images/404.svg" width="60%" />
        <p>Oops. Missed a Boat.
              <Typography

            component='span'
            color='Primary'
            style={{ cursor: "pointer" }}
          >
            {" "}
            <a
              href='/'
              style={{
                color: "#22B11F", fontSize: '22px',
                fontWeight: '700'
              }}
            // target='_blank'
            >
              Let's go home
              </a>
          </Typography></p>
      </div>

      {/* <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>404 Not Found</Typography>
            </Grid>
        </Grid> */}
      {/* <Typography variant="subtitle2" gutterBottom>Returning home in {count} ... </Typography> */}
    </div>
  )
};

export { NotFound };
