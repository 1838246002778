import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { navigate } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useStyles, AntTab, AntTabs } from "./../../styles/AntTabs";
import Typography from "@material-ui/core/Typography";
import MyGivesStyles from "./../../styles/Get.styles";
import MyGiveItem from "./../ui/my/MyGiveItem";
import { itemApi } from "../../api";
import { appSettings } from "./../../constants/appSettings";
import Skeleton from '@material-ui/lab/Skeleton';
import { HorizontalAds } from "../ui/ads/HorizontalAds";
import { useStoreState } from "./../../hooks";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { VerticalAds } from "../ui/ads/VerticalAds";

const GetStyles = makeStyles((theme) => ({
  productList: {
    display: "flex",
    flexWrap: "wrap",
    minHeight: "615px",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "1rem 0",
      alignItems: "center",
    },
  },
}));

const mobileCSS = makeStyles((theme) => ({
  filterContainer: {
    display: 'none',
    [theme.breakpoints.down("sm")]: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px',
      alignItems: 'center',
      padding: '0 10px',
      boxSizing: 'border-box',
      marginBottom: '10px'
    },
  },
  filterLabelWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      style={{ border: "none" }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SortMenuItems = ({
  mobileStyles,
  selectedFilterIndex,
  handleSortByChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null),
    open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSort = (event) => {
    handleSortByChange(event);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}>
        <img src='/assets/images/icon_sort.svg' alt='Sort' />
        <Typography
          variant='subtitle2'
          component='div'
          style={{ paddingLeft: "5px" }}
          className={mobileStyles.filterLabel}>
          Sort
        </Typography>
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}>
        <MenuItem
          onClick={handleSort}
          selected={selectedFilterIndex == 1}
          value={1}
          style={{ minWidth: "unset" }}>
          Newest First
        </MenuItem>
        <MenuItem
          onClick={handleSort}
          selected={selectedFilterIndex == 2}
          value={2}
          style={{ minWidth: "unset" }}>
          Oldest First
        </MenuItem>
      </Menu>
    </div>
  );
};

export const MyGives = (props) => {
  const classes = GetStyles(),
    mobileStyles = mobileCSS(),
    [itemList, setMyItemList] = React.useState([]),
    [value, setValue] = React.useState(props["filter-value"] ? +props["filter-value"] : 1),
    [selectedTab, setSelectedTab] = React.useState(""),
    skeletonNumbers = [...Array(6).keys()],
    [loading, setLoading] = React.useState(false),
    [refresh, setRefresh] = React.useState(false),
    [selectedFilterIndex, setSelectedFilterIndex] = React.useState(2),
    isLoggedIn = useStoreState((state) => state.auth.isLoggedIn),
    handleChange = (event, newValue) => {
      navigate('/my-gives/' + newValue, { replace: true });

      setValue(newValue);

      if (value === 3 && newValue === value) {
        setRefresh(!refresh);
      }
    };

  React.useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }


    setMyItemList([]);
    setPaginationKey({ key: { limit: 9 } });
    let tab = "all",
      filterIndex = 2;

    switch (value) {
      case 1:
      case 2:
        tab = "submitted"; //Awaiting Requests
        break;
      case 3:
        tab = "accepted"; //Accepted Requests
        break;
      case 4:
        tab = "given"; //Completed Requests
        filterIndex = 1;
        break;
      case 5:
        tab = "new"; // Pending Guves
        filterIndex = 1;
        break;
      default:
        tab = "all";
        filterIndex = 1;
        break;
    }

    setSelectedTab(tab);
    setSelectedFilterIndex(filterIndex);
    fetchMoreData(tab, true, filterIndex);
  }, [value, refresh]);


  const classes1 = useStyles(),
    localClasses = MyGivesStyles(),
    handleSortByChange = (event) => {
      setSelectedFilterIndex(event.target.value);
      setMyItemList([]);
      fetchMoreData(selectedTab, true, event.target.value);
    };

  function updateItemObject(item) {
    let nemItem = {
      ...item,
      itemTitle: [],
      itemImage: [],
      category: [],
      condition: [],
      id: [],
    };
    if (item && item.title) {
      let keys = Object.keys(item.title);
      keys.map(function (itemTitle, i) {
        nemItem.id[i] = itemTitle;
        nemItem.itemTitle[i] = item.title[itemTitle];
        let img = item.images[itemTitle].startsWith("http")
          ? item.images[itemTitle]
          : appSettings.MEDIA_URL + item.images[itemTitle];
        nemItem.itemImage[i] = img;
        nemItem.itemImage[i] = nemItem.itemImage[i].replace("\\", "/");
        nemItem.itemImage[i] = nemItem.itemImage[i].replace("\\", "/");
        let cat_condition = item.cat_condition[itemTitle].split("_");
        nemItem.category[i] = cat_condition[0];
        nemItem.condition[i] = cat_condition[1];
      });
    }
    return nemItem;
  }

  const processItems = (noItemsMessage) => {
    return (
      <>
        {itemList.length > 0
          ? <>
            <Box className={localClasses.filterChipsContainer} >
              <section className={localClasses.filtersWrapper}>
                {/* <Typography variant="h4" component="div">
                    Filters
                  </Typography> */}
              </section>
              <div className={localClasses.sortWrapper} style={{ display: "flex", alignItems: "center", border: '1px solid #dedede', borderRadius: '4px', padding: '0px 0px 1px 8px' }}>
                <InputLabel id='sort-by-label' className={localClasses.labelAutoWidth}>
                  <Typography variant='h4' component='div' style={{ fontWeight: '400' }}>
                    Sort by:&nbsp;
                    </Typography>
                </InputLabel>
                <FormControl
                  className={localClasses.selectSortBy}
                  style={{
                    minWidth: "unset",
                    fontWeight: "unset",
                    marginTop: "1.5 rem",
                  }}>
                  <Select
                    labelId='sort-by-label'
                    value={selectedFilterIndex}
                    onChange={handleSortByChange}
                    style={{ minWidth: "unset", marginTop: "1.5 rem" }}>
                    {/* <MenuItem value={0} style={{ minWidth: "unset" }}>
                        Priority
                      </MenuItem> */}
                    <MenuItem value={1} style={{ minWidth: "unset" }}>
                      Newest First
                      </MenuItem>
                    <MenuItem value={2} style={{ minWidth: "unset" }}>
                      Oldest First
                      </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Box>
            <div className={mobileStyles.filterContainer}>
              <div className={mobileStyles.filterLabelWrapper}>
                <SortMenuItems
                  mobileStyles={mobileStyles}
                  selectedFilterIndex={selectedFilterIndex}
                  handleSortByChange={handleSortByChange}
                />
              </div>
            </div>
          </>
          : null
        }

        <InfiniteScroll
          dataLength={itemList.length}
          next={fetchMoreData}
          hasMore={hasMore}>
          <Box display='flex' flexWrap='wrap' className={classes.productList}>
            {itemList.length === 0 && loading == false ? (
              <div
                style={{
                  fontSize: "18px",
                  paddingTop: "50px",
                  width: "100%",
                  textAlign: "center",
                }}>
                <Typography
                  style={{
                    color: "rgb(33, 150, 243)",
                    letterSpacing: "0.5px",
                  }}
                  component='div'
                  variant='body2'
                >
                  {noItemsMessage}
                </Typography>
              </div>
            ) : (
                ""
              )}
            {itemList &&
              itemList.map(function (item, index) {
                if (item && item.title) {
                  let nemItem = updateItemObject(item);
                  if (nemItem.itemTitle.length < 1) return;
                  return (
                    <MyGiveItem item={nemItem} key={index} handleTab={handleChange} />
                  );
                } else {
                  return;
                }
              })}

            {loading &&
              skeletonNumbers.map(el => <Box key={el} margin='0 20px 20px 0'>
                <Skeleton variant="rect" width={289} height={206} animation="wave" />
                <Skeleton variant="circle" width={40} height={40} animation="wave" />
                <Skeleton variant="text" animation="wave" />
              </Box>)
            }
          </Box>
        </InfiniteScroll>
      </>
    );
  };

  const [hasMore, setMore] = React.useState(true),
    [paginationKey, setPaginationKey] = React.useState({ key: { limit: 9 } }),
    fetchMoreData = (tab, reset, newFilterIndex) => {
      let selected = selectedTab,
        sortIndex = selectedFilterIndex,
        filter = null;

      if (tab) {
        selected = tab;
      }

      if (value === 2) {
        filter = { awaiting_requests: false };
      } else if (value === 1) {
        filter = { awaiting_requests: true };
      }

      setLoading(true);
      let pagination = paginationKey;
      if (reset) {
        pagination = { key: { limit: 9 } };
        setPaginationKey({ key: { limit: 9 } });
        sortIndex = newFilterIndex;
      }

      itemApi.getMyGivesList(
        selected,
        filter,
        sortIndex,
        pagination,
        function (response) {
          if (response && response.data) {
            if (itemList && itemList.length > 0) {
              if (reset) {
                setMyItemList(response.data);
              } else {
                let merge = itemList.concat(response.data);
                setMyItemList(merge);
              }
            } else {
              setMyItemList(response.data);
            }
          }
          if (response.page && response.page && response.page.key) {
            let key = { ...response.page };
            key.limit = 9;
            setPaginationKey({ key });
            setMore(true);
          } else {
            setMore(false);
          }
          setLoading(false);
        },
        function (response) {
          setLoading(false);
        }
      );
    };

  const giveLink = (msg, linkName) => {
    return (
      <>
        {msg}
        <Typography
          component='span'
          onClick={() => navigate("/give/books")}
          color='primary'
          style={{ cursor: "pointer" }}>
          {linkName}
        </Typography>
      </>
    );
  };

  return (
    <>
      <Box width='100%' display='flex'>
        <div className={classes1.root} >
          <div>
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label='My Gives Tabs'
              variant='scrollable'
              scrollButtons='on'>
              <AntTab label='All' />
              <AntTab label='Awaiting Requests' />
              <AntTab label='Pending Requests' />
              <AntTab label='Accepted Requests' />
              <AntTab label='Completed Requests' />
              <AntTab label='Pending Gives' />
            </AntTabs>
            <Typography className={classes1.padding} />
          </div>
          <TabPanel value={value} index={0} className={localClasses.root}>
            {processItems(giveLink("Oops. You haven't given yet. ", "Start?"))}
          </TabPanel>
          <TabPanel value={value} index={1} className={localClasses.root}>
            {processItems(giveLink("No awaiting requests yet. "))}
          </TabPanel>
          <TabPanel value={value} index={2} className={localClasses.root}>
            {processItems(giveLink("No pending requests yet. "))}
          </TabPanel>
          <TabPanel value={value} index={3} className={localClasses.root}>
            {processItems(giveLink("No requests accepted. ", "Give Now?"))}
          </TabPanel>
          <TabPanel value={value} index={4} className={localClasses.root}>
            {processItems("No completed gives yet. ")}
          </TabPanel>
          <TabPanel value={value} index={5} className={localClasses.root}>
            {processItems(giveLink("No saved gives. ", "Give now?"))}
          </TabPanel>
        </div>

        <Box width='20%' mt={2}>
          <VerticalAds></VerticalAds>
        </Box>
      </Box>
      <Box width='100%'>
        <HorizontalAds></HorizontalAds>
      </Box>
    </>
  );
};
