import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    padding: "4rem 0",
    margin: "0 auto",
    maxWidth: "1200px",
    minWidth: "650px",

    [theme.breakpoints.only("md")]: {
      maxWidth: "960px"
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minWidth: "unset",
      padding: "1rem",
    },

    "& section:first-child": {
      margin: "4rem 0 1rem 0",
      padding: "0 1rem",

      [theme.breakpoints.down("sm")]: {
        margin: "1rem 0",
        padding: 0,
      },
    },

    "& section": {
      width: "50%",

      [theme.breakpoints.down("sm")]: {
        width: "unset",
      },
    },
  },

  title: {
    fontSize: "24px",
    fontWeight: "bold",
    letterSpacing: "0.11px",
    margin: "0",
  },

  content: {
    marginTop: '30px',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.5px'
  },

  imgsvgs: {
    marginTop: "75px",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      padding: "0 20px",
      marginTop: "20px",
    },
  },

  customPadding: {
    [theme.breakpoints.down("sm")]: {
      padding: "15px 20px 0",
    },
  },

}));

function Covid19() {
  const classes = useStyles();

  return (
    <section style={{ margin: "30px 0 " }}>
      <Grid container>
        <Grid container item sm={6} xs={12} className={classes.customPadding}>
          <Grid item xs={12}>
            <h4 className={classes.title}>Our Covid-19 Message</h4>
            <p className={classes.content}>
              We are living in exceptional times and no community has remained
              untouched globally by the impact of Covid-19. </p>

            <p className={classes.content}>Now, more than ever,
            there is a reason to reach out and touch the lives of others, in
            your neighborhood and beyond - virtually or through a kind gesture
              of gifting. </p>

            <p className={classes.content} color="primary" style={{
              borderLeft: '8px solid #22B11F',
              color: '#22B11F', fontSize: '18px', fontWeight: '600', paddingLeft: '15px'
            }}>
              <i style={{}} >We believe that we can all share what we have, so
              everyone can have more. Our belief led us to begin this effort a
              while ago and now, to adjust it to these times.</i></p>

            <p className={classes.content}>
              We understand that normal pickups and delivery are not possible currently.
            But we hope that people can still find safe ways to leave gifts for others: at the community center, by the mailbox, garage or common areas of an apartment building.</p>

            {/* <p className={classes.content}>
              Whatever
              you do, please take the customary precautions advised by public
              health officials, and protect yourself and others.
              </p> */}


            <p className={classes.content}>
              The gift of sharing a much loved book with another is a joy. </p>

            <p className={classes.content}>
              Since public libraries are closed and bookstores are no longer the haven they used to be, we hope our platform will  help those looking for the simple pleasure of reading and sharing in these tumultuous times.

          </p>




            <p className={classes.content}>
              We started with books, but we are not stopping there. We are
              reorienting our plans to see how we can help communities by adding
              necessary categories first. We look forward to your feedback!
            </p>

            <p className={classes.content}>
              <b>Stay safe and strong!</b>
            </p>

            <Box style={{ margin: '50px 0' }}>
              <Typography color="primary"><b>-Kindeeds Team</b></Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item sm={5} xs={12} style={{ textAlign: "right" }}>
          <img
            src='/assets/images/Covid-19.svg'
            style={{}}
            className={classes.imgsvgs}
            alt=''
          />
        </Grid>
      </Grid>
    </section>
  );
}

export default Covid19;
