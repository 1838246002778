import { navigate } from '@reach/router';
import { httpMethodTypes } from "../constants/";
import { useStoreActions, useStoreState } from '../store';
import axios from 'axios'
import {appSettings} from "../constants/appSettings";
const BASE_URL =  appSettings.BaseURL;

// import { useStoreState } from './../hooks';

let sessionExpired = false

function createRequestOptions(requestMethod, isAuthorized = false, requestDataBlk) {
    const params = requestDataBlk;
    const requestOptions = {
        method: requestMethod,
        headers: {
            "Accept": "application/json",
            // 'Accept-Language':selectedLang,
            'Content-Type': 'application/json'
        },
    };
    if ((requestMethod == httpMethodTypes.POST || requestMethod == httpMethodTypes.PUT) && requestDataBlk) {
        requestOptions.body = JSON.stringify(requestDataBlk);
    }
    if (isAuthorized) {
        //if (!authToken) {
        //const token = useStoreState(state => state.auth.token);
        const authToken = localStorage.getItem('token');
        //}
        requestOptions.headers.Authorization = 'Token ' + authToken;
    }
    return requestOptions;
}
let resStatus = 0;
function sendRequest(url, requestOptions, successCallBack, errorCallBack) {
    //    const view = useStoreState(state => state.view);
    //    const setLoading = useStoreActions(actions => actions.view.setLoading);
    // setLoading(true);
    return fetch(url, requestOptions)
        .then(res => {
            // setLoading(false);

            resStatus = res.status
            if (resStatus == 204 || resStatus == 500 || resStatus == 404) {
                return res;
            }
            return res.json()
        })
        .then(res => {
            // setLoading(false);
            switch (resStatus) {
                case 200:
                case 201:
                case 204:
                    if (sessionExpired) {
                        sessionExpired = false
                    }
                    return successCallBack(res, resStatus);
                    break;
                case 400:
                    if (res) {
                        if (errorCallBack) {
                            return errorCallBack(res, resStatus);
                        }
                        else {
                            var errors = ""
                            for (var key in res) {
                                if (res.hasOwnProperty(key)) {
                                    var val = res[key];
                                    errors += val + " "
                                } ``
                            }
                            alert(errors);
                        }
                    }
                    else {
                        const error = 'Failed to perform action'
                        alert(error);
                    }

                    break;
                case 401:
                    if (!sessionExpired) {
                        sessionExpired = true;
                        localStorage.removeItem('token');
                        navigate('/');
                        if (errorCallBack) {
                            errorCallBack(res);
                        } else {
                            alert('SESSION_EXPIRED');
                        }
                    }
                    break;
                case 403:
                    if (res) {
                        var errors = ""
                        for (var key in res) {
                            if (res.hasOwnProperty(key)) {
                                var val = res[key];
                                errors += val + " "
                            }
                        }
                        alert(errors);
                    }
                    navigate('/403');
                    break;
                case 404:
                    navigate('/404')
                    break;

                case 500:
                    alert('server error, try again')
                    break
            }
        })
        .catch(err => {
            // store.dispatch(hideLoading())
            console.error(err)
        });

    function sessionTimeOut() { return { type: 'SESSION_TIME_OUT' } }
}

async function callGetMethod(url, requestDataBlk, successCallBack, errorCallBack){
    if(requestDataBlk){
        try {
            await axios.get(url, requestDataBlk).then(function (response) {
                successCallBack(response.data);
            }).catch(function (error) {
                if(errorCallBack){ errorCallBack({...error}) }else{ return; }
            })
        } catch (error) {
            if(errorCallBack){ errorCallBack({...error}) };
        }
    }else{
        try {
            await axios.get(url).then(function (response) {
                successCallBack(response.data);
            }).catch(function (error) {
                if(errorCallBack){ errorCallBack({...error}) }else{ return; }
            })
        } catch (error) {
            if(errorCallBack){ errorCallBack({...error}) };
        }
    }
}
async function callPutMethod(url, requestDataBlk, successCallBack, errorCallBack){
    try {
        await axios.put(url, requestDataBlk).then(function (response) {
            successCallBack(response.data);
        }).catch(function (error) {
            if(errorCallBack){ errorCallBack({...error}) }else{ return; }
        })
    } catch (error) {
        if(errorCallBack){ errorCallBack({...error}) }
    }
}
async function callPostMethod(url, requestDataBlk, successCallBack, errorCallBack){
    if(requestDataBlk){
        try {
            await axios.post(url, requestDataBlk).then(function (response) {
                successCallBack(response.data);
            }).catch(function (error) {
                if(errorCallBack){ errorCallBack({...error}) }else{ return; }
            })
        } catch (error) {
            if(errorCallBack){ errorCallBack({...error}) };
        }
    }else{
        try {
            await axios.post(url).then(function (response) {
                successCallBack(response.data);
            }).catch(function (error) {
                if(errorCallBack){ errorCallBack({...error}) }else{ return; }
            })
        } catch (error) {
            if(errorCallBack){ errorCallBack({...error}) };
        }
    }
}

/* Each function returning a promise can reject it and it needs to be handled
 * at each level. So you can use awrap to wrap the promise around, so that
 * all exceptions are caught and returned as return values
 * Normally,
 * let results = await FunctionReturnsPromise()
 * With awrap
 * let [err, results] = await awrap(FunctionReturnsPromise())
 *
 * In case, if the function already returns in the format [err, results]
 * Call as follows:
 * let [err, results] = await awrap(FunctionReturnsPromise(), true)
 */
const awrap = (promise, deref = false) => {
  return promise
    .then(data => {
      if (deref && typeof data === "object" && data.length === 2) return data
      else return [null, data]
    })
    .catch(err => [err, null])
}

/*
  This is a wrapper to axios, where errors are returned inline and a simpler alternative
  to the above calls. Avoids too many callbacks
*/
const callApi = async ({url, method, data, json = true, headers = {}, timeout})  => {
  let opts = { method: method ? method : "GET" }
  opts.url = url
  opts.headers = headers
  if(timeout)
    opts.timeout
  const authToken = localStorage.getItem('token');
  if(authToken)
    opts.headers.Authorization = 'Token ' + authToken;
  if(json) {
    opts.responseType = 'json'
    if(data)
      opts.data = data
  }
  else {
    opts.headers["content-type"] = 'application/x-www-form-urlencoded'
    if(data)
      opts.data = qs.stringify(data)
  }


  {
    let err, response
    ;[err, response ] = await awrap(axios(opts))
    if(err && err.response && err.response.status && err.response.data && err.response.data.error)
      return [ `${err.response.statusText}: ${err.response.data.error}`, null]
    if(err && err.response && err.response.status)
      return [ `${err.response.status}: ${err.response.statusText} Data:${err.response.data}`, null]
    if(!response)
      return [ err, null ]
    if(response.status != 200)
      return [ `${response.status}: ${response.statusText} Data:${response.data}`, null]
    return [err, response]
  }
}

const safeJsonParse = (str) => {
  try {
    let json = JSON.parse(str)
    return json
  }
  catch(err) {
    return null
  }
}

export const apiHelper = {
    createRequestOptions,
    sendRequest,
    callGetMethod,
    callPostMethod,
    callPutMethod,
    awrap,
    callApi,
    safeJsonParse
};
