import { makeStyles } from '@material-ui/core/styles';

const CustomStyles = makeStyles(theme => ({
     DropzoneArea : {
          backgroundColor: "white !importent",
     },
     dragImage: {
          boxSizing: 'border-box',
          height: '121px',
          border: '1px dashed' + theme.palette.primary.main,
          borderRadius: '2px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          borderColor : 'red',
          backgroundColor: "white !importent",
     },
     DropZone:{
          backgroundColor: "white !importent",
     }
}));

export default CustomStyles;
