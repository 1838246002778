import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const AntTabs = withStyles({
    indicator: {
        backgroundColor: 'none',
    },
})(Tabs);

export const AntTab = withStyles(theme => ({
    root: {
        textTransform: 'none',
        minWidth: 'unset',
        paddingLeft: '0',
        color: theme.palette.primary.tabColor,
        marginRight: theme.spacing(4),
        opacity: '0.6',
        '&:hover': {
            opacity: 1,
        },
        '&$selected': {
            opacity: 1,
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: 'unset',

            '& .MuiTab-wrapper': {
                borderBottom: '2px solid' + theme.palette.primary.main
            }
        },
        '&:focus': {
            opacity: 1,
        },
    },
    selected: {},
}))(props => <Tab disableRipple {...props} />);

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.primary,
        // TBD, width 80% was being used as inine styles earlier,
        // which we cannot levrage for mobile.
        width: '80%',

        [theme.breakpoints.down("sm")]: {
            width: '100%'
        },
    }
}));
