import * as React from 'react';
import { navigate } from '@reach/router';
import { securityApi } from './../../../../api/securityApi';
import { Formik, Field, Form } from 'formik';
import {
  Button,
  Typography,
  makeStyles,
} from '@material-ui/core';
import * as yup from 'yup';
import { FormikTextField } from '../../../ui/FormikTextField';
import Card from "@material-ui/core/Card/Card";

const schema = yup.object().shape({
  Email: yup.string().email('Please enter a valid email').required(),
});
const LoginStyles = makeStyles(theme => ({
  card: {
    boxSizing: 'border-box',
    padding: '15px',
    display: 'flex',
    height: '100vh',
    backgroundColor: "transparent",
    boxShadow: "none !important",
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '900px',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: "#ffffff",
      minHeight: 'unset',
      height: 'unset'
    },

  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '460px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      height: '100%'
    }
  },
  title1: {
    color: '#505050',
    fontSize: '30px',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    }
  }
}));

const ForgotPasswordForm = () => {
  const classes = LoginStyles();

  return (
    <Card className={classes.card} style={{ width: '100%', display: 'flex' }}>
      <section className={classes.loginContainer}>
        <Typography component='div' className={classes.title1}>Reset your password</Typography>
        <p style={{ textAlign: 'left' }}>Please enter your registered email address. We will send a reset link.</p>
        <Formik
          initialValues={{
            Email: '',
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              const requestDataBlk = {
                email: values.Email.toLowerCase(),
              }
              securityApi.resetPassword(requestDataBlk, function (response, resStatus) {
                navigate('/set-password');
              });
            } catch ({ error }) {
            }
            setSubmitting(false);
          }}
          render={({ submitForm, isSubmitting, values }) => (
            <Form style={{ width: '100%', paddingTop: "30px" }} align="center">
              <Field
                type="email"
                label="Email"
                name="Email"
                fullWidth
                component={FormikTextField}
                style={{ width: '100%', paddingBottom: '30px' }} align="left"
              />
              <br /><br />
              <Button type="submit" variant="contained" color="primary">Submit</Button>
            </Form>
          )}
        />
      </section>
    </Card>
  );
}

export { ForgotPasswordForm };
