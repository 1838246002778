import React, { useEffect } from "react";
import { userApi } from "../../../api";
import Skeleton from "@material-ui/lab/Skeleton";
import { useStoreActions, useStoreState } from "../../../store";
import AccountSettingsStyles from "../../../styles/AccountSettings.styles";
import Fade from "@material-ui/core/Fade";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Typography, Button, Box, TextField } from "@material-ui/core";
import { Check, Clear, ReportProblemOutlined } from "@material-ui/icons";

export default function AccountInfo() {
  const classes = AccountSettingsStyles(),
    [open, setOpen] = React.useState(false),
    [currentPassword, setCurrentPassword] = React.useState(""),
    [newPassword, setNewpassword] = React.useState(""),
    [loadingData, setLoadingData] = React.useState(false),
    [confirmPassword, setConfirmPassword] = React.useState(""),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    handleClose = () => {
      setOpen(false);
    },
    handleChangePassword = () => {
      setOpen(true);
    },
    [user, setUser] = React.useState({
      fullName: null,
      displayName: null,
      city: null,
      area: null,
      userAvatar: null,
      phone: null,
      email: "",
    });

  useEffect(() => {
    userApi.getLoggedInUserDetails(function (resp) {
      // Get the loggedIn user details
      if (resp) {
        let userObj = { ...user };

        userObj.fullName = resp[0].full_name;
        userObj.displayName = resp[0].display_name;
        userObj.city = resp[0].u_city;
        userObj.area = resp[0].area;
        userObj.email = resp[0].email;
        userObj.phone = resp[0].mobile ? resp[0].mobile : null;
        userObj.userAvatar = resp[0].user_avatar;

        setUser(userObj);
        setLoadingData(true);
      }
    });
  }, []);

  const updateUserPassword = () => {
    userApi.updateUserPassword(
      {
        old_password: currentPassword,
        new_password1: newPassword,
        new_password2: confirmPassword,
      },
      function (resp) {
        if (resp) {
          setOpen(false);
          showAlert({ message: resp.detail, open: true, severity: "success" });
        }
      }
    );
  };

  const [passwordFocused, setPasswordFocused] = React.useState(false);
  const sessionUser = useStoreState((state) => state.auth.user);

  const [passwordMinCharRule, setPasswordMinCharRule] = React.useState(false);
  const [passwordUpperCaseRule, setPasswordUpperCaseRule] =
    React.useState(false);
  const [passwordNumberRule, setPasswordNumberRule] = React.useState(false);

  const checkPasswordValidation = (password) => {
    setPasswordMinCharRule(password.length >= 8);
    setPasswordUpperCaseRule(/[A-Z]/.test(password));
    setPasswordNumberRule(/[0-9]/.test(password));
  };

  return (
    <section className={classes.aboutMe}>
      <aside style={{ paddingRight: "unset" }}>
        {loadingData ? (
          <>
            <Box mt={2} width="100%">
              <TextField
                fullWidth
                type="text"
                label="Email"
                name="email"
                value={user && user.email}
              />
            </Box>
            <Box
              width="100%"
              style={{ visibility: open ? "hidden" : "visible" }}
            >
              <Button
                style={{ float: "right" }}
                onClick={handleChangePassword}
                disabled={
                  sessionUser && sessionUser.is_social_user ? true : false
                }
              >
                Change Password
              </Button>
            </Box>
          </>
        ) : (
          <Skeleton
            variant="rect"
            style={{ width: "100%", marginTop: "2rem" }}
            height={48}
          ></Skeleton>
        )}
        <Fade in={open}>
          <div>
            <Box mt={2} width="100%">
              <TextField
                type="password"
                label="Current Password"
                name="currentPassword"
                fullWidth
                onChange={(e) => {
                  setCurrentPassword(e.target.value);
                }}
                onFocus={(e) => {
                  setPasswordFocused(false);
                }}
              />
            </Box>
            <Box mt={2} width="100%">
              <TextField
                type="password"
                label="New Password"
                name="newPassword"
                fullWidth
                onChange={(e) => {
                  setNewpassword(e.target.value);
                }}
                onFocus={(e) => {
                  setPasswordFocused(true);
                }}
                onKeyUp={(e) => {
                  checkPasswordValidation(e.target.value);
                }}
              />
            </Box>
            <Alert
              severity="info"
              style={{
                marginTop: "10px",
                display: passwordFocused ? "flex" : "none",
              }}
            >
              <AlertTitle>Password must contain:</AlertTitle>
              <div>
                <div className={classes.passwordRulesDiv}>
                  {passwordMinCharRule ? (
                    <Check
                      color="primary"
                      className={classes.passwordRuleIcon}
                    />
                  ) : (
                    <Clear color="error" className={classes.passwordRuleIcon} />
                  )}
                  <Typography
                    variant="subtitle2"
                    className={classes.passwordRuleText}
                  >
                    Minimum 8 Characters
                  </Typography>
                </div>
                <div className={classes.passwordRulesDiv}>
                  {passwordUpperCaseRule ? (
                    <Check
                      color="primary"
                      className={classes.passwordRuleIcon}
                    />
                  ) : (
                    <Clear color="error" className={classes.passwordRuleIcon} />
                  )}
                  <Typography
                    variant="subtitle2"
                    className={classes.passwordRuleText}
                  >
                    At least 1 Uppercase Letter
                  </Typography>
                </div>
                <div className={classes.passwordRulesDiv}>
                  {passwordNumberRule ? (
                    <Check
                      color="primary"
                      className={classes.passwordRuleIcon}
                    />
                  ) : (
                    <Clear color="error" className={classes.passwordRuleIcon} />
                  )}
                  <Typography
                    variant="subtitle2"
                    className={classes.passwordRuleText}
                  >
                    At least 1 Number
                  </Typography>
                </div>
                <div className={classes.passwordRulesDiv}>
                  <ReportProblemOutlined className={classes.passwordRuleIcon} />
                  <Typography
                    variant="subtitle2"
                    className={classes.passwordRuleText}
                  >
                    Avoid Common Words
                  </Typography>
                </div>
              </div>
            </Alert>
            <Box mt={2} width="100%">
              <TextField
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                fullWidth
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                onFocus={(e) => {
                  setPasswordFocused(false);
                }}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                variant="outlined"
                onClick={handleClose}
                style={{ marginRight: "1rem" }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={updateUserPassword}
              >
                Submit
              </Button>
            </Box>
          </div>
        </Fade>
      </aside>
      <aside>&nbsp;</aside>
    </section>
  );
}
