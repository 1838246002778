import React from 'react';
import { utilHelper } from './../../../helpers/utilHelper';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  bannerContainer: {
    clear: 'both',

    [theme.breakpoints.down('sm')]: {
      margin: '0px'
    }
  },

  advertisement: {
    margin: '2rem 0',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

function Iframe(props) {
  return (
    <div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />
  );
}

const LargeRectangleAds = (props) => {
  const classes = useStyles(),
    advertisement = utilHelper.getRandomAds('largeRectangle');

  return (
    <div className={classes.bannerContainer}>
      <section className={classes.advertisement} >
        <Iframe iframe={advertisement} allow="autoplay" />
      </section>
    </div>
  );
};

export { LargeRectangleAds };
