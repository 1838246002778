import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './styles/theme';

import 'react-dropzone-uploader/dist/styles.css';

import './assets/css/styleCustom.css';


fetch('https://freegeoip.app/json/')
  .then(response => response.json())
  .then(data => {
    window.countryCode = data.country_code;
  });


ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.querySelector('#root'),
);
