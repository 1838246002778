import moment from 'moment'
import { getHorizontalAds, getVerticalAds, getLargeRectangleAds } from './Ads';

export const utilHelper = {
  getTimeValue,
  encodeImageFileName,
  toCamelCase,
  slugify,
  getRandomAds,
  timeDifference
};


function getTimeValue(time) {
  const ampm = time > 11 ? 'PM' : 'AM';
  let value = time;
  if (time > 12) {
    value = time - 12;
  }
  return value + ' ' + ampm;
}

function timeDifference(date) {
  date = Date.parse(date, "YYYY-MM-DDTHH:mm:ss.sssZ");
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    // return interval + ' years';
    return date.toDateString();
  }

  interval = Math.floor(seconds / 2592000);

  if (interval > 1) {
    // return interval + ' months';
    return date.toDateString();
  }

  interval = Math.floor(seconds / 86400);

  if (interval > 1) {
    if (interval === 7) {
      return '1 week ago';
    } else if (interval === 14) {
      return '2 weeks ago';
    } else if (interval < 14) {
      return interval + ' days ago';
    }

    return date.toDateString();
  }

  interval = Math.floor(seconds / 3600);

  if (interval > 1) {
    return interval + ' hours ago';
  }

  interval = Math.floor(seconds / 60);

  if (interval > 1) {
    return interval + ' minutes ago';
  }

  return Math.floor(seconds) + ' seconds ago';
}

function encodeImageFileName(fileName) {
  if (!fileName) return '';
  var name = fileName.substring(fileName.lastIndexOf('/') + 1);
  name = fileName.substring(0, fileName.lastIndexOf('/') + 1) + encodeURI(name)
  return name;
}

function toCamelCase(text) {
  if (!text) return '';
  return text.replace(/\b(\w)/g, function (match, capture) {
    return capture.toUpperCase();
  });
}

function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaeeeeiiiioooouuuunc------';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

function getRandomAds(type) {
  let adsList = [];

  switch (type) {
    case 'horizontal':
      adsList = getHorizontalAds();
      break;
    case 'vertical':
      adsList = getVerticalAds();
      break;
    case 'largeRectangle':
      adsList = getLargeRectangleAds();
      break;
  }

  return adsList[Math.floor(Math.random() * Math.floor(adsList.length))];
}