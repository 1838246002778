import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';

export const ConfirmationDialog = ({
    open,
    title,
    variant,
    dynamicTemplate,
    description,
    onSubmit,
    onClose,
    styleClass
}) => {
    return (
        <Dialog open={open} className={styleClass ? styleClass : ''}>
            <DialogTitle id='alert-dialog-title'>
                <Typography variant='h3' component='span'>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant='subtitle2' component='span'>
                        {description}
                    </Typography>
                    {dynamicTemplate && (
                        dynamicTemplate
                    )}
                </DialogContentText>
            </DialogContent>
            {variant === 'confirm' && (
                <DialogActions>
                    <>
                        <Button variant="outlined" color='primary' onClick={onClose} autoFocus>
                            Cancel</Button>
                        <Button variant="contained" color='primary' onClick={onSubmit}>
                            Confirm</Button>
                    </>
                </DialogActions>
            )}

            {variant === 'info' && (
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button variant="contained" color='primary' onClick={onSubmit}>OK</Button>
                </DialogActions>
            )}

            {variant === 'save' && (
                <DialogActions>
                    <Button variant="outlined" color='primary' onClick={onClose} autoFocus>Cancel</Button>
                    <Button variant="contained" color='primary' onClick={onSubmit}>Save</Button>
                </DialogActions>
            )}

        </Dialog>
    );
};
