import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { itemApi, shippingApi } from "../../../api";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Button from "@material-ui/core/Button/Button";
import { Box, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Skeleton from '@material-ui/lab/Skeleton';
import InfoIcon from "@material-ui/icons/Info";
import { useStoreActions } from "../../../store";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import AccountSettingsStyles from "../../../styles/AccountSettings.styles";

const progressStyle = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
}));

const radioBtnCSS = makeStyles((theme) => ({
  formControlLabel: {
    '& .MuiFormControlLabel-label': {
      color: '#717171',
      fontFamily: "Open Sans",
      fontSize: '14px',
      letterSpacing: ' 0',
      lineHeight: '16px',
      paddingLeft: '5px'
    },
    "& .MuiSvgIcon-root": {
      width: "18px",
    }
  },
}));

let previousData = {};

export default function Preferences() {
  const classes = AccountSettingsStyles(),
    progress = progressStyle(),
    [loading, setLoading] = React.useState(false),
    [loadingData, setLoadingData] = React.useState(false),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    radioBtnStyles = radioBtnCSS(),
    handleCancel = () => {
      setGiveValue(previousData.givePref);
      setNumberOfDays(previousData.numberOfDays);
      setDeliveryValue(previousData.deliveryPref);
      setShipValue(previousData.shipPref);
    };

  let deliveryValuesList = [],
    shippingValueslist = [],
    givevalueList = [],
    [shippingPreferences, setShipping] = useState([]),
    [shippingValue, setShipValue] = useState([]),
    [givePreferences, setGive] = useState([]),
    [giveValue, setGiveValue] = useState([]),
    [deliveryPreferences, setDelivery] = useState([]),
    [deliveryValue, setDeliveryValue] = useState([]),
    [numberOfDays, setNumberOfDays] = useState("");

  useEffect(() => {
    shippingApi.getShippingPreference(function (resp) {
      if (resp) {
        shippingValueslist = resp;
        setShipping(resp);
      }
    });

    shippingApi.getDeliveryPreference(function (resp) {
      if (resp) {
        deliveryValuesList = resp;
        setDelivery(resp);
      }
    });

    itemApi.getAllGivePreferenceList(function (response) {
      if (response) {
        givevalueList = response;
        setGive(response);
      }

      itemApi.getUserGivePreference(function (resp) {
        // get the logged In user preferences
        if (resp) {
          if (resp.give_pref && givevalueList) {
            previousData.givePref = givevalueList.find(
              (x) => x.preference === resp.give_pref_id
            );

            setGiveValue(previousData.givePref);
          }

          if (resp.item_listed_max_days) {
            previousData.numberOfDays = resp.item_listed_max_days;

            setNumberOfDays(previousData.numberOfDays);
          }

          shippingApi.getuserShippingAndDeliveryPreferences(function (resp) {
            if (resp) {
              if (resp.delivery_pref && deliveryValuesList) {
                previousData.deliveryPref = deliveryValuesList.find(
                  (x) => x.preference === resp.delivery_pref_id
                );

                setDeliveryValue(previousData.deliveryPref);
              }

              if (resp.ship_pref && shippingValueslist) {
                previousData.shipPref = shippingValueslist.find(
                  (x) => x.preference === resp.ship_pref_id
                );

                setShipValue(previousData.shipPref);
              }
            }
          });

          setLoadingData(true);
        }
      });
    });
  }, []);

  const handleChange = (e) => {
    setNumberOfDays(e.target.value);
  },
    updateUserPreferences = () => {
      // Update user preferences
      setLoading(true);

      shippingApi.updateUserShippingPreference(
        {
          ship_pref: shippingValue && shippingValue.description,
          ship_pref_id: shippingValue && shippingValue.preference,
          delivery_pref: shippingValue && deliveryValue.description,
          delivery_pref_id: shippingValue && deliveryValue.preference,
        },
        function (resp) {
          // Update Shipping preference
          if (resp) {
            previousData.deliveryPref = deliveryValue;

            setDeliveryValue(deliveryValue);
            setShipValue(shippingValue);
          }
        }
      );

      itemApi.updateUserGivePreference(
        {
          give_pref: giveValue && giveValue.description,
          give_pref_id: giveValue && giveValue.preference,
          item_listed_max_days: numberOfDays,
        },
        function (resp) {
          // Update Give preference
          setLoading(false);

          if (resp) {
            previousData.givePref = giveValue;
            setGiveValue(giveValue);

            if (resp.item_listed_max_days) {
              previousData.numberOfDays = resp.item_listed_max_days;
              setNumberOfDays(resp.item_listed_max_days);
            }

            showAlert({
              message: "Preferences updated successfully",
              open: true,
              severity: "success",
            });
          }
        },
        function () {
          setLoading(false);
          showAlert({
            message: "Failed to update preferences",
            open: true,
            severity: "error",
          });
        }
      );
    };

  return (
    <>
      <Box className={classes.preferencesContainer}>
        <Box className={classes.preferences}>
          <Typography
            variant='h2'
            component='div'
            style={{ marginBottom: "1rem" }}>
            Shipping Preference
            </Typography>
          <FormControl style={{ marginBottom: "1rem" }}>
            {loadingData ? <Autocomplete
              options={deliveryPreferences}
              getOptionDisabled={(option) => option.status === 0}
              getOptionLabel={(option) => option.description}
              onChange={(e, value) => {
                setDeliveryValue(value);
              }}
              fullWidth
              value={deliveryValue && deliveryValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Shipping'
                  fullWidth
                  margin='normal'
                />
              )}
            /> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '2rem' }} height={48}></Skeleton>}
          </FormControl>

          <FormControl style={{ marginBottom: "2rem" }}>
            {loadingData ? <Autocomplete
              options={shippingPreferences}
              getOptionDisabled={(option) => option.status === 0}
              getOptionLabel={(option) => option.description}
              onChange={(e, value) => {
                setShipValue(value);
              }}
              value={shippingValue && shippingValue}
              id='shipping'
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Within'
                  fullWidth
                  margin='normal'
                />
              )}
            /> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '2rem' }} height={48}></Skeleton>}
          </FormControl>
        </Box>
        <Box className={classes.preferences}>
          <Typography
            variant='h2'
            component='div'
            style={{ marginBottom: "1rem" }}>
            Give Preference
            </Typography>
          <FormControl style={{ marginBottom: "2rem" }}>
            {loadingData ? <Autocomplete
              options={givePreferences}
              getOptionDisabled={(option) => option.status === 0}
              getOptionLabel={(option) => option.description}
              onChange={(e, value) => {
                setGiveValue(value);
              }}
              value={giveValue && giveValue}
              id='give'
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Give to'
                  fullWidth
                  margin='normal'
                />
              )}
            /> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '2rem' }} height={48}></Skeleton>}
          </FormControl>
          <FormControl component='fieldset' style={{ display: 'none' }}>
            {loadingData && <FormLabel component='legend'>
              <Typography variant='subtitle2' component='div' style={{ fontSize: "14px" }}>
                How long you want the item listed ?{" "}
                <InfoIcon style={{ fontSize: "1rem", color: "#2196f3", pointerEvents: 'none' }} ></InfoIcon>
              </Typography>
            </FormLabel>}
            {loadingData ? <RadioGroup
              aria-label='days'
              name='days'
              value={numberOfDays}
              onChange={handleChange}
              style={{ display: 'flex', flexDirection: 'row', marginTop: '6px' }}>
              <FormControlLabel
                value='7'
                control={<Radio color='primary' />}
                label='7 days'
                labelPlacement='end'
                className={radioBtnStyles.formControlLabel}
              />
              <FormControlLabel
                value='15'
                control={<Radio color='primary' />}
                label='15 days'
                labelPlacement='end'
                className={radioBtnStyles.formControlLabel}
              />
              <FormControlLabel
                value='30'
                control={<Radio color='primary' />}
                label='30 days'
                labelPlacement='end'
                className={radioBtnStyles.formControlLabel}
              />
            </RadioGroup> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '16px' }} height={48}></Skeleton>}
          </FormControl>
        </Box>
      </Box>
      <Box
        className={classes.buttonContainer}
        style={{ marginTop: "36px", justifyContent: "center", width: "75%" }}
      >
        <div className={progress.wrapper}>
          {loadingData ? <Button
            variant='contained'
            color='primary'
            onClick={updateUserPreferences}
            disabled={loading}>
            Update
            </Button> : <Skeleton variant="rect" width={117} height={40}></Skeleton>}
          {loading && (
            <CircularProgress size={24} className={progress.buttonProgress} />
          )}
        </div>
        <div className={progress.wrapper}>
          {loadingData ? <Button
            variant='outlined'
            color='secondary'
            style={{ marginRight: "1rem" }}
            onClick={handleCancel}>
            Cancel
            </Button> : <Skeleton variant="rect" width={117} height={40}></Skeleton>}
        </div>
      </Box>
    </>
  );
}
