import React from 'react';
import { ResetPasswordForm } from './forms/ResetPasswordForm';
import { Grid } from '@material-ui/core';
import tabContainerStyles from './../../../styles/tabContainer.styles'
import { makeStyles } from "@material-ui/core/styles";
import { CommonLeftBlock } from './commonLeftBlock';

const resetpassword = makeStyles(theme => ({
  root: {
    marginBottom: '-1.5rem',
  },
}));



const ResetPassword = (props) => {
  const classes = resetpassword();
  const classesTabContainer = tabContainerStyles();
  return (
    <div className={classesTabContainer.root}>
      <div className={classesTabContainer.tabContainer}></div>
      <React.Fragment>
        <Grid className={classes.root} container>
          <CommonLeftBlock></CommonLeftBlock>

          <Grid item sm={12} xs={12} md={6} style={{ backgroundColor: 'rgba(73,73,73,7%)' }}>
            <ResetPasswordForm props={props} />
          </Grid>
        </Grid>



      </React.Fragment>
    </div>
  )
};
export { ResetPassword };
