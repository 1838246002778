import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { ContactUsForm } from "./forms/ContactUsForm";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { LargeRectangleAds } from "../../ui/ads/LargeRectangleAds";

const ContactUsStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiGrid-grid-xs-6': {
            padding: '1rem',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                flexBasis: '100%'
            }
        },

      
        '& .MuiTypography-h1': {
            fontSize: '24px !important',
            margin: '0rem 0px 2rem'
        },
        '& .MuiTypography-body2': {
            fontSize: '16px !important',
        },

        

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            marginTop: '2rem',
            //margin: '0',
            '& .MuiTypography-h1': {
                fontSize: '21px !important',
            },

        },
    },

}));


const ContactUs = () => {
    const classes = ContactUsStyles();

    return (
        <Grid className={classes.root} container>
            <Grid item xs={6}>
                <ContactUsForm />
            </Grid>

            <Grid item xs={6} style={{ padding: '2rem 1.5rem' }}>
                <Typography variant='h1' component='div'>Find more ways to help!</Typography>
                <Typography variant='body2' component='p'>
                    There are many ways to join us and support our mission. Contact us to find out  more about volunteer opportunities and ways that you can get our message to your friends and family and charities.
                </Typography>
                <img src='assets/images/contactus.svg' width='100%' style={{ margin: '1.5rem 0' }}></img>
            </Grid>

            
        </Grid>
    )
};
export { ContactUs };
