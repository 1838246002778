import React  from 'react';
import { navigate } from '@reach/router';
import { GiveBooks } from './give/GiveBooks';
import { Typography, Button, makeStyles, Card, CardActions, CardContent } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
}));

const GiveItem = (props) => {

    const classes = useStyles(),
        [page, setPage] = React.useState(props.slug),
        [bundle_id, setBundleId] = React.useState(props.bundle_id ? props.bundle_id : '');

    React.useLayoutEffect(() => {
    }, [])


    const openDialog = () => {
        confirm({
            variant: 'info',
            catchOnCancel: true,
            title: 'Coming Soon ...',
            description: 'We are currently supporting Give Book only. We will be adding support for other categories soon.'
        }).then(() => {
            setPage('');
        })
            .catch(() => {
                setPage('');
            });

    };
    const comingSoon = () => {
        return (
            <div className={classes.root} style={{ minHeight: "400px", width: '100%', textAlign: 'center', display: "flex", flexWrap: 'wrap' }}>
                <Card style={{ width: '40%', height: '230px', padding: "20px", marginTop: '100Px', marginLeft: '30%' }}>
                    <CardContent>
                        {/* <Paper elevation={4} style={{ width:'250px', height:'250px', padding:"20px"}} > */}
                        <div>
                            <Typography variant="h5" component="h1">Coming Soon ...</Typography>
                        </div>
                        <div>
                            <Typography variant='subtitle2' component='div'>Currently we are supporting only books.</Typography>
                            <Typography variant='subtitle2' component='div'>We are working on other categories</Typography>
                            <Typography variant='subtitle2' component='div'>Please visit again.</Typography>
                        </div>
                        {/* </Paper> */}
                    </CardContent>
                    <CardActions style={{ textAlign: 'center', display: 'unset' }}>
                        <div style={{ height: '40px' }}>
                            <Button variant='outlined' color='primary' onClick={(e) => { window.history.back() }} style={{ marginRight: "10px", }}>Ok</Button> &nbsp;
                        <Button variant='contained' color='primary' onClick={(e) => { setPage('books'); navigate('/give/books'); }}>Give Book</Button>
                        </div>
                    </CardActions>
                </Card>
            </div>
        )
    }

    return (
        <>
            {page === "books" ? <GiveBooks {...props} /> :
                page === "gadgets" ? comingSoon() :
                    page === "toys" ? comingSoon() :
                        page === "clothes" ? comingSoon() :
                            page === "games" ? comingSoon() :
                                page === "sports" ? comingSoon() :
                                    page === "music" ? comingSoon() :
                                        page === "magazines" ? comingSoon() :
                                            page === "movies" ? comingSoon() :
                                                page === "others" ? comingSoon() : ''
            }
        </>
    );
};

export { GiveItem };
