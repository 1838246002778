import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { navigate } from "@reach/router";
import tabContainerStyles from "../../../styles/tabContainer.styles";

const useStyles = makeStyles((theme) => ({
    header: {
        background: "#F2FFF1",
        paddingTop: "1rem",
    },
    bg: {
        [theme.breakpoints.down("sm")]: {
            background: "#F2FFF1",

            "&:nth-child(odd)": {
                background: "#fff",
            },

            "&:nth-child(even)": {
                background: "#fff",
            },

            "& .makeStyles-header-1": {
                paddingTop: 0,
            },

            "& header": {
                boxShadow: "unset",

                "& .MuiIconButton-root": {
                    display: "none",
                },

                "& .makeStyles-headerActions-21": {
                    display: "block",
                },
            },
        },
    },
    container: {
        display: "flex",
        padding: "4rem 0",
        margin: "0 auto",
        maxWidth: "1200px",
        minWidth: "650px",

        [theme.breakpoints.only("md")]: {
            maxWidth: "960px"
        },

        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            minWidth: "unset",
            padding: "1rem",
        },

        "& section:first-child": {
            margin: "4rem 0 1rem 0",
            padding: "0 1rem",

            [theme.breakpoints.down("sm")]: {
                margin: "1rem 0",
                padding: 0,
            },
        },

        "& section": {
            width: "50%",

            [theme.breakpoints.down("sm")]: {
                width: "unset",
            },
        },
    },
    giveStuff: {
        color: "#231C07",
        fontSize: "42px",
        lineHeight: "45px",
        margin: "2rem 0",

        [theme.breakpoints.down("sm")]: {
            fontSize: "31px",
            margin: 0,
        },
    },
    circle: {
        // height: "253px",
        width: "100%",
        borderRadius: "50%",
    },
    flexItem: {
        flex: "1 1",
        padding: "0 1rem",
        textAlign: "justify",
        margin: '1rem 0',
    },
    circleContainer: {
        display: "flex",

        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    buttonSection1: {
        display: "flex",
        marginTop: "2rem",

        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    statImg: {
        display: "block",
        height: "100%",
        width: "100%",
    },
}));

function AboutUs() {
    const classes = useStyles(),
        classesTabContainer = tabContainerStyles();

    return (
        <div className={classesTabContainer.root} style={{ marginTop: '32px', marginBottom: '60px' }}>
            <div className={classesTabContainer.tabContainer}></div>
            <section className={classes.bg}>
                <Box className={classes.container} flexDirection='column'>
                    <Typography
                        variant='h4'
                        className={classes.giveStuff}
                        component='div'
                        style={{ padding: "0 16px" }}
                    >
                        We Believe
                </Typography>
                    <Box className={classes.circleContainer}>
                        <div className={classes.flexItem} style={{ borderRight: '2px solid #eeeeee' }}>
                            <div className={classes.circle}>
                                <img
                                    className={classes.statImg}
                                    src='assets/images/hannah-rodrigo-sharing.jpg'
                                ></img>
                            </div>
                            <Typography
                                variant='h3'
                                style={{ fontSize: "24px", margin: "2rem 0" }}
                                component='div'
                            // color="primary"
                            >
                                Sharing is under-rated
                            </Typography>
                            <Typography
                                variant='h6'
                                component='div'
                                style={{ fontSize: "18px" }}
                            >
                                Many of us own perfectly good things that we don't use or need.
                                But they may serve another's need. So why not share more? (and
                                help the environment some!)
                            </Typography>
                        </div>
                        <div className={classes.flexItem} style={{ borderRight: '2px solid #eeeeee' }}>
                            <div className={classes.circle}>
                                <img
                                    className={classes.statImg}
                                    src='assets/images/alex-loup-reciving.jpg'
                                ></img>
                            </div>
                            <Typography
                                variant='h3'
                                style={{ fontSize: "24px", margin: "2rem 0" }}
                                component='div'
                            // color="primary"
                            >
                                Receiving is Fun
                            </Typography>
                            <Typography
                                variant='h6'
                                component='div'
                                style={{ fontSize: "18px" }}
                            >
                                One good turn deserves another. Why buy everything you need if
                                you can receive some of it for free?
                            </Typography>
                        </div>
                        <div className={classes.flexItem}>
                            <div className={classes.circle}>
                                <img
                                    className={classes.statImg}
                                    src='assets/images/support-a-cause-charity.jpg'
                                    style={{ height: '206px' }}
                                ></img>
                            </div>
                            <Typography
                                variant='h3'
                                style={{ fontSize: "24px", margin: "2rem 0" }}
                                component='div'
                            // color="primary"
                            >
                                Benefiting Causes
                            </Typography>
                            <Typography
                                variant='h6'
                                component='div'
                                style={{ fontSize: "18px" }}
                            >
                                Sorting and discarding unwanted donated items costs time and
                                money. What if charities could get exactly what they want?
                            </Typography>
                        </div>
                    </Box>

                    <Box
                        className={classes.buttonSection1}
                        style={{ display: "flex", width: "100%", justifyContent: "center" }}
                    >
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => { navigate("/contact-us"); }}
                            style={{ marginLeft: "1rem" }}
                        >
                            Contact Us
                    </Button>
                    </Box>
                </Box>
            </section>
        </div>
    );
}

export default AboutUs
