import { AsYouType, getCountryCallingCode, parsePhoneNumber, ParseError } from 'libphonenumber-js';

const isoCountries = [
    { 'code': 'AF', 'name': 'Afghanistan', 'pcode': "\\d{4}" }, //Done
    { 'code': 'AX', 'name': 'Aland Islands', 'pcode': "22\\d{3}" },
    { 'code': 'AL', 'name': 'Albania', 'pcode': "\\d{4}" }, //Done
    { 'code': 'DZ', 'name': 'Algeria', 'pcode': "\\d{5}" },
    { 'code': 'AS', 'name': 'American Samoa', 'pcode': "\\d{5}" }, //Done
    { 'code': 'AD', 'name': 'Andorra', 'pcode': "AD\\d{3}" },
    { 'code': 'AO', 'name': 'Angola', 'pcode': null },
    { 'code': 'AI', 'name': 'Anguilla', 'pcode': "\\d{5}" },
    { 'code': 'AQ', 'name': 'Antarctica', 'pcode': "\\d{4}" }, //Done
    { 'code': 'AG', 'name': 'Antigua And Barbuda', 'pcode': "\\d{5}" },
    { 'code': 'AR', 'name': 'Argentina', 'pcode': "([A-HJ-NP-Z])?\\d{4}([A-Z]{3})?" },
    { 'code': 'AM', 'name': 'Armenia', 'pcode': "(37)?\\d{4}" },
    { 'code': 'AW', 'name': 'Aruba', 'pcode': "^0{4}aw$" },
    { 'code': 'AU', 'name': 'Australia', 'pcode': "\\d{4}" }, //Done
    { 'code': 'AT', 'name': 'Austria', 'pcode': "\\d{4}" }, //Done
    { 'code': 'AZ', 'name': 'Azerbaijan', 'pcode': "\\d{4}" }, //Done
    { 'code': 'BS', 'name': 'Bahamas', 'pcode': null },
    { 'code': 'BH', 'name': 'Bahrain', 'pcode': "((1[0-2]|[2-9])\\d{2})?" },
    { 'code': 'BD', 'name': 'Bangladesh', 'pcode': "\\d{4}" }, //Done
    { 'code': 'BB', 'name': 'Barbados', 'pcode': "(BB\\d{5})?" },
    { 'code': 'BY', 'name': 'Belarus', 'pcode': "\\d{6}" },
    { 'code': 'BE', 'name': 'Belgium', 'pcode': "\\d{4}" }, //Done
    { 'code': 'BZ', 'name': 'Belize', 'pcode': null },
    { 'code': 'BJ', 'name': 'Benin', 'pcode': null },
    { 'code': 'BM', 'name': 'Bermuda', 'pcode': "[A-Z]{2}[ ]?[A-Z0-9]{2}" },
    { 'code': 'BT', 'name': 'Bhutan', 'pcode': "\\d{5}" },
    { 'code': 'BO', 'name': 'Bolivia', 'pcode': null },
    { 'code': 'BA', 'name': 'Bosnia And Herzegovina', 'pcode': "\\d{5}" },
    { 'code': 'BW', 'name': 'Botswana', 'pcode': null },
    { 'code': 'BV', 'name': 'Bouvet Island', 'pcode': null },
    { 'code': 'BR', 'name': 'Brazil', 'pcode': "\\d{5}[\\-]?\\d{3}" },
    { 'code': 'IO', 'name': 'British Indian Ocean Territory', 'pcode': "BBND 1ZZ" },
    { 'code': 'BN', 'name': 'Brunei Darussalam', 'pcode': "[A-Z]{2}[ ]?\\d{4}" },
    { 'code': 'BG', 'name': 'Bulgaria', 'pcode': "\\d{4}" }, //Done
    { 'code': 'BF', 'name': 'Burkina Faso', 'pcode': null },
    { 'code': 'BI', 'name': 'Burundi', 'pcode': null },
    { 'code': 'KH', 'name': 'Cambodia', 'pcode': "\\d{5}" },
    { 'code': 'CM', 'name': 'Cameroon', 'pcode': null },
    { 'code': 'CA', 'name': 'Canada', 'pcode': "[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d" },
    { 'code': 'CV', 'name': 'Cape Verde', 'pcode': "\\d{4}" }, //Done
    { 'code': 'KY', 'name': 'Cayman Islands', 'pcode': "\\d{5}" },
    { 'code': 'CF', 'name': 'Central African Republic', 'pcode': null },
    { 'code': 'TD', 'name': 'Chad', 'pcode': "\\d{5}" },
    { 'code': 'CL', 'name': 'Chile', 'pcode': "\\d{7}" },
    { 'code': 'CN', 'name': 'China', 'pcode': "\\d{6}" },
    { 'code': 'CX', 'name': 'Christmas Island', 'pcode': "\\d{4}" }, //Done
    { 'code': 'CC', 'name': 'Cocos (Keeling) Islands', 'pcode': "\\d{4}" }, //Done
    { 'code': 'CO', 'name': 'Colombia', 'pcode': "\\d{6}" },
    { 'code': 'KM', 'name': 'Comoros', 'pcode': null },
    { 'code': 'CG', 'name': 'Congo', 'pcode': null },
    { 'code': 'CD', 'name': 'Congo, Democratic Republic', 'pcode': null },
    { 'code': 'CK', 'name': 'Cook Islands', 'pcode': "\\d{4}" }, //Done
    { 'code': 'CR', 'name': 'Costa Rica', 'pcode': "\\d{4,5}|\\d{3}-\\d{4}" },
    { 'code': 'CI', 'name': 'Cote D\'Ivoire', 'pcode': null },
    { 'code': 'HR', 'name': 'Croatia', 'pcode': "\\d{5}" },
    { 'code': 'CU', 'name': 'Cuba', 'pcode': "\\d{5}" },
    { 'code': 'CY', 'name': 'Cyprus', 'pcode': "\\d{4}" }, //Done
    { 'code': 'CZ', 'name': 'Czech Republic', 'pcode': "\\d{3}[ ]?\\d{2}" },
    { 'code': 'DK', 'name': 'Denmark', 'pcode': "\\d{4}" }, //Done
    { 'code': 'DJ', 'name': 'Djibouti', 'pcode': null },
    { 'code': 'DM', 'name': 'Dominica', 'pcode': null },
    { 'code': 'DO', 'name': 'Dominican Republic', 'pcode': "\\d{5}" },
    { 'code': 'EC', 'name': 'Ecuador', 'pcode': "([A-Z]\\d{4}[A-Z]|(?:[A-Z]{2})?\\d{6})?" },
    { 'code': 'EG', 'name': 'Egypt', 'pcode': "\\d{5}" },
    { 'code': 'SV', 'name': 'El Salvador', 'pcode': null },
    { 'code': 'GQ', 'name': 'Equatorial Guinea', 'pcode': null },
    { 'code': 'ER', 'name': 'Eritrea', 'pcode': null },
    { 'code': 'EE', 'name': 'Estonia', 'pcode': "\\d{5}" },
    { 'code': 'ET', 'name': 'Ethiopia', 'pcode': "\\d{4}" }, //Done
    { 'code': 'FK', 'name': 'Falkland Islands (Malvinas)', 'pcode': "FIQQ 1ZZ" },
    { 'code': 'FO', 'name': 'Faroe Islands', 'pcode': "\\d{3}" },
    { 'code': 'FJ', 'name': 'Fiji', 'pcode': null },
    { 'code': 'FI', 'name': 'Finland', 'pcode': "\\d{5}" },
    { 'code': 'FR', 'name': 'France', 'pcode': "\\d{2}[ ]?\\d{3}" },
    { 'code': 'GF', 'name': 'French Guiana', 'pcode': "9[78]3\\d{2}" },
    { 'code': 'PF', 'name': 'French Polynesia', 'pcode': "987\\d{2}" },
    { 'code': 'TF', 'name': 'French Southern Territories', 'pcode': "\\d{5}" },
    { 'code': 'GA', 'name': 'Gabon', 'pcode': null },
    { 'code': 'GM', 'name': 'Gambia', 'pcode': null },
    { 'code': 'GE', 'name': 'Georgia', 'pcode': "\\d{4}" }, //Done
    { 'code': 'DE', 'name': 'Germany', 'pcode': "\\d{5}" },
    { 'code': 'GH', 'name': 'Ghana', 'pcode': null },
    {
        'code': 'GI', 'name': 'Gibraltar', 'pcode': "([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2}) "
    },
    { 'code': 'GR', 'name': 'Greece', 'pcode': "\\d{3}[ ]?\\d{2}" },
    { 'code': 'GL', 'name': 'Greenland', 'pcode': "39\\d{2}" },
    { 'code': 'GD', 'name': 'Grenada', 'pcode': null },
    { 'code': 'GP', 'name': 'Guadeloupe', 'pcode': "9[78][01]\\d{2}" },
    { 'code': 'GU', 'name': 'Guam', 'pcode': "969[123]\\d([ \\-]\\d{4})?" },
    { 'code': 'GT', 'name': 'Guatemala', 'pcode': "\\d{5}" },
    { 'code': 'GG', 'name': 'Guernsey', 'pcode': "GY\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}" },
    { 'code': 'GN', 'name': 'Guinea', 'pcode': "\\d{3}" },
    { 'code': 'GW', 'name': 'Guinea-Bissau', 'pcode': "\\d{4}" }, //Done
    { 'code': 'GY', 'name': 'Guyana', 'pcode': null },
    { 'code': 'HT', 'name': 'Haiti', 'pcode': "\\d{4}" }, //Done
    { 'code': 'HM', 'name': 'Heard Island & Mcdonald Islands', 'pcode': "\\d{4}" }, //Done
    { 'code': 'VA', 'name': 'Holy See (Vatican City State)', 'pcode': "\\d{5}" }, //Done
    { 'code': 'HN', 'name': 'Honduras', 'pcode': "(?:\\d{5})?" },
    { 'code': 'HK', 'name': 'Hong Kong', 'pcode': "\\d{6}" },
    { 'code': 'HU', 'name': 'Hungary', 'pcode': "\\d{4}" }, //Done
    { 'code': 'IS', 'name': 'Iceland', 'pcode': "\\d{3}" },
    { 'code': 'IN', 'name': 'India', 'pcode': "\\d{6}" },
    { 'code': 'ID', 'name': 'Indonesia', 'pcode': "\\d{5}" },
    { 'code': 'IR', 'name': 'Iran, Islamic Republic Of', 'pcode': "\\d{4}" },
    { 'code': 'IQ', 'name': 'Iraq', 'pcode': "\\d{5}" },
    { 'code': 'IE', 'name': 'Ireland', 'pcode': null },
    { 'code': 'IM', 'name': 'Isle Of Man', 'pcode': "IM\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}" },
    { 'code': 'IL', 'name': 'Israel', 'pcode': "\\d{5}" },
    { 'code': 'IT', 'name': 'Italy', 'pcode': "\\d{5}" },
    { 'code': 'JM', 'name': 'Jamaica', 'pcode': null },
    { 'code': 'JP', 'name': 'Japan', 'pcode': "\\d{3}-\\d{4}" },
    { 'code': 'JE', 'name': 'Jersey', 'pcode': "JE\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}" },
    { 'code': 'JO', 'name': 'Jordan', 'pcode': "\\d{5}" },
    { 'code': 'KZ', 'name': 'Kazakhstan', 'pcode': "\\d{6}" },
    { 'code': 'KE', 'name': 'Kenya', 'pcode': "\\d{5}" },
    { 'code': 'KI', 'name': 'Kiribati', 'pcode': null },
    { 'code': 'KR', 'name': 'Korea', 'pcode': "\\d{3}[\\-]\\d{3}" },
    { 'code': 'KW', 'name': 'Kuwait', 'pcode': "\\d{5}" },
    { 'code': 'KG', 'name': 'Kyrgyzstan', 'pcode': "\\d{6}" },
    {
        'code': 'LA', 'name': 'Lao People\'s Democratic Republic', 'pcode': "\\d{5}"
    },
    { 'code': 'LV', 'name': 'Latvia', 'pcode': "\\d{4}" }, //Done
    { 'code': 'LB', 'name': 'Lebanon', 'pcode': "(\\d{4}([ ]?\\d{4})?)?" },
    { 'code': 'LS', 'name': 'Lesotho', 'pcode': "\\d{3}" },
    { 'code': 'LR', 'name': 'Liberia', 'pcode': "\\d{4}" }, //Done
    { 'code': 'LY', 'name': 'Libyan Arab Jamahiriya', 'pcode': "\\d{4}" },
    { 'code': 'LI', 'name': 'Liechtenstein', 'pcode': "(948[5-9])|(949[0-7])" },
    { 'code': 'LT', 'name': 'Lithuania', 'pcode': "\\d{5}" },
    { 'code': 'LU', 'name': 'Luxembourg', 'pcode': "\\d{4}" }, //Done
    { 'code': 'MO', 'name': 'Macao', 'pcode': "\\d{4}" },
    { 'code': 'MK', 'name': 'Macedonia', 'pcode': "\\d{4}" }, //Done
    { 'code': 'MG', 'name': 'Madagascar', 'pcode': "\\d{3}" },
    { 'code': 'MW', 'name': 'Malawi', 'pcode': null },
    { 'code': 'MY', 'name': 'Malaysia', 'pcode': "\\d{5}" },
    { 'code': 'MV', 'name': 'Maldives', 'pcode': "\\d{5}" },
    { 'code': 'ML', 'name': 'Mali', 'pcode': null },
    { 'code': 'MT', 'name': 'Malta', 'pcode': "[A-Z]{3}[ ]?\\d{2,4}" },
    { 'code': 'MH', 'name': 'Marshall Islands', 'pcode': "969[67]\\d([ \\-]\\d{4})?" },
    { 'code': 'MQ', 'name': 'Martinique', 'pcode': "9[78]2\\d{2}" },
    { 'code': 'MR', 'name': 'Mauritania', 'pcode': null },
    { 'code': 'MU', 'name': 'Mauritius', 'pcode': "(\\d{3}[A-Z]{2}\\d{3})?" },
    { 'code': 'YT', 'name': 'Mayotte', 'pcode': "976\\d{2}" },
    { 'code': 'MX', 'name': 'Mexico', 'pcode': "\\d{5}" },
    { 'code': 'FM', 'name': 'Micronesia, Federated States Of', 'pcode': "(9694[1-4])([ \\-]\\d{4})?" },
    { 'code': 'MD', 'name': 'Moldova', 'pcode': "\\d{4}" }, //Done
    { 'code': 'MC', 'name': 'Monaco', 'pcode': "980\\d{2}" },
    { 'code': 'MN', 'name': 'Mongolia', 'pcode': "\\d{6}" },
    { 'code': 'ME', 'name': 'Montenegro', 'pcode': "8\\d{4}" },
    { 'code': 'MS', 'name': 'Montserrat', 'pcode': "\\d{4}" }, //Done
    { 'code': 'MA', 'name': 'Morocco', 'pcode': "\\d{5}" },
    { 'code': 'MZ', 'name': 'Mozambique', 'pcode': "\\d{4}" }, //Done
    { 'code': 'MM', 'name': 'Myanmar', 'pcode': "\\d{5}" },
    { 'code': 'NA', 'name': 'Namibia', 'pcode': null },
    { 'code': 'NR', 'name': 'Nauru', 'pcode': null },
    { 'code': 'NP', 'name': 'Nepal', 'pcode': "\\d{5}" },
    { 'code': 'NL', 'name': 'Netherlands', 'pcode': "\\d{4}[ ]?[A-Z]{2}" },
    { 'code': 'AN', 'name': 'Netherlands Antilles', 'pcode': null },
    { 'code': 'NC', 'name': 'New Caledonia', 'pcode': "988\\d{2}" },
    { 'code': 'NZ', 'name': 'New Zealand', 'pcode': "\\d{4}" }, //Done
    { 'code': 'NI', 'name': 'Nicaragua', 'pcode': "((\\d{4}-)?\\d{3}-\\d{3}(-\\d{1})?)?" },
    { 'code': 'NE', 'name': 'Niger', 'pcode': "\\d{4}" }, //Done
    { 'code': 'NG', 'name': 'Nigeria', 'pcode': "(\\d{6})?" },
    { 'code': 'NU', 'name': 'Niue', 'pcode': null },
    { 'code': 'NF', 'name': 'Norfolk Island', 'pcode': "\\d{4}" }, //Done
    { 'code': 'MP', 'name': 'Northern Mariana Islands', 'pcode': "9695[012]([ \\-]\\d{4})?" },
    { 'code': 'NO', 'name': 'Norway', 'pcode': "\\d{4}" }, //Done
    { 'code': 'OM', 'name': 'Oman', 'pcode': "(PC )?\\d{3}" },
    { 'code': 'PK', 'name': 'Pakistan', 'pcode': "\\d{5}" },
    { 'code': 'PW', 'name': 'Palau', 'pcode': "\\d{5}" },
    { 'code': 'PS', 'name': 'Palestinian Territory, Occupied', 'pcode': "\\d{5}" },
    { 'code': 'PA', 'name': 'Panama', 'pcode': null },
    { 'code': 'PG', 'name': 'Papua New Guinea', 'pcode': "\\d{3}" },
    { 'code': 'PY', 'name': 'Paraguay', 'pcode': "\\d{4}" }, //Done
    { 'code': 'PE', 'name': 'Peru', 'pcode': "\\d{5}" },
    { 'code': 'PH', 'name': 'Philippines', 'pcode': "\\d{4}" }, //Done
    { 'code': 'PN', 'name': 'Pitcairn', 'pcode': "PCRN 1ZZ" },
    { 'code': 'PL', 'name': 'Poland', 'pcode': "\\d{2}-\\d{3}" },
    { 'code': 'PT', 'name': 'Portugal', 'pcode': "\\d{4}([\\-]\\d{3})?" },
    { 'code': 'PR', 'name': 'Puerto Rico', 'pcode': "00[679]\\d{2}([ \\-]\\d{4})?" },
    { 'code': 'QA', 'name': 'Qatar', 'pcode': null },
    { 'code': 'RE', 'name': 'Reunion', 'pcode': "9[78]4\\d{2}" },
    { 'code': 'RO', 'name': 'Romania', 'pcode': "\\d{6}" },
    { 'code': 'RU', 'name': 'Russian Federation', 'pcode': "\\d{6}" },
    { 'code': 'RW', 'name': 'Rwanda', 'pcode': null },
    { 'code': 'BL', 'name': 'Saint Barthelemy', 'pcode': "\\d{5}" },
    { 'code': 'SH', 'name': 'Saint Helena', 'pcode': "(ASCN|STHL) 1ZZ" },
    { 'code': 'KN', 'name': 'Saint Kitts And Nevis', 'pcode': null },
    { 'code': 'LC', 'name': 'Saint Lucia', 'pcode': null },
    { 'code': 'MF', 'name': 'Saint Martin', 'pcode': "\\d{5}" },
    { 'code': 'PM', 'name': 'Saint Pierre And Miquelon', 'pcode': "9[78]5\\d{2}" },
    { 'code': 'VC', 'name': 'Saint Vincent And Grenadines', 'pcode': "\\d{4}" }, //Done
    { 'code': 'WS', 'name': 'Samoa', 'pcode': "\\d{5}" },
    { 'code': 'SM', 'name': 'San Marino', 'pcode': "4789\\d" },
    { 'code': 'ST', 'name': 'Sao Tome And Principe', 'pcode': null },
    { 'code': 'SA', 'name': 'Saudi Arabia', 'pcode': "\\d{5}" },
    { 'code': 'SN', 'name': 'Senegal', 'pcode': "\\d{5}" },
    { 'code': 'RS', 'name': 'Serbia', 'pcode': "\\d{6}" },
    { 'code': 'SC', 'name': 'Seychelles', 'pcode': null },
    { 'code': 'SL', 'name': 'Sierra Leone', 'pcode': null },
    { 'code': 'SG', 'name': 'Singapore', 'pcode': "\\d{6}" },
    { 'code': 'SK', 'name': 'Slovakia', 'pcode': "\\d{3}[ ]?\\d{2}" },
    { 'code': 'SI', 'name': 'Slovenia', 'pcode': "\\d{4}" }, //Done
    { 'code': 'SB', 'name': 'Solomon Islands', 'pcode': null },
    { 'code': 'SO', 'name': 'Somalia', 'pcode': "\\d{5}" },
    { 'code': 'ZA', 'name': 'South Africa', 'pcode': "\\d{4}" }, //Done
    { 'code': 'GS', 'name': 'South Georgia And Sandwich Isl.', 'pcode': "SIQQ 1ZZ" },
    { 'code': 'ES', 'name': 'Spain', 'pcode': "\\d{5}" },
    { 'code': 'LK', 'name': 'Sri Lanka', 'pcode': "\\d{5}" }, //Done
    { 'code': 'SD', 'name': 'Sudan', 'pcode': "\\d{5}" },
    { 'code': 'SR', 'name': 'Suriname', 'pcode': null },
    { 'code': 'SJ', 'name': 'Svalbard And Jan Mayen', 'pcode': "\\d{4}" }, //Done
    { 'code': 'SZ', 'name': 'Swaziland', 'pcode': "[HLMS]\\d{3}" },
    { 'code': 'SE', 'name': 'Sweden', 'pcode': "\\d{3}[ ]?\\d{2}" },
    { 'code': 'CH', 'name': 'Switzerland', 'pcode': "\\d{4}" }, //Done
    { 'code': 'SY', 'name': 'Syrian Arab Republic', 'pcode': null },
    { 'code': 'TW', 'name': 'Taiwan', 'pcode': "\\d{3}(\\d{2})?" },
    { 'code': 'TJ', 'name': 'Tajikistan', 'pcode': "\\d{6}" },
    { 'code': 'TZ', 'name': 'Tanzania', 'pcode': null },
    { 'code': 'TH', 'name': 'Thailand', 'pcode': "\\d{5}" },
    { 'code': 'TL', 'name': 'Timor-Leste', 'pcode': "\\d{5}" },
    { 'code': 'TG', 'name': 'Togo', 'pcode': null },
    { 'code': 'TK', 'name': 'Tokelau', 'pcode': null },
    { 'code': 'TO', 'name': 'Tonga', 'pcode': null },
    { 'code': 'TT', 'name': 'Trinidad And Tobago', 'pcode': null },
    { 'code': 'TN', 'name': 'Tunisia', 'pcode': "\\d{4}" }, //Done
    { 'code': 'TR', 'name': 'Turkey', 'pcode': "\\d{5}" },
    { 'code': 'TM', 'name': 'Turkmenistan', 'pcode': "\\d{6}" },
    { 'code': 'TC', 'name': 'Turks And Caicos Islands', 'pcode': "TKCA 1ZZ" },
    { 'code': 'TV', 'name': 'Tuvalu', 'pcode': null },
    { 'code': 'UG', 'name': 'Uganda', 'pcode': null },
    { 'code': 'UA', 'name': 'Ukraine', 'pcode': "\\d{5}" },
    { 'code': 'AE', 'name': 'United Arab Emirates', 'pcode': null },
    { 'code': 'GB', 'name': 'United Kingdom', 'pcode': "GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\\d{1,4}" },
    { 'code': 'US', 'name': 'United States', 'pcode': "\\d{5}([ \\-]\\d{4})?" },
    { 'code': 'UM', 'name': 'United States Outlying Islands', 'pcode': "\\d{5}" },
    { 'code': 'UY', 'name': 'Uruguay', 'pcode': "\\d{5}" },
    { 'code': 'UZ', 'name': 'Uzbekistan', 'pcode': "\\d{6}" },
    { 'code': 'VU', 'name': 'Vanuatu', 'pcode': null },
    { 'code': 'VE', 'name': 'Venezuela', 'pcode': "\\d{4}" }, //Done
    { 'code': 'VN', 'name': 'Viet Nam', 'pcode': "\\d{6}" },
    { 'code': 'VG', 'name': 'Virgin Islands, British', 'pcode': null },
    { 'code': 'VI', 'name': 'Virgin Islands, U.S.', 'pcode': "008(([0-4]\\d)|(5[01]))([ \\-]\\d{4})?" },
    { 'code': 'WF', 'name': 'Wallis And Futuna', 'pcode': "986\\d{2}" },
    { 'code': 'EH', 'name': 'Western Sahara', 'pcode': null },
    { 'code': 'YE', 'name': 'Yemen', 'pcode': null },
    { 'code': 'ZM', 'name': 'Zambia', 'pcode': "\\d{5}" }, //Done
    { 'code': 'ZW', 'name': 'Zimbabwe', 'pcode': null }
];


export function getCountryCode(countryName) {
    let countryObj = isoCountries.find(country =>
        country.name?.trim()?.toLowerCase() === countryName?.trim()?.toLowerCase());
    if (countryObj)
        return countryObj.code;
}

export function getPostCodeFormat(countryName) {
    let countryObj = isoCountries.find(country =>
        country.name?.trim()?.toLowerCase() === countryName?.trim()?.toLowerCase());
    if (countryObj)
        return countryObj.pcode;
}

export function getPhoneNumber(phoneNumber, country) {
    let countryCode = getCountryCode(country);
    if (phoneNumber) {
        phoneNumber = phoneNumber.replace(/\s/gi, '');
        if (countryCode) {
            try {
                phoneNumber = parsePhoneNumber(phoneNumber).nationalNumber;
            } catch (error) {
                if (error instanceof ParseError) {
                } else {
                }
            } finally {
                phoneNumber = '+' + getCountryCallingCode(countryCode) + ' ' + (new AsYouType(countryCode).input(phoneNumber));
            }
        }
    }
    return phoneNumber;
}