import React from 'react';
import { ForgotPasswordForm } from './forms/ForgotPasswordForm';
import { navigate } from '@reach/router';
import { Grid } from '@material-ui/core';
import tabContainerStyles from './../../../styles/tabContainer.styles';
import { useStoreState } from './../../../hooks';
import { makeStyles } from '@material-ui/core/styles';
import { CommonLeftBlock } from './commonLeftBlock';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '-1.5rem',
    '& .MuiGrid-grid-xs-6': {
      padding: '1rem',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        flexBasis: '100%'
      }
    }
  }
}));

const ForgotPassword = () => {
  const classes = useStyles(),
    classesTabContainer = tabContainerStyles(),
    isLoggedIn = useStoreState(state => state.auth.isLoggedIn);

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, []);

  return (
    <Grid className={classes.root} container>
      <CommonLeftBlock></CommonLeftBlock>

      <Grid item sm={12} xs={12} md={6} style={{ backgroundColor: 'rgba(73,73,73,7%)' }}>
        <ForgotPasswordForm />
      </Grid>
    </Grid>
  )
};
export { ForgotPassword };
