import {apiHelper} from '../helpers';
import {httpMethodTypes} from "../constants/httpMethodTypes";
import {appSettings} from "../constants/appSettings";
import axios from 'axios'
const BASE_URL =  appSettings.BaseURL;

export const userApi = {
    getProfile,
    updateProfile,
    uploadImage,
    getUserPrivacy,
    updateUserPrivacy,
    googleLogin,
    getUserSummaryByHash,
    getLoggedInUserDetails,
    updateLoggedInUserProfile,
    updateUserPassword,
    verifyEmail,
    resetPassword,
    getProfileSummary,
    resendVerificationEmail,
    recaptcha
};
async function callGetMethod(url, requestDataBlk, successCallBack, errorCallBack){
    if(requestDataBlk){
        try {
            await axios.get(url, requestDataBlk).then(function (response) {
                successCallBack(response.data);
            }).catch(function (error) {
                if(errorCallBack){ errorCallBack({...error}) }else{ return; }   
            })
        } catch (error) {
            if(errorCallBack){ errorCallBack({...error}) };
        }
    }else{
        try {
            await axios.get(url).then(function (response) {
                successCallBack(response.data);
            }).catch(function (error) {
                if(errorCallBack){ errorCallBack({...error}) }else{ return; }   
            })
        } catch (error) {
            if(errorCallBack){ errorCallBack({...error}) };
        }
    }
}
async function callPutMethod(url, requestDataBlk, successCallBack, errorCallBack){
    try {
        await axios.put(url, requestDataBlk).then(function (response) {
            successCallBack(response.data);
        }).catch(function (error) {
            if(errorCallBack){ errorCallBack({...error}) }else{ return; }   
        })
    } catch (error) {
        if(errorCallBack){ errorCallBack({...error}) }
    }
}
async function callPostMethod(url, requestDataBlk, successCallBack, errorCallBack){
    if(requestDataBlk){
        try {
            await axios.post(url, requestDataBlk).then(function (response) {
                successCallBack(response.data);
            }).catch(function (error) {
                if(errorCallBack){ errorCallBack({...error}) }else{ return; }   
            })
        } catch (error) {
            if(errorCallBack){ errorCallBack({...error}) };
        }
    }else{
        try {
            await axios.post(url).then(function (response) {
                successCallBack(response.data);
            }).catch(function (error) {
                if(errorCallBack){ errorCallBack({...error}) }else{ return; }   
            })
        } catch (error) {
            if(errorCallBack){ errorCallBack({...error}) };
        }    
    }
}

async function getProfile(requestDataBlk, successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, true, requestDataBlk)
    // const url = BASE_URL + 'api/user/profile/';
    // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
    await callGetMethod ('api/user/profile/', requestDataBlk, successCallBack, errorCallBack);
}

async function updateProfile(requestDataBlk, successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT, true, requestDataBlk)
    // const url = BASE_URL + 'api/user/profile/';
    // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
    await callPutMethod ('api/user/profile/', requestDataBlk, successCallBack, errorCallBack);
}

function uploadImage(requestDataBlk, successCallBack, errorCallBack) {
    const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.POST, true, requestDataBlk)
    delete requestOptions.headers["Content-Type"];
    delete  requestOptions.headers["Accept"];
    var data = new FormData();
    data.append("image", requestDataBlk);
    requestOptions.body = data;
    const url = BASE_URL + 'api/user/profile-image/';
    apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
}

async function getUserPrivacy(successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, true, {})
    // const url = BASE_URL + 'api/user/privacy-status/';
    // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
    await callGetMethod ('api/user/privacy-status/', null, successCallBack, errorCallBack);
}

async function updateUserPrivacy(requestDataBlk, successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT, true, requestDataBlk)
    // const url = BASE_URL + 'api/user/privacy-status/';
    await callPutMethod ('api/user/privacy-status/', requestDataBlk, successCallBack, errorCallBack);
}

async function googleLogin(requestDataBlk, successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.POST, false, requestDataBlk)
  // requestOptions.headers.Authorization =  requestDataBlk.access_token;
    // const url = BASE_URL + 'api/user/rest-auth/google/';
    // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
    await callPostMethod ('api/user/rest-auth/google/',requestDataBlk, successCallBack, errorCallBack);
}

async function getUserSummaryByHash(userHash, successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, true)
    const url = '' + 'api/user/account/summary/'+userHash+'/';
    // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
    await callGetMethod (url, null, successCallBack, errorCallBack);
}

async function getLoggedInUserDetails(successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, true)
    // const url = BASE_URL + 'api/user/account/';
    // apiHelper.sendRequest(url, requestOptions, successCallBack);
    await callGetMethod ('api/user/account/', null, successCallBack, errorCallBack);
}

async function updateLoggedInUserProfile(requestDataBlk, successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT, true, requestDataBlk)
    // const url = BASE_URL + 'api/user/account/';
    // apiHelper.sendRequest(url, requestOptions, successCallBack);
    await callPutMethod ('api/user/account/', requestDataBlk, successCallBack, errorCallBack);
}

async function updateUserPassword(requestDataBlk, successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.POST, true, requestDataBlk)
    // const url = BASE_URL + 'api/user/password-change/';
    // apiHelper.sendRequest(url, requestOptions, successCallBack);
    await callPostMethod ('api/user/password/change/', requestDataBlk, successCallBack, errorCallBack);
}

async function verifyEmail(key, successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
    const url = '' + `api/user/account-confirm-email/${key}/`;
    // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
    await callGetMethod (url, null, successCallBack, errorCallBack);
}

async function resetPassword(requestDataBlk,uid,token, successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.POST, false, requestDataBlk)
    const url = '' + `api/user/password/reset/confirm/${uid}/${token}`;
    // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
    await callPostMethod (url, requestDataBlk, successCallBack, errorCallBack);
}

async function getProfileSummary( successCallBack, errorCallBack) {
    await callGetMethod ('api/user/profile/summary/', null, successCallBack, errorCallBack);
}

async function resendVerificationEmail(requestDataBlk, successCallBack, errorCallBack) {
    // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
    const url = '' + `api/user/resend-email-verification/`;
    // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
    await callPostMethod (url, requestDataBlk, successCallBack, errorCallBack);
}

async function recaptcha(token, successCallBack, errorCallBack) {
    const url = '/api/core/validate/recaptcha/';

    await callPostMethod (url, token, successCallBack, errorCallBack);
}



