export const appSettings = {
    // Local Development
    BaseURL: process.env.NODE_ENV === "development" ? 'http://localhost:8000/' : window.origin + '/',

    // AWS Development Server
    // BaseURL: 'https://dev.kindeeds.com/',

    // AWS Stage Server
    // BaseURL: 'https://cgkstage.kindeeds.com/',

    // AWS Prod Server
    // BaseURL: 'https://www.kindeeds.com/',

    IndividualUserLandingPage: 'give-Now',

    // Local Development
    MEDIA_URL: process.env.NODE_ENV === "development" ? 'http://localhost:8000' : window.origin + '/',

    // AWS Development Server
    // MEDIA_URL: 'https://dev.kindeeds.com',

    // AWS Stage Server
    // MEDIA_URL: 'https://cgkstage.kindeeds.com',

    // AWS Prod Server
    // MEDIA_URL: 'https://www.kindeeds.com',

    // Local Development
    SOCIAL_REDIRECT_URL: process.env.NODE_ENV === "development" ? 'http://localhost:3000/login/' : window.origin + '/login/',

    // AWS Development Server
    // SOCIAL_REDIRECT_URL: 'https://dev.kindeeds.com/login/',

    // AWS Stating Server
    // SOCIAL_REDIRECT_URL: 'https://cgkstage.kindeeds.com/login/',

    // AWS Production Server
    // SOCIAL_REDIRECT_URL: 'https://www.kindeeds.com/login/',


    // FACEBOOK APPLICATION ID
    fb_app_id: '270215334110143',

    //GOOGLE CLIENT ID
    google_client_id: '495804054225-i72021usn0v4lco203s2utc89ag2ef2e.apps.googleusercontent.com',
};
