import * as React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { securityApi, userApi } from "./../../../../api";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { useConfirmation } from "./../../../../ConfirmationService";
import { FormikTextField } from "../../../ui/FormikTextField";
import { makeStyles } from "@material-ui/core/styles";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const LoginStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  container: {
    backgroundColor: "#ffffff",
    padding: "2rem",
  },
  card: {
    boxSizing: "border-box",
    padding: "15px",
    display: "flex",
    flexDirection: "row",
  },
  rectangle: {
    backgroundColor: "rgba(127,204,40,0.03)",
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
  },
  formControl: {
    width: "75%",
  },
  cover: {
    width: "66px",
    height: "66px",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "1rem",

    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  or: {
    display: "flex",
    margin: "2rem 0",

    "&::after": {
      content: `''`,
      flex: "1 1",
      borderBottom: "1px solid #E7E7E7",
      margin: "auto 2rem",
    },

    "&::before": {
      content: `''`,
      flex: "1 1",
      borderBottom: "1px solid #E7E7E7",
      margin: "auto 2rem",
    },
  },
  fbIconContainer: {
    background: "#263C68",
    height: "40px",
    margin: "0 10px",
    borderRadius: "4px",
    cursor: "pointer",
  },

  iconContainer: {
    background: "#FFF",
    height: "40px",
    margin: "0 10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: "1rem",
  },
}));

const ContactUsForm = () => {
  const classes = LoginStyles();
  const showAlert = useStoreActions((actions) => actions.alert.showAlert);
  const [loading, setLoading] = React.useState(false);
  const [isSubmitted, setSubmitted] = React.useState(false);

  let previousUserData = {
    fullname: "",
    name: "",
    phone: "",
    email: null,
    country: ""
  };

  const [user, setUser] = React.useState(previousUserData),
    isLoggedIn = useStoreState((state) => state.auth.isLoggedIn);



  React.useEffect(() => {
    if (isLoggedIn) {
      userApi.getLoggedInUserDetails(function (resp) {
        if (resp) {
          let userObj = { ...user };
          userObj.fullname = resp[0].full_name;
          userObj.name = resp[0].display_name;
          userObj.email = resp[0].email;
          userObj.phone = resp[0].mobile ? resp[0].mobile : null;
          userObj.country = resp[0].u_country;
          setUser(userObj);
        }
      });
    }
  }, []);

  const schema = yup.object().shape({
    email: yup.string().required("Please enter email.").email("Please enter a valid email.").nullable(),
    name: yup.string().required("Please enter name."),
    phone: yup.string()
      .nullable()
      .test('isPhoneNumberValid', 'Please enter valid phone number.',
        function (value) {
          if (value) {
            const phoneNumber = parsePhoneNumberFromString(value, countryCode)
            if (phoneNumber) {
              const patt = /[a-zA-Z]/gi;
              let res = !patt.test(value);
              return phoneNumber.isValid() && phoneNumber.isPossible() && res;
            }
          } else {
            return true;
          }
        }).nullable(),
    message: yup.string().required("Please enter your comments."),
    remember: yup.boolean(),
  });

  return (
    <section className={classes.loginContainer}>
      <Typography variant="h1" component="div">
        {!isSubmitted && "Contact Us"}
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: user.email,
          name: user.name,
          phone: user.phone,
          organization: "",
          message: "",
        }}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const requestDataBlk = {
              email: values.email.toLowerCase(),
              name: values.name,
              phone: values.phone,
              organization: values.organization,
              comments: values.message,
            };
            let newUser = { ...user };
            newUser.email = values.email.toLowerCase();
            newUser.name = values.name;
            newUser.phone = values.phone;
            setLoading(true);
            securityApi.contactUs(
              requestDataBlk,
              function (response, resStatus) {
                setLoading(false);
                resetForm();
                setSubmitted(true);
                // showAlert({ message: "You have successfully submitted your request. We will get back to you soon.", open: true, severity: 'success' });
              },
              function (response, resStatus) {
                setLoading(false);
              }
            );
          } catch ({ error }) {
            showAlert({
              message: "Submitting contact us Failed",
              open: true,
              severity: "error",
            });
          }
          setSubmitting(false);
        }}
        render={({ submitForm, isSubmitting, values }) =>
          !isSubmitted ? (
            <Form style={{ width: "100%" }}>
              <Field
                type="text"
                label="Email"
                name="email"
                fullWidth
                component={FormikTextField}
                style={{ margin: "1rem 0" }}
                defaultValue={user.email ? user.email : " "}
                value={user.email ? user.email : " "}
                onChange={(e, value) => {
                  setFieldValue("email", value);
                  setUser({
                    ...user,
                    email: value,
                    name: values.name,
                    phone: values.phone,
                    // organization: values.organization,
                    // comments: values.message,
                  });
                }}
              />
              <Field
                type="text"
                label="Name"
                name="name"
                fullWidth
                style={{ margin: "1rem 0" }}
                defaultValue={user.name}
                value={user.name}
                component={FormikTextField}
                onChange={(e, value) => {
                  setFieldValue("name", value);
                  setUser({
                    ...user,
                    name: value,
                    email: values.email,
                    phone: values.phone,
                    // organization: values.organization,
                    // comments: values.message,
                  });
                }}
              />
              <Field
                type="text"
                label="Phone"
                name="phone"
                fullWidth
                style={{ margin: "1rem 0" }}
                component={FormikTextField}
                value={user.phone}
                defaultValue={user.phone}
                inputProps={{ maxLength: 18 }}
                onChange={(e, value) => {
                  setFieldValue("phone", value);
                  setUser({
                    ...user,
                    phone: value,
                    email: values.email,
                    name: values.name,
                    // organization: values.organization,
                    // comments: values.message,
                  });
                }}
              />
              <Field
                type="text"
                label="Organization"
                name="organization"
                fullWidth
                style={{ margin: "1rem 0" }}
                component={FormikTextField}
                onChange={(e, value) => {
                  setFieldValue("organization", value);
                  setUser({
                    ...user,
                    // organization: value,
                    email: values.email,
                    name: values.name,
                    phone: values.phone,
                    // comments: values.message,
                  });
                }}
              />
              <Field
                multiline
                label="What interests you most ?"
                placeholder="What interests you most ?"
                rows="4"
                name="message"
                fullWidth
                style={{ margin: "1rem 0" }}
                component={FormikTextField}
                onChange={(e, value) => {
                  setFieldValue("message", value);
                  setUser({
                    ...user,
                    // comments: value,
                    email: values.email,
                    name: values.name,
                    phone: values.phone,
                    // organization: values.organization,
                  });
                }}
              />
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  color="primary"
                  onClick={submitForm}
                >
                  Send
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Form>
          ) : (
              <>
                <Typography
                  variant="h2"
                  component="div"
                  style={{ margin: "1rem 0", color: "#22B11F" }}
                >
                  Thank you for contacting us!
              </Typography>
                <Typography variant="body2" component="p">
                  We have received your enquiry and will respond to you within 48
                  hours.
              </Typography>
              </>
            )
        }
      />
    </section>
  );
};

export { ContactUsForm };
