import React from 'react';
import { makeStyles } from '@material-ui/core';
import { utilHelper } from './../../../helpers/utilHelper';

const useStyles = makeStyles(theme => ({
  container: {
    '& .MuiPaper-root': {
      width: '358px'
    },

    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0'
    }
  },

  advertisement: {
    padding: '5px 10px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },

}));

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const VerticalAds = (props) => {
  const classes = useStyles(),
    advertisement = utilHelper.getRandomAds('vertical');

  return (

    <div style={{ clear: "both", width: '100%', height: '100%', margin: "0 5px" }} className={classes.advertisement}>
      <Iframe iframe={advertisement} allow="autoplay" /></div>
  );
};

export { VerticalAds };
