import {apiHelper} from '../helpers';
import {httpMethodTypes} from "../constants/httpMethodTypes";
import {appSettings} from "../constants/appSettings";
import axios from 'axios'
const BASE_URL =  appSettings.BaseURL;

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const getPubKey = async () => {
  let [err, response] = await apiHelper.callApi({url: BASE_URL + 'api/payments/getPubKey'})
  return [err, response ? response.data : null]
}

const getSettings = async () => {
  let [err, response] = await apiHelper.callApi({url: BASE_URL + 'api/payments/getSettings'})
  return [err, response ? response.data : null]
}

const getLocationKey = async () => {
  let [err, response] = await apiHelper.callApi({url: BASE_URL + 'api/payments/getLocationKey'})
  return [err, response ? response.data : null]
}

const location = { ready: false }
const getLocation = async (key) => {
  location.ready = false
  if(!key) {
    let [err, response] = await apiHelper.callApi({url: BASE_URL + 'api/payments/getSettings', timeout: 1000})
    key = response && response.data && response.data.IPDATA_CO_API_KEY ? response.data.IPDATA_CO_API_KEY : null
  }
  if(key) {
      fetch(`https://api.ipdata.co/?api-key=${key}`)
        .then(response => response.json())
        .then(data => {
          ["country_code", "is_eu"].map(f => location[f] = data[f])
          location.ready = true
        })
        .catch(() => { location.ready = true } )
  }
  else
    location.ready = true
}

const createPaymentIntent = async (amount, currency, frequency) => {
  let [err, response] = await apiHelper.callApi({
    url: BASE_URL + 'api/payments/createPaymentIntent',
    method: "PUT",
    data: {
      amount,
      currency,
      frequency
    },
  })
  if(err) return [err, null]
  return [err, response ? response.data : null]
}

const paymentSuccess = async (paymentId, amount, currency, nameOnCard ) => {
  let [err, response] = await apiHelper.callApi({
    url: BASE_URL + 'api/payments/paymentSuccess',
    method: "PUT",
    data: {
      paymentId,
      amount,
      currency,
      nameOnCard
    }
  })
  if(err) return [err, null]
  return [err, response ? response.data : null]
}

const createSubscription = async (paymentMethodId, amount, currency, frequency) => {
  let [err, response] = await apiHelper.callApi({
    url: BASE_URL + 'api/payments/createSubscription',
    method: "PUT",
    data: {
      paymentMethodId,
      amount,
      currency,
      frequency
    },
  })
  if(err) return [err, null]
  return [err, response ? response.data : null]
}

export const stripeApi = {
  getPubKey,
  getLocation,
  getSettings,
  createPaymentIntent,
  paymentSuccess,
  createSubscription,
  location
};
