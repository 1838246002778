import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router";
import PropTypes from "prop-types";
import { Box, InputLabel, FormControl, Select, MenuItem, Typography } from "@material-ui/core";
import { useStyles, AntTab, AntTabs } from "../../styles/AntTabs";
import MyGivesStyles from "./../../styles/Get.styles";
import MyGetItem from "../ui/my/MyGetItem";
import { itemApi } from "../../api";
import { appSettings } from "../../constants/appSettings";
import Skeleton from '@material-ui/lab/Skeleton';
import { HorizontalAds } from "../ui/ads/HorizontalAds";
import { useStoreState } from "./../../hooks";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { VerticalAds } from "../ui/ads/VerticalAds";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      style={{ border: "none" }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const GetStyles = makeStyles((theme) => ({
  product: {
    padding: "0 2rem",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  productList: {
    display: "flex",
    flexWrap: "wrap",
    minHeight: "615px",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "1rem 0",
      alignItems: "center",
    },
  },
}));

const mobileCSS = makeStyles((theme) => ({
  filterContainer: {
    display: 'none',
    [theme.breakpoints.down("sm")]: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px',
      alignItems: 'center',
      padding: '0 10px',
      boxSizing: 'border-box',
      marginBottom: '10px'
    },
  },
  filterLabelWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const SortMenuItems = ({
  mobileStyles,
  selectedFilterIndex,
  handleSortByChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null),
    open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSort = (event) => {
    handleSortByChange(event);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}>
        <img src='/assets/images/icon_sort.svg' alt='Sort' />
        <Typography
          variant='subtitle2'
          component='div'
          style={{ paddingLeft: "5px" }}
          className={mobileStyles.filterLabel}>
          Sort
        </Typography>
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}>
        <MenuItem
          onClick={handleSort}
          selected={selectedFilterIndex == 1}
          value={1}
          style={{ minWidth: "unset" }}>
          Newest First
        </MenuItem>
        <MenuItem
          onClick={handleSort}
          selected={selectedFilterIndex == 2}
          value={2}
          style={{ minWidth: "unset" }}>
          Oldest First
        </MenuItem>
      </Menu>
    </div>
  );
};

export const MyGets = (props) => {
  const classes = GetStyles(),
    classes1 = useStyles(),
    localClasses = MyGivesStyles(),
    mobileStyles = mobileCSS(),
    [myRequests, setMyRequests] = React.useState([]),
    [value, setValue] = React.useState(props["filter-value"] ? +props["filter-value"] : 1),
    [selectedTab, setSelectedTab] = React.useState(""),
    [loading, setLoading] = React.useState(false),
    skeletonNumbers = [...Array(6).keys()],
    [isDetailsPage, setDetailsPage] = React.useState(false),
    [refresh, setRefresh] = React.useState(false),
    [selectedFilterIndex, setSelectedFilterIndex] = React.useState(2),
    isLoggedIn = useStoreState((state) => state.auth.isLoggedIn);

  const handleChange = (event, newValue) => {
    navigate('/my-gets/' + newValue, { replace: true });
    setValue(newValue);
  };

  const [itemConditionOptions, setItemConditionOptions] = React.useState([]);
  React.useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    const categorySlug = "books";
    itemApi.getFieldsByCategory(
      categorySlug,
      function (response) {
        if (response) {
          let condition = null;
          response.map((item) => {
            if (item.name === "book_condition") {
              setItemConditionOptions(item.selection_values);
            }
          });
        }
      },
      function (response) { }
    );
  }, []);

  React.useEffect(() => {
    setMyRequests([]);
    setPaginationKey({ key: { limit: 9 } });
    let tab = "";
    let filterIndex = 2;
    switch (value) {
      case 1:
        tab = "requested"; //Awaiting response
        break;
      case 2:
        tab = "accepted"; //Accepted requests
        break;
      case 3:
        tab = "completed"; //Completed Get
        filterIndex = 1;
        break;
      default:
        tab = "";
        filterIndex = 1;
        break;
    }
    setLoading(true);
    setSelectedTab(tab);
    setSelectedFilterIndex(filterIndex);
    fetchMoreData(tab, true, filterIndex);
  }, [value, refresh]);

  const handleSortByChange = (event) => {
    setSelectedFilterIndex(event.target.value);
    setMyRequests([]);
    fetchMoreData(selectedTab, true, event.target.value);
  };

  function updateItemObject(item) {
    let nemItem = {
      ...item,
      itemTitle: [],
      itemImage: [],
      category: [],
      condition: [],
      id: [],
    };
    let keys = Object.keys(item.title);
    keys.map(function (itemTitle, i) {
      nemItem.id[i] = itemTitle;
      nemItem.itemTitle[i] = item.title[itemTitle];
      nemItem.itemImage[i] = item.images[itemTitle].startsWith("http")
        ? item.images[itemTitle]
        : appSettings.MEDIA_URL + item.images[itemTitle];
      nemItem.itemImage[i] = nemItem.itemImage[i].replace("\\", "/");
      nemItem.itemImage[i] = nemItem.itemImage[i].replace("\\", "/");
      let cat_condition = item.cat_condition[itemTitle].split("_");
      nemItem.category[i] = cat_condition[0];
      nemItem.condition[i] = cat_condition[1];
    });
    return nemItem;
  }

  const processItems = (noItemsMessage) => {
    return (
      <>
        <Box display='unset'>
          {myRequests.length > 0
            ? <>
              <Box className={localClasses.filterChipsContainer}>
                <section className={localClasses.filtersWrapper}>
                  {/* <Typography variant="h4" component="div">
                    Filters
                  </Typography> */}
                </section>
                <div style={{ display: "flex", alignItems: "center", border: '1px solid #dedede', borderRadius: '4px', padding: '0px 0px 1px 8px' }}>
                  <InputLabel id='sort-by-label' className={localClasses.labelAutoWidth}>
                    <Typography variant='h4' component='div' style={{ fontWeight: '400' }}>
                      Sort by:&nbsp;
                      </Typography>
                  </InputLabel>
                  <FormControl
                    className={localClasses.selectSortBy}
                    style={{
                      minWidth: "unset",
                      fontWeight: "unset",
                      marginTop: "1.5 rem",
                    }}>
                    <Select
                      labelId='sort-by-label'
                      value={selectedFilterIndex}
                      onChange={handleSortByChange}
                      style={{ minWidth: "unset", marginTop: "1.5 rem" }}>
                      {/* <MenuItem value={0} style={{ minWidth: "unset" }}>
                        Priority
                      </MenuItem> */}
                      <MenuItem value={1} style={{ minWidth: "unset" }}>
                        Newest First
                        </MenuItem>
                      <MenuItem value={2} style={{ minWidth: "unset" }}>
                        Oldest First
                        </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Box>
              <div className={mobileStyles.filterContainer}>
                <div className={mobileStyles.filterLabelWrapper}>
                  <SortMenuItems
                    mobileStyles={mobileStyles}
                    selectedFilterIndex={selectedFilterIndex}
                    handleSortByChange={handleSortByChange}
                  />
                </div>
              </div>
            </>
            : null
          }
          <InfiniteScroll
            dataLength={myRequests.length}
            next={fetchMoreData}
            hasMore={hasMore}>
            <Box display='flex' flexWrap='wrap' className={classes.productList}>
              {myRequests.length === 0 && loading == false ? (
                <div
                  style={{
                    fontSize: "18px",
                    paddingTop: "50px",
                    width: "100%",
                    textAlign: "center",
                  }}>
                  <Typography
                    style={{
                      color: "rgb(33, 150, 243)",
                      letterSpacing: "0.5px",
                    }}
                    component='div'
                    variant='body2'
                  >
                    {noItemsMessage}
                  </Typography>
                </div>
              ) : (
                  ""
                )}
              {
                <>
                  {myRequests.map(function (myRequest, index) {
                    if (myRequest.bundle_info && myRequest.bundle_info[0]) {
                      let item = myRequest.bundle_info[0];
                      if (item && item.title) {
                        let nemItem = updateItemObject(item);
                        if (nemItem.itemTitle.length < 1) return;
                        return (
                          <MyGetItem item={nemItem} bundle={myRequest} itemConditionOptions={itemConditionOptions} refresh={refresh} setRefresh={setRefresh} />
                        );
                      } else {
                        return;
                      }
                    }
                  })}
                </>
              }

              {loading &&
                skeletonNumbers.map(el => <Box key={el} margin='0 20px 20px 0'>
                  <Skeleton variant="rect" width={289} height={206} animation="wave" />
                  <Skeleton variant="circle" width={40} height={40} animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                </Box>)
              }
            </Box>
          </InfiniteScroll>
        </Box>
      </>
    );
  };

  const [hasMore, setMore] = React.useState(true),
    [paginationKey, setPaginationKey] = React.useState({ key: { limit: 9 } }),
    fetchMoreData = (tab, reset, newFilterIndex) => {
      let selected = selectedTab,
        sortIndex = selectedFilterIndex,
        filter = '',
        pagination = paginationKey;

      if (reset) {
        selected = tab;
      }

      setLoading(true);

      if (reset) {
        pagination = { key: { limit: 9 } };
        setPaginationKey({ key: { limit: 9 } });
        sortIndex = newFilterIndex;
      }

      itemApi.getMyGetsList(
        selected,
        filter,
        sortIndex,
        pagination,
        function (response) {
          setLoading(false);
          if (response && response.data) {
            if (myRequests && myRequests.length > 0) {
              if (reset) {
                setMyRequests(response.data);
              } else {
                let merge = myRequests.concat(response.data);
                setMyRequests(merge);
              }
            } else {
              setMyRequests(response.data);
            }
          }
          if (response.page && response.page && response.page.key) {
            let key = { ...response.page };
            key.limit = 9;
            setPaginationKey({ key });
            setMore(true);
          } else {
            setMore(false);
          }
        },
        function (response) {
          setLoading(false);
        }
      );
    };
  const getLink = () => {
    return (
      <>
        You have not requested anything yet.{" "}
        <Typography
          component='span'
          onClick={() => navigate("/get")}
          color='primary'
          style={{ cursor: "pointer" }}>
          Get now?
        </Typography>
      </>
    );
  };

  return (
    <>

      <Box display='flex' width='100%'>
        <div className={classes1.root} style={{ width: "80%" }}>
          <div>
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label='ant example'
              variant='scrollable'
              scrollButtons='on'>
              <AntTab label='All' key='all' />
              <AntTab label='Awaiting Response' key='submitted' />
              <AntTab label='Accepted Requests' key='accepted' />
              <AntTab label='Completed Gets' key='completed' />
            </AntTabs>
            <Typography className={classes.padding} />
          </div>
          <TabPanel value={value} index={0} className={localClasses.root}>
            {processItems(getLink())}
          </TabPanel>
          <TabPanel value={value} index={1} className={localClasses.root}>
            {processItems(getLink())}
          </TabPanel>
          <TabPanel value={value} index={2} className={localClasses.root}>
            {processItems(getLink())}
          </TabPanel>
          <TabPanel value={value} index={3} className={localClasses.root}>
            {processItems(getLink())}
          </TabPanel>
        </div>

        <Box width='20%' mt={2}>
          <VerticalAds></VerticalAds>
        </Box>
      </Box>

      <Box width='100%'>
        <HorizontalAds></HorizontalAds>
      </Box>
    </>
  );
};
