import { makeStyles } from '@material-ui/core/styles';

const GiveBooksStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    container: {
        backgroundColor: '#ffffff',
    },
    titleSection: {
        padding: '1rem 3rem',

        [theme.breakpoints.down('sm')]: {
            padding: '0 1rem 0rem',
            background: '#fafafa'
        }
    },
    bundleContainer: {
        padding: '20px',
        background: '#fafafa',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',

        '& .MuiExpansionPanel-root': {
            display: 'none',
            margin: '0',
            boxShadow: 'none',
            border: 'none',
            background: '#fafafa',

            '&:before': {
                height: 0
            },

            '& .MuiExpansionPanelSummary-root': {
                background: '#fafafa',
                width: '100%'
            },

            '& .MuiExpansionPanelSummary-content': {
                justifyContent: 'center',
                margin: '12px 0 !important',
                width: '100%'
            }
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: '0',

            '& .MuiBox-root': {
                // display: 'none'
                margin: '0 0 16px 0 !important',
            },

            '& .MuiExpansionPanel-root': {
                display: 'block',
                width: '100%',

                '&:before': {
                    height: 0
                }
            }
        }
    },
    card: {
        minHeight: '165px',
        width: '402px',
        boxSizing: 'border-box',
        padding: '15px',
        marginRight: '30px',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '15px',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '1rem',
            minHeight: '150px'
        }
    },
    bundleSpaceSection: {
        minHeight: '150px',
        width: '402px',
        border: '1px dashed #383838',
        boxSizing: 'border-box',
        borderRadius: '2px',
        paddingTop: '55px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    volumeNumber: {
        cursor: 'pointer',
        display: 'inline-block',
        width: '28px',
        border: '1px solid #363636',
        textAlign: 'center',
        padding: '1px',

        '&:hover': {
            border: '1px solid #069755',
            background: theme.palette.primary.main,
            color: '#fff',
            fontWeight: '600'
        }
    },
    searchIconContainer: {
        paddingRight: '0',
        width: '100%'
    },
    rectangle: {
        minHeight: '367px',
        background: 'rgba(127,204,40,0.03)',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1rem'
    },
    formControl: {
        width: '75%'
    },
    cover: {
        width: 80,
        height: 120
    },
    dragImagesSection: {
        boxSizing: 'border-box',
        height: '121px',
        border: '1px dashed' + theme.palette.primary.main,
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        position: 'relative',

        [theme.breakpoints.down('sm')]: {
            borderRadius: 'unset'
        },

        '& input': {
            height: '100%',
            left: '0',
            opacity: '0',
            position: 'absolute',
            top: '0',
            width: '100%',
            cursor: 'pointer'
        },
        '& .MuiButton-root': {
            position: 'static'
        }
    },
    formSection: {
        padding: '1rem 3rem',

        '& .MuiExpansionPanel-root': {
            display: 'none',
            margin: '16px 0',
            boxShadow: 'none',

            '& .MuiExpansionPanelSummary-root': {
                background: 'rgba(34,177,31,0.13)',
                minHeight: '40px',
            },

            '& .MuiExpansionPanelSummary-content': {
                justifyContent: 'center',
                margin: '12px 0 !important',

                '& :before': {
                    height: 0
                }
            }
        },

        [theme.breakpoints.down('sm')]: {
            flexGrow: 0,
            maxWidth: '100%',
            flexBasis: '100%',
            width: '100%',
            padding: '1rem',

            '& .MuiFormGroup-row': {
                flexDirection: 'column',

                '& .MuiFormControlLabel-root': {
                    marginBottom: '0.5rem'
                }
            },

            '& .MuiExpansionPanel-root': {
                display: 'block'
            }
        }
    },
    
    formSection1: {
        padding: '1rem 3rem',

        '& .MuiExpansionPanel-root': {
            display: 'none',
            margin: '16px 0',
            boxShadow: 'none',

            '& .MuiExpansionPanelSummary-root': {
                background: 'rgba(34,177,31,0.13)'
            },

            '& .MuiExpansionPanelSummary-content': {
                justifyContent: 'center',
                margin: '12px 0 !important',

                '&:before': {
                    height: 0
                }
            }
        },

        [theme.breakpoints.down('sm')]: {
            flexGrow: 0,
            maxWidth: '100%',
            flexBasis: '100%',
            width: '100%',
            padding: '1rem',

            '& .MuiFormGroup-row': {
                flexDirection: 'column',

                '& .MuiFormControlLabel-root': {
                    marginBottom: '0.5rem'
                }
            },

            '& .MuiExpansionPanel-root': {
                display: 'block'
            }
        }
    },
    formSection2: {
        padding: '1rem 3rem',

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    checkCircleIcon: {
        alignSelf: 'flex-end',
        color: theme.palette.primary.main
    },
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
            height: 400,
        }
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    controls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& .MuiSlider-root': {
            flexBasis: '75%'
        }
    },
    sliderLabel: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 65,
        },
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    },
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            width: '75%',
            maxWidth: '828px',

            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: 0
            }
        }
    },
    buttonContainer: {
        display:'flex',
        flexDirection:'row-reverse',
        marginTop: '2rem',

        '& .MuiButton-root': {
            marginLeft: '1rem'
        },

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    fixedButtonSection: {
        backgroundColor: '#fff',
        bottom: 0,
        position: 'fixed',
        padding: '1rem 0 !important',
        width: '100%',
        zIndex: 2,
        display: 'none',
        alignItems: 'center',

        '& .MuiButton-root': {
            margin: '0 auto'
        },

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row-reverse',
            marginLeft: '-1rem',
            justifyContent: 'center',

            '& .MuiButton-root': {
                minWidth: '105px'
            }
        }
    },
    itemDetails: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',

        '& .MuiTypography-subtitle1': {
            flex: '1 1'
        },

        '& .MuiTypography-h5': {
            width: '30%'
        }
    },
    mobileHeader: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            // color: '#231C07',
            // fontFamily: "Open Sans",
            // fontSize: '16px',
            // fontWeight: 'bold',
            // letterSpacing: '0',
            // lineHeight: '31px',
        }
    },
    mobileExpand: {
        '& .MuiIconButton-edgeEnd' : {
            [theme.breakpoints.down('sm')]: {
                marginRight: '0px'
            }
        },
        '& .MuiExpansionPanelSummary-expandIcon.Mui-expanded' : {
            [theme.breakpoints.down('sm')]: {
                transform: 'rotate(0deg)'
            }
        }

    },
    mobileAutoComplete : {
        [theme.breakpoints.down('sm')]: {
            ' & input': {
                [theme.breakpoints.down('sm')]: {
                    fontSize: '14px',
                }
            }
        }
    },
    mobileFormLabel: {
        '& .MuiFormLabel-root':{
            [theme.breakpoints.down('sm')]: {
                color: '#AAAAAA',
                fontFamily: "Open Sans",
                fontSize: '14px',
                letterSpacing: '0',
                lineHeight: '18px',
            }
        },
        '& .MuiFormLabel-root.Mui-focused' : {
            [theme.breakpoints.down('sm')]: {
                color: '#22B11F'
            }
        }
    },
    noMargin: {
        [theme.breakpoints.down('sm')]: {
            margin: '0'
        }
    },
    paddingTopBottom: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '20px',
            paddingBottom: '10px'
        }
    }
}));

export default GiveBooksStyles;
