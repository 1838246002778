import { makeStyles } from '@material-ui/core/styles';

const MyGivesStyles = makeStyles(theme => ({
    root: {
        '& .MuiBox-root': {
            display: 'flex'
        },

        '& .MuiBadge-anchorOriginTopRightRectangle': {
            backgroundColor: '#A7080D',
            top: '22px',
            right: '16px',
            border: '2px solid #fff',
            height: '20px',
            width: '20px',
            color: '#fff'
        }
    },
    heading: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '32px',
        backgroundColor: '#A7080D',
        padding: '1rem',
        alignItems: 'center'
    },
    advertisement: {
        width: '30%',

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    requestContainer: {
        borderTop: '4px solid' + theme.palette.primary.main,
        boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
        alignItems: 'start',
        marginTop: '10px',
        padding: '1rem',

        '& .MuiButton-root': {
            width: '100%',
            marginTop: '1rem'
        },
        '& .MuiTypography-subtitle2': {
            wordBreak: 'break-all'
        }
    },
    totalRequests: {
        marginLeft: '1rem',
        borderRadius: '2px',
        marginBottom: '-11px',
        padding: '3px',
        color: '#2196f3',
        cursor: 'pointer'
    },

    title: {
        color: '#ffffff',
        fontWeight: '700'
    },

    imageContainer: {
        width: '100%',
        height: '75%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backfaceVisibility: 'hidden',
        transform: 'translate3d(0, 0, 0)'
    },

    card: {
        height: '353px',
        width: '291px',
        margin: '20px 20px 20px 0',
        boxShadow: 'none',
        border: '1px solid #e2e2e2',

        '&:hover': {
            boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)',

            '& $buttonContainer': {
                display: 'flex',
                justifyContent: 'space-around'
            },

            '& $prevNextContainer': {
                visibility: 'visible',
            },

            '& $dotContainer': {
                display: 'block'
            }
        },

        [theme.breakpoints.down("sm")]: {
            margin: '0',
            marginBottom: '30px'
        },
    },
    dotContainer: {
        alignSelf: 'center',
        marginTop: '1rem',
        display: 'none'
    },
    prevNextContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px',
        visibility: 'hidden',
        zIndex: 2,
        '& svg': {
            mixBlendMode: 'difference'
        }
    },
    buttonContainer: {
        display: 'none'
    },
    checkmark: {
        position: 'absolute',
        zIndex: '1000',
        fontSize: '5rem',
        top: '50%',
        left: '50%',
        color: '#2D72CC',
        transform: 'translate(-50%, -50%)',

        '&:hover': {
            fill: '#2D72CC'
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none', 
        }
    },
    showRequests: {
        position: 'absolute',
        zIndex: '1000',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    feedbackWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));

export default MyGivesStyles;