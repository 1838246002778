import React from 'react';
import GlobalStyles from './../../styles/Global.styles';
import Box from '@material-ui/core/Box';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useConfirmation } from './../../ConfirmationService';
import InfoIcon from '@material-ui/icons/Info';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditIcon from '@material-ui/icons/Edit';
import { itemApi } from './../../api/itemApi';
import { userApi } from './../../api/userApi';
import { Component, useState, useEffect } from 'react';
import { CircularProgress, makeStyles, withStyles } from "@material-ui/core";
import { useStoreActions } from '../../store';
import { VerticalAds } from '../ui/ads/VerticalAds';
import Tooltip from "@material-ui/core/Tooltip";
import { navigate } from '@reach/router';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '2rem',
    height: '100%',
    width: '900px',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      '& .MuiGrid-grid-xs-7': {
        maxWidth: '100%',
        flexBasis: '100%'
      },

      '& .MuiGrid-grid-xs-5': {
        display: 'none'
      },

      '& .MuiButton-root': {
        flex: '1 1'
      }
    }
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',

    [theme.breakpoints.down('sm')]: {
      top: '36px',
      right: '34px'
    }
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  requestNotificationWrapper: {
    marginTop: '50%',
    transform: 'translate(0,-75%)',

    [theme.breakpoints.down('sm')]: {
      transform: 'translate(0,-40%)',
    }
  },
  helperTextRight: {
    display: "flex",
    alignSelf: "flex-end"
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    color: 'rgb(13, 60, 97)',
    backgroundColor: 'rgb(232, 244, 253)',
    maxWidth: '220px',
    border: "1px solid #dadde9",
    fontSize: '12px',
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 400,
    lineHeight: '1.43',
    borderRadius: '4px',
    margin: 'auto'
  },
}))(Tooltip);

export const Request = (props) => {
  const classes = useStyles();

  const [selected, setSelected] = React.useState(false);
  const [Request, SetRequest] = React.useState();
  const [requestDescription, setRequestDescription] = React.useState("");
  const [user_info, setUser_info] = React.useState([]);
  const [for_whom, setFor_whom] = React.useState('self');
  const [show_address, setShow_address] = React.useState(false);
  const [name, setName] = React.useState();
  const [address, setAddress] = React.useState();
  const [city, setCity] = React.useState();
  const [state_of, setState_of] = React.useState();
  const [zip_code, setZipCode] = React.useState();
  const [phone, setPhone] = React.useState();
  const [edit, setEdit] = React.useState(false);
  const [state, setState] = React.useState({
    checkedA: true,

  });

  const [loading, setLoading] = React.useState(false),
    [isRequested, setRequested] = React.useState(false),
    showAlert = useStoreActions(actions => actions.alert.showAlert),
    bundleId = props.bundle_Id,
    deliveryPref = props.detail.item.delivery_pref,
    giverName = props.giverName;

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });

  };

  const displayAddress = () => {
    setShow_address(true);
  }

  const setForWhomRequested = (who) => {
    setFor_whom(who)
  }
  const sendRequest = () => {
    if (!for_whom) {
      showAlert({ message: 'Please select for whom this item is requested', open: true, severity: 'warning' })
      return false;
    }
    if (!requestDescription) {
      showAlert({ message: 'Please enter message for giver', open: true, severity: 'warning' })
      return false;
    }

    const requestDataBlock = {
      message: requestDescription,
      r_status: 'requested',
      for_whom: for_whom,
      bundle_id: bundleId
    }
    setLoading(true)
    itemApi.putRequestItem(requestDataBlock, function (response) {
      setLoading(false)
      if (response) {
        SetRequest(response);
        props.setRequestStatus(true);
        props.setItemRequested(true);
        // openDialog()
        setRequested(true);
      }
    }, function () {
      setLoading(false)
    })
  }
  const confirm = useConfirmation().bind(this);
  // const openDialog = () => {
  //     confirm({
  //         variant: 'info',
  //         catchOnCancel: true,
  //         title: 'Product has been requested successfully.',
  //         description: 'We will notify you if your request is accepted. Please note that requests may be accepted by giver based on queue position or your location proximity to giver.'
  //     }).then(() => {
  //         props.closeModel();
  //         props.closeModel(false);
  //         props.setItemRequested(true);
  //     })
  // };

  return (
    <React.Fragment>
      <Grid
        container
        className={classes.container}>
        <svg
          width='14px'
          height='14px'
          viewBox='0 0 10 10'
          className={classes.closeIcon}
          onClick={(e) => {
            props.closeModel(false);
          }}>
          <g
            id='Archive'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
          >
            <g
              id='SVGs'
              transform='translate(-443.000000, -82.000000)'
              fill='#717171'
            >
              <g
                id='Icon-/-share-Copy-2'
                transform='translate(443.000000, 82.000000)'
              >
                <path
                  d='M8.68005842,0 L10,1.31994158 L6.319,4.999 L10.0000001,8.68005842 L8.68005842,10.0000001 L5,6.319 L1.31994158,10 L-1.77635684e-15,8.68005842 L3.68,5 L8.90101202e-08,1.31994167 L1.31994167,8.90102259e-08 L5,3.68 L8.68005842,0 Z'
                  id='x'
                ></path>
              </g>
            </g>
          </g>
        </svg>
        <Grid item xs={7}>
          {!isRequested && <>
            <Typography
              variant='h1'
              component='div'
              style={{ marginBottom: "1rem" }}
            >
              Get Item
                </Typography>
            <Typography variant='subtitle2' component='div'>
              For
                </Typography>
            <ToggleButton
              value='self'
              selected={for_whom === "self" ? true : false}
              onChange={() => {
                setSelected(!selected);
              }}
              onClick={(e) => {
                setForWhomRequested("self");
              }}
            >
              Self
                </ToggleButton>
                &nbsp;&nbsp;
                <ToggleButton
              value='someone'
              selected={for_whom === "someone" ? true : false}
              onChange={() => {
                setSelected(!selected);
              }}
              onClick={(e) => {
                setForWhomRequested("someone");
              }}
            >
              Someone
                </ToggleButton>
          </>}
          {show_address && (
            <>
              <Typography
                variant='subtitle2'
                component='div'
                style={{ margin: "2rem 0 1rem 0" }}
              >
                Add your shipping address{" "}
                <InfoIcon style={{ fontSize: "1rem" }}></InfoIcon>
              </Typography>
              <TextField
                style={{ marginBottom: "1rem" }}
                label='Name'
                onChange={(e) => {
                  let value = e.target.value;
                  setName(value);
                }}
                fullWidth
              />
              <TextField
                label='Street Address'
                style={{ marginBottom: "1rem" }}
                onChange={(e) => {
                  let value = e.target.value;
                  setAddress(value);
                }}
                fullWidth
              />
              <TextField
                label='City'
                style={{ marginBottom: "1rem" }}
                onChange={(e) => {
                  let value = e.target.value;
                  setCity(value);
                }}
                fullWidth
              />
              <TextField
                label='State / Province / Region'
                style={{ marginBottom: "1rem" }}
                onChange={(e) => {
                  let value = e.target.value;
                  setState_of(value);
                }}
                fullWidth
              />
              <TextField
                label='Zipcode'
                style={{ marginBottom: "1rem" }}
                onChange={(e) => {
                  let value = e.target.value;
                  setZipCode(value);
                }}
                fullWidth
              />
              <TextField
                label='Phone'
                style={{ marginBottom: "1rem" }}
                onChange={(e) => {
                  let value = e.target.value;
                  setPhone(value);
                }}
                fullWidth
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.checkedA}
                    onChange={handleChange("checkedA")}
                    value='checkedA'
                  />
                }
                style={{ marginLeft: "-10px" }}
                label='Save my preferences to my profile.'
              />
              <Typography variant='subtitle1' component='div'>
                Address privacy info goes here…
                    </Typography>
              <Box mt={2} display='flex' flexDirection='row-reverse'>
                <Button variant='contained' color='primary'>
                  Save & Continue
                      </Button>
                <Button
                  variant='outlined'
                  color='secondary'
                  style={{ marginRight: "1rem" }}
                >
                  Cancel
                      </Button>
              </Box>
            </>
          )}
          {deliveryPref === "Delivery" && (
            <div
              style={{
                padding: "5px",
                margin: "2rem 0",
                width: "235px",
                border: "1px solid #AAAAAA",
                borderRadius: "2px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant='h5' component='div'>
                  Your Address
                      </Typography>
                <EditIcon
                  style={{ fontSize: "1rem" }}
                  onClick={displayAddress}
                ></EditIcon>
              </div>
              <Typography variant='subtitle2' component='div'>
                Name:{name}
              </Typography>
              <Typography variant='subtitle2' component='div'>
                Address here:{address}
              </Typography>
              <Typography variant='subtitle2' component='div'>
                Address here:{address}
              </Typography>
              <Typography variant='subtitle2' component='div'>
                PhoneNo:{phone}
              </Typography>
            </div>
          )}
          {!isRequested ? (<>
            <Alert
              severity='info'
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              You will receive the date and place to pickup once the giver
              accepts your request.
                </Alert>
            <Box mt={1}>
              <TextField
                multiline
                label={"Message to " + giverName}
                placeholder='Please provide as much relevant info for giver to consider request. Please be aware that requests may be accepted based on queue position or your location proximity to giver.'
                rows='4'
                onChange={(e) => {
                  let value = e.target.value;
                  setRequestDescription(value);
                }}
                inputProps={{ maxLength: 200 }}
                fullWidth
                helperText={`${requestDescription ? requestDescription?.length : 0}/200`}
                FormHelperTextProps={{ classes: { root: classes.helperTextRight } }}
              />

              {/* <Typography variant='subtitle1' style={{ marginTop: '2rem', width: '70%', lineHeight: '21px' }} component='div'>By submitting your request, you agree to abide by our
                    <a href='/termsofuse' target='_new'>Terms of Service </a>and <a href='/termsofuse' target='_new'>Guidelines</a> for requests.</Typography> */}
              <Typography
                variant='subtitle1'
                style={{
                  lineHeight: "21px",
                  wordBreak: "break-word",
                  margin: "3rem 0 1rem 0",
                }}
                component='div'
              >
                By requesting this item, I accept that I have read, understood and agree to abide by the{" "}
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <ul style={{ margin: "0 3px", padding: "6px 12px" }}>
                        <li>
                          I am requesting an item for myself or someone I
                          know.
                            </li>
                        <li>
                          I may not sell this item unless I have explicit
                          permission from the giver.
                            </li>
                        <li>
                          I will respond within 24 hours to giver's
                          notification.
                            </li>
                        <li>
                          I understand that the giver is free to choose
                          another requestor if I do not respond within 24
                          hours.
                            </li>
                        <li>
                          I agree to honor my request and pick up the item
                          from the giver at the specified location and confirmed time
                          slot.
                            </li>
                      </ul>
                    </React.Fragment>
                  }
                >
                  <span style={{ color: "#2196f3" }}>
                    Community Guidelines
                      </span>
                </HtmlTooltip>
                    ,{" "}
                <a href='/termsofuse' target='_new'>
                  Terms of Use
                    </a>{" "}and{" "}
                <a href='/privacypolicy' target='_new'>
                  Privacy Policy.
                    </a>
              </Typography>
              <Box display='flex' flexDirection='row-reverse'>
                <div className={classes.wrapper}>
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={loading}
                    onClick={sendRequest}>
                    Send Request
                      </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
                <div className={classes.wrapper}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    style={{ marginRight: "1rem" }}
                    onClick={(e) => {
                      props.closeModel(false);
                    }}
                    style={{ marginRight: "1rem" }}
                  >
                    Cancel
                      </Button>
                </div>
              </Box>
            </Box>
          </>) : (
              <Box className={classes.requestNotificationWrapper}>
                <Typography
                  component='div'
                  variant='subtitle2'
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    width: "70%"
                  }}
                >
                  <h3
                    style={{
                      color: "#22B11F",
                    }}
                  >
                    Thank you for requesting item!
                </h3>
                  <p>We will notify you if your request is accepted.</p>
                  <p>
                    {" "}
                  Please note that requests may be accepted by giver based on
                  queue position or your location proximity to giver.
                </p>
                  <Typography
                    mt={3}
                    style={{
                      cursor: "pointer",
                      padding: "5px 15px",
                      borderRadius: "4px",
                      border: "1px solid rgba(127, 204, 40, 0.5)",
                      color: "#fff",
                      backgroundColor: "#22B11F",
                      fontSize: ".875rem",
                      display: "inline-block",
                      display: "flex",
                      width: "130px",
                      margin: "auto",
                      marginTop: "30px",
                    }}
                    onClick={(e) =>
                      navigate("/my-gets/1")
                    }
                    component='span'
                    color='primary'
                  >
                    View Request
                </Typography>
                </Typography>
              </Box>
            )}
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={4} style={{ height: '100%' }}>
          <VerticalAds></VerticalAds>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}