import React from 'react';
import {
    List, ListItem, ListItemText, Box,
    Chip, Avatar, ListItemAvatar, Typography, useStyles, makeStyles, Grid
} from '@material-ui/core';
import { itemApi, userApi } from '../../api';
import { useStoreState } from "./../../hooks";
import LoadingIcon from '../ui/loadingIcon';
import Rating from "@material-ui/lab/Rating";
import NotificationStyle from "./../../styles/notification.style";
import { HorizontalAds } from "../ui/ads/HorizontalAds";
import { VerticalAds } from "../ui/ads/VerticalAds";
import renderHTML from 'react-render-html';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';

const localStyle = makeStyles((theme) => ({
    container: {
        padding: '15px',
        backgroundColor: theme.palette.background.primary,

        [theme.breakpoints.down('sm')]: {
            padding: '15px',
        }
    },
    title: {
        padding: '0px 16px',
        marginBottom: '10px'
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
        }
    }
})
);

export default function Notifications(props) {
    const classes = NotificationStyle(),
        localClasses = localStyle(),
        [state, setState] = React.useState({
            top: false,
            left: false,
            bottom: true,
            right: false,
        }),
        toggleDrawer = (anchor, open) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            setState({ ...state, [anchor]: open });
        },
        isLoggedIn = useStoreState((state) => state.auth.isLoggedIn),
        [notifications, setNotifications] = React.useState(null),
        [loading, setLoading] = React.useState(false),
        [user, setUser] = React.useState("");



    React.useEffect(() => {
        if (isLoggedIn) {
            setLoading(true);
            itemApi.getNotifications((response) => {
                setNotifications(response);
                setLoading(false);
            });
        }
    }, []);

    // const getuserDetails = async (userHash) => {
    //     setLoading(true);
    //     const receiverData = await userApi.getUserSummaryByHash(userHash);
    //     const data = await receiverData;
    //     // const json = await data.json();
    //     console.log(data);
    //     setLoading(false);
    //     return data;
    // }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <div className={classes.bottomDrawer}>
                    <h4 className={classes.title + " " + localClasses.title}>Notifications</h4>
                    {loading ?
                        (<Box margin='0 20px 20px 0'>
                            <LoadingIcon />

                        </Box>) :
                        (<List>
                            {notifications && notifications?.results.map((notification, index) => {
                                // {['Inbox', 'All'].map((text, index) => (
                                // const user = getuserDetails(notification.to_user_hash);
                                // console.log("User", user)
                                return (< ListItem button key={notification.id} >
                                    <Chip
                                        size="small"
                                        label="Primary Clickable"
                                        clickable
                                        color="primary"
                                    />
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' }}>
                                                <ListItemAvatar>
                                                    <Avatar alt={notification.data.user_name.toUpperCase()} src="/static/images/avatar/3.jpg" />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={<Typography
                                                        variant='subtitle2'
                                                        component='div'
                                                        style={{
                                                            fontSize: '12px'
                                                        }}
                                                    >
                                                        {notification.data && notification.data.user_name}
                                                        {/* {'Test User'} */}
                                                    </Typography>}
                                                    style={{ display: 'inline-flex', alignItems: 'center' }}
                                                    secondary={
                                                        <Rating
                                                            name='test'
                                                            style={{ marginLeft: '10px', fontSize: "12px" }}
                                                            value={4}
                                                            disabled />
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box stlye={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                marginRight: '10px'
                                            }}>
                                                <Typography
                                                    variant='subtitle2'
                                                    component='div'
                                                    style={{
                                                        fontSize: '14px',
                                                        color: 'primary'
                                                    }}
                                                >{notification.title}
                                                </Typography>
                                                <Typography
                                                    variant='subtitle2'
                                                    component='div'
                                                    style={{
                                                        fontSize: '14px'
                                                    }}
                                                >{renderHTML(notification.body)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box className={localClasses.content} style={{ justifyContent: 'flex-end' }} >
                                                <AccessTimeOutlinedIcon fontSize="small" style={{ marginRight: '5px' }} />
                                                <Typography
                                                    variant='subtitle2'
                                                    component='div'
                                                    style={{
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    {notification.date_created}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </ListItem>)
                            })}
                            {notifications && notifications.count == 0 &&
                                <ListItem key={'No result'} style={{ alignItems: 'center' }}
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                    <ListItemText primary={'No any notifications'} />
                                </ListItem>}
                        </List>)}
                </div>
            </Grid>
            {/* <Grid item xs={2}>
                <VerticalAds></VerticalAds>
            </Grid>
            <Grid item xs={12}>
                <HorizontalAds></HorizontalAds>
            </Grid> */}
        </Grid >
    );
}
