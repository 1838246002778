import React, {Component, useState, useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingIcon(props){
    return(
        <div className="" style={{width : '100%', justifyContent: 'center', alignItems: 'center', minHeight: '300px', display: 'flex'}}>
            {/* <img style={{width:'40px'}} src="/assets/images/loading-cgk.gif"/> */}
            <CircularProgress color="primary" />
            {/*<br/><span style={{fontWeight:"bold",fontSize:"10px"}}>{props.loadingMsg}</span>*/}
        </div>
    )
}
