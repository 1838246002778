import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import { AntTab, AntTabs } from './../ui/AntTabs';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useConfirmation } from './../../ConfirmationService';
import ProfileStyles from './../../styles/Profile.styles';
import TextField from '@material-ui/core/TextField';
import { Avatar } from '@material-ui/core';
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded';
import EditIcon from '@material-ui/icons/Edit';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            style={{ border: 'none' }}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export const Profile = () => {
    useEffect(() => {
        const anchor = document.createElement("a");
        anchor.setAttribute("class", "twitter-timeline");
        anchor.setAttribute("data-theme", "dark");
        anchor.setAttribute("data-tweet-limit", "5");
        anchor.setAttribute("data-chrome", "noheader nofooter noborders");
        anchor.setAttribute("href", "https://twitter.com/pshiral");
        document.getElementsByClassName("twitter-embed")[0].appendChild(anchor);

        const script = document.createElement("script");
        script.setAttribute("src", "https://platform.twitter.com/widgets.js");
        document.getElementsByClassName("twitter-embed")[0].appendChild(script);
    }, []);
    const classes = ProfileStyles(),
        [value, setValue] = React.useState(0),
        confirm = useConfirmation().bind(this),
        items = [
        {
            name: 'Random Name #1',
            deliveryMode: 'Pickup only',
            condition: 'Good condition',
            GiverName: 'Test Giver',
            city: 'Pune',
            images: [
                'assets/images/mobile.jpeg',
                'assets/images/mobile.jpeg',
                'assets/images/mobile.jpeg'
            ],
        },
        {
            name: 'Random Name #2',
            condition: 'Good condition',
            deliveryMode: 'Delivery',
            GiverName: 'Test Giver',
            city: 'Pune',
            images: [
                'assets/images/samantha.jpg',
                'assets/images/samantha.jpg',
                'assets/images/samantha.jpg'
            ]
        },
        {
            name: 'Random Name #2',
            condition: 'Good condition',
            deliveryMode: 'Delivery',
            GiverName: 'Test Giver',
            city: 'Pune',
            images: [
                'assets/images/mobile.jpeg',
                'assets/images/mobile.jpeg',
                'assets/images/mobile.jpeg'
            ]
        },
        {
            name: 'Random Name #2',
            condition: 'Good condition',
            deliveryMode: 'Delivery',
            GiverName: 'Test Giver',
            city: 'Pune',
            images: [
                'assets/images/mobile.jpeg',
                'assets/images/mobile.jpeg',
                'assets/images/mobile.jpeg'
            ]
        }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const componentDidMount = () => {
        if (!window.doBuild) {
            preloadWidgetScript();
        } else {
            window.doBuild();
        }
    };

    const preloadWidgetScript = () => {
        const script = document.createElement('script');
        script.async = true;
        script.dataset.pinBuild = 'doBuild';
        script.src = '//assets.pinterest.com/js/pinit.js';
        document.body.appendChild(script);
    }

    componentDidMount();

    const editProfile = () => {
        confirm({
            variant: 'save',
            catchOnCancel: true,
            dynamicTemplate: (<Box>
                <Typography variant='subtitle2' style={{ textAlign: 'start' }} component='div'>Avatar Image</Typography>
                <Box display='flex'>
                    <Box mr={4}>
                        <Avatar className={classes.dpContainer} style={{ width: '90px', height: '90px', marginTop: '0.5rem' }}>
                            P
                            <div className={classes.photoIconContainer}>
                                <PhotoCameraRoundedIcon color='primary'></PhotoCameraRoundedIcon>
                            </div>
                        </Avatar>
                    </Box>
                    <TextField
                        multiline
                        label='Your quote'
                        placeholder='Add your quote here'
                        rows='4'
                        fullWidth />
                </Box>
                <Box mt={2}>
                    <TextField
                        label='Title'
                        type='text'
                        fullWidth />
                </Box>
                <Box mt={2}>
                    <TextField
                        label='Facebook link'
                        type='text'
                        fullWidth />
                </Box>
                <Box mt={2}>
                    <TextField
                        label='Twitter'
                        type='text'
                        fullWidth />
                </Box>
                <Box mt={2}>
                    <TextField
                        label='Instagram'
                        type='text'
                        fullWidth />
                </Box>
            </Box>),
            title: 'Edit Profile',
            description: '',
            styleClass: 'dialogContainer'
        }).then(() => console.log('Ok'))
            .catch(() => console.log('No'));
    };

    return (
        <Box display='flex'>
            <Box display='flex' flexDirection='column' width='75%'>
                <aside className={classes.avatarContainer}>
                    <Box width='15%'>
                        <Avatar className={classes.dpContainer}>
                            P
                            <div className={classes.photoIconContainer}>
                                <PhotoCameraRoundedIcon color='primary'></PhotoCameraRoundedIcon>
                            </div>
                        </Avatar>
                        <Box display='flex' alignSelf='center' mb={2}>
                            <img src='assets/images/facebook.svg' width='24px' height='24px' style={{ margin: '0 10px' }}></img>
                            <img src='assets/images/instagram.svg' width='24px' height='24px' style={{ margin: '0 10px' }}></img>
                            <img src='assets/images/twitter.svg' width='24px' height='24px' style={{ margin: '0 10px' }}></img>
                        </Box>
                    </Box>
                    <Box mr={3} width='60%'>
                        <Box display='flex' alignItems='baseline' mb={0.5}>
                            <Typography variant='h1' component='div'>Parshuram Shiral</Typography>
                            <Typography variant='subtitle2' component='div' style={{ marginLeft: '5px' }}>Pune</Typography>
                        </Box>
                        <Typography variant='subtitle2' component='div'>Software Engineer at Oracle</Typography>
                        <Box display='flex' alignItems='center' my={0.5}>
                            <Rating name="disabled" style={{ fontSize: '1rem' }} value={4} disabled />
                            <Typography variant='subtitle1' component='div' style={{ marginLeft: '5px' }}>
                                (123 Reviews)</Typography>
                        </Box>
                        <svg width="16px" height="12px" viewBox="0 0 101 75" version="1.1">
                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Quote" fill="#717171" fill-rule="nonzero">
                                    <path d="M80.2307692,75 C86.2307692,75 91.1923077,73.3796296 95.1153846,70.1388889 C99.0384615,66.8981481 101,62.5 101,56.9444444 C101,50.9259259 99.2115385,46.1805556 95.6346154,42.7083333 C92.0576923,39.2361111 87.2692308,38.0787037 81.2692308,39.2361111 C80.8076923,33.2175926 82.3076923,28.125 85.7692308,23.9583333 C89.2307692,19.7916667 93.7307692,16.4351852 99.2692308,13.8888889 L99.2692308,13.8888889 L89.9230769,0 C86.9230769,0.925925926 83.4615385,2.5462963 79.5384615,4.86111111 C75.6153846,7.17592593 71.9230769,10.2430556 68.4615385,14.0625 C65,17.8819444 62.0576923,22.6851852 59.6346154,28.4722222 C57.2115385,34.2592593 56,41.087963 56,48.9583333 C56,57.5231481 58.1923077,64.0046296 62.5769231,68.4027778 C66.9615385,72.8009259 72.8461538,75 80.2307692,75 Z M24.2307692,75 C30.2307692,75 35.1923077,73.3796296 39.1153846,70.1388889 C43.0384615,66.8981481 45,62.5 45,56.9444444 C45,50.9259259 43.2115385,46.1805556 39.6346154,42.7083333 C36.0576923,39.2361111 31.2692308,38.0787037 25.2692308,39.2361111 C24.8076923,33.2175926 26.3076923,28.125 29.7692308,23.9583333 C33.2307692,19.7916667 37.7307692,16.4351852 43.2692308,13.8888889 L43.2692308,13.8888889 L33.9230769,0 C30.9230769,0.925925926 27.4615385,2.5462963 23.5384615,4.86111111 C19.6153846,7.17592593 15.9230769,10.2430556 12.4615385,14.0625 C9,17.8819444 6.05769231,22.6851852 3.63461538,28.4722222 C1.21153846,34.2592593 0,41.087963 0,48.9583333 C0,57.5231481 2.19230769,64.0046296 6.57692308,68.4027778 C10.9615385,72.8009259 16.8461538,75 24.2307692,75 Z" id="“"></path>
                                </g>
                            </g>
                        </svg>
                        <Typography variant='body1' style={{ fontStyle: 'italic', color: '#717171' }} component='div'>
                            It's not how much we give but how much Love we put into giving.
                    </Typography>
                    </Box>

                    <Box display='flex' width='20%'>
                        <section>
                            <Typography variant='h1' style={{ fontSize: '36px' }} component='div'>23</Typography>
                            <Typography variant='body1' component='div'>Gives</Typography>
                        </section>
                        <section className={classes.line}>

                        </section>
                        <section>
                            <Typography variant='h1' style={{ fontSize: '36px' }} component='div'>03</Typography>
                            <Typography variant='body1' component='div'>Gets</Typography>
                        </section>
                    </Box>

                    <EditIcon onClick={editProfile} style={{ fontSize: '1rem', alignSelf: 'baseline' }}></EditIcon>
                </aside>

                <Box display='flex'>
                    <div className={classes.tabs}>
                        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                            <AntTab label="My Blogs" />
                            <AntTab label="Activity Snapshots" />
                            <AntTab label="My Reviews" />
                            <AntTab label="I Care About" />
                            <AntTab label="My Wishlist" />
                        </AntTabs>
                        <Divider style={{ margin: '0.2rem 0 0.5rem 0' }} />
                        <TabPanel value={value} index={0}>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Typography variant='subtitle2' style={{ textAlign: 'right' }} component='div'><a href=''>Hide activity</a></Typography>
                            <Box display='flex' mt={4}>
                                <section className={classes.snapshot} style={{ background: `url(${items[0].images[0]}) center /contain no-repeat` }}>
                                </section>
                                <Box ml={4}>
                                    <Typography variant='subtitle2' component='div'>Posted a Harry Potter Book</Typography>
                                    <Typography variant='subtitle2' component='div' style={{ fontStyle: 'italic' }}>2 mins ago (12 Reviews)</Typography>
                                </Box>
                            </Box>
                            <Box display='flex' mt={4}>
                                <section className={classes.snapshot} style={{ background: `url(${items[0].images[0]}) center /contain no-repeat` }}>
                                </section>
                                <Box ml={4}>
                                    <Typography variant='subtitle2' component='div'>Posted a Harry Potter Book</Typography>
                                    <Typography variant='subtitle2' component='div' style={{ fontStyle: 'italic' }}>2 mins ago (12 Reviews)</Typography>
                                </Box>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                        </TabPanel>
                    </div>
                    <Box display='flex' flexDirection='column' my={6} width='25%'>
                        <Box marginY={2}>
                            <Typography variant='h3' component='div'>
                                Causes I Support <EditIcon style={{ fontSize: '1rem', float: 'right' }}></EditIcon>
                            </Typography>
                            <Divider style={{ margin: '0.2rem 0 0.5rem 0' }} />
                            <Typography variant='subtitle2' component='div'>Cure Hunger</Typography>
                            <Typography variant='subtitle2' component='div'>Combat Poverty</Typography>
                            <Typography variant='subtitle2' component='div'>Combat Poverty</Typography>
                        </Box>
                        <Box marginY={2}>
                            <Typography variant='h3' component='div'>Charities I Support</Typography>
                            <Divider style={{ margin: '0.2rem 0 0.5rem 0' }} />
                            <Typography variant='subtitle2' component='div'>Global Giving</Typography>
                            <Typography variant='subtitle2' component='div'>Combat Poverty</Typography>
                            <Typography variant='subtitle2' component='div'>American Red Cross</Typography>
                            <Typography variant='subtitle2' component='div'>World Wildlife Fund</Typography>
                        </Box>
                        <Box marginY={2}>
                            <Typography variant='h3' component='div'>Volunteering</Typography>
                            <Divider style={{ margin: '0.2rem 0 0.5rem 0' }} />
                            <Typography variant='subtitle2' component='div'>Child Education</Typography>
                            <Typography variant='subtitle2' component='div'>Poverty</Typography>
                            <Typography variant='subtitle2' component='div'>Women Empowerment</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box width='75%' mt={2}>
                    <a data-pin-do="embedUser" data-pin-scale-height="240" href="https://in.pinterest.com/parshshiral/"></a>
                </Box>
                <Box className="twitterContainer" width='75%' mt={2}>
                    <div className="twitter-embed"></div>
                </Box>
            </Box>
            <section className={classes.advertisement}></section>
        </Box>
    );
}