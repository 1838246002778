import React from 'react';
import PropTypes from 'prop-types';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useStoreActions, useStoreState } from './../../hooks';
import { securityApi, userApi } from '../../api';
import { navigate } from '@reach/router';
import Avatar from '@material-ui/core/Avatar';
import { HorizontalAds } from "../ui/ads/HorizontalAds";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',

    [theme.breakpoints.down('sm')]: {
      marginTop: '75px',

      '& .MuiAppBar-root': {
        display: 'none'
      }
    }
  },
  tabContainer: {
    padding: '0',
    margin: '0 auto',
    maxWidth: '1200px',

    [theme.breakpoints.only("md")]: {
      maxWidth: "960px"
    },

    '& .MuiBox-root': {
      padding: 0
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset'
    }
  },
  menu: {
    margin: '0 auto',
    maxWidth: '1200px',

    [theme.breakpoints.only("md")]: {
      maxWidth: "960px"
    }
  },
  searchBarSection: {
    display: 'flex',
    alignItems: 'center'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff'
  },
  inputRoot: {
    color: '#fff',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  verifyEmailSection: {
    boxSizing: 'border-box',
    margin: '0 auto',
    minWidth: '650px',
    maxWidth: '1200px',
    letterSpacing: '1px',
    backgroundColor: 'aliceblue',
    padding: '15px',
    color: "rgb(13, 60, 97)",
    '& .MuiTypography-subtitle2': {
      fontWeight: '600 !important'
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset'
    }
  },
  mobileAppBar: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'fixed',
      top: '55px',
      left: '0px',
      right: '0px',
      background: '#fff',
      height: '103px',
      paddingTop: '30px',
      zIndex: '1001'
    }
  },
  mobileAppBarTabWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  mobileAppBarTab: {
    height: '56px',
    width: '57px',
    borderRadius: '15px',
    color: 'rgba(27,27,27,0.7)',
    fontFamily: "Open Sans",
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '17px',
    textAlign: 'center'
  },
  mobileAvatarBlue: {
    backgroundColor: 'rgba(45,114,204,0.1)'
  },
  mobileAvatarPeach: {
    backgroundColor: 'rgba(235,147,60,0.15)'
  },
  mobileAvatarPink: {
    backgroundColor: 'rgba(208,0,6,0.1)'
  },
  mobileAvatarOrange: {
    backgroundColor: 'rgba(255,185,0,0.15)'
  },
  mobileAvatarBlue: {
    backgroundColor: 'rgba(45,114,204,0.1)'
  },
  avatarSmallFonts: {
    fontSize: '11px'
  },
  activeAvatar: {
    border: '1px solid #22B11F',
    color: '#22B11F',
    backgroundColor: 'rgba(34,177,31,0.06)',
    boxShadow: '0 0 31px 0 rgba(34,177,31,0.18)'
  },
  mobileDisabled: {
    color: '#22B11F',
    backgroundColor: 'rgba(34,177,31,0.06)',
  }
}));

export default function ApplicationBar(props) {
  const classes = useStyles(),
    [value, setValue] = React.useState(false),
    user = useStoreState(state => state.auth.user),
    token = useStoreState((state) => state.auth.token),
    updateUser = useStoreActions(actions => actions.auth.updateUser),
    propsBundleId = props.bundleId ? props.bundleId : '',
    isVerifyEmailSent = useStoreState(state => state.auth.isVerifyEmailSent),
    isJustRegistered = useStoreState(state => state.auth.isJustRegistered),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    [bundle_id, setBundleId] = React.useState(propsBundleId),
    resendVerificationEmail = () => {
      const reqBlk = {
        "key": token
      };

      userApi.resendVerificationEmail(reqBlk, handleSuccessResendVerificationEmail, handleErrorResendVerificationEmail)
    },
    handleSuccessResendVerificationEmail = () => {
      showAlert({
        message: "Verification email has been sent successfully.",
        open: true,
        severity: "success",
      });
    },
    handleErrorResendVerificationEmail = (resp) => {
      showAlert({
        message: "Something went wrong. Please contact Kindeeds team.",
        open: true,
        severity: "error",
      });
    },
    handleChange = (event, newValue) => {
      if (event.target.hasAttribute('disabled')) {
        return false
      }

      switch (newValue) {
        case 0:
          navigate("/my-gives/1");
          break;
        case 1:
          navigate("/my-gets/1");
          break;
        case 2:
          navigate("/give/books");
          break;
        case 3:
          navigate("/get");
          break;
        case 4:
          navigate("/covid-19");
          break;
      }
      //    setValue(newValue);
      setBundleId('');
      securityApi.checkSession(user, updateUser);
    };

  React.useEffect(() => {
    let path = window.location.pathname;

    if (path.startsWith('/my-gives')) {
      setValue(0);
    } else if (path.startsWith('/my-gets')) {
      setValue(1);
    } else if (path.startsWith('/give')) {
      setValue(2);
    } else if (path.startsWith('/get')) {
      setValue(3);
    } else if (path.startsWith('/covid-19')) {
      setValue(4);
    }
    else {
      setValue(false);
    }
  }, [][value]);

  return (
    <div className={classes.root}>
      <div className={classes.mobileAppBar}>
        <div className={classes.mobileAppBarTabWrapper}>
          <Avatar
            className={`
              ${classes.mobileAppBarTab} ${classes.mobileAvatarBlue} 
              ${value === 0 ? classes.activeAvatar : ''}
              ${!user ? classes.mobileDisabled : ''}
            `}
            onClick={evt => handleChange(evt, 0)} disabled={user ? false : true}>
            My<br />Gives
          </Avatar>
          <Avatar
            className={`
              ${classes.mobileAppBarTab} ${classes.mobileAvatarPeach} 
              ${value === 1 ? classes.activeAvatar : ''}
              ${!user ? classes.mobileDisabled : ''}
            `}
            onClick={evt => handleChange(evt, 1)} disabled={user ? false : true}>
            My<br /> Gets
          </Avatar>
          <Avatar
            className={`
              ${classes.mobileAppBarTab} ${classes.mobileAvatarPink} 
              ${value === 2 ? classes.activeAvatar : ''}
              ${!user ? classes.mobileDisabled : ''}
            `}
            onClick={evt => handleChange(evt, 2)} disabled={user ? false : true}>
            Give
          </Avatar>
          <Avatar
            className={`
              ${classes.mobileAppBarTab} ${classes.mobileAvatarOrange} 
              ${value === 3 ? classes.activeAvatar : ''}
              ${!user ? classes.mobileDisabled : ''}  
            `}
            onClick={evt => handleChange(evt, 3)}>
            Get
          </Avatar>
          {/* <Avatar 
            className={`
              ${classes.mobileAppBarTab} ${classes.mobileAvatarBlue} ${classes.avatarSmallFonts}
              ${value === 4 ? classes.activeAvatar: ''}
              ${!user ? classes.mobileDisabled : ''}  
            `} 
            onClick={evt => handleChange(evt, 4)}>
            COVID<br/>-19
          </Avatar> */}
        </div>
      </div>
      <AppBar position="static">
        <Grid className={classes.menu} container>
          <Grid item xs={12}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="My Gives" {...tabProps(0)} disabled={user ? false : true} />
              <Tab label="My Gets" {...tabProps(1)} disabled={user ? false : true} />
              <Tab label="Give" {...tabProps(2)} disabled={user ? false : true} />
              <Tab label="Get" {...tabProps(3)} />
              {/* <Tab label="Support a Cause" {...tabProps(4)} /> */}
              <Tab label="COVID-19" {...tabProps(4)} style={{ letterSpacing: '1px' }} />
            </Tabs>
          </Grid>
          {/* <Grid item xs={2} className={classes.searchBarSection}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Grid> */}
        </Grid>
      </AppBar>
      {!isVerifyEmailSent && !isJustRegistered && user && (!user.u_status || user.u_status == 1) &&
        <section className={classes.verifyEmailSection}>
          <Box style={{ display: 'flex', justifyContent: 'center', }}>
            <Typography variant='subtitle2' style={{ marginLeft: '0.5rem' }} component='div'>
              {user && user.email ? <>Please confirm your email sent to  <Typography variant='subtitle2' component='span' style={{ color: '#22B11F' }}>{user.email}.</Typography></> : "Please confirm your email."}
            </Typography>
          </Box>
        </section>}
      {window.location.pathname !== '/dashboard' && <Box width='100%'> <HorizontalAds></HorizontalAds> </Box>}
      <TabPanel className={classes.tabContainer} value={value} index={0}>
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index={1}>
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index={2}>
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index={3}>
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={value} index={4}>
        <TabPanel className={classes.tabContainer} value={value} index={5}>
        </TabPanel>
      </TabPanel>
    </div>
  );
}

export { ApplicationBar }