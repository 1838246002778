import React from 'react';
import Helmet from 'react-helmet';
import Grid from '@material-ui/core/Grid';
// import { Footer } from '../ui/Footer';
// import { Dialog } from '../ui/Dialog';
// import { useLocation } from '../../hooks/useLocation';
// import { useWindowScrollPosition } from '../../hooks/useWindowScrollPosition';
// import { ErrorBoundaryMain } from '../ErrorBoundaries';
// import { DialogQueue } from '@rmwc/dialog';
// import { CONFIG } from '../../config';
// import { QUERY } from '../../graphs';
// import { Redirect, RouteComponentProps } from '@reach/router';
// import { AppLoader } from '../screens/AppLoader';
// import { debounce } from 'lodash';
import ApplicationBar from './../ui/ApplicationBar'
import { Header } from './../ui/Header';
import { makeStyles } from '@material-ui/core/styles';
import { HomePage } from '../screens/HomePage';
import LearnMore from '../screens/static/LearnMore';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f5f5',
    minWidth: '1250px',
    maxWidth: '1650px'
  },
  tabContainer: {
    margin: '-24px 12%'
  },
  container: {
    padding: '0',
    margin: '0 auto',
    maxWidth: '1200px',
    minHeight: '400px',

    [theme.breakpoints.only("md")]: {
      maxWidth: "960px"
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: "167px"
    },

    '& .MuiBox-root': {
      padding: 0
    }
  },
  searchBarSection: {
    alignSelf: 'center'
  }
}));

const PrivateLayout = ({ component: Component, ...rest }) => {
  // If valid user show private component
  return <Component {...rest} />;
};

const MainPrivateLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      {/* <Helmet titleTemplate="%s | CGK - MainLayout" />
      <Header></Header> */}
      {window.location.pathname === '/' ? <HomePage /> : (window.location.pathname === '/learnmore' ? '' : <ApplicationBar></ApplicationBar>)}
      <div className={classes.container}>
        {/* </div><Grid item xs={8}>  */}
        {children}
        {/* </Grid>
        <Grid item xs={4}>
          <VerticalAds />
        </Grid>*/}
      </div>
    </>
  )
};

const PublicLayout = ({ component: Component, ...rest }) => {
  // If valid user show private component
  return <Component {...rest} />;
};
const MainPublicLayout = ({ children }) => {
  return (
    <>
      {/* <Helmet titleTemplate="%s | CGK - MainLayout" />
      <Header></Header> */}
      {/* <ApplicationBar></ApplicationBar> */}
      <div>
        {children}
      </div>
    </>
  )
};

const ShareLayout = ({ children }) => {
  return (
    <div>
      <Helmet titleTemplate="%s | CGK - ShareLayout" />
      <Header></Header>
      {/* <ApplicationBar></ApplicationBar> */}
      {children}
    </div>
  );
};

export { MainPrivateLayout, ShareLayout, PrivateLayout, PublicLayout, MainPublicLayout };
