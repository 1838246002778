import React from 'react';
import { makeStyles } from '@material-ui/core';
import { utilHelper } from './../../../helpers/utilHelper';

const useStyles = makeStyles(theme => ({
  bannerContainer: {
    clear: 'both',

    [theme.breakpoints.down('sm')]: {
      margin: '0px'
    }
  },

  advertisement: {
    margin: '2rem 0',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const HorizontalAds = (props) => {
  const classes = useStyles(),
    advertisement = utilHelper.getRandomAds('horizontal');

  //GetStyles();
  return (
    <div className={classes.bannerContainer}>
      <section className={classes.advertisement}>
        <Iframe iframe={advertisement} allow="autoplay" />
      </section>
    </div>
  );
};

export { HorizontalAds };
