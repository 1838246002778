import React from "react";
import { Grid, Radio, TextField, Box, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, makeStyles, Table, TableBody, TableRow, TableCell, withStyles } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useConfirmation } from "./../../ConfirmationService";
import { navigate } from '@reach/router';
import { ItemImagesContainer } from "../../components/ui/ItemImagesContainer";
import { ItemDetails } from "../../components/ui/ItemDetails";
import { GoodReadDetails } from "../../components/ui/GoodReadDetails";
import { itemApi, userApi } from "./../../api";
import { utilHelper } from "./../../helpers/utilHelper";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { LargeRectangleAds } from "../ui/ads/LargeRectangleAds";
import { useStoreActions, useStoreState } from "./../../hooks";
import { appSettings } from "./../../constants/appSettings";
import { getPhoneNumber } from '../../utils/countrylist.js';
import Rating from "@material-ui/lab/Rating";
import { GetStylesLocal } from './../../styles/addressCommon.styles';
import Chip from "@material-ui/core/Chip";
import { useStyles } from "./../../styles/AntTabs.js";
import MyGivesStyles from "./../../styles/MyGives.styles.js";
import Dialog from "@material-ui/core/Dialog/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions/DialogActions";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

const BASE_URL = appSettings.BaseURL;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


const useStylesOpacity = makeStyles((theme) => ({
  setSection: {
    padding: "4px",
    width: "77px",
    backgroundColor: theme.palette.primary.secondary,
    borderRadius: "0 0px 20px 0px",
    margin: "5px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
}));
const MyGetsAddress = (props) => {
  const [displayIndex, setDisplayIndex] = React.useState(0),
    [item, setItem] = React.useState(null),
    [reason, setReason] = React.useState(null),
    [message, setMessage] = React.useState(null),
    [additional_info, setAdditionalInfo] = React.useState(null),
    [pickup_loc_1, setPickupLoc1] = React.useState(null),
    [pickup_loc_2, setPickupLoc2] = React.useState(null),
    [request_id, setRequestId] = React.useState(null),
    [prefer_mode_comm, setPreferMode] = React.useState(null),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    [shareUrl, setShareUrl] = React.useState(""),
    [loadingExtraInfo, setLoadingExtraInfo] = React.useState(false),
    getExtraInfo = (itemId) => {
      setLoadingExtraInfo(true);
      itemApi.getItemExtraInfo(itemId, function (response) {
        if (response) {
          setExtraInfo(response);
          setLoadingExtraInfo(false);
        }
      });
    },
    [extraInfo, setExtraInfo] = React.useState([]),
    local = useStylesOpacity(),
    isLoggedIn = useStoreState((state) => state.auth.isLoggedIn),
    [bundle, setBundle] = React.useState([]),
    [itemDetails, setItemDetails] = React.useState([]),
    [confirmedInfo, setConfirmedInfo] = React.useState([]),
    [isItemRequested, setItemRequested] = React.useState(false),
    [requestStatus, setRequestStatus] = React.useState([]),
    [msgToGiver, setMsgToGiver] = React.useState(""),
    [loading, setLoading] = React.useState(false),
    [rStatus, setRStatus] = React.useState(null),
    [prefer_pickup_loc, setPrefer_pickup_loc] = React.useState(null),
    [itemConditionOptions, setItemConditionOptions] = React.useState([]),
    confirm = useConfirmation().bind(this),
    showMessageDialog = (title, message) => {
      confirm({
        variant: "info",
        catchOnCancel: true,
        title: title,
        description: message,
      });
    },
    classes = GetStylesLocal(),
    userObj = {
      name: null,
      user_avatar: null,
      city: null,
      user_rating: 0,
    },
    [giveUser, setUser] = React.useState(userObj),
    getItemsDetails = (item) => {
      itemApi.getItemsDetails(item.bundle_id, function (response) {
        if (response) {
          let isItemRequestedByuser = response.request ? true : false;
          setItemRequested(isItemRequestedByuser);
          setBundle(response.Items[0]);
          setRequestStatus(response.request);
          setShareUrl(
            BASE_URL +
            "get/details/books/" +
            utilHelper.slugify(response.Items[0].i_name) +
            "/" +
            item.bundle_id
          );
          getExtraInfo(response.Items[0].item_id);
          let newrItemDeatilsesponse = [];
          for (let i = 0; i < response.Items.length; i++) {
            newrItemDeatilsesponse[response.Items[i].item_id] = response.Items[i];
          }
          setItemDetails(newrItemDeatilsesponse);
        }
      });
      setLoadingExtraInfo(true);
      userApi.getUserSummaryByHash(item.user_hash, function (response) {
        if (response) {
          let receiverData = { ...giveUser };
          receiverData.name = response[0].display_name;
          receiverData.city = response[0].u_city;
          receiverData.user_avatar = response[0].user_avatar;
          receiverData.user_rating = response[0].user_rating;
          setUser(receiverData);
          setLoadingExtraInfo(false);
        }
      });
    };

  const updateItemObject = (item) => {
    let nemItem = {
      ...item,
      itemTitle: [],
      itemImage: [],
      category: [],
      condition: [],
      id: [],
    };
    if (item && item.title) {
      let keys = Object.keys(item.title);
      keys.map(function (itemTitle, i) {
        nemItem.id[i] = itemTitle;
        nemItem.itemTitle[i] = item.title[itemTitle];
        let img = item.images[itemTitle].startsWith("http")
          ? item.images[itemTitle]
          : appSettings.MEDIA_URL + item.images[itemTitle];
        nemItem.itemImage[i] = img;
        nemItem.itemImage[i] = nemItem.itemImage[i].replace("\\", "/");
        nemItem.itemImage[i] = nemItem.itemImage[i].replace("\\", "/");
        let cat_condition = item.cat_condition[itemTitle].split("_");
        nemItem.category[i] = cat_condition[0];
        nemItem.condition[i] = cat_condition[1];
      });
    }
    return nemItem;
  }

  React.useEffect(() => {
    if (isLoggedIn) {
      itemApi.getMyGetItemByBundle(
        props["item-id"],
        function (response) {
          if (response.bundle && response.bundle.title) {
            let nemItem = updateItemObject(response.bundle);
            setItem(nemItem);
            setPrefer_pickup_loc(response.bundle.prefer_pickup_loc);
            setMessage(response.bundle.message);
            setPickupLoc1(response.bundle.pickup_loc_1);
            setPickupLoc2(response.bundle.pickup_loc_2);
            setPreferMode(response.bundle.prefer_mode_comm);
            setRequestId(response.bundle.accept_request_id || response.bundle.request_id);
            setRStatus(response.r_status);
            setMsgToGiver(response.bundle.conf_message);
            if (response.request && response.request[0]) {
              setReason(response.request[0].reason);
              setAdditionalInfo(response.request[0].additional_info);
            }
            setLoading(false);
            getItemsDetails(nemItem);
          }
        });

      const categorySlug = "books";
      itemApi.getFieldsByCategory(
        categorySlug,
        function (response) {
          if (response) {
            let condition = null;
            response.map((item) => {
              if (item.name === "book_condition") {
                setItemConditionOptions(item.selection_values);
              }
            });
          }
        },
        function (response) { }
      );
    } else {
      navigate("/login", { state: { redirectUrl: window.location.pathname } });
    }
  }, []);

  const [open, setOpen] = React.useState(false),
    [selectedValue, setSelectedValue] = React.useState("pickupLocation1"),
    handleRadioChange = (event) => {
      setSelectedValue(event.target.value);
    };

  const openDialogForMeetingFixed = () => {
    setLoading(true);
    if (!msgToGiver) {
      showAlert({
        message: `Please enter a message to the giver.`,
        open: true,
        severity: "warning",
      });
      setLoading(false);
      return false;
    }
    let requestDataBlock = [];
    {
      selectedValue == "pickupLocation1" && pickup_loc_1
        ? (requestDataBlock = {
          prefer_pickup_loc: {
            addr: {
              name: pickup_loc_1.addr.name,
              street: pickup_loc_1.addr.street,
              city: pickup_loc_1.addr.city,
              state: pickup_loc_1.addr.state,
              country: pickup_loc_1.addr.country,
              zip: pickup_loc_1.addr.zip,
            },
            day_avail: pickup_loc_1.day_avail,
            from_time: pickup_loc_1.from_time,
            to_time: pickup_loc_1.to_time,
          },
          request_id: request_id,
          conf_message: msgToGiver,
          bundle_id: item.bundle_id,
          prefer_mode_comm: prefer_mode_comm,
        })
        : (requestDataBlock = {
          prefer_pickup_loc: {
            addr: {
              name: pickup_loc_2.addr.name,
              street: pickup_loc_2.addr.street,
              city: pickup_loc_2.addr.city,
              state: pickup_loc_2.addr.state,
              country: pickup_loc_2.addr.country,
              zip: pickup_loc_2.addr.zip,
            },
            day_avail: pickup_loc_1.day_avail,
            from_time: pickup_loc_2.from_time,
            to_time: pickup_loc_2.to_time,
          },
          request_id: request_id,
          conf_message: msgToGiver,
          bundle_id: item.bundle_id,
          prefer_mode_comm: prefer_mode_comm,
        });
    }
    const prefer_pickup_loc = { ...requestDataBlock.prefer_pickup_loc };

    setPrefer_pickup_loc(prefer_pickup_loc);
    setMsgToGiver(msgToGiver);

    itemApi.confirmRequest(
      requestDataBlock,
      function (response) {
        if (response) {
          setLoading(false);
          setConfirmedInfo(response.Attributes);
          setRStatus("confirmed");
        } else {
          showMessageDialog("Error", "Error");
        }
      },
      function () {
        setLoading(false);
      }
    );
  };

  const getProductDetails = () => {
    return (
      <Grid item xs={6} className={classes.product}>
        <ItemImagesContainer
          itemDetails={itemDetails}
          titles={item.itemTitle}
          item={item}
          shareUrl={shareUrl}
          getExtraInfo={getExtraInfo}
          displayIndex={displayIndex}
          setDisplayIndex={setDisplayIndex}
        ></ItemImagesContainer>
        <Box mt={4}>
          <ItemDetails
            itemDetails={itemDetails}
            displayIndex={displayIndex}
            item={item}
          ></ItemDetails>
        </Box>
        {extraInfo.authorName && (
          <Box mt={4}>
            <GoodReadDetails
              extraInfo={extraInfo}
              loading={loadingExtraInfo}
            ></GoodReadDetails>
          </Box>
        )}
      </Grid>
    );
  };
  const classes1 = useStyles(),
    localClasses = MyGivesStyles(),
    [openGiverModel, setGiverModel] = React.useState(false),
    [ratingValue, setRatingValue] = React.useState(""),
    [itemCondition, setItemCondition] = React.useState(""),
    [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false),
    [feedbackMessage, setFeedbackMessage] = React.useState("");
  // [refresh, setRefresh] = React.useState(false);

  const handleOpenGiverModel = () => {
    setGiverModel(true);
  };

  const handleCloseGiverModel = () => {
    setGiverModel(false);
  };
  const submitGiverRating = () => {
    if (ratingValue) {
      setLoading(true);
      const reqBlk = {
        give_ratings: ratingValue,
        recieved_item_condition: itemCondition.value,
        bundle_id: item.bundle_id,
      };
      let bundleId = item.bundle_id;
      itemApi.submitFeedback(bundleId, reqBlk, function (response) {
        setLoading(false);
        setFeedbackSubmitted(true);
        setFeedbackMessage("Thanks for your feedback. Your request is completed.");
      });
    } else {
      showAlert({
        message: "Please provide ratings.",
        open: true,
        severity: "warning",
      });
    }
  };
  return (
    <section className={classes.container}>
      <Grid container>
        {item && getProductDetails()}
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h5' component='div'>
              View details
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>{item && getProductDetails()}</ExpansionPanelDetails>
        </ExpansionPanel>

        {rStatus === "confirmed" &&
          <Grid item xs={6}>
            {/* || r_status === "confirmed" */}
            <Typography
              variant='h1'
              style={{ marginBottom: "3rem" }}
              component='div'
            >
              Your pick up is confirmed by {giveUser?.name + "."}
            </Typography>
            <Typography
              variant='subtitle2'
              component='div'
              style={{ marginBottom: "3rem" }}
            >
              Please be on the time at the venue to receive the item.
            </Typography>
            <Grid container style={{ marginBottom: "3rem" }}>
              <Grid item xs={12}>
                <section >
                  <section style={{ alignSelf: "center" }}>
                    <Typography variant='subtitle' component='div' style={{ marginBottom: "1rem", fontWeight: '600' }}>
                      Pick up location:
                    </Typography>
                    <section className={classes.wrapper}>
                      <Grid item xs={6} className={classes.marpad}
                        style={{
                          backgroundColor: "aliceblue",
                          borderRadius: "4px",
                          padding: "1rem !important"
                        }}
                      >
                        <Typography
                          variant='subtitle2'
                          component='div'
                          style={{ fontWeight: 600 }}
                        >
                          Address{" "}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {prefer_pickup_loc?.addr?.name}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {prefer_pickup_loc?.addr?.street}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {prefer_pickup_loc?.addr?.city}, {prefer_pickup_loc?.addr?.state}, {prefer_pickup_loc?.addr?.country}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {prefer_pickup_loc?.addr?.zip}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.marpad}
                        style={{
                          backgroundColor: "aliceblue",
                          borderRadius: "4px",
                          padding: "1rem !important"
                        }}
                      >
                        <Typography
                          variant='subtitle2'
                          component='div'
                          style={{ fontWeight: 600 }}
                        >
                          Day{" "}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {prefer_pickup_loc?.day_avail?.map((value, key) => {
                            return <Typography variant='subtitle2' component='div'>{value["value"] + "  "}</Typography>;
                          })}
                        </Typography>
                        {prefer_pickup_loc?.from_time &&
                          <>
                            <Typography
                              variant='subtitle2'
                              component='div'
                              style={{ fontWeight: 600, marginTop: '10px' }}
                            >
                              Time
                            </Typography>
                            <Typography variant='subtitle2' component='div'>
                              {utilHelper.getTimeValue(prefer_pickup_loc?.from_time)}{" "}
                              to {utilHelper.getTimeValue(prefer_pickup_loc?.to_time)}
                            </Typography>
                          </>
                        }
                      </Grid>
                    </section>
                  </section>
                </section>
              </Grid>
            </Grid>
            <Typography variant='subtitle' component='span' style={{ fontWeight: '600' }}>
              Message sent: {" "}
            </Typography>
            <Typography variant='subtitle2' component='span'>
              {msgToGiver}
            </Typography>
            <Typography
              variant='subtitle'
              style={{ margin: "3rem 0", color: "#22B11F" }}
              component='div'
            >
              Thank you. Please be on time to pick up the item.
            </Typography>
            <Box width='100%' height='100%' mt={3} display='none'>
              <LargeRectangleAds></LargeRectangleAds>{" "}
            </Box>
          </Grid>}
        {rStatus === "completed" &&
          <Grid item xs={6}>
            {/* || r_status === "confirmed" */}
            <Typography
              variant='h1'
              style={{ marginBottom: "3rem" }}
              component='div'
            >
              You have received this item from {giveUser?.name + "."}{" "}
            </Typography>
            {/* <section style={{ marginBottom: "3rem" }}>
              <Typography variant='h3' component='div' style={{ fontWeight: '600' }}>
                Your request message:{" "}
              </Typography>
              <Typography variant='subtitle2' component='span'>
                {message}
              </Typography>
            </section> */}
            <Grid container style={{ marginBottom: "3rem" }}>
              <Grid item xs={12}>
                <section>
                  <section style={{ alignSelf: "center" }}>
                    <Typography variant='subtitle' component='div' style={{ fontWeight: '600', marginBottom: "1rem" }}>
                      Pick up location:
                    </Typography>
                    <section className={classes.wrapper}>
                      <Grid item xs={6} className={classes.marpad}
                        style={{
                          backgroundColor: "aliceblue",
                          borderRadius: "4px",
                          padding: "1rem !important"
                        }}
                      >
                        <Typography
                          variant='subtitle2'
                          component='div'
                          style={{ fontWeight: 600 }}
                        >
                          Address{" "}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {prefer_pickup_loc.addr.name}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {prefer_pickup_loc.addr.street}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {prefer_pickup_loc?.addr?.city}, {prefer_pickup_loc?.addr?.state}, {prefer_pickup_loc?.addr?.country}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {prefer_pickup_loc?.addr?.zip}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.marpad}
                        style={{
                          backgroundColor: "aliceblue",
                          borderRadius: "4px",
                          padding: "1rem !important"
                        }}
                      >
                        <Typography
                          variant='subtitle2'
                          component='div'
                          style={{ fontWeight: 600 }}
                        >
                          Day{" "}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {prefer_pickup_loc?.day_avail?.map((value, key) => {
                            return <Typography variant='subtitle2' component='div'>{value["value"] + "  "}</Typography>;
                          })}
                        </Typography>
                        {prefer_pickup_loc?.from_time &&
                          <>
                            <Typography
                              variant='subtitle2'
                              component='div'
                              style={{ fontWeight: 600, marginTop: "10px" }}
                            >
                              Time
                            </Typography>
                            <Typography variant='subtitle2' component='div'>
                              {utilHelper.getTimeValue(prefer_pickup_loc?.from_time)}{" "}
                               to {utilHelper.getTimeValue(prefer_pickup_loc?.to_time)}
                            </Typography>
                          </>
                        }
                      </Grid>
                    </section>
                  </section>
                </section>
              </Grid>
            </Grid>
            <Grid container style={{ marginBottom: "3rem" }}>
              {item.give_ratings ? (<Grid item xs={6}>
                <section style={{ alignSelf: "center" }}>
                  <Typography variant='subtitle'
                    component='div' style={{ fontWeight: 600, marginBottom: "1rem" }}>
                    Your Feedback:
                       </Typography>
                  <section
                    style={{
                      padding: "1rem",
                      backgroundColor: "aliceblue",
                    }}
                  >
                    <Table size='small'>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant='subtitle2' component='div' style={{ fontSize: '14px' }}>
                              {/* Rating given to requestor */}
                                 Rating:
                                </Typography>
                          </TableCell>
                          <TableCell>
                            <Rating
                              name='disabled'
                              style={{ fontSize: "18px" }}
                              value={+item.give_ratings}
                              disabled
                            />
                          </TableCell>
                        </TableRow>
                        {item.recieved_item_condition &&
                          <TableRow>
                            <TableCell>
                              <Typography variant='subtitle2' component='div' style={{ fontSize: '14px' }}>
                                Condition:
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='subtitle2' component='div' >{item.recieved_item_condition}</Typography>
                            </TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>
                  </section>
                </section>
              </Grid>) :
                (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleOpenGiverModel}>
                    Rate {giveUser?.name}{" "}
                    {/* TODO || props.bundle.r_status ==="confirmed" */}
                  </Button>
                )
              }
            </Grid>
            <Box width='100%' height='100%' mt={3} display='none'>
              <LargeRectangleAds></LargeRectangleAds>{" "}
            </Box>
            {/*Model for rate the giver*/}
            <Dialog
              // onClose={handleCloseGiverModel}
              aria-labelledby='customized-dialog-title'
              open={openGiverModel}
            >
              <DialogTitle
                id='customized-dialog-title'
              // onClose={handleCloseGiverModel}
              >
                Rate the giver
            </DialogTitle>
              <DialogContent dividers>
                <p>
                  We hope that you have enjoyed the overall experience. Please
                  rate the giver to help the community.
              </p>
                {giveUser && giveUser.name && (
                  <p>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{ width: "20%", padding: "1px", float: "left" }}
                      >
                        <Avatar
                          className={classes1.avatar}
                          src={giveUser.user_avatar ? giveUser.user_avatar : ""}
                        ></Avatar>
                      </div>
                      <div style={{ width: "78%", padding: "1px" }}>
                        <b>{giveUser && giveUser.name}</b>
                        <br />
                        <span>{giveUser && giveUser.city}</span>
                      </div>
                    </div>
                  </p>
                )}
                <Typography variant='subtitle2' component='div'>
                  Condition Listed :
              {item.condition[displayIndex]}
                </Typography>
                <Autocomplete
                  options={itemConditionOptions}
                  getOptionLabel={(option) => option.value}
                  onChange={(e, value) => {
                    setItemCondition(value);
                  }}
                  value={itemCondition}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label='Received Item Condition'
                      name='condition'
                    />
                  )}
                />
                <p>
                  How would you rate giver ? <br />
                  <Rating
                    name='simple-controlled'
                    value={+ratingValue}
                    onChange={(event, newValue) => {
                      setRatingValue(newValue);
                    }}
                  />
                </p>
              </DialogContent>
              {
                !feedbackSubmitted
                  ? <DialogActions>
                    <Button
                      variant='contained'
                      onClick={() => {
                        //handleCloseGiverModel
                        setFeedbackSubmitted(true);
                        setFeedbackMessage("Ok, no problem. You can rate them later under Completed Gets.");
                      }}
                      color=''
                    >
                      Cancel
                    </Button>
                    <div className={local.wrapper}>
                      <Button
                        variant='contained'
                        onClick={submitGiverRating}
                        color='primary'
                        disabled={loading}
                      >
                        Submit
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={local.buttonProgress}
                        />
                      )}
                    </div>
                  </DialogActions>
                  : <div className={localClasses.feedbackWrapper}>
                    <p>{feedbackMessage}</p>
                    <Button
                      variant='contained'
                      onClick={() => {
                        // no api on cancel.
                        if (feedbackMessage.includes("Thanks for your feedback")) {
                          handleCloseGiverModel(false);
                          window.location.reload();
                        } else {
                          handleCloseGiverModel(false);
                        }
                        setFeedbackSubmitted(false);
                        setFeedbackMessage("");
                      }}
                      color='primary'>
                      OK
                  </Button>
                  </div>
              }
            </Dialog>
          </Grid>
        }
        {(rStatus === "rejected" || rStatus === "not given") &&
          <Grid item xs={6}>
            <section style={{ alignSelf: "center", marginTop: "25px" }}>
              <Typography variant='h1' component='div' style={{ marginBottom: '3rem' }}>
                Your request is declined by {giveUser?.name}.
              </Typography>
              {reason && <section
                style={{
                  padding: "1rem",
                  marginTop: "10px",
                  backgroundColor: "aliceblue",
                }}
              >
                <Table size='small'>
                  <TableBody>
                    {reason && <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2' component='div' style={{ fontSize: '14px' }}>
                          Reason:
                          </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='subtitle2' component='div'>{reason}</Typography>
                      </TableCell>
                    </TableRow>}
                    {additional_info && <TableRow>
                      <TableCell>
                        <Typography variant='subtitle2' component='div' style={{ fontSize: '14px' }}>
                          Comments:
                          </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='subtitle2' component='div'><q>{additional_info}</q></Typography>
                      </TableCell>
                    </TableRow>}
                  </TableBody>
                </Table>
              </section>}
            </section>
          </Grid>
        }
        {(rStatus === "accepted" || confirmedInfo?.r_status === "accepted") &&
          <Grid item xs={6}>
            <Typography
              variant='h1'
              style={{ marginBottom: "3rem" }}
              component='div'
            >
              Your request has been accepted by {giveUser?.name}!{" "}
            </Typography>
            <Typography
              variant='subtitle2'
              component='div'
              style={{ marginBottom: "3rem" }}
            >
              Please select preferred location and time slot before time ends
              and pick up the item from given address.
            </Typography>
            <Grid container style={{ marginBottom: "3rem" }}>
              <Grid item xs={12}>
                <section>
                  <section style={{ alignSelf: "center" }}>
                    <Radio
                      checked={selectedValue === "pickupLocation1"}
                      onChange={handleRadioChange}
                      value='pickupLocation1'
                      name='pickupLocation1'
                      color='primary'
                      style={{ display: "none" }}
                    />
                    <Typography variant='subtitle' component='div' style={{ marginBottom: "1rem", fontWeight: '600' }}>
                      Pick up location:
                    </Typography>
                    <section className={classes.wrapper}>
                      <Grid item xs={6} className={classes.marpad}
                        style={{
                          backgroundColor: "aliceblue",
                          borderRadius: "4px",
                          padding: "1rem !important"
                        }}
                      >
                        <Typography
                          variant='subtitle2'
                          component='div'
                          style={{ fontWeight: 600 }}
                        >
                          Address{" "}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {pickup_loc_1.addr.name}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {pickup_loc_1.addr.street}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {pickup_loc_1?.addr?.city}, {pickup_loc_1?.addr?.state}, {pickup_loc_1?.addr?.country}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {pickup_loc_1?.addr?.zip}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.marpad}
                        style={{
                          backgroundColor: "aliceblue",
                          borderRadius: "4px",
                          padding: "1rem !important"
                        }}
                      >
                        <Typography
                          variant='subtitle2'
                          component='div'
                          style={{ fontWeight: 600 }}
                        >
                          Day{" "}
                        </Typography>
                        {pickup_loc_1?.from_time !== -1 &&
                          <>
                            <Typography variant='subtitle2' component='div'>
                              {pickup_loc_1?.day_avail?.map((value, key) => {
                                return <Typography variant='subtitle2' component='div'>{value["value"] + "  "}</Typography>;
                              })}
                            </Typography>
                            <Typography
                              variant='subtitle2'
                              component='div'
                              style={{ fontWeight: 600, marginTop: '10px' }}
                            >
                              Time
                            </Typography>
                            <Typography variant='subtitle2' component='div'>
                              {utilHelper.getTimeValue(pickup_loc_1?.from_time) == "0 AM" ? "12 Midnight" : utilHelper.getTimeValue(
                                pickup_loc_1?.from_time
                              )}{" "}
                             to {utilHelper.getTimeValue(pickup_loc_1?.to_time) == "0 AM" ? "12 Midnight" : utilHelper.getTimeValue(pickup_loc_1?.to_time)}
                            </Typography>
                          </>
                        }
                      </Grid>
                    </section>
                  </section>
                </section>
              </Grid>
              <Grid item xs={12}>
                {pickup_loc_2 !== null && <section>
                  <Radio
                    checked={selectedValue === "pickupLocation2"}
                    onChange={handleRadioChange}
                    value='pickupLocation2'
                    name='pickupLocation2'
                    color='primary'
                  />
                  <section style={{ alignSelf: "center" }}>
                    <Typography variant='subtitle' component='div' style={{ marginBottom: "1rem", fontWeight: '600' }}>
                      Pick up location 2:
                    </Typography>
                    <section className={classes.wrapper}>
                      <Grid item xs={6} className={classes.marpad}
                        style={{
                          backgroundColor: "aliceblue",
                          borderRadius: "4px",
                          padding: "1rem !important"
                        }}
                      >
                        <Typography variant='subtitle2' component='div'>
                          {pickup_loc_2.addr.name}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {pickup_loc_1.addr.street}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {pickup_loc_1?.addr?.city}, {pickup_loc_1?.addr?.state}, {pickup_loc_1?.addr?.country}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {pickup_loc_1?.addr?.zip}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.marpad}
                        style={{
                          backgroundColor: "aliceblue",
                          borderRadius: "4px",
                          padding: "1rem !important"
                        }}
                      >
                        <Typography
                          variant='subtitle2'
                          component='div'
                          style={{ fontWeight: 600 }}
                        >
                          Day{" "}
                        </Typography>
                        <Typography variant='subtitle2' component='div'>
                          {pickup_loc_1?.day_avail?.map((value, key) => {
                            return <Typography variant='subtitle2' component='div'>{value["value"] + "  "}</Typography>;
                          })}
                        </Typography>
                        {pickup_loc_1?.from_time !== -1 &&
                          <>
                            <Typography
                              variant='subtitle2'
                              component='div'
                              style={{ fontWeight: 600, marginTop: '10px' }}
                            >
                              Time
                            </Typography>
                            <Typography variant='subtitle2' component='div'>
                              {utilHelper.getTimeValue(pickup_loc_1?.from_time) == "0 AM" ? "12 Midnight" : tilHelper.getTimeValue(pickup_loc_1?.to_time)}{" "}
                              to {utilHelper.getTimeValue(pickup_loc_1?.to_time) == "0 AM" ? "12 Midnight" : utilHelper.getTimeValue(pickup_loc_1?.to_time)}
                            </Typography>
                          </>
                        }
                      </Grid>
                    </section>
                  </section>
                </section>}
              </Grid>
            </Grid>
            {prefer_mode_comm && (prefer_mode_comm?.email || prefer_mode_comm?.mobile) &&
              < Grid container style={{ alignSelf: "center", marginBottom: "3rem" }}>
                <Grid item xs={12}>
                  <Typography variant='subtitle' component='div' style={{ marginBottom: "1rem", fontWeight: '600' }}>
                    Contact Details:
               </Typography>
                  <section className={classes.wrapper}>
                    {prefer_mode_comm &&
                      prefer_mode_comm?.email && (
                        <Grid item xs={6} style={{ paddingLeft: '0px !important' }}>
                          <Typography
                            variant='h4'
                            component='div'
                            style={{
                              display: 'flex',
                              flex: '1 1',
                              fontWeight: 600
                            }}>
                            <p style={{ marginTop: '7px' }}>Email:</p>{' '}
                            <Chip
                              label={prefer_mode_comm.email}
                              style={{
                                marginLeft: '10px',
                                backgroundColor: 'aliceblue',
                                fontWeight: '500',
                                fontSize: '14px',
                                width: '80%'
                              }}
                            />
                          </Typography>
                        </Grid>)}
                    {prefer_mode_comm &&
                      prefer_mode_comm?.mobile && (
                        <Grid item xs={6} style={{ paddingLeft: '0px !important' }}>
                          <Typography
                            variant='h4'
                            component='div'
                            style={{
                              display: 'flex',
                              flex: '1 1',
                              fontWeight: 600
                            }}
                          >
                            <p style={{ marginTop: '7px' }}>Phone:</p>{' '}
                            <Chip
                              label={pickup_loc_1?.addr?.country ? getPhoneNumber(prefer_mode_comm?.mobile, pickup_loc_1.addr.country) : prefer_mode_comm?.mobile}
                              style={{
                                marginLeft: '10px',
                                backgroundColor: 'aliceblue',
                                fontWeight: '500',
                                fontSize: '14px',
                                // width: '80%'
                              }}
                            />
                          </Typography>
                        </Grid>
                      )}
                  </section>
                </Grid>
              </Grid>}
            <TextField
              multiline
              label='Leave a Message to the giver'
              placeholder='Please provide any other relevant information'
              rows='4'
              value={msgToGiver}
              onInput={(e) => setMsgToGiver(e.target.value)}
              fullWidth
              inputProps={{ maxLength: 200 }}
              FormHelperTextProps={{ classes: { root: classes.helperTextRight } }}
              helperText={`${msgToGiver ? msgToGiver?.length : 0}/200`}
            />
            <>
              <Box mt={2} display='flex' flexDirection='row-reverse'>
                <div className={local.wrapper}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={openDialogForMeetingFixed}
                    disabled={loading}>
                    I Agree
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={local.buttonProgress}
                    />
                  )}
                </div>
                {/*<Button variant='outlined' color='secondary' onClick={openDialogWithValidReason} style={{ marginRight: '1rem' }}>Cancel</Button>*/}
                <div className={local.wrapper}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={(e) => {
                      window.history.back();
                    }}
                    style={{ marginRight: "1rem" }}
                  >
                    Cancel
                  </Button>
                </div>
              </Box>
            </>
            {/* :
                        <Box mt={4} display='flex' justifyContent='flex-end'>
                            <Typography variant='h5' component='div' style={{ color: '#A63D40' }}>You cannot confirm this item, the given time is over. </Typography>
                        </Box>
                        } */}

            <Box width='100%' height='100%' mt={3} display='none'>
              <LargeRectangleAds></LargeRectangleAds>{" "}
            </Box>
          </Grid>
        }
        {rStatus === 'requested' &&
          <Grid item xs={6}>
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#fbfdf8",
                padding: "10px",
              }}>
              <img alt='waiting' width='70%' src='/assets/images/waiting.svg' />
            </div>
            <Alert severity='info'
              style={{ marginTop: "20px", marginBottom: "20px" }}>
              Waiting for response.</Alert>

            <Box width='100%' height='100%' mt={3} display='none'>
              <LargeRectangleAds></LargeRectangleAds>
            </Box>
          </Grid>
        }
      </Grid>
    </section >
  );
};
export { MyGetsAddress };
