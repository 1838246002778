import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { navigate } from "@reach/router";
import tabContainerStyles from "./../../styles/tabContainer.styles";

import { useStoreState } from "./../../hooks";

const useStyles = makeStyles((theme) => ({
  header: {
    background: "#F2FFF1",
    paddingTop: "1rem",
  },
  bg: {
    [theme.breakpoints.down("sm")]: {
      background: "#F2FFF1",

      "&:nth-child(odd)": {
        background: "#fff",
      },

      "&:nth-child(even)": {
        background: "#fff",
      },

      "& .makeStyles-header-1": {
        paddingTop: 0,
      },

      "& header": {
        boxShadow: "unset",

        "& .MuiIconButton-root": {
          display: "none",
        },

        "& .makeStyles-headerActions-21": {
          display: "block",
        },
      },
    },
  },
  supportCause: {
    color: "rgba(35,28,7,0.3)",
    fontSize: "33px",
    lineHeight: "45px",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      fontSize: "31px",
    },
  },
  container: {
    display: "flex",
    padding: "4rem 0",
    margin: "0 auto",
    maxWidth: "1200px",
    minWidth: "650px",

    [theme.breakpoints.only("md")]: {
      maxWidth: "960px"
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minWidth: "unset",
      padding: "1rem",
      marginTop: '35px'
    },

    "& section:first-child": {
      margin: "4rem 0 1rem 0",
      padding: "0 1rem",

      [theme.breakpoints.down("sm")]: {
        margin: "1rem 0",
        padding: 0,
      },
    },

    "& section": {
      width: "50%",

      [theme.breakpoints.down("sm")]: {
        width: "unset",
      },
    },
  },
  giveStuff: {
    color: "#231C07",
    fontSize: "42px",
    lineHeight: "45px",
    margin: "2rem 0",

    [theme.breakpoints.down("sm")]: {
      fontSize: "31px",
      margin: 0,
    },
  },
  banner: {
    background: `url('/assets/images/banner.svg') center /contain no-repeat`,
    height: "80%",

    [theme.breakpoints.down("sm")]: {
      height: "335px",
    },
  },
  comingSoon: {
    color: "#20A71D",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "right",
    position: "absolute",
    right: 0,
  },
  greySection: {
    background: `url('/assets/images/Mask.svg') repeat`,
    //maxWidth: "1200px",
    maxWidth: 'initial',
    minWidth: "650px",
    margin: "0 auto",

    [theme.breakpoints.down("sm")]: {
      background: "#F2FFF1",
      minWidth: "unset",
    },

    [theme.breakpoints.only("md")]: {
      maxWidth: "960px"
    },
  },
  getImage: {
    background: `url('/assets/images/get.svg') center /contain no-repeat`,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  giveImage: {
    background: `url('/assets/images/give.svg') center /contain no-repeat`,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  smGiveImage: {
    background: `url('/assets/images/give.svg') center /contain no-repeat`,
    display: "none",

    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
      height: "426px",
    },
  },
  smGetImage: {
    background: `url('/assets/images/get.svg') center /contain no-repeat`,
    display: "none",

    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
      height: "426px",
    },
  },
  circle: {
    height: "253px",
    width: "100%",
    // backgroundColor: "#F2FFF1",
    borderRadius: "50%",
    // margin: '1rem auto'
  },
  flexItem: {
    flex: "1 1",
    margin: "1rem",
    textAlign: "justify",
  },
  quote: {
    color: "#717171",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  quoteAuthor: {
    color: "#555555",
    position: "absolute",
    right: 0,

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      position: "unset",
      right: "unset",
      float: "right",
      marginTop: "1rem",
    },
  },
  imgContainer: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: '1200px',
    minWidth: '650px',
    flexDirection: 'column',
    marginTop: '6rem',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      minWidth: 'unset',
      padding: '1rem'
    },

    [theme.breakpoints.only("md")]: {
      maxWidth: "960px"
    },

    '& section:first-child': {
      margin: '4rem 0 1rem 0',
      padding: '0 1rem',

      [theme.breakpoints.down('sm')]: {
        margin: '1rem 0',
        padding: 0
      }
    },


    '& section': {
      width: '50%',

      [theme.breakpoints.down('sm')]: {
        width: 'unset'
      }
    }
  },
  titleText: {
    color: '#231C07',
    fontSize: '28px',
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: '1.4',
    marginTop: '35px',
    marginBottom: '75px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px',
      marginBottom: '30px',
    }
  },
  buttonSection1: {
    display: "flex",
    marginBottom: "2rem",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonSection2: {
    display: "none",
    marginTop: "2rem",

    "& .MuiButton-root": {
      flex: "1 1",
    },

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: 'center',

      '& .MuiButton-root': {
        height: '40px',
        width: '134.32px',
        borderRadius: '4px',
        backgroundColor: '#22B11F',
        flex: '0'
      },

      '& .MuiButton-outlinedSecondary': {
        backgroundColor: '#FFF'
      }
    },
  },
  statImg: {
    // textAlign: 'center',
    // borderRadius: 'inherit',
    display: "block",
    height: "100%",
    width: "100%",
  },
  primaryText: {
    color: '#22B11F',
  },
}));

export const HomePage = () => {
  const classes = useStyles(),
    classesTabContainer = tabContainerStyles(),
    isLoggedIn = useStoreState((state) => state.auth.isLoggedIn);

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <div className={classesTabContainer.root}>
      <div className={classesTabContainer.tabContainer}></div>
      <section className={classes.bg}>
        <div className={classes.header}>
          <section className={classes.container}>
            <section className={classes.wrapperSection1}>
              <Typography
                variant='h1'
                className={classes.giveStuff}
                component='div'
              >
                GIVE STUFF
              </Typography>
              <Typography
                variant='h1'
                className={classes.giveStuff}
                component='div'
              >
                GET STUFF{" "}
              </Typography>
              <Typography
                variant='h1'
                className={classes.supportCause}
                component='span'
              >
                SUPPORT A CAUSE
                <Typography
                  variant='h3'
                  className={classes.comingSoon}
                  component='div'
                >
                  COMING SOON
                </Typography>
              </Typography>
              <Typography
                variant='body2'
                component='div'
                style={{ margin: "3rem 0" }}
              >
                For Minimalists. Green Advocates. Sharing Enthusiasts.
              </Typography>
              <Box className={classes.buttonSection1}>
                <Button variant='outlined' color='secondary' onClick={() => navigate('/learnmore')}>
                  Learn More
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => navigate('/register')}
                  style={{ marginLeft: "1rem" }}
                >
                  Join Now!
                </Button>
              </Box>
            </section>

            <section>
              <div className={classes.banner}></div>
              <div>
                <Typography
                  variant='h6'
                  className={classes.quote}
                  component='span'
                  style={{ color: '#20A71D', fontStyle: "italic" }}
                >
                  “Start where you are. Use what you have. Do what you can.”
                  <Typography
                    variant='subtitle2'
                    className={classes.quoteAuthor}
                    component='div'
                  >
                    - Arthur Ashe
                  </Typography>
                </Typography>
                <Box className={classes.buttonSection2}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={() => navigate('/learnmore')}
                  >
                    Learn More
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => navigate('/register')}
                    style={{ marginLeft: "1rem" }}
                  >
                    Join Now!
                  </Button>
                </Box>
              </div>
            </section>
          </section>
        </div>

        <div className={classes.container}>
          <section className={classes.giveImage}></section>
          <section>
            <Typography
              variant='h1'
              className={classes.giveStuff}
              component='div'
              style={{ margin: '0px', marginTop: '2rem', marginBottom: '16px' }}
            >
              Give it
            </Typography>
            <Typography
              variant='h1'
              style={{ fontSize: "24px" }}
              component='div'
            >
              to Individual or Charity
            </Typography>
            <section className={classes.smGiveImage}></section>
            <Typography
              variant='h6'
              style={{ fontSize: "24px", marginTop: "2rem" }}
              component='div'
            >
              Give anything - new, barely used, gently used or simply extras you
              own to other individuals or charities in your neighborhood or any
              place they may be.
            </Typography>
            <Typography
              variant='h5'
              style={{ fontSize: "24px", marginTop: "4rem", cursor: "pointer" }}
              component='div'
              onClick={(e) => {
                navigate("/login");
              }}
            >
              Give Now{" "}
              <ArrowRightAltIcon
                style={{
                  fontSize: "2rem",
                  color: "#22B11F",
                  verticalAlign: "bottom",
                }}
              ></ArrowRightAltIcon>
            </Typography>
          </section>
        </div>

        <div className={classes.greySection}>
          <div className={classes.container}>
            <section>
              <Typography
                variant='h1'
                className={classes.giveStuff}
                component='div'
                // onClick={(e) => {
                //   navigate("/get");
                // }}
                style={{ margin: '0px', marginTop: '2rem', marginBottom: '16px' }}
              >
                GET
              </Typography>
              <Typography
                variant='h1'
                style={{ fontSize: "24px" }}
                component='div'
              >
                In return for free
              </Typography>
              <section className={classes.smGetImage}></section>
              <Typography
                variant='h6'
                style={{ fontSize: "24px", marginTop: "2rem" }}
                component='div'
              >
                Need a tool or a video game? a working laptop for a teen? an old
                iPad for a school in need? toys for kids? Check if someone is
                giving it away!
              </Typography>
              <Typography
                variant='h5'
                style={{
                  fontSize: "24px",
                  marginTop: "4rem",
                  cursor: "pointer",
                }}
                component='div'
                onClick={(e) => {
                  navigate("/get");
                }}
              >
                Get Now{" "}
                <ArrowRightAltIcon
                  style={{
                    fontSize: "2rem",
                    color: "#22B11F",
                    verticalAlign: "bottom",
                  }}
                ></ArrowRightAltIcon>
              </Typography>
              {/* <Typography variant='body1' component='div'>First 3 gets are free!</Typography> */}
            </section>
            <section className={classes.getImage}></section>
          </div>
        </div>

        <section className={classes.imgContainer}>
          <Grid container style={{ justifyContent: 'center', textAlign: 'center', }}>
            <div className="carousel-background">
              <img src="/assets/images/learnmore/Banner3.svg" alt="" width="100%" className={classes.topBanner} /></div>
            <div className={classes.titleText}>a community for mindful sharing -  freely - <br />because <span className={classes.primaryText}>we can!</span></div>
          </Grid>
          <Box
            className={classes.buttonSection1}
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Button variant='outlined' color='secondary' onClick={() => { navigate("/learnmore"); }}>
              Learn More
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={() => { navigate("/register"); }}
              style={{ marginLeft: "1rem" }}
            >
              Join Now!
            </Button>
          </Box>
        </section>
      </section>
    </div>
  );
};
