import React from "react";
import { Router } from "@reach/router";
import {
  MainPrivateLayout,
  MainPublicLayout,
} from "../layouts";
import Helmet from "react-helmet";
import { Header } from "./../ui/Header";
import { useConfirmation } from "./../../ConfirmationService";

import { Profile } from "./../screens/Profile";
import { NotFound } from "./../screens/NotFound";
import { NotFound403 } from "./../screens/NotFound403";
import { Login } from "./../screens/auth/Login";
import { Register } from "./../screens/auth/Register";
import { Logout } from "./../screens/auth/Logout";
import { ForgotPassword } from "./../screens/auth/ForgotPassword";
import { ResetPassword } from "./../screens/auth/ResetPassword";
import { SetPassword } from "./../screens/auth/SetPassword";
import { VerifyEmail } from "./../screens/auth/VerifiedEmail";
import { ExternalItemDetails } from "./../screens/ExternalItemDetails";
import { Dashboard } from "./../screens/Dashboard";
import { useStoreActions, useStoreState } from "./../../hooks";
import { Footer } from "../ui/Footer";
import { appSettings } from "../../constants";
import { navigate } from "@reach/router";
import { securityApi } from "../../api";
import { Get } from "../screens/Get";
import { MyGives } from "../screens/MyGives";
import { ItemRequests } from "../screens/ItemRequests";
import { MyGets } from "../screens/MyGets";
import { UserProfile } from "../ui/Userprofile";
import { HomePage } from "../screens/HomePage";
import { GiveItem } from "../screens/GiveItem";
import { Contribute } from "../screens/Contribute";

import TermsOfUse from "../screens/static/TermsOfUse";
import AboutUs from "../screens/static/AboutUs";
import AffilliateDisclaimer from "../screens/static/AffilliateDisclaimer";
import Preview from "../screens/give/Preview";
import Privacy from "../screens/static/Privacy";
import LearnMore from "../screens/static/LearnMore";
import Downtime from "../screens/static/Downtime";
import MembershipAgreement from "../screens/static/MembershipAgreement";
import { ContactUs } from "../screens/auth/contactUs";
import { MyRequest } from "../screens/MyRequest";
import { MyGetsAddress } from "../screens/MyGetsAddress";
import Covid19 from "../screens/static/Covid19";
import { ScrollToTop } from "./../../helpers/ScrollToTop";
import Notifications from "../screens/Notifications";

const UntypedLogin = Login,
  UntypedLogout = Logout,
  Onboarding = () => {
    return <div>welcome</div>;
  };

const RouteProvider = () => {
  const user = useStoreState((state) => state.auth.user),
    updateUser = useStoreActions((actions) => actions.auth.updateUser),
    setFetching = useStoreActions((actions) => actions.network.setFetching),
    logout = useStoreActions((actions) => actions.auth.logout),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    confirm = useConfirmation().bind(this),
    openDialog = (title, message, redirect) => {
      confirm({
        variant: "info",
        catchOnCancel: true,
        title: title,
        description: message,
      })
        .then(() => {
          if (redirect) {
            logout();
            navigate("/login");
          }
        })
        .catch(() => {
          logout();
          navigate("/login");
        });
    };

  const axios = require("axios").default;
  axios.defaults.baseURL = appSettings.BaseURL;
  axios.interceptors.request.use(
    async (config) => {
      setFetching({ fetching: true });
      config.headers["Accept"] = "application/json";
      const authToken = localStorage.getItem("token");
      if (authToken) {
        config.headers.Authorization = "Token " + authToken;
      }

      // remove the token if url call is logout,
      // so that any pre request fired before logout
      // can be handled in axios interceptors response.
      if (config && config.url && config.url.endsWith('logout/')) {
        localStorage.removeItem('token');
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      setFetching({ fetching: false });
      return response;
    },
    function (error) {
      setFetching({ fetching: false });
      switch (error.response.status) {
        case 406:
        case 412:
        case 424:
          break;
        case 400:
          if (error.response) {
            var errors = "";
            for (var key in error.response.data) {
              if (error.response.data.hasOwnProperty(key)) {
                var val = error.response.data[key];
                errors += val + " \n\r";
              }
            }
            // openDialog('Error', errors);
            showAlert({ message: errors, open: true, severity: "error" });
          }
          break;
        case 401:
          logout();

          if (error.response.data.detail === "Invalid token.") {
            navigate('/login', { state: { invalidSession: true }, replace: true });
          } else {
            navigate("/");
          }

          break;
        case 402:
          if (error.response) {
            var errors = "";
            for (var key in error.response.data) {
              if (error.response.data.hasOwnProperty(key)) {
                var val = error.response.data[key];
                errors += val + " \n\r";
              }
            }
            showAlert({ message: errors, open: true, severity: "error" });
          }
          break;
        case 403:
          if (error.response) {
            var errors = "";
            for (var key in error.response.data) {
              if (error.response.data.hasOwnProperty(key)) {
                var val = error.response.data[key];
                errors += val + " \n\r";
              }
            }
            showAlert({ message: errors, open: true, severity: "error" });
          }
          break;
        case 404:
          navigate("/404", { replace: true });
          break;
        case 500:
          if (error.response) {
            var errors = "";
            for (var key in error.response.data) {
              if (error.response.data.hasOwnProperty(key)) {
                var val = error.response.data[key];
                errors += val + " \n\r";
              }
            }
            showAlert({ message: errors, open: true, severity: "error" });
          }
          break;
        default:
          openDialog(
            "Server Error",
            "There was some error while accessing server. \nPlease try after some time."
          );
      }

      return Promise.reject({ ...error });
    }
  );
  React.useEffect(() => {
    securityApi.checkSession(user, updateUser, true);
  }, []);
  return (
    <div>
      <Helmet titleTemplate="%s | Kindeeds" />
      <Header></Header>
      <Router primary={false}>
        <ScrollToTop path="/">
          <HomePage path="/" />

          <MainPrivateLayout path="/" component={MainPublicLayout}>
            <NotFound default />
            <Dashboard path="/dashboard" />
            {/* <Give path="/give" index={2} /> */}
            <GiveItem path="/give/:slug" index={2} />
            <GiveItem path="/give/:slug/:bundleId" />
            <GiveItem path="/give/:slug/:bundleId/add" />
            <GiveItem path="/give/:slug/:bundleId/edit/:itemId" />
            <Preview path="/give/:slug/:bundleId/preview" />

            <MyRequest path="/my-gives/:slug/:item-id" />
            <ItemRequests path="/my-gives/:slug/:item-id/requests" />
            <MyGetsAddress path="/my-gets/:slug/:item-id" />

            <ExternalItemDetails path="/get/details/:slug/:itemTitleSlug/:bundleId" />
            <Get path="/get" index={3} />
            <Get path="/get/list" index={3} />

            <MyGives path="/my-gives" />
            <MyGives path="/my-gives" state={0} />

            <MyGives path='/my-gives' />
            <MyGives path='/my-gives/:filter-value' />
            <MyGets path='/my-gets' />
            <MyGets path='/my-gets/:filter-value' />

            <UserProfile path="/account" />
            <Profile path="/profile/:userId" />

            <AboutUs path="/about-us" />
            <AffilliateDisclaimer path="/affilliate-disclaimer" />
            <ContactUs path="/contact-us" />
            <TermsOfUse path="/termsofuse" />
            <MembershipAgreement path="/membership-agreement" />
            <Privacy path="/privacypolicy" />
            <LearnMore path="/learnmore" />
            <Downtime path="/downtime" />
            <Covid19 path="/covid-19" index={4} />
            <Notifications path="/notifications" index={4} />
          </MainPrivateLayout>
          <NotFound403 path="/403" />

          <Contribute path="/contribute" />
          <Onboarding path="/welcome" />
          <Register path="/register" />
          <ForgotPassword path="/reset-password" />
          <ResetPassword path="/set-new-password/:userId/:token/" />
          <SetPassword path="/set-password" />
          <UntypedLogin path="/login" />
          <UntypedLogin path="/login/:fb" />

          <VerifyEmail path="/verify-email/:token" />
          <UntypedLogout path="/logout" />
          <NotFound path="/404" />
        </ScrollToTop>
      </Router>
      <Footer></Footer>
    </div>
  );
};

export { RouteProvider };
