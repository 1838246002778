import { makeStyles } from '@material-ui/core/styles';

const ProfileStyles = makeStyles(theme => ({
    advertisement: {
        width: '25%',
        backgroundColor: theme.palette.secondary.secondary
    },
    tabs: {
        width: '75%',
        paddingRight: '2rem'
    },
    dpContainer: {
        height: '110px',
        width: '110px',
        position: 'relative',
        overflow: 'unset'
    },
    photoIconContainer: {
        padding: '5px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
        position: 'absolute',
        bottom: '0',
        borderRadius: '50%',
        right: '10px'
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '2rem',
        boxSizing: 'border-box',
        height: '199px',
        border: '1px solid #FAFAFA',
        borderRadius: '4px',
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
        paddingLeft: '1rem'
    },
    line: {
        boxSizing: 'border-box',
        height: '49px',
        width: '1px',
        background: '#979797',
        margin: '0.5rem 1rem'
    },
    snapshot: {
        height: '179px',
        width: '147px'
    }
}));

export default ProfileStyles;