import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import PreviewItem from "./previewItem";
import PrefrencesForm from "./forms/PrefrencesForm";
import { userApi, itemApi } from "../../../api";
import { useConfirmation } from "../../../ConfirmationService";
import { useStoreActions, useStoreState } from "./../../../hooks";
import { navigate } from "@reach/router";

const LoginStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  container: {
    backgroundColor: "#ffffff",
    padding: "2rem 80px",

    [theme.breakpoints.down("sm")]: {
      padding: "1rem",

      "& .MuiGrid-container": {
        flexDirection: "column",

        "& .MuiGrid-item": {
          flexBasis: "100%",
          maxWidth: "100%",
          padding: "0 !important",
          margin: "1rem 0",
        },
      },
    },

    [theme.breakpoints.only("md")]: {
      padding: "1rem",
    },
  },
  card: {
    boxSizing: "border-box",
    padding: "15px",
    display: "flex",
    flexDirection: "row",
  },
  rectangle: {
    backgroundColor: "rgba(127,204,40,0.03)",
    display: "flex",
    flexDirection: "column",
    padding: "2rem",

    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      margin: "1rem 0",
    },
  },
  formControl: {
    width: "75%",
  },
  cover: {
    width: "66px",
    height: "66px",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "1rem",

    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: "1rem 0",
    },
  },
  or: {
    display: "flex",
    margin: "2rem 0",

    "&::after": {
      content: `''`,
      flex: "1 1",
      borderBottom: "1px solid #E7E7E7",
      margin: "auto 2rem",
    },

    "&::before": {
      content: `''`,
      flex: "1 1",
      borderBottom: "1px solid #E7E7E7",
      margin: "auto 2rem",
    },
  },
  iconContainer: {
    background: "#263C68",
    height: "40px",
    margin: "0 10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  buttonContainer: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "row-reverse",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  fixedButtonSection: {
    backgroundColor: "#fff",
    bottom: 0,
    position: "fixed",
    zIndex: 2,
    padding: "1rem 0 !important",
    width: "100%",
    display: "none",
    alignItems: "center",
    margin: "0 -1rem",

    "& .MuiButton-root": {
      margin: "0 10px",
      flex: "1 1",
    },

    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
}));

export default function Preview(props) {
  const classes = LoginStyles(),
    [showPreview, setShowPreview] = React.useState(false),
    [submitting, setSubmitting] = React.useState(""),
    [done, setDone] = React.useState(false),
    user = useStoreState((state) => state.auth.user),
    updateUser = useStoreActions((actions) => actions.auth.updateUser),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    confirm = useConfirmation().bind(this),
    showDialog = (title, message) => {
      confirm({
        variant: "info",
        catchOnCancel: true,
        title: title,
        description: message,
      });
    };

  const [preference, setPrefrences] = React.useState({
    delivery: null,
    delivery_id: null,
    shipping: null,
    shipping_id: null,
    givePreference: null,
    givePreference_id: null,
    city: null,
    item_listed_max_days: 30,
    saveToMyProfile: false,
  });

  const updatePreferece = (object) => {
    let newPreference = { ...preference };

    if (object.delivery) {
      newPreference.delivery = object.delivery.description;
      newPreference.delivery_id = object.delivery.preference;
    }
    if (object.city) {
      newPreference.city = object.city;
    }
    if (object.item_listed_max_days) {
      newPreference.item_listed_max_days = object.item_listed_max_days;
    }
    if (object.shipping) {
      newPreference.shipping = object.shipping.description;
      newPreference.shipping_id = object.shipping.preference;
    }
    if (object.givePreference) {
      newPreference.givePreference = object.givePreference.description;
      newPreference.givePreference_id = object.givePreference.preference;
    }
    if (object.savePref) {
      newPreference.saveToMyProfile = object.savePref;
    }

    setPrefrences(newPreference);
  };

  const handleCancel = (name) => (event) => {
    window.history.back();
  };

  const submitBundle = (bundleId) => {
    userApi.getLoggedInUserDetails(
      function (resp) {
        if (resp) {
          if (resp[0].u_status == 2) {
            let city = resp[0].u_city;

            if (city && !preference.city) {
              preference.city = `${resp[0].u_city}, ${resp[0].u_state}, ${resp[0].u_country}`;
            }

            if (resp[0].u_city || preference.city) {
              setSubmitting("submit");

              if (preference.city) {
                var cityArray = preference.city.split(",");
                let storeUserCopy = { ...user };
                if (cityArray[0]) {
                  storeUserCopy.u_city = cityArray[0].trim();
                }
                if (cityArray[1]) {
                  storeUserCopy.u_state = cityArray[1].trim();
                }
                if (cityArray[2]) {
                  storeUserCopy.u_country = cityArray[2].trim();
                }
                updateUser({ user: storeUserCopy });
              }
              itemApi.submitBundle(
                {
                  bundle_id: bundleId,
                  b_status: "submitted",
                  delivery_pref: preference.delivery,
                  delivery_pref_id: preference.delivery_id,
                  ship_pref: preference.shipping,
                  ship_pref_id: preference.shipping_id,
                  city: preference.city,
                  give_pref: preference.givePreference,
                  give_pref_id: preference.givePreference_id,
                  is_save: preference.saveToMyProfile,
                  item_listed_max_days: preference.item_listed_max_days,
                },
                function (response) {
                  if (response) {
                    setDone(true);
                    setShowPreview(false);
                    setSubmitting("");
                  }
                },
                function (response) {
                  setDone(true);
                  setSubmitting("");
                  setShowPreview(false);
                }
              );
            } else {
              showAlert({
                message: "Please set your address before you publish the item.",
                open: true,
                severity: "error",
              });
              setShowPreview(false);
              setSubmitting("");
            }
          } else {
            showAlert({
              message: "Please verify your email address to give and get",
              open: true,
              severity: "error",
            });
            setShowPreview(false);
            setSubmitting("");
          }
        }
      },
      function () {
        showDialog("Error", "Error");
        setShowPreview(false);
        setSubmitting("");
      }
    );
  };

  return (
    <div className={classes.container}>
      {!done && (
        <Typography variant='h1' component='div'>
          Preview
        </Typography>
      )}
      <Grid container style={{ marginTop: "2rem" }}>
        <Grid item xs={6} style={{ paddingRight: "2rem" }}>
          <PreviewItem
            done={done}
            setDone={setDone}
            bundleId={props.bundleId}
            cancelPreview={handleCancel}
            submitItem={submitBundle}
            editItem={props.editItem}
            submitting={props.submitting}
            slug={props.slug}
            preference={preference}
          />
        </Grid>
        <Grid item xs={6}>
          {!done && (
            <PrefrencesForm
              updatePref={updatePreferece}
              preference={preference}
              setPrefrences={setPrefrences}
            />
          )}
          {done && (
            <section style={{ textAlign: "center" }}>
              <Typography
                mt={2}
                variant='body2'
                style={{
                  color: "#383838",
                  fontSize: "18px",
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: "400",
                  lineHeight: "1.43",
                  textAlign: "center",
                }}
                component='div'
              >
                {/* Congrats! You have successfully published your item. */}
                <h3 style={{ color: "#22B11F" }}>Thank you for giving!</h3>
                <p>
                  {" "}
                  Your item is successfully listed! We will notify you when
                  someone requests it.
                </p>
              </Typography>
              <Typography
                variant='body1'
                style={{
                  cursor: "pointer",
                  padding: "5px 15px",
                  borderRadius: "4px",
                  border: "1px solid rgba(127, 204, 40, 0.5)",
                  color: "#fff",
                  backgroundColor: "#22B11F",
                  fontSize: "13px",
                }}
                onClick={(e) => navigate("/get")}
                component='span'
                color='primary'
              >
                View Item Listing
              </Typography>
            </section>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
