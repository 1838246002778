import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { navigate } from "@reach/router";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    wrap: {
        [theme.breakpoints.down("sm")]: {
            padding: '20px',
        },
    },
    hover: {
        cursor: 'pointer',
        padding: '5px 0',
        '&:hover': {
                color: '#22B11F'
        }

    },
    justify: {
        textAlign: 'justify'
    },

    leftPanel:{
        margin: "112px 0",
        [theme.breakpoints.down("sm")]: {
                margin: "0",
        },
    },

    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    }

}));

function Privacy() {
    const classes = useStyles();

    return (

        <div className={classes.wrap}>

            <Grid container>
                <Grid sm={2} className={classes.leftPanel}>
                        
                        <Typography variant='subtitle1' component='div' className = {classes.hover}
                        onClick={(e) => {
                                navigate("/termsofuse");
                              }}
                        
                        >Terms of Use</Typography>
                        <Typography variant='h5' component='div' className = {classes.hover} 
                        
                        onClick={(e) => {
                                navigate("privacypolicy");
                              }}
                        
                        >Privacy Policy</Typography>
                        <Typography variant='subtitle1' component='div' className = {classes.hover} 
                        
                        onClick={(e) => {
                                navigate("affilliate-disclaimer");
                              }}
                        
                        >Affiliate Disclaimer / Credits</Typography>
                        
                </Grid> 
                <Grid sm={10}>
                <Typography variant='h1' component='div' style={{ margin: "60px 0px 30px " }} >Privacy Policy</Typography>
            <p className={classes.justify}>Protecting your private information is our priority. This Statement of Privacy applies to
            kindeeds.com and RiverDog Ventures LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to RiverDog Ventures LLC
            include kindeeds.com and RiverDog. The RiverDog website is an online platform for sharing items
            such as books, gadgets, clothes, shoes freely with other individuals or charities site. By using the
                    RiverDog website, you consent to the data practices described in this statement.</p>

            <p className={classes.title}>Collection of your Personal Information</p>
            <p className={classes.justify}>In order to better provide you with products and services offered on our Site, RiverDog may
                    collect personally identifiable information, such as your:</p>

            <p className={classes.justify}>-First and Last Name<br />
                    -Mailing Address<br />
                    -E-mail Address<br />
                    -Phone Number<br />
                    -Location</p>

            <p className={classes.justify}>If you voluntarily contribute or purchase RiverDog's products and services now or in the future, we
            collect billing and credit card information. This information is used to complete the purchase
                    transaction.</p>

            <p className={classes.justify}>Please keep in mind that if you directly disclose personally identifiable information or personally
            sensitive data through RiverDog's public message boards, this information may be collected and
                    used by others.</p>

            <p className={classes.justify}>We do not collect any personal information about you unless you voluntarily provide it to us.
            However, you may be required to provide certain personal information to us when you elect to use
            certain products or services available on the Site. These may include: (a) registering for an account
            on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c)
            signing up for special offers from selected third parties; (d) sending us an email message; (e)
            submitting your credit card or other payment information when ordering and purchasing products
            and services on our Site. To wit, we will use your information for, but not limited to,
            communicating with you in relation to services and/or products you have requested from us. We
                    also may gather additional personal or non-personal information in the future.</p>

            <p className={classes.title}>Use of your Personal Information</p>
            <p className={classes.justify}>RiverDog collects and uses your personal information to operate its website(s) and deliver the
                    services you have requested.</p>

            <p className={classes.justify}>RiverDog may also use your personally identifiable information to inform you of other products or services available from RiverDog and its affiliates.</p>

            <p className={classes.title}>Sharing Information with Third Parties</p>
            <p className={classes.justify}>RiverDog does not sell, rent or lease its customer lists to third parties.</p>

            <p className={classes.justify}>RiverDog may, from time to time, contact you on behalf of external business partners about a
            particular offering that may be of interest to you. In those cases, your unique personally identifiable
            information (e-mail, name, address, telephone number) is transferred to the third party. RiverDog
            may share data with trusted partners to help perform statistical analysis, send you email or postal
            mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from
            using your personal information except to provide these services to RiverDog, and they are
                    required to maintain the confidentiality of your information.</p>

            <p className={classes.justify}>RiverDog may disclose your personal information, without notice, if required to do so by law or in
            the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply
            with legal process served on RiverDog or the site; (b) protect and defend the rights or property of
            RiverDog; and/or (c) act under exigent circumstances to protect the personal safety of users of
                    RiverDog, or the public.</p>

            <p className={classes.title}>Tracking User Behavior</p>
            <p className={classes.justify}>RiverDog may keep track of the websites and pages our users visit within RiverDog, in order to
            determine what RiverDog services are the most popular. This data is used to deliver customized
            content and advertising within RiverDog to customers whose behavior indicates that they are
                    interested in a particular subject area.</p>

            <p className={classes.title}>Automatically Collected Information</p>
            <p className={classes.justify}>Information about your computer hardware and software may be automatically collected by
            RiverDog. This information can include: your IP address, browser type, domain names, access
            times and referring website addresses. This information is used for the operation of the service, to
            maintain quality of the service, and to provide general statistics regarding use of the RiverDog
                    website.</p>

            <p className={classes.title}>Use of Cookies</p>
            <p className={classes.justify}>The RiverDog website may use "cookies" to help you personalize your online experience. A
            cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be
            used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you,
                    and can only be read by a web server in the domain that issued the cookie to you.</p>

            <p className={classes.justify}>One of the primary purposes of cookies is to provide a convenience feature to save you time. The
            purpose of a cookie is to tell the Web server that you have returned to a specific page. For
            example, if you personalize RiverDog pages, or register with RiverDog site or services, a cookie
            helps RiverDog to recall your specific information on subsequent visits. This simplifies the process
            of recording your personal information, such as billing addresses, shipping addresses, and so on.
            When you return to the same RiverDog website, the information you previously provided can be
                    retrieved, so you can easily use the RiverDog features that you customized.</p>

            <p className={classes.justify}>You have the ability to accept or decline cookies. Most Web browsers automatically accept
            cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you
            choose to decline cookies, you may not be able to fully experience the interactive features of the
                    RiverDog services or websites you visit.</p>

            <p className={classes.title}>Links</p>
            <p className={classes.justify}>This website contains links to other sites. Please be aware that we are not responsible for the
            content or privacy practices of such other sites. We encourage our users to be aware when they
            leave our site and to read the privacy statements of any other site that collects personally
                    identifiable information.</p>

            <p className={classes.title}>Security of your Personal Information</p>
            <p className={classes.justify}>RiverDog secures your personal information from unauthorized access, use, or disclosure.
                    RiverDog uses the following methods for this purpose:</p>

            <p>  - SSL Protocol</p>

            <p className={classes.justify}>When personal information (such as a credit card number) is transmitted to other websites, it is
                    protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>

            <p className={classes.justify}>We strive to take appropriate security measures to protect against unauthorized access to or
            alteration of your personal information. Unfortunately, no data transmission over the Internet or any
            wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect
            your personal information, you acknowledge that: (a) there are security and privacy limitations
            inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any
            and all information and data exchanged between you and us through this Site cannot be
                    guaranteed.</p>

            <p className={classes.title}>Children Under Thirteen</p>
            <p className={classes.justify}>RiverDog does not knowingly collect personally identifiable information from children under the
            age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for
                    permission to use this website.</p>

            <p className={classes.title}>Opt-Out & Unsubscribe from Third Party Communications</p>
            <p className={classes.justify}>We respect your privacy and give you an opportunity to opt-out of receiving announcements of
            certain information. Users may opt-out of receiving any or all communications from third-party
                    partners of RiverDog by contacting us here: optout@kindeeds.com</p>

            <p className={classes.title}>E-mail Communications</p>
            <p className={classes.justify}>From time to time, RiverDog may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general
            communication. In order to improve our Services, we may receive a notification when you open an
                    email from RiverDog or click on a link therein.</p>

            <p className={classes.justify}>If you would like to stop receiving marketing or promotional communications via email from
                    RiverDog, you may opt out of such communications by unsubscribe@kindeeds.com.</p>

            <p className={classes.title}>External Data Storage Sites</p>
            <p className={classes.justify}>We may store your data on servers provided by third party hosting vendors with whom we have
                    contracted.</p>

            <p className={classes.title}>Changes to this Statement</p>
            <p className={classes.justify}>RiverDog reserves the right to change this Privacy Policy from time to time. We will notify you
            about significant changes in the way we treat personal information by sending a notice to the
            primary email address specified in your account, by placing a prominent notice on our site, and/or
            by updating any privacy information on this page. Your continued use of the Site and/or Services
            available through this Site after such modifications will constitute your: (a) acknowledgment of the
                    modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>

            <p className={classes.title}>Contact Information</p>
            <p className={classes.justify}>RiverDog welcomes your questions or comments regarding this Statement of Privacy. If you
                    believe that RiverDog has not adhered to this Statement, please contact RiverDog at:</p>

            <p >RiverDog Ventures LLC<br />
                    3201 Edwards Mill Rd, Ste 141<br />
                    Raleigh, North Carolina 27612</p>

            <p>Email Address:<br />
                    contactus@kindeeds.com</p>

            <p>_________________<br />
                    Effective as of May 4, 2020</p>
                </Grid> 
            </Grid>    

           
        </div>
    )
}

export default Privacy
