import * as React from "react";
import { navigate } from "@reach/router";
import { userApi } from "./../../../../api/userApi";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Formik, Field, Form } from "formik";
import { Typography, Button, makeStyles, Card } from "@material-ui/core";
import * as yup from "yup";
import { FormikTextField } from "../../../ui/FormikTextField";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Check, Clear, ReportProblemOutlined } from "@material-ui/icons";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, " ")
    .matches(/[A-Z]/, " ")
    .matches(/[0-9]/, " ")
    .required("Please enter new password"),
  confirmPassword: yup.string().required("Please enter confirm password"),
});
const LoginStyles = makeStyles((theme) => ({
  card: {
    boxSizing: "border-box",
    padding: "15px",
    width: "100%",
    display: "flex",
    height: "100vh",
    minHeight: "900px",
    backgroundColor: "transparent",
    boxShadow: "none !important",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#ffffff",
      minHeight: "unset",
      height: "unset",
    },
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    width: "460px",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      height: "100%",
    },
  },
  title1: {
    color: "#505050",
    fontSize: "30px",
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      fontSize: "21px",
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  passwordRulesDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  passwordRuleIcon:{
    width:'1.25rem'
  },
  passwordRuleText:{
    marginLeft:'5px'
  }
}));

const ResetPasswordForm = (props) => {
  const classes = LoginStyles();

  const [loading, setLoading] = React.useState(false);
  const openDialog = (message) => {
    showAlert({
      message: message,
      open: true,
      severity: "success",
    });
    navigate("/login");
  };

  const [passwordFocused, setPasswordFocused] = React.useState(false);

  const [passwordMinCharRule, setPasswordMinCharRule] = React.useState(false);
  const [passwordUpperCaseRule, setPasswordUpperCaseRule] =
    React.useState(false);
  const [passwordNumberRule, setPasswordNumberRule] = React.useState(false);

  const checkPasswordValidation = (password) => {
    setPasswordMinCharRule(password.length >= 8);
    setPasswordUpperCaseRule(/[A-Z]/.test(password));
    setPasswordNumberRule(/[0-9]/.test(password));
  };

  return (
    <Card className={classes.card}>
      <section className={classes.loginContainer}>
        <Typography component="div" className={classes.title1}>
          Set New Password
        </Typography>
        <Formik
          initialValues={{
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              const requestDataBlk = {
                new_password1: values.newPassword,
                new_password2: values.confirmPassword,
                uid: props.props.userId,
                token: props.props.token,
              };
              let userId = props.props.userId;
              let token = props.props.token;
              setLoading(true);
              userApi.resetPassword(
                requestDataBlk,
                userId,
                token,
                function (response) {
                  setLoading(false);

                  navigate("/login", {
                    state: { isPasswordReset: true, replace: true },
                  });
                },
                function () {
                  setLoading(false);
                }
              );
            } catch ({ error }) {}
            setSubmitting(false);
          }}
          render={({ submitForm, isSubmitting, values }) => (
            <Form style={{ width: "100%" }}>
              <Field
                type="password"
                label="New password"
                name="newPassword"
                style={{ margin: "1rem 0" }}
                fullWidth
                component={FormikTextField}
                onFocus={(e) => {
                  setPasswordFocused(true);
                }}
                onKeyUp={(e) => {
                  checkPasswordValidation(e.target.value);
                }}
              />
              <Alert
                severity="info"
                style={{ display: passwordFocused ? "flex" : "none" }}
              >
                <AlertTitle>Password must contain:</AlertTitle>
                <div>
                  <div className={classes.passwordRulesDiv}>
                    {passwordMinCharRule ? (
                      <Check color="primary" className={classes.passwordRuleIcon}/>
                    ) : (
                      <Clear color="error" className={classes.passwordRuleIcon}/>
                    )}
                    <Typography variant="subtitle2" className={classes.passwordRuleText}>
                      Minimum 8 Characters
                    </Typography>
                  </div>
                  <div className={classes.passwordRulesDiv}>
                    {passwordUpperCaseRule ? (
                      <Check color="primary" className={classes.passwordRuleIcon}/>
                    ) : (
                      <Clear color="error" className={classes.passwordRuleIcon}/>
                    )}
                    <Typography variant="subtitle2" className={classes.passwordRuleText}>
                      At least 1 Uppercase Letter
                    </Typography>
                  </div>
                  <div className={classes.passwordRulesDiv}>
                    {passwordNumberRule ? (
                      <Check color="primary" className={classes.passwordRuleIcon}/>
                    ) : (
                      <Clear color="error" className={classes.passwordRuleIcon}/>
                    )}
                    <Typography variant="subtitle2" className={classes.passwordRuleText}>
                      At least 1 Number
                    </Typography>
                  </div>
                  <div className={classes.passwordRulesDiv}>
                    <ReportProblemOutlined className={classes.passwordRuleIcon}/>
                    <Typography variant="subtitle2" className={classes.passwordRuleText}>
                      Avoid Common Words
                    </Typography>
                  </div>
                </div>
              </Alert>
              <Field
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                style={{ margin: "1rem 0" }}
                fullWidth
                component={FormikTextField}
                onFocus={(e) => {
                  setPasswordFocused(false);
                }}
              />
              <div className={classes.wrapper} align="center">
                <Button
                  variant="contained"
                  type="submit"
                  style={{ width: "146px", alignSelf: "center" }}
                  disabled={loading}
                  color="primary"
                  onClick={submitForm}
                >
                  Submit
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Form>
          )}
        />
      </section>
    </Card>
  );
};

export { ResetPasswordForm };
