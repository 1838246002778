import { makeStyles } from '@material-ui/core/styles';

const GlobalStyles = makeStyles(theme => ({
    header: {
        height: '75px',
        width: '100%',
        display: 'flex',

        [theme.breakpoints.down('sm')]: {
            padding: '0 1rem',
            boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
            position: 'fixed',
            zIndex: 1002,
            top: '0',
            height: '56px',
            background: '#fff'
        }
    },
    drawer: {
        width: '262px',
        padding: '1rem',

        '& .MuiTypography-subtitle2': {
            margin: '1rem',

            '&:hover': {
                color: 'rgb(235, 147, 60)',
                fontWeight: '600'
            }
        }
    },
    avatarButton: {
        margin: '0 3px',
        display: 'unset',

        '& .MuiButton-label': {
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-between'
        }
    },
    notifications: {
        marginTop: '4rem',
        display: 'flex',

        '& .MuiBadge-anchorOriginTopRightRectangle': {
            backgroundColor: '#A7080D',
            border: '2px solid #fff',
            height: '20px',
            width: '20px',
            color: '#fff',
            marginLeft: '1rem'
        }
    },
    closeIcon: {
        float: 'right',

        '&: hover': {
            fill: 'rgb(235, 147, 60)',
            fontWeight: '600'
        }
    },
    headSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        minWidth: '650px',
        maxWidth: '1200px',
        alignItems: 'center',
        margin: '0 auto',

        [theme.breakpoints.only("md")]: {
            maxWidth: "960px"
        },

        '& .MuiIconButton-root': {
            display: 'none'
        },

        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',

            '& .MuiIconButton-root': {
                display: 'flex',
                paddingLeft: '4px'
            }
        }
    },

    appLogo: {
        width: '162px',
        height: '38px',
        [theme.breakpoints.down('sm')]: {
            width: '124px',
            height: '28px;',
        }
    },


    rightAction: {
        display: 'none',
        marginRight: '16px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex'
        }
    },
    headerActions: {
        display: 'flex',

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    mdIcon: {
        height: '28px',
        width: '28px',
        margin: '5px',
        cursor: 'pointer',
        borderRadius: '50%',
    },
    footer: {
        backgroundColor: '#231C07',
        padding: '2rem 5rem',
        marginTop: '1.5rem',

        [theme.breakpoints.down('sm')]: {
            padding: '1rem',
            marginTop: 0
        }
    },
    footerChild: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '650px',
        maxWidth: '1200px',
        margin: '0 auto',

        [theme.breakpoints.only("md")]: {
            maxWidth: "960px"
        },

        '& section': {
            marginTop: '1rem',

            '& .MuiTypography-root': {
                cursor: 'pointer'
            },

            '& .MuiTypography-h3': {
                color: '#ffffff',
                fontWeight: '700',
                marginBottom: '1rem'
            },

            '& .MuiTypography-subtitle2': {
                color: '#ffffff', marginBottom: '0.5rem'
            }
        },

        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            minWidth: 'unset',

            // '& section': {
            //     flexGrow: 1,
            //     width: '40%'
            // },
        }
    },
    copyRight: {
        color: '#ffffff',
        // margin: '2rem 0',
        maxWidth: '1200px',
        margin: '2rem auto',
        [theme.breakpoints.only("md")]: {
            maxWidth: "960px"
        },

        [theme.breakpoints.down('sm')]: {
            margin: '1rem',
            marginLeft: '0'
        }
    },
    iconsContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'column',
            display: 'flex',
            margin: '1rem 0 !important',
            alignItems: 'center',

            '& .MuiBox-root': {
                margin: 0
            }
        }
    },

    hideOnMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },

    '@global': {
        img: {
            objectFit: 'contain',
            textAlign: 'center'
        },
        svg: {
            cursor: 'pointer'
        },
        body: {
            '& .dialogContainer': {
                position: 'relative',
                '& .MuiDialog-paper': {
                    padding: '0 !important',
                    maxHeight: 'unset',

                    '& .MuiDialogActions-root': {
                        height: '77px',
                        backgroundColor: '#F2F5F7'
                    }
                }
            },

            '& .grecaptcha-badge': { visibility: 'hidden' },

            [theme.breakpoints.down('sm')]: {
                '& .MuiTypography-noWrap': {
                    whiteSpace: 'unset'
                }
            }
        }
    },
    hamburgerLogoWrapper: {
        display: 'flex'
    },
    mobileAvatarWrapper: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center'
        }
    },
    mobileAvatar: {
        height: '24px',
        width: '24px'
    },
    removeMobileHeader: {
        [theme.breakpoints.down('sm')]: {
            // paddingLeft: '0'
        }
    },
    hideMobileHamburger: {
        display: 'none'
    },
    avatarNameWrapper: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    avatarWelcomeLabel: {
        marginLeft: '5px'
    },
    sectionDivider: {
        height: '1px',
        background: 'rgb(34, 177, 31)'
    },
    footerOrder2: {
        [theme.breakpoints.down('sm')]: {
            order: '2',
            marginTop: '0px !important',
        }
    },
    mobileLoginButton: {
        height: '33px !important',
        minWidth: '78px !important',
        '& .MuiButton-label': {
            color: '#FFFFFF',
            fontFamily: "Open Sans",
            fontSize: '14px',
            letterSpacing: '0',
            lineHeight: '19px',
            textAlign: 'center',
            textShadow: '0 1px 2px 0 rgba(0,0,0,0.38)'
        }
    },
    contributeWrapper: {
        padding: '0 6px',
        marginRight: '22px',
        fontWeight: '700',
        paddingTop: '4px',
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            marginRight: '15px',
            // marginRight: '0px', for notification
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    }
}));

export default GlobalStyles;