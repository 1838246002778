import React from 'react';
import {
  Avatar,
  Button,
  Box,
  Grid,
  Typography,
  Badge,
  makeStyles,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { imageApi, genericApi, userApi } from '../../../api';
import imageCompression from 'browser-image-compression';
import throttle from 'lodash/throttle';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useStoreActions, useStoreState } from '../../../store';
import Skeleton from '@material-ui/lab/Skeleton';
import AccountSettingsStyles from '../../../styles/AccountSettings.styles';
import { getCountryCallingCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import ReactPhoneInput from 'react-phone-input-mui';
import * as yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FormikTextField } from '../../ui/FormikTextField';
import { FormikSearchTextField } from '../../ui/FormikSearchTextField';
import { getPostCodeFormat, getCountryCode } from '../../../utils/countrylist.js';

const progressStyle = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

let previousUserData = {
  fullName: '',
  displayName: '',
  city: '',
  state: '',
  country: '',
  countryCode: 'us',
  area: '',
  userAvatar: '',
  zipcode: '',
  phone: ''
};

export default function PersonalInfo() {
  const [inputValue, setInputValue] = React.useState(''),
    [loading, setLoading] = React.useState(false),
    [loadingData, setLoadingData] = React.useState(false),
    [isLocationSet, setIsLocaction] = React.useState(false),
    [userAvatar, setUserAvatar] = React.useState(''),
    [loadingCity, setLoadingCity] = React.useState(false),
    [fullName, setFullName] = React.useState(''),
    [displayName, setDisplayName] = React.useState(''),
    [city, setCity] = React.useState(''),
    [state, setState] = React.useState(''),
    [country, setCountry] = React.useState(''),
    [countryCode, setCountryCode] = React.useState(''),
    [area, setArea] = React.useState(''),
    [zipcode, setZipcode] = React.useState(''),
    [phone, setPhone] = React.useState(''),
    classes = AccountSettingsStyles(),
    classes1 = progressStyle(),
    defaultAvatars = [
      '/assets/avatars/avatar1.svg',
      '/assets/avatars/avatar2.svg',
      '/assets/avatars/avatar3.svg',
      '/assets/avatars/avatar4.svg',
      '/assets/avatars/avatar5.svg',
      '/assets/avatars/avatar6.svg',
    ],
    kindeedAvatars = [
      '/assets/avatars/KindeedsAvtar/blackCircle.svg',
      '/assets/avatars/KindeedsAvtar/blackSquare.svg',
      '/assets/avatars/KindeedsAvtar/brownCircle.svg',
      '/assets/avatars/KindeedsAvtar/brownSquare.svg',
      '/assets/avatars/KindeedsAvtar/greenCircle.svg',
      '/assets/avatars/KindeedsAvtar/greenSquare.svg',
    ],
    [options, setOptions] = React.useState([]),
    storeUser = useStoreState((state) => state.auth.user),
    updateUser = useStoreActions((actions) => actions.auth.updateUser),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    fetch = React.useMemo(
      () =>
        throttle((input, callback) => {
          if (!input) {
            return callback([]);
          }
          if (input.input.length < 4) {
            return;
          }

          setLoadingCity(true);

          genericApi.getCitiesByGeoLocations(input.input, function (response) {
            return callback(response);
          });
        }, 200),
      []
    ),
    [isKindeeds, setKindeeds] = React.useState(false),
    setValues = (user) => {
      setFullName(user.fullName);
      setDisplayName(user.displayName);
      setCity(user.city);
      setState(user.state);
      setCountry(user.country);
      setCountryCode(user.countryCode);
      setArea(user.area);
      setZipcode(user.zipcode);
      setPhone(user.phone);
    };

  let schema = yup.object().shape({
    fullName: yup.string().trim().required('Please enter full name.').nullable(),
    displayName: yup
      .string()
      .trim()
      .required('Please enter display name.')
      .matches(/^(?:(?!kindee).)+$/gi,
        'That name has been taken. Please choose another.'
      )
      .nullable(),
    city: yup.string().required('Please enter city.').nullable(),
    state: yup.string().nullable(),
    country: yup.string().nullable(),
    area: yup.string().required('Please enter area.').nullable(),
    userAvatar: yup.string().nullable(),
    zipcode: yup.string().nullable().test({
      name: 'isZipCodeValid',
      test: function (value) {
        if (value) {
          const patt = getPostCodeFormat(country);
          let res = !(new RegExp("^" + patt + "$", 'i').test(value));
          if (patt && res) {
            return this.createError({
              message: `Please enter a valid zipcode.`,
              path: 'zipcode', // Fieldname
            })
          }
          else {
            return true;
          }
        } else {
          return true;
        }
      }
    }).nullable(),
    phone: yup.string().nullable()
      .test('isPhoneNumberValid', 'Please enter valid phone number.',
        function (value) {
          if (value && value !== '+' + getCountryCallingCode(countryCode)) {
            const phoneNumber = parsePhoneNumberFromString(value, countryCode),
              patt = /[a-zA-Z]/gi,
              res = !patt.test(value);

            return !!(phoneNumber && phoneNumber.isPossible() && res);
          } else {
            return true;
          }
        }).nullable()
  });

  if (isKindeeds) {
    schema = yup.object().shape({
      fullName: yup.string().trim().required('Please enter full name.').nullable(),
      displayName: yup
        .string()
        .trim()
        .required('Please enter display name.')
        .nullable(),
      city: yup.string().required('Please enter city.').nullable(),
      state: yup.string().nullable(),
      country: yup.string().nullable(),
      area: yup.string().required('Please enter area.').nullable(),
      userAvatar: yup.string().nullable(),
      zipcode: yup.string().nullable().test({
        name: 'isZipCodeValid',
        test: function (value) {
          if (value) {
            const patt = getPostCodeFormat(country);
            let res = !(new RegExp("^" + patt + "$", 'i').test(value));
            if (patt && res) {
              return this.createError({
                message: `Please enter a valid zipcode.`,
                path: 'zipcode', // Fieldname
              })
            }
            else {
              return true;
            }
          } else {
            return true;
          }
        }
      }).nullable(),
      phone: yup.string().nullable()
        .test('isPhoneNumberValid', 'Please enter valid phone number.',
          function (value) {
            if (value && value !== '+' + getCountryCallingCode(countryCode)) {
              const phoneNumber = parsePhoneNumberFromString(value, countryCode),
                patt = /[a-zA-Z]/gi,
                res = !patt.test(value);

              return !!(phoneNumber && phoneNumber.isPossible() && res);
            } else {
              return true;
            }
          }).nullable()
    });
  }

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);
        setLoadingCity(false);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  React.useEffect(() => {
    userApi.getLoggedInUserDetails(function (resp) {
      if (resp) {
        let userObj = { ...previousUserData };
        userObj.fullName = resp[0].full_name;
        userObj.displayName = resp[0].display_name;
        userObj.area = resp[0].area;
        userObj.zipcode = resp[0].zipcode;
        userObj.userAvatar = resp[0].user_avatar;

        setUserAvatar(resp[0].user_avatar)

        if (resp[0].u_city) {
          userObj.city = resp[0].u_city?.trim();
          userObj.state = resp[0].u_state?.trim();
          userObj.country = resp[0].u_country?.trim();
          userObj.countryCode = getCountryCode(userObj.country);
          const parsedPhoneValue = resp[0].mobile ? parsePhoneNumberFromString(resp[0].mobile, userObj.countryCode)?.nationalNumber : '',
            countryCallingCode = '+' + getCountryCallingCode(userObj.countryCode);

          userObj.phone = parsedPhoneValue ? (countryCallingCode + parsedPhoneValue) : countryCallingCode;
          setIsLocaction(true);
          setCountryCode(userObj.countryCode);
        }

        previousUserData = { ...userObj };

        setValues(userObj);
        if (resp[0].email?.toLowerCase()?.includes('kindeeds.com')) {
          setKindeeds(true)
        }

        setLoadingData(true);
      }
    });
  }, []);

  const updateProfile = (values) => {
    const phoneNumber = values.phone ? parsePhoneNumberFromString(values.phone, values.countryCode) : '',
      reqBlk = {
        display_name: values.displayName || null,
        full_name: values.fullName || null,
        u_city: values.city || '',
        area: values.area || '',
        user_avatar: userAvatar,
        mobile: phoneNumber && phoneNumber.nationalNumber ? phoneNumber.nationalNumber : '',
        u_state: values.state?.trim() || '',
        u_country: values.country?.trim() || '',
        country_code: values.countryCode,
        zipcode: values.zipcode || ''
      };

    setLoading(true);

    userApi.updateLoggedInUserProfile(
      reqBlk,
      function (resp) {
        setLoading(false);
        if (resp) {
          previousUserData = {
            fullName: fullName,
            displayName: displayName,
            city: city,
            state: state,
            country: country,
            countryCode: countryCode,
            area: area,
            userAvatar: userAvatar,
            zipcode: zipcode,
            phone: phone
          };

          let storeUserCopy = { ...storeUser };

          storeUserCopy.user_avatar = resp.user_avatar;
          storeUserCopy.full_name = resp.full_name;
          storeUserCopy.user_hash = resp.user_hash;
          storeUserCopy.mobile = resp.mobile;
          storeUserCopy.display_name = resp.display_name;

          updateUser({ user: storeUserCopy });

          showAlert({
            message: 'You have successfully updated profile.',
            open: true,
            severity: 'success',
          });
        } else {
          showAlert({
            message: 'There is an error in updating profile.',
            open: true,
            severity: 'error',
          });
        }
      },
      function (error) {
        setLoading(false);
      }
    );
  };

  const handleFileUpload = (e) => {
    let options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 1600,
      useWebWorker: true,
    };
    imageCompression(e.target.files[0], options)
      .then(function (compressedFile) {
        imageApi.profileImageUpload(compressedFile, function (response) {
          setUserAvatar(userAvatar);
          e.target.value = '';
        });
      })
      .catch(function (error) {
        showAlert({
          message: 'Unable to upload a file. This file type is not supported!',
          open: true,
          severity: 'error',
        });
      });
  };
  return (
    <section className={classes.aboutMe}>
      <aside>
        <Formik
          enableReinitialize
          initialValues={{
            displayName: displayName,
            fullName: fullName,
            city: city,
            state: state,
            country: country,
            countryCode: countryCode,
            area: area,
            zipcode: zipcode,
            phone: phone
          }}
          validationSchema={schema}
          onSubmit={async (
            values
          ) => {
            try {
              updateProfile(values);
            } catch (error) {
              showAlert({ message: 'Error', open: true, severity: 'error' });
            }
          }}
          render={({ submitForm, handleReset, values, errors }) => (
            <Form style={{ width: '100%' }}>
              {loadingData ? <Box mt={2}>
                <Field
                  fullWidth
                  type='text'
                  label='Display Name'
                  name='displayName'
                  value={displayName}
                  component={FormikTextField} />
              </Box> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '16px' }} height={48}></Skeleton>}
              {loadingData ? <Box mt={2}>
                <Field
                  fullWidth
                  type='text'
                  label='Full Name'
                  name='fullName'
                  component={FormikTextField}
                  value={fullName}
                />
              </Box> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '16px' }} height={48}></Skeleton>}
              {loadingData ? <Box mt={2}>
                <Autocomplete
                  id='city-search_id'
                  getOptionLabel={(option) => {
                    const address = typeof option === 'string' ? option.split(',') : option.city.split(',');

                    if (address.length > 1) {
                      setCity(address[0]);
                      setState(address[1].trim());
                      setCountry(address[2].trim());
                    }

                    return address[0].trim();
                  }
                  }
                  filterOptions={(x) => x}
                  options={options}
                  autoComplete='new-city'
                  autoSelect={true}
                  includeInputInList
                  disableOpenOnFocus
                  loading={loadingCity}
                  getOptionSelected={(option, value) => value?.city == option?.city}
                  value={city}
                  onChange={(event, value) => {
                    if (value) {
                      setValues({
                        ...values,
                        city: value.city
                      });

                      if (countryCode !== value.country_code) {
                        setCountryCode('');
                        setCountryCode(value.country_code);
                        setPhone(getCountryCallingCode(value.country_code));
                      } else {
                        setPhone(values.phone);
                      }
                    } else {
                      setValues({
                        ...values,
                        city: ''
                      });
                    }
                  }}
                  onInputChange={(event, newValue) => {
                    setInputValue(newValue);
                  }}
                  renderInput={(params) => (
                    <Field
                      fullWidth
                      {...params}
                      label='City'
                      name='city'
                      component={FormikSearchTextField}
                      className={classes.mobileFormLabel}
                    />
                  )}
                  renderOption={(option) => {
                    return (
                      <Grid container alignItems='center'>
                        <Grid item xs>
                          <Typography variant='body2' color='textSecondary'>
                            <span countryCode={option.country_code}>
                              {option.city}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
                {isLocationSet && city && previousUserData.city && city !== previousUserData.city && (
                  <Typography
                    variant='subtitle2'
                    component='div'
                    style={{ color: '#EB933C' }}>
                    This will apply to future gives only.
                  </Typography>
                )}
              </Box> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '16px' }} height={48}></Skeleton>}
              {loadingData ? <Box mt={2}>
                <TextField
                  fullWidth
                  type='text'
                  label='State'
                  name='state'
                  value={state}
                  disabled={true} />
              </Box> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '16px' }} height={48}></Skeleton>}

              {loadingData ? <Box mt={2}>
                <TextField
                  fullWidth
                  type='text'
                  label='Country'
                  name='country'
                  value={country}
                  disabled={true} />
              </Box> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '16px' }} height={48}></Skeleton>}

              {loadingData ? <Box mt={2}>
                <Field
                  fullWidth
                  type='text'
                  label='Neighborhood'
                  name='area'
                  value={area}
                  component={FormikTextField} />
              </Box> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '16px' }} height={48}></Skeleton>}

              {loadingData ? <Box mt={2}>
                <Field
                  fullWidth
                  type='text'
                  label='Zipcode'
                  name='zipcode'
                  component={FormikTextField}
                  value={zipcode}
                />
              </Box> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '16px' }} height={48}></Skeleton>}
              {loadingData ? <Box mt={4}>
                {
                  countryCode && <Field name='phone'>
                    {({ field, form, meta }) => (<><ReactPhoneInput
                      value={phone}
                      onChange={(e, value) => {
                        setValues({ ...values, phone: e || '' });
                      }}
                      onBlur={form.handleBlur}
                      defaultCountry={countryCode.toLowerCase()}
                      country={countryCode.toLowerCase()}
                      countryCodeEditable={false}
                      placeholder='Phone Number'
                      label='Phone Number'
                      component={TextField}
                      inputExtraProps={{
                        name: 'phone',
                        ...form,
                        error: meta.touched && meta.error,
                        helperText: meta.error
                      }} />
                    </>)}
                  </Field>
                }
              </Box> : <Skeleton variant='rect' style={{ width: '100%', marginTop: '16px' }} height={48}></Skeleton>}
              <Box mt={2} className={classes.buttonContainer}>
                <div className={classes1.wrapper}>
                  {loadingData ? <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      submitForm();
                    }}
                    disabled={loading}>
                    Update
                      </Button> : <Skeleton variant="rect" width={117} height={40}></Skeleton>}
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes1.buttonProgress}
                    />
                  )}
                </div>
                <div className={classes1.wrapper}>
                  {loadingData ? <Button
                    variant='outlined'
                    color='secondary'
                    style={{ marginRight: '1rem' }}
                    onClick={() => {
                      setValues(previousUserData);
                      handleReset();
                      setUserAvatar(previousUserData.userAvatar);
                    }}>
                    Cancel
                      </Button> : <Skeleton variant="rect" width={117} height={40}></Skeleton>}
                </div>
              </Box>
            </Form>
          )}
        />
      </aside>
      <aside className={classes.imageUploadAside}>
        <div className={classes.dpContainer}>
          <input
            accept='image/*'
            className={classes.input}
            style={{ display: 'none' }}
            id='raised-button-file'
            type='file'
            onChange={handleFileUpload}
          />
          <Badge
            overlap='circle'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={
              loadingData && <label
                htmlFor='raised-button-file'
                color='primary'
                style={{
                  borderRadius: '50%',
                }}>
                <CameraAltIcon
                  color='primary'
                  style={{
                    width: '30px',
                    height: '30px',
                    background: '#fff',
                    borderRadius: '50%',
                    padding: '5px',
                    cursor: 'pointer',
                  }}
                />
              </label>
            }>
            {!loadingData ? <Skeleton variant="circle" width={166} height={166}></Skeleton> : userAvatar ? <Avatar
              style={{ width: '166px', height: '166px' }}
              src={userAvatar}></Avatar>
              : (
                <Avatar style={{ width: '166px', height: '166px' }}>
                  {displayName
                    ? displayName[0]?.toUpperCase()
                    : fullName[0]?.toUpperCase()}
                </Avatar>
              )}
          </Badge>
        </div>
        {isKindeeds ? <section className={classes.avatarThumbnails}>
          {kindeedAvatars.map((value) => {
            return (
              <div onMouseEnter={(e) => {
                setUserAvatar(value);
              }}
                className={classes.avatar}
                style={{
                  background: `url('${value}') center /contain no-repeat`,
                }}></div>
            );
          })}
        </section> :
          <section className={classes.avatarThumbnails}>
            {defaultAvatars.map((value) => {
              return (
                !loadingData ? <Skeleton variant="circle" width={40} height={40} style={{ margin: '10px 0' }}></Skeleton> : <div
                  onMouseEnter={(e) => {
                    setUserAvatar(value);
                  }}
                  className={classes.avatar}
                  style={{
                    background: `#e5e5e5 url('${value}') center /contain no-repeat`,
                    border: userAvatar === value ? '1px solid #22B11F' : 'none'
                  }}></div>
              );
            })}
          </section>}
      </aside>
    </section>
  );
}
