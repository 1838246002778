import React from "react";
import { Box, Drawer, Button, Tooltip, Typography } from "@material-ui/core";
import { Request } from "./Request";
import { itemApi } from "./../../api/itemApi";
import { appSettings } from "../../constants/appSettings";
import { makeStyles } from "@material-ui/core/styles";
import { userApi } from "../../api";
import { utilHelper } from "../../helpers";
import { VerticalAds } from "../ui/ads/VerticalAds";
import { LargeRectangleAds } from "../ui/ads/LargeRectangleAds";
import { ItemImagesContainer } from "../../components/ui/ItemImagesContainer";
import { ItemDetails } from "../../components/ui/ItemDetails";
import { GoodReadDetails } from "../../components/ui/GoodReadDetails";

const GetStyle = makeStyles((theme) => ({
  getDetailsContainer: {
    margin: "2rem 0",

    [theme.breakpoints.down("sm")]: {
      margin: "1rem",
    },
  },
  bannerSection: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  details: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    marginBottom: "2rem",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  detailsContainer: {
    width: "80%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  // backButton: {
  //     cursor: 'pointer',
  //     color: '#22B11F',
  //     textDecoration: 'underline',
  //     margin: '20px 0',
  //     [theme.breakpoints.down('sm')]: {
  //         margin: '20px 20px 0'
  //     },
  // },
  itemDetails: {
    marginLeft: "2rem",
    flex: "1 1",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  fixedButtonSection: {
    backgroundColor: theme.palette.secondary.secondary,
    // bottom: 0,
    // position: "fixed",
    // zIndex: 2,
    padding: "1rem 0 !important",
    width: "100%",
    // display: "none",
    alignItems: "center",

    "& .MuiButton-root": {
      margin: "0 10px",
      flex: "1 1",
    },

    "& .MuiTypography-subtitle2": {
      flex: "1 1",
    },

    "& .MuiBadge-root": {
      alignSelf: "baseline",
    },

    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
}));

const BASE_URL = appSettings.BaseURL;

const GetDetails = (props) => {
  const [displayIndex, setDisplayIndex] = React.useState(0),
    [conditionFilterValues, setConditionFilterValues] = React.useState([]),
    [bundle, setBundle] = React.useState([]),
    [itemDetails, setItemDetails] = React.useState([]),
    [isItemRequested, setItemRequested] = React.useState(false),
    [shareUrl, setShareUrl] = React.useState(""),
    [requestSuccess, setRequestSuccess] = React.useState(false),
    [loading, setLoading] = React.useState(false),
    [extraInfo, setExtraInfo] = React.useState([]),
    titles = props.item.itemTitle,
    item = props.item,
    bundleId = props.item.bundle_id,
    classes = GetStyle(),
    userObj = {
      name: null,
      user_avatar: null,
      city: null,
      user_rating: 0,
    },
    [giveUser, setUser] = React.useState(userObj),
    getExtraInfo = (itemId) => {
      setLoading(true);
      itemApi.getItemExtraInfo(itemId, function (response) {
        if (response) {
          setExtraInfo(response);
          setLoading(false);
        }
      });
    };

  React.useEffect(() => {
    setLoading(true);
    itemApi.getFieldsByCategory("books", function (response) {
      if (response) {
        response.map((item) => {
          if (item.name === "book_condition") {
            setConditionFilterValues(item.selection_values);
          }
        });
      }
    });
    itemApi.getItemsDetails(bundleId, function (response) {
      if (response) {
        let isItemRequestedByuser = response.request ? true : false;
        setItemRequested(isItemRequestedByuser);
        setBundle(response.Items[0]);
        getExtraInfo(response.Items[0].item_id);

        setBundle(response.Items[0]);
        setShareUrl(
          BASE_URL +
          "get/details/books/" +
          utilHelper.slugify(response.Items[0].i_name) +
          "/" +
          bundleId
        );
        let newrItemDeatilsesponse = [];
        for (let i = 0; i < response.Items.length; i++) {
          newrItemDeatilsesponse[response.Items[i].item_id] = response.Items[i];
        }
        setItemDetails(newrItemDeatilsesponse);
        // if (response.bundle.can_request) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const req = urlParams.get("request");
        if (req) {
          handleDrawerOpen();
        }
        // }
      }
    });
  }, []);
  React.useEffect(() => {
    let userHash = props.item.user_hash;
    userApi.getUserSummaryByHash(userHash, function (response) {
      if (response) {
        let receiverData = { ...giveUser };
        receiverData.name = response[0].display_name;
        receiverData.city = response[0].u_city;
        receiverData.user_avatar = response[0].user_avatar;
        receiverData.user_rating = response[0].user_rating;
        setUser(receiverData);
      }
    });
  }, []);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' className={classes.getDetailsContainer}>
        <Box className={classes.detailsContainer}>
          <Box className={classes.details}>
            <ItemImagesContainer
              itemDetails={itemDetails}
              titles={titles}
              item={item}
              shareUrl={shareUrl}
              getExtraInfo={getExtraInfo}
              displayIndex={displayIndex}
              setDisplayIndex={setDisplayIndex}
            ></ItemImagesContainer>

            <Box className={classes.itemDetails}>
              <ItemDetails
                itemDetails={itemDetails}
                conditionFilterValues={conditionFilterValues}
                displayIndex={displayIndex}
                isItemRequested={isItemRequested}
                giveUser={giveUser}
                handleDrawerOpen={handleDrawerOpen}
                item={item}
              ></ItemDetails>
              <Box mt={5} style={{ marginTop: "1rem" }}>
                {item.b_status == "given" ? (
                  <Button variant='contained' color='primary' disabled={true}>
                    Gifted
                  </Button>
                ) : (
                    <>
                      <Button
                        variant='contained'
                        color='primary'
                        disabled={item.can_request == false || isItemRequested || openDrawer}
                        onClick={handleDrawerOpen}
                      >
                        Request
                      </Button>
                      {item.message && (
                        <Typography
                          variant='subtitle2'
                          component='div'
                          style={{
                            color: "#EB933C",
                            fontSize: "13px",
                            marginTop: "5px",
                          }}
                        >
                          {item.message}
                        </Typography>
                      )}
                    </>
                  )}
              </Box>
            </Box>
          </Box>

          {extraInfo.authorName && (
            <GoodReadDetails
              extraInfo={extraInfo}
              loading={loading}
            ></GoodReadDetails>
          )}
        </Box>
        <Box
          className={classes.bannerSection}
          width='20%'
          style={{ height: "600px" }}
        >
          <VerticalAds></VerticalAds>
        </Box>
      </Box>
      <Box width='100%'>
        <LargeRectangleAds></LargeRectangleAds>
      </Box>
      <Drawer anchor='right' open={openDrawer} variant='persistent'>
        <Request
          bundle_Id={props.item.bundle_id}
          detail={props}
          giverName={giveUser.name}
          closeModel={handleDrawerClose}
          setItemRequested={setItemRequested}
          setRequestStatus={setRequestSuccess}
        />
      </Drawer>
    </Box>
  );
};
export { GetDetails };
