import React from 'react';
import Box from '@material-ui/core/Box';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { appSettings } from '../../constants';
import { makeStyles } from '@material-ui/core/styles';
import { utilHelper } from '../../helpers';
import {
    FacebookIcon,
    TwitterIcon,
    EmailIcon,
    PinterestIcon,
    LinkedinIcon,
    FacebookShareButton,
    EmailShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    WhatsappIcon,
} from 'react-share';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Menu from "@material-ui/core/Menu/Menu";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

const GetStyle = makeStyles(theme => ({
    productDetailsLeft: {
        flex: '1 1',

        [theme.breakpoints.down('sm')]: {
            marginRight: '0',
            width: '100%',

            '& .MuiButton-root': {
                display: 'none'
            }
        },


    },

    dot: {
        fontSize: '16px',
        opacity: '0.5',
        color: theme.palette.primary.main,
        margin: '0 3px',
        '&:hover': {
            opacity: 1,
            transform: 'scale(1.4)'
        }
    },
    dotSelected: {
        fontSize: '16px',
        color: theme.palette.primary.main,
        margin: '0 3px'
    },

    imgContainer: {
        padding: '25px 0 0 !important'
    },


    thumbnails: {
        margin: '0',
        padding: '15px 0 !important'
    },

    flag: {
        visibility: 'hidden',
        alignSelf: 'flex-end',
        mixBlendMode: 'difference',
        color: '#fff',
        cursor: 'pointer',
        transition: 'visibility 0.3s ease'
    },

    thumb: {
        position: 'relative',
        top: '1rem',
        transition: '0.15s all ease',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },

    imageContainer: {
        width: '100%',
        height: '283px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: 'all 0.3s ease',
        backfaceVisibility: 'hidden',
        transform: 'translate3d(0, 0, 0)'
    },
    dotContainer: {
        alignSelf: 'center',
        opacity: 0,
        transition: 'opacity 0.3s ease',
        marginTop: '1rem',
        marginBottom: '1rem',
        textAlign: 'center'
    },
}));

const ItemImagesContainer = (props) => {
    const { itemDetails, item, titles, shareUrl, getExtraInfo, displayIndex, setDisplayIndex } = props,
        [anchorEl, setAnchorEl] = React.useState(null),
        classes = GetStyle(),
        openShare = Boolean(anchorEl),
        handleShareButtonClick = event => {
            setAnchorEl(event.currentTarget);
        },
        handleShareButtonClose = () => {
            setAnchorEl(null);
        },
        [imgClicked, setImgClicked] = React.useState(false),
        [imageChange, setImageChange] = React.useState('');

    const handleImageChange = (img) => {
        setImgClicked(true);
        setImageChange(img);
    }

    return (
        <Box display='flex' className={classes.productDetailsLeft} flexDirection='column' bgcolor='#ffffff'>
            <div className={classes.imgContainer}>

                <div className={classes.imageContainer} style={{ background: `url(${imgClicked === true ? utilHelper.encodeImageFileName(imageChange) : utilHelper.encodeImageFileName(item.itemImage[displayIndex])}) center /contain no-repeat #fff` }}>
                    <Box display='flex' justifyContent='space-between' padding='8px 8px 0 0 !important'>
                        {item.items_count > 1 ?
                            <svg width="77px" height="23px" viewBox="0 0 77 23" >
                                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="Product-listing-/-Verticle,Bundle" transform="translate(0.000000, -5.000000)">
                                        <g id="Group" transform="translate(0.000000, 5.000000)">
                                            <polygon id="Rectangle" fill="#22B11F" points="0 0 77 0 67 23 0 23"></polygon>
                                            <text id="SET-1/3" fontFamily="'Open Sans', sans-serif" fontSize="14" fontWeight="400" line-spacing="20" fill="#FFFFFF">
                                                <tspan x="4" y="17.3359375">SET </tspan>
                                                <tspan x="37.578125" y="17" fontWeight="600">{displayIndex + 1}</tspan>
                                                <tspan x="47.8203125" y="17">/{item.items_count}</tspan>
                                            </text>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            : <div style={{ visibility: 'hidden' }}></div>
                        }
                        {/* <Box width='24px' height='24px' display='flex'
                            alignItems='center' justifyContent='center'
                            bgcolor='#ffffff' borderRadius='50%'
                        //  style={{ visibility: 'hidden' }}
                        >
                            <FavoriteBorderIcon className={classes.customIcon} color='primary' />
                            <FavoriteIcon color='primary' className={classes.customIcon} />
                        </Box> */}
                    </Box>
                    {/* <div className={classes.prevNextContainer} style={{ visibility: item.items_count > 1 ? 'visible' : 'hidden' }}>
                                    <img src='/assets/images/previous.svg' onClick={clickLeft}></img>
                                    <img src='/assets/images/next.svg' onClick={clickRight}></img>
                                    <svg width="16px" height="16px" viewBox="0 0 16 16" onClick={clickLeft}>
                                        <g id="Archive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="SVGs" transform="translate(-393.000000, -59.000000)" fill="#ffffff">
                                                <g id="Icon/arrow_backward" transform="translate(393.000000, 59.000000)">
                                                    <polygon id="Icon-Path" transform="translate(8.272308, 8.000000) scale(-1, 1) translate(-8.272308, -8.000000) " points="6.15384615 2.46153846 4.85230769 3.76307692 9.08 8 4.85230769 12.2369231 6.15384615 13.5384615 11.6923077 8"></polygon>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                                    <svg width="16px" height="16px" viewBox="0 0 16 16" onClick={clickRight}>
                                        <g id="Archive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="SVGs" transform="translate(-418.000000, -59.000000)" fill="#ffffff">
                                                <g id="Icon/arrow_backward-Copy" transform="translate(418.000000, 59.000000)">
                                                    <polygon id="Icon-Path" points="6.15384615 2.46153846 4.85230769 3.76307692 9.08 8 4.85230769 12.2369231 6.15384615 13.5384615 11.6923077 8"></polygon>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div> */}
                    <Box display='flex' justifyContent='space-between' padding='0 15px !important' alignItems='center' bgcolor="#fff">
                        <svg width="16px" height="16px" viewBox="0 0 16 16" onClick={handleShareButtonClick} className={classes.flag}>
                            <g id="Archive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="SVGs" transform="translate(-397.000000, -148.000000)" fill="#ffffff">
                                    <g id="Icon-/-share-Copy-4" transform="translate(397.000000, 148.000000)">
                                        <path d="M9.41176471,1.88235294 L8.47058824,0 L0.941176471,0 L0.941176471,16 L2.82352941,16 L2.82352941,9.41176471 L7.52941176,9.41176471 L8.47058824,11.2941176 L15.0588235,11.2941176 L15.0588235,1.88235294 L9.41176471,1.88235294 Z M13.1764706,9.41176471 L9.41176471,9.41176471 L8.47058824,7.52941176 L2.82352941,7.52941176 L2.82352941,1.88235294 L7.52941176,1.88235294 L8.47058824,3.76470588 L13.1764706,3.76470588 L13.1764706,9.41176471 Z" id="Icon-Shape"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <svg width="16px" height="16px" viewBox="0 0 16 16" className={classes.flag} style={{ visibility: 'visible' }} onClick={handleShareButtonClick} >
                            <g id="Archive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="SVGs" transform="translate(-393.000000, -79.000000)" fill="#ffffff">
                                    <g id="Icon-/-share" transform="translate(393.000000, 79.000000)">
                                        <path d="M15,7.6 L9.55555556,2 L9.55555556,5.2 C4.11111111,6 1.77777778,10 1,14 C2.94444444,11.2 5.66666667,9.92 9.55555556,9.92 L9.55555556,13.2 L15,7.6 Z" id="Icon-Path"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </Box>
                </div>
                <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={openShare} onClose={handleShareButtonClose} >
                    <MenuItem onClick={handleShareButtonClose} style={{ minWidth: 'unset' }}>
                        <FacebookShareButton
                            url={shareUrl}
                            quote={"Share on Kindeeds and put the heart back into giving!"}
                            className="share-icons">
                            <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                    </MenuItem>
                    <MenuItem onClick={handleShareButtonClose} style={{ minWidth: 'unset' }}>
                        <TwitterShareButton
                            url={shareUrl}
                            title={"Share on Kindeeds and put the heart back into giving!"}
                            className="share-icons">
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                    </MenuItem>
                    <MenuItem onClick={handleShareButtonClose} style={{ minWidth: 'unset' }}>
                        <LinkedinShareButton
                            url={shareUrl}
                            title='Share on Kindeeds and put the heart back into giving!'
                            className="share-icons">
                            <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                    </MenuItem>
                    <MenuItem
                        onClick={handleShareButtonClose}
                        style={{ minWidth: "unset" }} >
                        <EmailShareButton
                            url={"bit.ly/getkind-eeds"}
                            subject={"Share on Kindeeds and put the heart back into giving!"}
                            body={`Get "${titles[0]}" in ${item.u_city} @ `} >
                            <EmailIcon size={32} round={true}></EmailIcon>
                        </EmailShareButton>
                    </MenuItem>
                </Menu>
                <section className={classes.dotContainer} style={{ opacity: 1 }}>
                    {item.items_count > 1 &&
                        titles && titles.map(function (itemLocal, index) {
                            return (<FiberManualRecordIcon color='primary' className={displayIndex === index ? classes.dotSelected : classes.dot} onMouseEnter={e => { setDisplayIndex(index); setImgClicked(false); getExtraInfo(item.id[index]) }}></FiberManualRecordIcon>)
                        })}
                </section>

            </div>

            <Box my={4} display='flex' className={classes.thumbnails}>
                {itemDetails[item.id[displayIndex]] && itemDetails[item.id[displayIndex]].images && itemDetails[item.id[displayIndex]].images.map(
                    function (image, index) {
                        image = image.startsWith('http') ? image : appSettings.MEDIA_URL + image;
                        image = image.replace('\\', '/');
                        image = image.replace('\\', '/');
                        return (
                            <div onMouseEnter={(e) => { handleImageChange(image) }}>
                                <img src={image} width='58px' height='47px' className={classes.thumb} ></img>
                            </div>
                        );
                    })
                }
            </Box>
        </Box>
    );
}
export { ItemImagesContainer };