import {apiHelper} from '../helpers';
import {httpMethodTypes} from "../constants/httpMethodTypes";
import {appSettings} from "../constants/appSettings";

import axios from 'axios'

export const genericApi = {
    getCities,
    getStates,
    getCountries,
    getCitiesByGeoLocations
};

async function callGetMethod(url, requestDataBlk, successCallBack, errorCallBack){
    try {
        await axios.get(url, requestDataBlk).then(function (response) {
            successCallBack(response.data);
        }).catch(function (error) {
            if(errorCallBack){ errorCallBack({...error}) }else{ return; }   
        })
    } catch (error) {
        alert(error);
        if(errorCallBack){ errorCallBack({...error}) }
    }
}

async function getCities(requestDataBlk, successCallBack, errorCallBack) {
    await callGetMethod ('api/generic/cities/', requestDataBlk, successCallBack, errorCallBack);
}

async function getStates(requestDataBlk, successCallBack, errorCallBack) {
    await callGetMethod ('api/generic/states/', requestDataBlk, successCallBack, errorCallBack);
}

async function getCountries(requestDataBlk, successCallBack, errorCallBack) {
    await callGetMethod ('api/generic/countries/', requestDataBlk, successCallBack, errorCallBack);
}

async function getCitiesByGeoLocations(query, successCallBack, errorCallBack) {
    const url = '' + `api/generic/get-locations/?q=${query}`;
    await callGetMethod (url, null, successCallBack, errorCallBack);
}
