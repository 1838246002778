import React from 'react';
import { RegisterForm } from './forms/RegisterForm';
import { CommonLeftBlock } from './commonLeftBlock';
import { Grid } from '@material-ui/core';
import { navigate } from '@reach/router';
import tabContainerStyles from './../../../styles/tabContainer.styles'
import { makeStyles } from "@material-ui/core/styles";
import { useStoreState } from './../../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '-1.5rem',
  }
}));


const Register = () => {

  const classesTabContainer = tabContainerStyles(),
    classes = useStyles(),
    isLoggedIn = useStoreState(state => state.auth.isLoggedIn);

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classesTabContainer.tabContainer}></div>
      <React.Fragment>

        <Grid container direction="row">
          <CommonLeftBlock></CommonLeftBlock>
          <Grid item sm={12} xs={12} md={6} style={{ backgroundColor: 'rgba(73,73,73,7%)' }} alignItems="center" justify="center" className={classes.rightblock}>
            <div style={{ maxWidth: '' }}><RegisterForm /></div>
          </Grid>

        </Grid>
      </React.Fragment>
    </div>
  )
};
export { Register };
