import React from 'react';
import { useEffect } from 'react';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { itemApi } from './../../api/itemApi';
import LoadingIcon from './../ui/loadingIcon';

const GetStyle = makeStyles(theme => ({
    goodReadsDetails: {
        top: '-2%',
        position: 'relative',
        backgroundColor: '#f9f9f9',
        padding: '15px !important',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    hr: {
        borderTop: '1px solid #dadada !important'
    },
    detailsBtn: {
        display: 'flex'
    },
    seemoreBtn: {
        position: 'relative',
        top: '-27px',
        left: '-10px',
        backgroundColor: 'white',
        color: '#22b11f',
        padding: '8px 10px',
        fontSize: '16px',
        fontWeight: '600'
    }
}));

const GoodReadDetails = (props) => {
    const { extraInfo, loading } = props,
        classes = GetStyle();
    return (
        <>
            <hr className={classes.hr} />

            <Box className={classes.detailsBtn}>
                <div className={classes.seemoreBtn}>
                    Details <span style={{ fontSize: '12px', fontWeight: '100', color: '#59461B', paddingLeft: '5px' }}>Powered by</span> <img alt='goodreads' height='16px'
                        src={extraInfo.powered_by_logo} style={{ paddingLeft: '5px' }} />
                </div>
            </Box>


            {loading ? <LoadingIcon loadingMsg="Loading ..." /> :
                <>
                    <Box display='flex' className={classes.goodReadsDetails}>
                        <Grid item sm={6} xs={12}>

                            {extraInfo.authorName &&
                                <Box display='flex' alignItems='baseline' marginTop='5px'>
                                    <Typography variant='h5' component='div' style={{ width: '105px', fontWeight: '600', fontSize: '14px' }}>Author</Typography>
                                    <Typography variant='subtitle1' component='div' style={{ width: '170px', padding: '0 5px 0 15px', fontSize: '14px' }}>{extraInfo.authorName}</Typography>
                                </Box>
                            }

                            {extraInfo.average_rating &&
                                <Box display='flex' alignItems='center' marginTop='5px'>
                                    <Typography variant='h5' component='div' style={{ width: '105px', fontWeight: '600', fontSize: '14px' }}>Rating</Typography>

                                    <Typography variant='subtitle1' component='div' style={{ width: '170px', padding: '0 5px 0 15px', fontSize: '14px' }}>
                                        {extraInfo.average_rating}
                                        {extraInfo.ratings_count && <span>{" (" + extraInfo.ratings_count + ")"}</span>}
                                    </Typography>

                                </Box>
                            }

                            {extraInfo.publisher &&
                                <Box display='flex' alignItems='center' marginTop='5px'>
                                    <Typography variant='h5' component='div' style={{ width: '105px', fontWeight: '600', fontSize: '14px' }}>Publisher</Typography>
                                    <Typography variant='subtitle1' component='div' style={{ width: '170px', padding: '0 5px 0 15px', fontSize: '14px' }}>{extraInfo.publisher}</Typography>
                                </Box>
                            }

                            {extraInfo.publication_year &&
                                <Box display='flex' alignItems='center' marginTop='5px'>
                                    <Typography variant='h5' component='div' style={{ width: '105px', fontWeight: '600', fontSize: '14px' }}>Publication Date</Typography>

                                    <Typography variant='subtitle1' component='div' style={{ width: '170px', padding: '0 5px 0 15px', fontSize: '14px' }}>
                                        {extraInfo.publication_month &&
                                            <span>{extraInfo.publication_month}</span>}
                                        {extraInfo.publication_day &&
                                            <span>{", " + extraInfo.publication_day + " "}</span>}
                                        <span>{extraInfo.publication_year}</span>
                                    </Typography>
                                </Box>
                            }


                        </Grid>
                        <Grid item sm={6} xs={12}>

                            {extraInfo.language_code &&
                                <Box display='flex' alignItems='center' marginTop='5px'>
                                    <Typography variant='h5' component='div' style={{ width: '105px', fontWeight: '600', fontSize: '14px' }}>Language</Typography>
                                    <Typography variant='subtitle1' component='div' style={{ width: '170px', padding: '0 5px 0 15px', fontSize: '14px' }}>{extraInfo.language_code}</Typography>
                                </Box>
                            }
                            {extraInfo.isbn &&
                                <Box display='flex' alignItems='center' marginTop='5px'>
                                    <Typography variant='h5' component='div' style={{ width: '105px', fontWeight: '600', fontSize: '14px' }}>ISBN</Typography>
                                    <Typography variant='subtitle1' component='div' style={{ width: '170px', padding: '0 5px 0 15px', fontSize: '14px' }}>{extraInfo.isbn}</Typography>
                                </Box>
                            }
                            {extraInfo.isbn13 &&
                                <Box display='flex' alignItems='center' marginTop='5px'>
                                    <Typography variant='h5' component='div' style={{ width: '105px', fontWeight: '600', fontSize: '14px' }}>ISBN 13</Typography>
                                    <Typography variant='subtitle1' component='div' style={{ width: '170px', padding: '0 5px 0 15px', fontSize: '14px' }}>{extraInfo.isbn13}</Typography>
                                </Box>
                            }
                        </Grid>
                    </Box>


                </>
            }
        </>
    );
}
export { GoodReadDetails };