import React from 'react';
import Grid from '@material-ui/core/Grid';

const AppLoader = ({ message }) => {
  return (
      <Grid span={12} align="middle" style={{ textAlign: 'center' }}>
        {message && (
            <section id="intro1">
            <div className="intro-container1">
                <div className=""
                     style={{paddingTop: "20%",width: "85px",marginLeft: "auto",marginRight: "auto",textAlign: "center"}}>
                    <img src="/assets/images/loading-cgk.gif" width="50"/><br/><span style={{fontWeight:"bold",fontSize:"10px"}}>LOADING DATA...</span></div>
            </div>
        </section>
        )}
      </Grid>
  );
};
export default AppLoader;