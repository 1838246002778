import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography";
import CustomStyles from "./../../../../styles/CustomStyles.styles";
import Grid from "@material-ui/core/Grid";
import throttle from "lodash/throttle";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import CloseFormIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { shippingApi } from "./../../../../api/shippingApi";
import { itemApi } from "./../../../../api/itemApi";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import { userApi, genericApi } from "../../../../api";
import { useStoreActions } from "./../../../../hooks";

const LoginStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  container: {
    backgroundColor: "#ffffff",
    padding: "2rem 80px",
  },
  card: {
    boxSizing: "border-box",
    padding: "15px",
    display: "flex",
    flexDirection: "row",
  },
  rectangle: {
    backgroundColor: "rgba(34,177,31,0.07)",
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  formControl: {
    width: "75%",
  },
  cover: {
    width: "66px",
    height: "66px",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "1rem",
  },
  or: {
    display: "flex",
    margin: "2rem 0",

    "&::after": {
      content: `''`,
      flex: "1 1",
      borderBottom: "1px solid #E7E7E7",
      margin: "auto 2rem",
    },

    "&::before": {
      content: `''`,
      flex: "1 1",
      borderBottom: "1px solid #E7E7E7",
      margin: "auto 2rem",
    },
  },
  iconContainer: {
    background: "#263C68",
    height: "40px",
    margin: "0 10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
}));

let previousUserData = {
  fullName: null,
  displayName: null,
  city: null,
  state: null,
  country: null,
  area: null,
  userAvatar: null,
  phone: null,
  email: "",
},
  previousData = {};

export default function PrefrencesForm(props) {
  const classes = CustomStyles(),
    localClasses = LoginStyles(),
    [city, setCity] = React.useState(null),
    [user, setUser] = React.useState(previousUserData),
    [shippingPreferences, setShipping] = useState([]),
    [shippingValue, setShipValue] = useState([]),
    [givePreferences, setGive] = useState([]),
    [giveValue, setGiveValue] = useState([]),
    [deliveryPreferences, setDelivery] = useState([]),
    [deliveryValue, setDeliveryValue] = useState([]),
    [savePref, setSavePref] = useState(false),
    [isUserPref, setUserPref] = useState(true),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    [given, setGiven] = useState(),
    [del_pref, setDel_pref] = useState(),
    [shipping_pref, setShipping_pref] = useState(),
    handleCancel = () => {
      setUserPref(true);
      setGiveValue(previousData.givePref);
      setDeliveryValue(previousData.deliveryPref);
      setShipValue(previousData.shipPref);

      if (previousUserData.city) {
        let curCity = `${previousUserData.city}, ${previousUserData.state}, ${previousUserData.country}`;
        setCity(curCity);

        props.updatePref({
          city: curCity,
        });

        let newPref = { ...props.preference };
        newPref.city = curCity;
        props.setPrefrences(newPref);
        let userObj = {
          ...user,
          city: curCity,
        };
        setUser(userObj);
      }
    },
    updateUserPreferences = () => {
      // Update user preferences
      if (!city) {
        showAlert({
          message: "Please enter city.",
          open: true,
          severity: "error",
        });
      }
      if (deliveryValue == null) {
        showAlert({
          message: "Please select shipping.",
          open: true,
          severity: "error",
        });
      }
      if (city && deliveryValue) {
        let newPref = { ...props.preference };
        previousData.deliveryPref = deliveryValue;
        previousData.shipPref = shippingValue;
        newPref.delivery = deliveryValue.description;
        newPref.shipping = shippingValue.description;
        newPref.delivery_id = deliveryValue.preference;
        newPref.shipping_id = shippingValue.preference;
        newPref.city = city;
        setDel_pref(deliveryValue.description);
        setShipping_pref(shippingValue.description);
        setDeliveryValue(deliveryValue);
        setShipValue(shippingValue);

        previousData.givePref = giveValue;
        newPref.givePreference = giveValue.description;
        newPref.givePreference_id = giveValue.preference;
        setGiven(giveValue.description);
        setGiveValue(giveValue);

        setUserPref(true);
        props.setPrefrences(newPref);

        if (savePref) {
          shippingApi.updateUserShippingPreference({
            ship_pref: shippingValue && shippingValue.description,
            ship_pref_id: shippingValue && shippingValue.preference,
            delivery_pref: shippingValue && deliveryValue.description,
            delivery_pref_id: shippingValue && deliveryValue.preference,
          });

          itemApi.updateUserGivePreference(
            {
              give_pref: giveValue && giveValue.description,
              give_pref_id: giveValue && giveValue.preference,
              item_listed_max_days: previousData.numberOfDays,
            },
            function (resp) {
              // Update Give preference
              if (resp) {
                showAlert({
                  message: "Preferences updated successfully",
                  open: true,
                  severity: "success",
                });
              }
            },
            function () {
              showAlert({
                message: "Failed to update preferences",
                open: true,
                severity: "error",
              });
            }
          );

          const location = user.city.split(",");

          userApi.updateLoggedInUserProfile(
            {
              display_name: previousUserData.displayName || null,
              full_name: previousUserData.fullName || null,
              u_city: location[0],
              area: previousUserData.area || "",
              user_avatar: previousUserData.userAvatar,
              mobile: previousUserData.phone || null,
              u_state: location[1] || null,
              u_country: location[2] || null,
              country_code: previousUserData.countryCode,
              zipcode: previousUserData.zipcode || "",
            },
            function (resp) { }
          );
        }
      }
    };

  let deliveryValuesList = [],
    shippingValueslist = [],
    givevalueList = [];

  //    const emptyPref = {
  //        delivery: null,
  //        shipping: null,
  //        givePreference: null,
  //        saveToMyProfile:false
  //    }
  //    const [pref, setPref] = React.useState(emptyPref);

  const handleEditPreferenceClick = (e) => {
    e.preventDefault();
    setUserPref(false);
  };

  useEffect(() => {
    userApi.getLoggedInUserDetails(function (resp) {
      // Get the loggedIn user details
      if (resp) {
        previousUserData.fullName = resp[0].full_name;
        previousUserData.displayName = resp[0].display_name;
        previousUserData.city = resp[0].u_city;
        previousUserData.state = resp[0].u_state;
        previousUserData.country = resp[0].u_country;
        previousUserData.area = resp[0].area;
        previousUserData.email = resp[0].email;
        previousUserData.phone = resp[0].mobile || null;
        previousUserData.userAvatar = resp[0].user_avatar;
        previousUserData.countryCode = resp[0].country_code;

        if (previousUserData.city) {
          let curCity = `${previousUserData.city}, ${previousUserData.state}, ${previousUserData.country}`;

          setCity(curCity);
          props.updatePref({ city: curCity });

          let newPref = { ...props.preference };
          newPref.city = curCity;
          props.setPrefrences(newPref);

          let userObj = { ...user, city: curCity };
          setUser(userObj);
        } else {
          setUserPref(false);
        }
      }
    });
  }, []);

  const [cities, setCities] = React.useState([]),
    [inputValue, setInputValue] = React.useState(""),
    [options, setOptions] = React.useState([]),
    fetch = React.useMemo(
      () =>
        throttle((input, callback) => {
          if (!input) {
            return callback([]);
          }
          if (input.input.length < 4) {
            return;
          }

          genericApi.getCitiesByGeoLocations(input.input, function (response) {
            setCities(response);
            return callback(response);
          });
        }, 200),
      []
    );

  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }
    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);
      }
    });
    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const handleSelectChange = (newValue) => {
    if (newValue == null) {
      return;
    } else {
      let newUser = { ...user };
      setCity(newValue.target.innerText);
      newUser.city = newValue.target.innerText;
      setUser(newUser);
      props.updatePref({ city: newValue.target.innerText });
      let newPref = { ...props.preference };
      newPref["city"] = newValue.target.innerText;
      props.setPrefrences(newPref);
      //             let codeValue = 'us';
      //             if(newValue.target.attributes["countryCode"]){
      //                 codeValue = newValue.target.attributes["countryCode"].value.toLowerCase();
      //             }
      //            updateCountryCode(codeValue);
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    shippingApi.getShippingPreference(function (resp) {
      if (resp) {
        shippingValueslist = resp;
        setShipping(resp);
      }
    });

    shippingApi.getDeliveryPreference(function (resp) {
      if (resp) {
        deliveryValuesList = resp;
        setDelivery(resp);
      }
    });

    itemApi.getAllGivePreferenceList(function (response) {
      if (response) {
        givevalueList = response;
        setGive(response);
      }
      // get the logged In user preferences
      itemApi.getUserGivePreference(function (giveResponse) {
        if (giveResponse) {
          if (giveResponse.give_pref && givevalueList) {
            previousData.givePref = givevalueList.find(
              (x) => x.preference === giveResponse.give_pref_id
            );
            setGiveValue(previousData.givePref);
            previousData.numberOfDays = giveResponse.item_listed_max_days;
          }
          setGiven(giveResponse.give_pref);

          shippingApi.getuserShippingAndDeliveryPreferences(function (resp) {
            if (resp) {
              if (resp.delivery_pref && deliveryValuesList) {
                previousData.deliveryPref = deliveryValuesList.find(
                  (x) => x.preference === resp.delivery_pref_id
                );
                setDeliveryValue(previousData.deliveryPref);
              }

              if (resp.ship_pref && shippingValueslist) {
                previousData.shipPref = shippingValueslist.find(
                  (x) => x.preference === resp.ship_pref_id
                );
                setShipValue(previousData.shipPref);
              }
              setDel_pref(resp.delivery_pref);
              setShipping_pref(resp.ship_pref);

              let newPref = { ...props.preference };

              newPref.delivery = resp.delivery_pref;
              newPref.delivery_id = resp.delivery_pref_id;
              newPref.shipping = resp.ship_pref;
              newPref.shipping_id = resp.ship_pref_id;
              newPref.givePreference = giveResponse.give_pref;
              newPref.givePreference_id = giveResponse.give_pref_id;
              newPref.item_listed_max_days = giveResponse.item_listed_max_days;

              props.setPrefrences(newPref);
            }
          });
        }
      });
    });
  }, []);

  return (
    <>
      {isUserPref === true ? (
        <section className={localClasses.rectangle}>
          <Box style={{ paddingBottom: "25px" }}>
            <Typography
              variant='h3'
              component='div'
              style={{ textAlign: "unset", float: "left" }}>
              Your Current Preferences :
            </Typography>
            <IconButton
              style={{ float: "right" }}
              aria-label='Edit'
              onClick={handleEditPreferenceClick}>
              <EditIcon />
            </IconButton>
          </Box>
          <Table size='small'>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant='h4' component='div'>
                    City
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2' component='div'>
                    {user && user.city ? `${user.city}` : ""}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    variant='h4'
                    component='div'
                    style={{ paddingTop: "15px" }}>
                    Shipping
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant='subtitle2'
                    component='div'
                    style={{ paddingTop: "15px" }}>
                    {del_pref}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    variant='h4'
                    component='div'
                    style={{ paddingTop: "15px" }}>
                    Give within
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant='subtitle2'
                    component='div'
                    style={{ paddingTop: "15px" }}>
                    {shipping_pref}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    variant='h4'
                    component='div'
                    style={{ paddingTop: "15px" }}
                  >
                    Give to
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant='subtitle2'
                    component='div'
                    style={{ paddingTop: "15px" }}>
                    {given}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </section>
      ) : (
          <section className={localClasses.rectangle}>
            <Box pb={3} display='flex' justifyContent='space-between'>
              <Typography
                variant='h3'
                component='div'
                style={{ textAlign: "unset", float: "left" }}
              >
                Your Current Preferences :
            </Typography>
              <IconButton aria-label='Close' onClick={handleCancel}>
                <CloseFormIcon />
              </IconButton>
            </Box>
            <Autocomplete
              id='city-search-id'
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option
              }
              filterOptions={(x) => x}
              options={options}
              autoComplete='off'
              includeInputInList
              disableOpenOnFocus
              // closeIcon={<CloseIcon fontSize='small' />}
              loading={true}
              loadingText='Loading'
              onChange={handleSelectChange}
              value={city}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='City'
                  fullWidth
                  name='city'
                  InputProps={{ ...params.InputProps }}
                  margin='normal'
                  onChange={handleChange}
                />
              )}
              renderOption={(option) => {
                return (
                  <Grid container alignItems='center'>
                    <Grid item xs>
                      <Typography variant='body2' color='textSecondary'>
                        <span countryCode={option.country_code}>
                          {option.city}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
              style={{ width: "100%" }}
            />
            <br />
            {/* <Typography variant='h3' component='div' style={{  marginBottom: '1rem'  }}>Shipping Preference</Typography> */}
            <Autocomplete
              options={deliveryPreferences}
              getOptionDisabled={(option) => option.status === 0}
              getOptionLabel={(option) => option.description}
              onChange={(e, value) => {
                setDeliveryValue(value);
                props.updatePref({ delivery: value });
              }}
              value={deliveryValue && deliveryValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Shipping'
                  margin='normal'
                  style={{ width: "100%" }}
                />
              )}
            />
            <br />
            <Autocomplete
              options={shippingPreferences}
              getOptionLabel={(option) => option.description}
              getOptionDisabled={(option) => option.status === 0}
              onChange={(e, value) => {
                setShipValue(value);
                props.updatePref({ shipping: value });
              }}
              value={shippingValue && shippingValue}
              id='shipping'
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Give within'
                  margin='normal'
                  style={{ width: "100%" }}
                />
              )}
            />
            <br />
            {/* <Typography variant='h3' component='div' style={{ marginBottom: '1rem' }}>Give Preference</Typography> */}
            <Autocomplete
              options={givePreferences}
              getOptionLabel={(option) => option.description}
              getOptionDisabled={(option) => option.status == 0}
              onChange={(e, value) => {
                setGiveValue(value);
                props.updatePref({ givePreference: value });
              }}
              value={giveValue && giveValue}
              id='give'
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.autocomplete}
                  label='Give to'
                  margin='normal'
                  style={{ width: "100%" }}
                />
              )}
            />
            <br />

            <Box display='flex' justifyContent='space-between'>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ padding: "9px" }}
                    onClick={(e, value) => {
                      setSavePref(e.target.checked);
                      // props.updatePref({savePref:e.target.checked})
                      let newPreference = { ...props.preference };
                      newPreference.saveToMyProfile = e.target.checked;
                      props.setPrefrences(newPreference);
                    }}
                  />
                }
                style={{ marginLeft: "-10px" }}
                label='Save my preferences to my profile'
              />
              <IconButton
                style={{ marginRight: "8px" }}
                onClick={updateUserPreferences}>
                <DoneOutlinedIcon />
              </IconButton>
            </Box>
          </section>
        )}
    </>
  );
}
