import { apiHelper } from '../helpers';
import { httpMethodTypes } from "../constants/httpMethodTypes";
import { appSettings } from "../constants/appSettings";

import axios from 'axios'
// const BASE_URL =  appSettings.BaseURL;

async function checkSession(userObject, updateUserObject, check, successCallBack, errorCallBack) {
    if (check || (userObject && userObject.u_status != 2)) {
        await axios.get('/api/user/session/check').then(function (response, statusCode) {
            if (response.data.detail == 'Invalid' && userObject) {
                // alert("checkSession = "+response.data.detail );
                // alert(userObject);
                updateUserObject({ user: nul });
            } else if (response.data.detail == 'Not verified' && (userObject && userObject.u_status)) {
                let user = { ...userObject, u_status: 1 }
                updateUserObject({ user: user });
            }
            else if (response.data.detail == 'Verified' && userObject.u_status != 2) {
                let user = { ...userObject, u_status: 2 }
                updateUserObject({ user: user });
            }
            if (successCallBack) successCallBack(response.data.detail);

        }).catch(function (error, statusCode) {
            if (errorCallBack) { errorCallBack({ ...error }) }
        })
    }
}

async function login(requestDataBlk, successCallBack, errorCallBack) {
    await callPostMethod('api/user/login/', requestDataBlk, successCallBack, errorCallBack);
}

async function socialLogin(requestDataBlk, successCallBack, errorCallBack) {
    await callPostMethod('api/user/social/', requestDataBlk, successCallBack, errorCallBack);
}

async function contactUs(requestDataBlk, successCallBack, errorCallBack) {
    await callPostMethod('api/user/contact-us/', requestDataBlk, successCallBack, errorCallBack);
}
async function logout(successCallBack) {
    await axios.post('api/user/logout/').then(function (response) {
        setTimeout(() => {
            successCallBack(response.data);
        }, 2000);
    }).catch(function (error) {
    })
}

async function userSignup(requestDataBlk, successCallBack, errorCallBack) {
    await callPostMethod('api/user/signup/', requestDataBlk, successCallBack, errorCallBack);
}

async function charitySignup(requestDataBlk, successCallBack, errorCallBack) {
    await callPostMethod('api/user/user-signup/', requestDataBlk, successCallBack, errorCallBack);
}

async function corporateSignup(requestDataBlk, successCallBack, errorCallBack) {
    await callPostMethod('api/user/signup/corporate', requestDataBlk, successCallBack, errorCallBack);
}

// function verifyPassword(requestDataBlk,successCallBack) {
//     const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.POST, true, requestDataBlk)
//     const url = BASE_URL + 'api/auth/password-check/';
//     apiHelper.sendRequest(url,requestOptions,successCallBack);
// }

async function resetPassword(requestDataBlk, successCallBack, errorCallBack) {
    await callPostMethod('api/user/password/reset/', requestDataBlk, successCallBack, errorCallBack);
}


async function setNewPassword(requestDataBlk, uid, token, successCallBack, errorCallBack) {
    await callPostMethod(`api/user/password/reset/confirm/${uid}/${token}/ `, requestDataBlk, successCallBack, errorCallBack);
}

async function updatePassword(requestDataBlk, successCallBack, errorCallBack) {
    await callPostMethod('api/user/password/change/', requestDataBlk, successCallBack, errorCallBack);
}


async function signupConfirmed(requestDataBlk, successCallBack, errorCallBack) {
    await callPostMethod('api/auth/signup/confirmed/', requestDataBlk, successCallBack, errorCallBack);
}

async function accountConfirmEmail(requestDataBlk, successCallBack, errorCallBack) {
    await callPostMethod('api/auth/account-confirm-email/', requestDataBlk, successCallBack, errorCallBack);
}

async function callPostMethod(url, requestDataBlk, successCallBack, errorCallBack) {
    try {
        await axios.post(url, requestDataBlk).then(function (response) {
            successCallBack(response.data);
        }).catch(function (error) {
            if (errorCallBack) { errorCallBack({ ...error }) } else { return; }
        })
    } catch (error) {
        if (errorCallBack) { errorCallBack({ ...error }) }
    }
}


export const securityApi = {
    checkSession,
    socialLogin,
    login,
    logout,
    userSignup,
    charitySignup,
    corporateSignup,
    signupConfirmed,
    accountConfirmEmail,
    resetPassword,
    setNewPassword,
    updatePassword,
    contactUs
};