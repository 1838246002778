import { makeStyles } from "@material-ui/core/styles";

const AccountSettingsStyles = makeStyles((theme) => ({
  advertisement: {
    width: "25%",
    backgroundColor: theme.palette.secondary.secondary,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  container: {
    width: "75%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",

      "& #simple-tabpanel-4": {
        padding: "0 1rem",

        "& .MuiBox-root": {
          width: "100%",
        },
      },
    },
  },
  aboutMe: {
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      padding: "1rem",
    },

    "& aside": {
      flex: "1 1",
      paddingRight: "10%",

      [theme.breakpoints.down("sm")]: {
        paddingRight: "0",
      },

      [theme.breakpoints.only("md")]: {
        paddingRight: "3%",
      },
    },
  },
  avatarThumbnails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "-20px",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      margin: "1rem 0",
    },
  },
  imageUploadAside: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  dpContainer: {
    height: "166px",
    width: "166px",
    position: "relative",
    overflow: "unset",
    marginTop: "-20px",

    [theme.breakpoints.down("sm")]: {
      margin: "1rem auto",
    },
  },
  photoIconContainer: {
    padding: "5px",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    bottom: "0",
    borderRadius: "50%",
    right: "10px",
  },
  avatar: {
    margin: "8px",
    width: "40px",
    border: "1px solid rgb(204, 204, 204)",
    height: "40px",
    cursor: "pointer",
    transition: "0.15s all ease",
    borderRadius: "50%",

    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  deliveryAddress: {
    boxSizing: "border-box",
    height: "110px",
    width: "165px",
    border: "1px solid #717171",
    borderRadius: "2px",
    margin: "0.5rem",
    padding: "10px",
  },
  customIcon: {
    fontSize: "1rem",
    fill: "#8D8D8D",

    "&:hover": {
      fill: "#8D8D8D",
    },
  },
  address: {
    marginTop: "2rem",
    fontWeight: 600,

    [theme.breakpoints.down("sm")]: {
      margin: "2rem 1rem",
    },
  },
  addPickup: {
    boxSizing: "border-box",
    height: "110px",
    width: "165px",
    border: "1px solid #717171",
    borderRadius: "2px",
    margin: "0.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      border: "2px solid #2D72CC",
    },
  },
  addPickupAddress: {
    boxSizing: "border-box",
    border: "2px solid #2D72CC",
    borderRadius: "2px",
    padding: "1rem 2rem",
    margin: "1rem 10% 0 0",

    "& .MuiTextField-root": {
      marginTop: "1rem",
    },

    "& .MuiFormControlLabel-root": {
      marginTop: "1rem",
    },

    [theme.breakpoints.down("sm")]: {
      margin: "2rem 1rem",

      "& .MuiBox-root": {
        margin: 0,
        display: "flex",
        flexDirection: "column",
      },
    },
  },
  addressType: {
    display: "flex",
    flexDirection: "row",
    margin: "0.5rem 0",
  },
  preferencesContainer: {
    display: "flex",
    marginTop: "2rem",

    [theme.breakpoints.down("sm")]: {
      margin: "1rem",
      flexDirection: "column",
    },
  },
  preferences: {
    display: "flex",
    flex: "1 1",
    flexDirection: "column",
    marginRight: "15%",

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,

      "& .MuiTypography-h2": {
        display: "none",
      },
    },
  },
  communications: {
    display: "flex",
    flexDirection: "column",
    margin: "2rem 10% 0 0",

    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },

    "& .MuiTypography-subtitle2": {
      flexBasis: "30%",
    },

    [theme.breakpoints.down("sm")]: {
      margin: "1rem",
      flexDirection: "column",

      "& .MuiTypography-subtitle2": {
        flexBasis: "50%",
      },

      "& .MuiBox-root": {
        margin: "1rem 0",
      },
    },
  },
  buttonContainer: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "row-reverse",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse !important",
      marginTop: "1rem !important",

      "& .MuiButton-root": {
        flex: "1 1",
      },
    },
  },
  passwordRulesDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  passwordRuleIcon: {
    width: "1.25 rem",
  },
  passwordRuleText: {
    marginLeft: "5px",
  },
}));

export default AccountSettingsStyles;
