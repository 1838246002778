import React, { useEffect } from "react";
import { Box, CircularProgress, withStyles, makeStyles, Button, Typography, IconButton, Menu, MenuItem, Card, CardHeader, CardContent, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { itemApi } from "../../../api";
import { appSettings } from "../../../constants";
import LoadingIcon from "../../ui/loadingIcon";
import { navigate } from "@reach/router";
import Dialog from "@material-ui/core/Dialog/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions/DialogActions";
import { HtmlTooltip } from '../../../styles/addressCommon.styles';

const LoginStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  container: {
    backgroundColor: "#ffffff",
    padding: "2rem 80px",

    [theme.breakpoints.down("sm")]: {
      padding: "1rem",

      "& .MuiGrid-container": {
        flexDirection: "column",

        "& .MuiGrid-item": {
          flexBasis: "100%",
          maxWidth: "100%",
          padding: "0 !important",
          margin: "1rem 0",
        },
      },
    },
  },
  card: {
    boxSizing: "border-box",
    padding: "15px",
    display: "flex",
    flexDirection: "row",
  },
  rectangle: {
    backgroundColor: "rgba(127,204,40,0.03)",
    display: "flex",
    flexDirection: "column",
    padding: "2rem",

    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      margin: "1rem 0",
    },
  },
  formControl: {
    width: "75%",
  },
  cover: {
    width: "66px",
    height: "66px",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "1rem",

    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: "1rem 0",
    },
  },
  or: {
    display: "flex",
    margin: "2rem 0",

    "&::after": {
      content: `''`,
      flex: "1 1",
      borderBottom: "1px solid #E7E7E7",
      margin: "auto 2rem",
    },

    "&::before": {
      content: `''`,
      flex: "1 1",
      borderBottom: "1px solid #E7E7E7",
      margin: "auto 2rem",
    },
  },
  iconContainer: {
    background: "#263C68",
    height: "40px",
    margin: "0 10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  buttonContainer: {
    // marginTop: '2rem',
    display: "flex",
    flexDirection: "row-reverse",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  fixedButtonSection: {
    backgroundColor: "#fff",
    bottom: 0,
    position: "fixed",
    zIndex: 2,
    padding: "1rem 0 !important",
    width: "100%",
    display: "none",
    alignItems: "center",
    margin: "0 -1rem",

    "& .MuiButton-root": {
      margin: "0 10px",
      flex: "1 1",
    },

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",

      "& .MuiButton-root": {
        margin: "0 0",
        flex: "1 1",
        minWidth: '105px'
      },
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const progressStyle = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
}));

const mobileCSS = makeStyles((theme) => ({
  guideLinesContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block'
    }
  },
  guideLinesWrapper: {
    color: "rgb(13, 60, 97)",
    backgroundColor: "rgb(232, 244, 253)",
    maxWidth: "220px",
    border: "1px solid #dadde9",
    fontSize: "12px",
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 400,
    lineHeight: "1.43",
    padding: '10px',
    margin: "auto",
    '& ul': {
      padding: '0px',
      listStyleType: 'disc',
    }
  },
  guideLineMenu: {
    '& .MuiList-padding': {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  },
  hide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function PreviewComponent(props) {
  const classes = LoginStyles(),
    [confirmModel, setConfirmModel] = React.useState(false),
    handleConfrimOpen = () => {
      setConfirmModel(true);
    },
    handleConfirmClose = () => {
      setConfirmModel(false);
    },
    [anchorEl, setAnchorEl] = React.useState(null),
    open = Boolean(anchorEl),
    handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    },
    handleClose = () => {
      setAnchorEl(null);
    },
    deleteBookFromList = (bundleId, ItemId) => {
      handleConfirmClose();
      if (bundleId && ItemId) {
        itemApi.deleteItemFormBundle(bundleId, ItemId, function (resp) {
          if (resp) {
            handleClose();
            if (resp.items_count < 1) {
              navigate("/give/books", { replace: true });
            } else {
              itemApi.getItemsByBundle(bundleId, function (response) {
                if (response) {
                  props.setPreviewItemList(response.Items);
                }
              });
            }
          }
        });
      }
    },
    item = props.item;

  const { done } = props;

  return (
    <Card className={classes.card} style={{ marginBottom: "10px" }}>
      <img
        className={classes.cover}
        src={
          item.images[0].startsWith("https")
            ? item.images[0]
            : appSettings.MEDIA_URL + item.images[0]
        }
        alt='Live from space album cover'
      />
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <CardHeader
          action={
            <div>
              {!done && (
                <IconButton aria-label='settings' onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              )}
              <Menu
                id='long-menu'
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: 100,
                  },
                }}
              >
                <MenuItem
                  onClick={() => props.editBook(item.bundle_id, item.item_id)}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleConfrimOpen();
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </div>
          }
          style={{
            padding: "0 0 0 16px",
            alignItems: "end",
          }}
          title={
            <Typography variant='body1' component='div'>
              {item && item.i_name}
            </Typography>
          }
          subheader={
            <Typography
              variant='subtitle1'
              style={{ fontWeight: 600 }}
              component='div'
            >
              {item.author ? "by" : ""} {item && item.author}
            </Typography>
          }
        />
        <CardContent
          style={{
            padding: "5px 0 0 16px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Table size='small'>
            <TableBody>
              {item.year && (
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    <Typography
                      variant='h5'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      Year
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='subtitle1'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      {item.year}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {item.edition && (
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    <Typography
                      variant='h5'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      Edition
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='subtitle1'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      {item.edition}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {item.genre && (
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    <Typography
                      variant='h5'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      Genre
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='subtitle1'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      {item.genre}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {item.book_type && (
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    <Typography
                      variant='h5'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      Type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='subtitle1'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      {item.book_type}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {item.i_condition && (
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    <Typography
                      variant='h5'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      Condition
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='subtitle1'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      {item.i_condition}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {item.lang && (
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    <Typography
                      variant='h5'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      Language
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='subtitle1'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      {item.lang}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {item.additional_info && (
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    <Typography
                      variant='h5'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      Info
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='subtitle1'
                      style={{ fontSize: "12px" }}
                      component='div'
                    >
                      {item.additional_info && item.additional_info.length > 200
                        ? item.additional_info.substring(0, 200) + "..."
                        : item.additional_info}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </div>
      <Dialog
        onClose={handleConfrimOpen}
        aria-labelledby='customized-dialog-title'
        open={confirmModel}
        maxWidth='xs'
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={handleConfirmClose}
          style={{ color: "#7FCC28" }}
        >
          Confirm
        </DialogTitle>
        <DialogContent>Do you want to delete the selected item?</DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            type='submit'
            color='primary'
            onClick={(e) => {
              handleClose();
              deleteBookFromList(item.bundle_id, item.item_id);
            }}
          >
            Yes
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={(e) => {
              handleClose();
              handleConfirmClose();
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default function PreviewItem(props) {
  const classes = LoginStyles(),
    progress = progressStyle(),
    mobileStyles = mobileCSS(),
    bundleId = props.bundleId,
    [loading, setLoading] = React.useState(false),
    [previewItemList, setPreviewItemList] = React.useState([]),
    [guidelineAnchorEl, setGuidelineAnchorEl] = React.useState(null);

  const handleGuidelineAnchorEl = (event) => {
    setGuidelineAnchorEl(event.currentTarget);
  };

  const handleGuidelineAnchorElClose = () => {
    setGuidelineAnchorEl(null);
  };

  useEffect(() => {
    if (bundleId) {
      setLoading(true);
      itemApi.getItemsByBundle(
        bundleId,
        function (resp) {
          if (resp) {
            if (resp.bundle && resp.bundle.b_status !== "new") {
              props.setDone(true);
            }
            setPreviewItemList(resp.Items);
          }
          setLoading(false);
        },
        function () {
          setLoading(false);
          window.history.back();
        }
      );
    }
    return () => { };
  }, []);

  const guideLineToolTip = () => (
    <React.Fragment>
      <ul style={{ margin: "0 3px", padding: "6px 12px" }}>
        <li>I am the owner of the item listed.</li>
        <li>
          I agree to wait at least 24 hours before accepting
          requests so the entire community can view the item.
        </li>
        <li>
          I agree to honor my give and complete the give with the
          accepted requestor safely.
        </li>
      </ul>
    </React.Fragment>
  );

  const addMore = () => {
    navigate(`/give/${props.slug}/${bundleId}/add`);
  },
    submitBundle = () => {
      props.submitItem(bundleId);
    },
    editBook = (bundleId, ItemId) => {
      navigate(`/give/${props.slug}/${bundleId}/edit/${ItemId}`);
    };

  const { done } = props;

  return (
    <>
      {loading == true ? <LoadingIcon loadingMsg='Loading items...' /> : ""}
      {previewItemList &&
        previewItemList.map(function (item, index) {
          return (
            <PreviewComponent
              done={done}
              key={item.item_id}
              item={item}
              editBook={editBook}
              setPreviewItemList={setPreviewItemList}
              index={index}
            />
          );
        })}

      {!done && (
        <>
          <Typography
            variant='subtitle1'
            style={{ marginTop: "2rem", fontSize: "11px" }}
            component='div'
          >
            By giving this item, I accept that I have read, understood and agree
            to abide by the{" "}
            <HtmlTooltip
              title={guideLineToolTip()}
            >
              <span className={mobileStyles.hide}>
                {/* <InfoIcon style={{ fontSize: "1rem" }}></InfoIcon>{" "} */}
                <span style={{ color: "#2196f3" }}>Community Guidelines</span>
              </span>
            </HtmlTooltip>
            {/* ony for mobile */}
            <span className={mobileStyles.guideLinesContainer}>
              <span style={{ color: "#2196f3" }} onClick={handleGuidelineAnchorEl}>Community Guidelines</span>
              <Menu
                id="guideline"
                anchorEl={guidelineAnchorEl}
                keepMounted
                open={Boolean(guidelineAnchorEl)}
                onClose={handleGuidelineAnchorElClose}
                className={mobileStyles.guideLineMenu}
              >
                <div className={mobileStyles.guideLinesWrapper} onClick={handleGuidelineAnchorElClose}>{guideLineToolTip()}</div>
              </Menu>
            </span>
            ,{" "}
            <a href='/termsofuse' target='_new'>
              Terms of Use
            </a>{" "}
            and{" "}
            <a href='/privacypolicy' target='_new'>
              Privacy Policy.
            </a>
          </Typography>
          <Box
            className={classes.buttonContainer}
            display='flex'
            flexDirection='row-reverse'
            visibility={loading ? "hidden" : "visible"}
          >
            <div className={progress.wrapper}>
              <Button
                variant='contained'
                color='primary'
                onClick={(e) => submitBundle()}
                disabled={
                  props.submitting ||
                  (previewItemList && previewItemList.length > 0 ? false : true)
                }
              >
                Publish
              </Button>
              {props.submitting && (
                <CircularProgress
                  size={24}
                  className={progress.buttonProgress}
                />
              )}
            </div>
            <div className={progress.wrapper}>
              <Button
                variant='outlined'
                color='primary'
                disabled={previewItemList.length >= 5 ? true : false}
                onClick={(e) => addMore()}
              >
                Add More
              </Button>
            </div>
            <div className={progress.wrapper}>
              <Button
                variant='outlined'
                color='primary'
                onClick={(e) => window.history.back()}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </>
      )}

      {!done && (
        <Box
          className={classes.fixedButtonSection}
          flexDirection='row-reverse'
          visibility={loading ? "hidden" : "visible"}
        >
          <div className={progress.wrapper}>
            <Button
              variant='contained'
              color='primary'
              onClick={(e) => submitBundle()}
              disabled={
                props.submitting ||
                (previewItemList && previewItemList.length > 0 ? false : true)
              }
            >
              Publish
            </Button>
            {props.submitting && (
              <CircularProgress size={24} className={progress.buttonProgress} />
            )}
          </div>
          <div className={progress.wrapper}>
            <Button
              variant='outlined'
              color='primary'
              disabled={previewItemList.length >= 5 ? true : false}
              onClick={(e) => addMore()}
            >
              Add More
            </Button>
          </div>
          <div className={progress.wrapper}>
            <Button
              variant='outlined'
              color='primary'
              onClick={(e) => window.history.back()}
            >
              Cancel
            </Button>
          </div>
        </Box>
      )}
    </>
  );
}
