import React from "react";
import GlobalStyles from "./../../styles/Global.styles";
import Typography from "@material-ui/core/Typography";
import { useStoreState } from "./../../hooks";
import Box from "@material-ui/core/Box";
import { navigate, globalHistory as history } from '@reach/router';
import CookieConsent, { Cookies } from "react-cookie-consent";

export const Footer = () => {
  const classes = GlobalStyles(),
    { location } = history,
    isFooterVisible = () => {
      return ['/login', '/register', '/reset-password', '/set-password'].indexOf(curLocation) === -1;
    },
    [curLocation, setCurLocation] = React.useState(location.pathname),
    isLoggedIn = useStoreState((state) => state.auth.isLoggedIn);

    React.useEffect(() => {
      return history.listen((curHistory) => {
          setCurLocation(curHistory.location.pathname);
      })
  }, [history]);

  return (isFooterVisible() && <footer className={classes.footer}>
    <div className={classes.footerChild}>
      <section>
        <Typography variant='h3' component='div'>
          About
          </Typography>
        <Typography variant='subtitle2' component='div' onClick={e => { navigate('/about-us') }}>Us</Typography>
        <Typography
          variant='subtitle2'
          component='div'
          onClick={(e) => {
            navigate("/covid-19");
          }}>
          Our Covid-19 Message
          </Typography>
        <Typography
          variant='subtitle2'
          component='div'
          onClick={(e) => {
            navigate("/affilliate-disclaimer");
          }}
        >
          Affiliate Disclaimer / Credits
            {/* / Affilliate Disclaimer */}
        </Typography>
        <Typography
          variant='subtitle2'
          component='div'
          onClick={(e) => {
            navigate("/termsofuse");
          }}
        >
          Terms of Use
          </Typography>
        {/* <Typography variant='subtitle2' component='div' onClick={e => { navigate('/membership-agreement') }} >Membership Agreement</Typography> */}
        <Typography
          variant='subtitle2'
          component='div'
          onClick={(e) => {
            navigate("/privacypolicy");
          }}>
          Privacy
          </Typography>
        {/* <Typography variant='subtitle2' component='div' style={{ color: '#ffffff', marginTop: '2.3rem' }}>v0.1.5</Typography>  */}
      </section>
      <section className={classes.footerOrder2}>
        <Typography variant='h3' component='div'>
          For
          </Typography>
        <Typography
          variant='subtitle2'
          component='div'
          onClick={(e) => {
            if (isLoggedIn) {
              navigate("/dashboard");
            } else {
              navigate("/login");
            }
          }}
        >
          Individuals
          </Typography>
        <Typography
          variant='subtitle2'
          component='div'
          onClick={(e) => {
            navigate("/contact-us");
          }}
        >
          Charities Signup
          </Typography>
      </section>
      {/* <section style={{ display: 'none' }} >
                    <Typography variant='h3' component='div'>Support</Typography>
                    <Typography variant='subtitle2' component='div'>Download iOS App</Typography>
                    <Typography variant='subtitle2' component='div'>Download Android App</Typography>
                </section> */}
      <section className={classes.footerOrder3}>
        <Typography variant='h3' component='div'>
          Quick Links
          </Typography>
        <Typography
          variant='subtitle2'
          component='div'
          onClick={(e) => {
            if (isLoggedIn) {
              navigate("/give/books");
            } else {
              navigate("/login");
            }
          }}>
          Give
          </Typography>
        <Typography
          variant='subtitle2'
          component='div'
          onClick={(e) => {
            navigate("/get");
          }}>
          Get
          </Typography>
        <Typography
          variant='subtitle2'
          component='div'
          onClick={(e) => {
            navigate("/learnmore");
          }}
        >
          Learn More
          </Typography>
        <Typography
          variant='subtitle2'
          component='div'
          onClick={(e) => {
            navigate("/contribute");
          }}
        >
          Contribute
          </Typography>
        <Typography
          variant='subtitle2'
          component='div'
          onClick={(e) => {
            navigate("/contact-us");
          }}
        >
          Contact Us
          </Typography>
        {/* <Typography variant='subtitle2' component='div'>Support a cause</Typography> */}
      </section>

      <Box mt={0} className={classes.iconsContainer}>
        {/* <Box display='flex' alignSelf='center' mb={4}>
                        <img src='/assets/images/Facebook.svg' width='24px' height='24px' style={{ margin: '0 10px' }}></img>
                        <img src='/assets/images/instagram.svg' width='24px' height='24px' style={{ margin: '0 10px' }}></img>
                        <img src='/assets/images/twitter.svg' width='24px' height='24px' style={{ margin: '0 10px' }}></img>
                    </Box> */}
        {/* <Typography variant='subtitle2' component='div' style={{ color: '#ffffff', marginBottom: '0.2rem' }}>Join our mailing list to stay in touch. </Typography>
                    <Typography variant='subtitle2' component='div' style={{ color: '#ffffff', marginBottom: '1rem' }}>We protect your privacy and are anti-spam.</Typography>
                    <Box display='flex'>
                        <FormControl variant="filled" style={{ backgroundColor: '#D8D8D8', borderRadius: '4px', height: '40px' }}>
                            <FilledInput
                                placeholder="Your Email"
                                style={{ height: '40px' }} />
                        </FormControl>
                        <Button variant='contained' style={{ border: 'none', marginLeft: '-5px' }} color='primary'>Sign me up!</Button>
                    </Box> */}
      </Box>
    </div>
    <Typography
      className={classes.copyRight}
      variant='subtitle2'
      component='div'>
      ©2020 Kindeeds.
      </Typography>
    <CookieConsent
      location='bottom'
      buttonText='Accept'
      cookieName='cgkCookieConsent'
      style={{ background: "#2B373B" }}
      color='primary'
      buttonStyle={{
        color: "#ffffff",
        backgroundColor: "#22B11F",
        fontSize: "14px",
      }}
      expires={150}>
      <h3>This website uses cookies</h3>
      <span>
        We use cookies to help you personalise your online experience. By
        continuing to browse the site you're agreeing to our use of cookies.
          Click on our{" "}
        <a href='/privacypolicy' target='_new' style={{ color: "#22B11F" }}>
          privacy policy
          </a>{" "}
          for more information on our use of cookies.
        </span>
    </CookieConsent>
  </footer>
  );
};
