

import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { navigate } from "@reach/router";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  wrap: {
      [theme.breakpoints.down("sm")]: {
          padding: '20px',
      },
  },
  hover: {
      cursor: 'pointer',
      padding: '5px 0',
      '&:hover': {
              color: '#22B11F'
      }

  },
  leftPanel:{
    margin: "112px 0",
    [theme.breakpoints.down("sm")]: {
            margin: "0",
    },
  },
  justify: {
      textAlign: 'justify'
  },
  title: {
      fontSize: '18px',
      fontWeight: 'bold',
  }

}));

function AffilliateDisclaimer() {
  const classes = useStyles();

  return (


    <div className={classes.wrap}>

            <Grid container>
            <Grid sm={2} xs={12} className={classes.leftPanel}>
                        
                        <Typography variant='subtitle1' component='div' className = {classes.hover}
                        onClick={(e) => {
                                navigate("/termsofuse");
                              }}
                        
                        >Terms of Use</Typography>
                        <Typography variant='subtitle1' component='div' className = {classes.hover} 
                        
                        onClick={(e) => {
                                navigate("privacypolicy");
                              }}
                        
                        >Privacy Policy</Typography>
                        <Typography variant='h5' component='div' className = {classes.hover} 
                        
                        onClick={(e) => {
                                navigate("affilliate-disclaimer");
                              }}
                        
                        >Affiliate Disclaimer / Credits</Typography>
                        
                </Grid> 
                <Grid sm={10} xs={12}>
                  <Typography variant='h1' component='div' style={{ margin: "60px 0px 30px " }} > Affiliate Disclaimer</Typography>
                  <p className={classes.justify}>
                    As an Amazon Associate, we earn from qualifying purchases. 
                    Kindeeds may also earn commissions from some or all of the links on site and other affiliate programs.
                    This helps us keep the site free for everyone and meet our operating expenses. We thank you for your support.
                  </p>            

                  <p className={classes.title}>Credits</p>

                  <Typography
            variant='subtitle2'
            component='div'
            style={{ textAlign: "justify" }}
          >
            We would like to thank
          <Typography
              variant='subtitle2'
              component='span'
              color='Primary'
              style={{ cursor: "pointer" }}
            >
              {" "}
              <a
                href='https://unsplash.com/'
                style={{ color: "#22B11F" }}
                target='_blank'
              >
                Unsplash
            </a>
            </Typography>
          ,
          <Typography
              variant='subtitle2'
              component='span'
              color='Primary'
              style={{ cursor: "pointer" }}
            >
              {" "}
              <a
                href='https://pixabay.com/'
                style={{ color: "#22B11F" }}
                target='_blank'
              >
                Pixabay
            </a>
            </Typography>
          ,
          <Typography
              variant='subtitle2'
              component='span'
              color='Primary'
              style={{ cursor: "pointer" }}
            >
              {" "}
              <a
                href='https://www.sarahl.com/'
                style={{ color: "#22B11F" }}
                target='_blank'
              >
                Sarah Lazarovic
            </a>
            </Typography>
          ,
          <Typography
              variant='subtitle2'
              component='span'
              color='Primary'
              style={{ cursor: "pointer" }}
            >
              {" "}
              <a
                href='https://unsplash.com/@mercantile'
                style={{ color: "#22B11F" }}
                target='_blank'
              >
                Clark Street Mercantile
            </a>
            </Typography>
          ,
          <Typography
              variant='subtitle2'
              component='span'
              color='Primary'
              style={{ cursor: "pointer" }}
            >
              {" "}
              <a
                href='https://unsplash.com/@larm'
                style={{ color: "#22B11F" }}
                target='_blank'
              >
                Larm Rmah
            </a>
            </Typography>
          ,
          <Typography
              variant='subtitle2'
              component='span'
              color='Primary'
              style={{ cursor: "pointer" }}
            >
              {" "}
              <a
                href='https://unsplash.com/@hannahrodrigo'
                style={{ color: "#22B11F" }}
                target='_blank'
              >
                Hannah Rodrigo
            </a>
            </Typography>
          ,
          <Typography
              variant='subtitle2'
              component='span'
              color='Primary'
              style={{ cursor: "pointer" }}
            >
              {" "}
              <a
                href='https://unsplash.com/@alexloup'
                style={{ color: "#22B11F" }}
                target='_blank'
              >
                Alex Loup
            </a>
            </Typography>{" "}
          and
          <Typography
              variant='subtitle2'
              component='span'
              color='Primary'
              style={{ cursor: "pointer" }}
            >
              {" "}
              <a
                href='https://unsplash.com/@alexanderhipp'
                target='_blank'
                style={{ color: "#22B11F" }}
              >
                Alexander Hipp
            </a>
            </Typography>{" "}
          for their generosity in sharing their photos and art online.
        </Typography>
                </Grid>  
            </Grid>
    </div>

  );
}

export default AffilliateDisclaimer;
