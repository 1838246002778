import React from 'react';
//import { useStoreActions } from '../../../../../../../hydrochempsc/hydrochempsc-app/hydrochempsc-app/src/hooks';

const Logout = ({ navigate }) => {
  // const logout = useStoreActions(actions => actions.auth.logout);
  // const hideUI = useStoreActions(actions => actions.ui.hide);

  // useEffect(() => {
  //   hideUI();
  //   // Wait until UI is hidden before logging out
  //   const timeout = setTimeout(() => {
  //     logout();
  //     navigate('/');
  //   }, 500);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [hideUI, logout, navigate]);
  // return null;
  return(
    <div>Logout</div>
  )
};

export { Logout };
