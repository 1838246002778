import { apiHelper } from '../helpers';
import { httpMethodTypes } from '../constants/httpMethodTypes';
import { appSettings } from '../constants/appSettings';

import axios from 'axios';

const BASE_URL = appSettings.BaseURL;

async function callGetMethod(
  url,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  if (requestDataBlk) {
    try {
      await axios
        .get(url, requestDataBlk)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  } else {
    try {
      await axios
        .get(url)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  }
}
async function callPutMethod(
  url,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  if (requestDataBlk) {
    try {
      await axios
        .put(url, requestDataBlk)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  } else {
    try {
      await axios
        .put(url)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  }
}
async function callPostMethod(
  url,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  if (requestDataBlk) {
    try {
      await axios
        .post(url, requestDataBlk)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  } else {
    try {
      await axios
        .post(url)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  }
}
async function callDeleteMethod(
  url,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  if (requestDataBlk) {
    try {
      await axios
        .delete(url, requestDataBlk)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  } else {
    try {
      await axios
        .delete(url)
        .then(function (response) {
          successCallBack(response.data);
        })
        .catch(function (error) {
          if (errorCallBack) {
            errorCallBack({ ...error });
          } else {
            return;
          }
        });
    } catch (error) {
      if (errorCallBack) {
        errorCallBack({ ...error });
      }
    }
  }
}

async function getAllGivePreferenceList(successCallBack, errorCallBack) {
  await callGetMethod(
    'api/core/master/give-preferences/',
    null,
    successCallBack,
    errorCallBack
  );
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, true, {})
  // const url = BASE_URL + 'api/item/master/give-preferences/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
}

async function getUserGivePreference(successCallBack, errorCallBack) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, true, {})
  // const url = BASE_URL + 'api/item/user/account/preferences/item/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
  await callGetMethod(
    'api/core/user/account/preferences/give/',
    null,
    successCallBack,
    errorCallBack
  );
}

/*function updateUserGivePreference(requestDataBlk, successCallBack, errorCallBack) {
    const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT, true, requestDataBlk)
    const url = BASE_URL + 'api/item/master/give-preference-item/';
    apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
}*/

async function getFeaturedItems(successCallBack, errorCallBack) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  // const url = BASE_URL + 'api/item/master/item-categories/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack,errorCallBack);
  await callGetMethod(
    'api/core/master/item-categories/',
    null,
    successCallBack,
    errorCallBack
  );
}
async function getFeaturedItemsTrue(successCallBack, errorCallBack) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  // const url = BASE_URL + 'api/item/master/item-categories/?is_featured=true';
  // apiHelper.sendRequest(url, requestOptions, successCallBack,errorCallBack);
  await callGetMethod(
    'api/core/master/item-categories/',
    null,
    successCallBack,
    errorCallBack
  );
}

async function getItemsFieldsTrue(
  categorySlug,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    'api/core/master/get-item-fields/' +
    categorySlug +
    '/?is_filterable=true';
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getFieldsByCategory(
  categorySlug,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url = '' + 'api/core/master/get-item-fields/' + categorySlug + '/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}
async function getDependencyChoices(
  categoryId,
  selectionId,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    'api/core/master/get-dependency-choices/' +
    categoryId +
    '/' +
    selectionId +
    '/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}
async function getBooksListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, true)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}/?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getMusicListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}/?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getMagazineListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}/?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod('', null, successCallBack, errorCallBack);
}

async function getBookDetails(
  categorySlug,
  fieldId,
  uniqueId,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' + `api/core/master/get-product-details/${categorySlug}/${uniqueId}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getItemDetails(
  categorySlug,
  fieldId,
  uniqueId,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-product-details/${categorySlug}/?unique_id=${uniqueId}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getShoeListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}/?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getMoviesListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  //   const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}/?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getClothsListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}/?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getGadgetsListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  //   const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}/?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getGamesListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}/?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getToysListByQuery(
  categorySlug,
  fieldId,
  query,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET, false)
  const url =
    '' +
    `api/core/master/get-products-for-item-search/${categorySlug}/${fieldId}/?search_keyword=${query}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function createBundle(successCallBack, errorCallBack) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.POST, true)
  // const url = BASE_URL + 'api/item/bundle/create/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
  await callPostMethod(
    'api/core/bundle/create/',
    null,
    successCallBack,
    errorCallBack
  );
}
async function createItem(requestBlk, successCallBack, errorCallBack) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.POST,true,requestBlk)
  // const url = BASE_URL + 'api/item/item/create/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
  await callPostMethod(
    'api/core/item/create/',
    requestBlk,
    successCallBack,
    errorCallBack
  );
}

async function submitBundle(requestBlk, successCallBack, errorCallBack) {
  await callPutMethod(
    'api/core/bundle/submit/',
    requestBlk,
    successCallBack,
    errorCallBack
  );
}

async function getItemsByBundle(bundleId, successCallBack, errorCallBack) {
  await callGetMethod(
    '' + `api/core/bundle/detail/${bundleId}`,
    null,
    successCallBack,
    errorCallBack
  );
}
async function deleteItemFormBundle(
  bundleId,
  ItemId,
  successCallBack,
  errorCallBack
) {
  await callDeleteMethod(
    '' + `api/core/delete/item/${bundleId}/${ItemId}/`,
    null,
    successCallBack,
    errorCallBack
  );
}

async function getMyGivesList(
  requestDataType,
  filter,
  sortValue,
  paginationKey,
  successCallBack,
  errorCallBack
) {
  let url = `api/core/mygives/list/${requestDataType}/?filter=${encodeURIComponent(
    JSON.stringify(filter)
  )}&sorting=${sortValue}`;
  if (paginationKey) {
    let keyString = encodeURIComponent(JSON.stringify(paginationKey.key));
    url = url + '&page=' + keyString;
  }
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getMyGiveItemByBundle(bundleId, successCallBack, errorCallBack) {
  await callGetMethod(
    `api/core/mygives/${bundleId}/`,
    null,
    successCallBack,
    errorCallBack
  );
}

async function getMyGetItemByBundle(bundleId, successCallBack, errorCallBack) {
  await callGetMethod(
    `api/core/myget/${bundleId}/`,
    null,
    successCallBack,
    errorCallBack
  );
}

async function getMyGetsList(
  requestDataType,
  filter,
  sortValue,
  paginationKey,
  successCallBack,
  errorCallBack
) {
  let url = `api/core/user/request/?r_status=${requestDataType}&filter=${filter}&sorting=${sortValue}`;
  if (paginationKey) {
    let keyString = encodeURIComponent(JSON.stringify(paginationKey.key));
    url = url + '&page=' + keyString;
  }
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getItemsList(
  filter,
  sortValue,
  paginationKey,
  cityFilterData,
  successCallBack,
  errorCallBack
) {
  let url = `api/core/bundle/search/?filter=${filter}&sorting=${sortValue}`;
  if (cityFilterData) {
    url = url + '&data=' + cityFilterData;
  }
  if (paginationKey) {
    let keyString = encodeURIComponent(JSON.stringify(paginationKey.key));
    url = url + '&page=' + keyString;
  }
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

async function getItemsDetails(
  requestDataType,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET,true)
  // const url = BASE_URL + 'api/item/bundle/detail/'+requestDataType;
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
  await callGetMethod(
    'api/core/bundle/detail/' + requestDataType,
    null,
    successCallBack,
    errorCallBack
  );
}
async function getItem(bundleId, ItemId, successCallBack, errorCallBack) {
  //  const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET,true)
  // const url = BASE_URL + `api/item/item/detail/${bundleId}/${ItemId}/`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack)
  await callGetMethod(
    `api/core/item/detail/${bundleId}/${ItemId}/`,
    null,
    successCallBack,
    errorCallBack
  );
}
async function updateItem(
  requestBlk,
  bundleId,
  ItemId,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT,true , requestBlk)
  const url = '' + `api/core/item-update/${bundleId}/${ItemId}/`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack)
  await callPutMethod(url, requestBlk, successCallBack, errorCallBack);
}

async function putRequestItem(requestDataBlk, successCallBack, errorCallBack) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.POST, true,requestDataBlk)
  // const url = BASE_URL + 'api/item/request/item/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
  await callPostMethod(
    'api/core/request/item/',
    requestDataBlk,
    successCallBack,
    errorCallBack
  );
}
async function getRequestsByBundleId(BundleId, successCallBack, errorCallBack) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET,true)
  // const url = BASE_URL + 'api/item/get/request/'+BundleId+'/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
  await callGetMethod(
    'api/core/get/request/' + BundleId + '/',
    null,
    successCallBack,
    errorCallBack
  );
}

async function updateRequest(requestDataBlk, successCallBack, errorCallBack) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT,true,requestDataBlk)
  // const url = BASE_URL + 'api/item/request/accept/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
  await callPutMethod(
    'api/core/request/accept/',
    requestDataBlk,
    successCallBack,
    errorCallBack
  );
}
async function confirmRequest(requestDataBlk, successCallBack, errorCallBack) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT,true,requestDataBlk)
  // const url = BASE_URL + 'api/item/request/confirm/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
  await callPutMethod(
    'api/core/request/confirm/',
    requestDataBlk,
    successCallBack,
    errorCallBack
  );
}

async function getItemExtraInfo(ItemId, successCallBack, errorCallBack) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.GET,true)
  // const url = BASE_URL + `api/item/item/extrainfo/${ItemId}/`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callGetMethod(
    `api/core/item/extrainfo/${ItemId}/`,
    null,
    successCallBack,
    errorCallBack
  );
}
async function updateBundleStatus(
  bundleId,
  type,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT, true, requestDataBlk)
  const url = `api/core/bundle/${bundleId}/?action=${type}`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callPutMethod(url, requestDataBlk, successCallBack, errorCallBack);
}

async function submitFeedback(
  bundleId,
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT, true, requestDataBlk)
  const url = `api/core/bundle/${bundleId}/rate/`;
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callPutMethod(url, requestDataBlk, successCallBack, errorCallBack);
}

async function updateUserGivePreference(
  requestDataBlk,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.PUT, true, requestDataBlk)
  // const url = BASE_URL + 'api/item/user/account/preferences/item/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack);
  await callPutMethod(
    'api/core/user/account/preferences/give/',
    requestDataBlk,
    successCallBack,
    errorCallBack
  );
}
async function setBundleConfirmed(
  requestDataBlock,
  successCallBack,
  errorCallBack
) {
  // const requestOptions = apiHelper.createRequestOptions(httpMethodTypes.POST, true)
  // const url = BASE_URL + 'api/item/bundle/create/';
  // apiHelper.sendRequest(url, requestOptions, successCallBack, errorCallBack);
  await callPostMethod(
    'api/core/bundle/confirmed/',
    requestDataBlock,
    successCallBack,
    errorCallBack
  );
}
async function updateBundleRejectRequest(
  bundle_id,
  request_id,
  successCallBack,
  errorCallBack
) {
  await callPutMethod(
    `api/core/bundle/${bundle_id}/${request_id}/rejected/`,
    null,
    successCallBack,
    errorCallBack
  );
}
async function getItemFlagTypes(successCallBack, errorCallBack) {
  await callGetMethod(
    'api/core/flag_types/',
    null,
    successCallBack,
    errorCallBack
  );
}
async function flagItem(requestDataBlock, successCallBack, errorCallBack) {
  await callPostMethod(
    'api/core/bundle/flag/',
    requestDataBlock,
    successCallBack,
    errorCallBack
  );
}
async function getNotifications(
  notificationFilter,
  successCallBack,
  errorCallBack
) {
  // async function getNotifications(filter, sortValue, paginationKey, cityFilterData, successCallBack, errorCallBack) {
  // if (paginationKey) {
  //     let keyString = encodeURIComponent(JSON.stringify(paginationKey.key))
  //     url = url + '&page=' + keyString
  // }

  let url = 'api/notifications/';

  let urlParam;
  if (Object.keys(notificationFilter).length > 0) {
    if (notificationFilter.read) {
      urlParam = 'read=True';
    } else {
      urlParam = 'read=False';
    }

    url = `${url}?${urlParam}`;
  }
  await callGetMethod(url, null, successCallBack, errorCallBack);
}
async function updateNotificationById(
  notificationId,
  action,
  successCallBack,
  errorCallBack
) {
  await callPutMethod(
    `api/notifications/update/${action}/${notificationId}/`,
    null,
    successCallBack,
    errorCallBack
  );
}

async function updateNotifications(action, successCallBack, errorCallBack) {
  await callPutMethod(
    `api/notifications/update/${action}/`,
    null,
    successCallBack,
    errorCallBack
  );
}

async function getNotificationCount(
  notificationFilter,
  successCallBack,
  errorCallBack
) {
  let url = 'api/notifications/count/';
  let urlParam;
  if (Object.keys(notificationFilter).length > 0) {
    if (notificationFilter.read) {
      urlParam = 'read=True';
    } else {
      urlParam = 'read=False';
    }

    url = `${url}?${urlParam}`;
  }
  await callGetMethod(url, null, successCallBack, errorCallBack);
}

export const itemApi = {
  getAllGivePreferenceList,
  getUserGivePreference,
  updateUserGivePreference,
  getFeaturedItems,
  getFieldsByCategory,
  getDependencyChoices,
  getBooksListByQuery,
  getMusicListByQuery,
  getMagazineListByQuery,
  getClothsListByQuery,
  getBookDetails,
  getShoeListByQuery,
  getGadgetsListByQuery,
  getMoviesListByQuery,
  getGamesListByQuery,
  getToysListByQuery,
  getFeaturedItemsTrue,
  createBundle,
  createItem,
  submitBundle,
  getItemsByBundle,
  deleteItemFormBundle,
  getItemDetails,
  getMyGivesList,
  getMyGetsList,
  getItemsList,
  getItemsDetails,
  getItem,
  updateItem,
  putRequestItem,
  getRequestsByBundleId,
  updateRequest,
  confirmRequest,
  getItemExtraInfo,
  updateBundleStatus,
  submitFeedback,
  getItemsFieldsTrue,
  setBundleConfirmed,
  updateBundleRejectRequest,
  getMyGiveItemByBundle,
  getMyGetItemByBundle,
  getItemFlagTypes,
  flagItem,
  getNotifications,
  updateNotificationById,
  updateNotifications,
  getNotificationCount,
};
