import React from "react";
import {
  Grid,
  Badge,
  Box,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Drawer,
  MenuItem,
  Select,
  InputLabel,
  RadioGroup,
  Radio,
  TextField,
  makeStyles,
  Menu,
  IconButton
} from "@material-ui/core";
import GetStyles from "./../../styles/Get.styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfiniteScroll from "react-infinite-scroll-component";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import throttle from "lodash/throttle";
import { useStoreState, useStoreActions } from "./../../hooks";
import { itemApi, shippingApi, genericApi, userApi } from "./../../api";
import { appSettings } from "./../../constants/appSettings";
import { DataContext } from "./GetItem";
import GetItem from "./GetItem";
import { GetDetails } from "./GetDetails";
import { HorizontalAds } from "../ui/ads/HorizontalAds";
import { HtmlTooltip } from './../../styles/addressCommon.styles';

const locationStyle = makeStyles((theme) => ({
  overrides: {
    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
        minHeight: 0,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0,
        minHeight: 0,

      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0',
        '& .Mui-expanded': {
          margin: '0',
          minHeight: '0',
        },
        '& .MuiIconButton-edgeEnd': {
          marginRight: '0'
        },
      }
    },
    MuiSvgIcon: {
      root: {
        width: '18px'
      }
    },
  },

  cityWrapper: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    width: "100%",
    marginTop: "0px",
    marginBottom: "15px",
    padding: "0px",
  },
  saveBtn: {
    color: "#22B11F",
    display: " inline-block",
    minWidth: "0",
    height: "inherit",
    padding: 0,
    margin: 0,
    '&:hover': {
      textDecoartion: 'none',
      backgroundColor: 'none',
      background: 'none'
    }
  },
})),
  mobileCSS = makeStyles((theme) => ({
    header: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        color: "#231C07",
        fontFfamily: "Open Sans",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "31px",
        paddingLeft: "20px",
      },
    },
    filterHeaderSection: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1rem',
      height: '10%',
      alignItems: 'center'
    },
    filterContainer: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
        alignItems: "center",
        padding: "0 20px",
        boxSizing: "border-box",
      },
    },
    filterLabelWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

    },
    showDisplay: {
      display: "block",
    },
    hideDisplay: {
      display: "none",
    },
    sortWrapper: {
      position: "absolute",
    },
    filterLabel: {
      color: "#717171",
      letterSpacing: "0",
      lineHeight: "17px",
      paddingLeft: "7px",
    },
    filterMenuWrapper: {
      width: '123px',
      backgroundColor: 'rgba(45,114,204,0.1)',
      width: '123px',
      display: 'flex',
      flexDirection: 'column'
    },
    formControlLabel: {
      '& .MuiFormControlLabel-label': {
        color: '#717171',
        fontFamily: "Open Sans",
        fontSize: '14px',
        letterSpacing: ' 0',
        lineHeight: '16px',
        paddingLeft: '5px'
      }
    },
    filterSection: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      padding: '0 1rem'
    }
  })),
  SortMenuItems = ({
    mobileStyles,
    selectedFilterIndex,
    handleSortByChange,
  }) => {
    const [anchorEl, setAnchorEl] = React.useState(null),
      open = Boolean(anchorEl),
      handleClose = () => {
        setAnchorEl(null);
      },
      handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      },
      handleSort = (event) => {
        handleSortByChange(event);
        setAnchorEl(null);
      };

    return (
      <div>
        <IconButton
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={handleClick}
        >
          <img src='/assets/images/icon_sort.svg' alt='Sort' />
          <Typography
            variant='subtitle2'
            component='div'
            style={{ paddingLeft: "5px" }}
            className={mobileStyles.filterLabel}
          >
            Sort
        </Typography>
        </IconButton>
        <Menu
          id='long-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              // maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          <MenuItem
            onClick={handleSort}
            selected={selectedFilterIndex == 1}
            value={1}
            style={{ minWidth: "unset" }}
          >
            Newest First
        </MenuItem>
          <MenuItem
            onClick={handleSort}
            selected={selectedFilterIndex == 2}
            value={2}
            style={{ minWidth: "unset" }}
          >
            Oldest First
        </MenuItem>
        </Menu>
      </div>
    );
  };

export const Get = (props) => {
  const [categories, setCategories] = React.useState([]),
    [preferences, setPreferences] = React.useState(""),
    [ship_preferences, setShipPreferences] = React.useState(""),
    showAlert = useStoreActions((actions) => actions.alert.showAlert),
    [deliveryPreferences, setDeliveryPreferences] = React.useState(""),
    [shippingPreferences, setShippingPreferences] = React.useState(""),
    [posted, setPosted] = React.useState(""),
    [availability, setAvailability] = React.useState(""),
    [conditionFilters, setConditionFilters] = React.useState([]),
    [shippingPreference, setShippingPreference] = React.useState([]),
    [DeliveryPreference, setDeliveryPreference] = React.useState([]),
    [items, setItems] = React.useState([]),
    [selectId, setId] = React.useState(""),
    storeUser = useStoreState((state) => state.auth.user),
    [conditionFilterValues, setConditionFilterValues] = React.useState([]),
    [selectedConditionFilterValues, setSelectedConditionFilterValues] = React.useState([]),
    [selectedCategoryFilterValues, setSelectedCategoryFilterValues] = React.useState([]),
    [selectedFilterIndex, setSelectedFilterIndex] = React.useState(1),
    [isChecked, setChecked] = React.useState(false),
    [isPref, setPref] = React.useState(false),
    [item, setItem] = React.useState(),
    [isDetailsPage, setDetailsPage] = React.useState(false),
    [encodedFilterString, setEncodedFilterString] = React.useState(""),
    [encodedCityFilterString, setEncodedCityFilterString] = React.useState(""),
    [sortFilterString, setSortFilterString] = React.useState(1),
    classes = GetStyles(),
    [loading, setLoading] = React.useState(false),
    [filterName, setFilterName] = React.useState({
      avail_: "All",
      avail_given: "Gifted",
      avail_submitted: "Available",
      posted_: "All",
      posted_today: "Today",
      posted_week_ago: "Last 7 days",
      posted_month_ago: "Last 30 days",
      delivery_: "Any",
    }),
    emptyUser = {
      city: "",
      area: "",
    },
    [options, setOptions] = React.useState([]),
    [city, setCity] = React.useState(null),
    [inputValue, setInputValue] = React.useState(""),
    [user, setUser] = React.useState(emptyUser),
    location = locationStyle(),
    [area, setArea] = React.useState(null),
    mobileStyles = mobileCSS(),
    skeletonNumbers = [...Array(6).keys()],
    isLoggedIn = useStoreState((state) => state.auth.isLoggedIn),
    [isCitySet, setIsCity] = React.useState(false),
    [isAreaSet, setIsArea] = React.useState(false),
    [isFilterSet, setIsFilter] = React.useState(false);

  React.useEffect(() => {
    // Get the loggedIn user details
    if (isLoggedIn) {
      // itemApi.getItemFlagTypes((response) => {
      //   setFlagTypes(response);
      // });

      userApi.getLoggedInUserDetails(function (resp) {
        if (resp) {
          let userObj = { ...user };
          if (resp[0].u_city) {
            const cityString =
              resp[0].u_city +
              ", " +
              resp[0].u_state +
              ", " +
              resp[0].u_country;
            const cityObj = {
              city: cityString,
            };
            userObj.city = cityObj;
            setCity(cityString);
          }
          if (resp[0].area) {
            setArea(resp[0].area);
          }
          setUser(userObj);
          // setLoadingData(true)
        }
      });
    }
  }, []);

  const updateProfile = () => {
    const cityArr = city && city.split(","),
      reqBlk = {
        u_city: cityArr ? cityArr[0] : null,
        area: area ? area : "",
        u_state: cityArr ? cityArr[1] : null,
        u_country: cityArr ? cityArr[2] : null,
      };
    setLoading(true);
    userApi.updateLoggedInUserProfile(reqBlk, function (resp) {
      setLoading(false);
      if (resp) {
        let userObj = { ...user };
        userObj.area = resp.area;
        const cityObj = {
          city: resp.u_city + ", " + resp.u_state + ", " + resp.u_country,
        };
        userObj.city = cityObj;
        setUser(userObj);
        showAlert({
          message: "Your profile data updated successfully.",
          open: true,
          severity: "success",
        });
        userApi.getLoggedInUserDetails(function (resp) {
          if (resp) {
            let storeUserCopy = { ...storeUser };
            storeUserCopy.user_avatar = resp[0].user_avatar;
            storeUserCopy.full_name = resp[0].full_name;
            storeUserCopy.user_hash = resp[0].user_hash;
            storeUserCopy.mobile = resp[0].mobile;
            storeUserCopy.display_name = resp[0].display_name;
            storeUserCopy.email = resp[0].email;
            storeUserCopy.area = resp[0].area;
            updateUser({ user: storeUserCopy });
          }
        });
      } else {
        showAlert({
          message: "There is an error in updating profile.",
          open: true,
          severity: "error",
        });
      }
    });
  },
    fetch = React.useMemo(
      () =>
        throttle((input, callback) => {
          if (!input) {
            return callback([]);
          }
          if (input.input.length < 4) {
            return;
          }
          genericApi.getCitiesByGeoLocations(input.input, function (response) {
            return callback(response);
          });
        }, 200),
      []
    );

  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const handleSelectChange = (newValue) => {
    if (newValue && newValue != city) {
      setCity(newValue.target.innerText);
    }
  },
    handleCity = () => {
      const user1 = { ...user };
      if (city) {
        if ("area" in user1) {
          delete user1["area"];
        }
        user1["city"] = city;
        setIsCity(true);
        setUser(user1);
        handleSelectPreference("", "", user1);
      }
    },
    handleArea = () => {
      const user1 = { ...user };
      if (area && user1["area"] !== area) {
        user1["area"] = area;
      }
      setUser(user1);
      setIsArea(true);
      handleSelectPreference("", "", user1);
    },
    handleChange = (event) => {
      setInputValue(event.target.value);
    },
    handleFeaturedItemsSuccessCallback = (response, resStatus) => {
      setCategories(response);
      const selectedCategoryFilterValues = [];
      selectedCategoryFilterValues[response[0].id] = response[0].name;
      setSelectedCategoryFilterValues(selectedCategoryFilterValues);
    },
    handleShippingSuccess = (response, resStatus) => {
      setShippingPreference(response);
    },
    itemDetailsClicked = (item) => {
      setDetailsPage(true);
    },
    clearFilter = (e) => {
      setPosted("");
      // setDeliveryPreferences("");
      setShippingPreferences("");
      setAvailability("");
      setEncodedFilterString("");
      setSelectedConditionFilterValues([]);
      setEncodedCityFilterString("");
      //    setSelectedCategoryFilterValues([]);
      handleSelectPreference("clear-all", 0);
      setIsCity(false);
      setIsArea(false);
      setIsFilter(false);
    }, displayDetails = () => {
      return <GetDetails item={item} />;
    },
    handleCategoriesFilterChange = (e, checked) => {
      let id = e.target.name;
      let category = e.target.value;
      //    setId(category);
      let selectedCategories = selectedCategoryFilterValues;
      if (checked) {
        selectedCategories[id] = category;
      } else {
        selectedCategories[id] = null;
      }
      setSelectedCategoryFilterValues(selectedCategories);
      handleSelectPreference("category", selectedCategories);
    },
    handleRemoveCategoryFilterFromChip = (id) => {
      let selectedCategories = selectedCategoryFilterValues;
      selectedCategories[id] = null;
      setSelectedCategoryFilterValues(selectedCategories);
      // handleSelectPreference("category", selectedCategories);
    },
    handleRemoveConditionFilterFromChip = (id) => {
      let selected = selectedConditionFilterValues;
      selected[id] = null;
      setSelectedConditionFilterValues(selected);
      handleSelectPreference("condition", selected);
    },
    handleConditionFilterChange = (event, checked) => {
      let id = event.target.name;
      let value = event.target.value;
      let selected = selectedConditionFilterValues;
      if (checked) {
        selected[id] = value;
      } else {
        selected[id] = null;
      }
      setSelectedConditionFilterValues(selected);
      handleSelectPreference("condition", selected);
    },
    handleSortByChange = (event) => {
      setSelectedFilterIndex(event.target.value);
      handleSelectPreference("sortValue", event.target.value);
    },
    CheckDelivery = () => {
      isPref === true ? setPref(false) : setPref(true);
    },
    handleSelectPreference = (e, sortValue, cityData) => {
      const { value, checked, name } =
        e && e.currentTarget ? e.currentTarget : ["", false, ""];
      let nd, ns, np, na,
        selected = deliveryPreferences == "" ? [] : [deliveryPreferences], //[...preferences];
        sel = shippingPreferences == "" ? [] : [shippingPreferences], //[...ship_preferences];
        post = posted == "" ? [] : [posted], //[...posted];
        avail = availability == "" ? [] : [availability],
        cat_condition = [],
        cat_category = [],
        selectedConditions = selectedConditionFilterValues;
      if (name === "delivery_pref") {
        setDeliveryPreferences(value);
        if (value) {
          selected = [value.split("_")[1]];
        } else {
          selected = [];
        }
      } else if (name === "ship_pref") {
        setShippingPreferences(value);
        if (value) {
          if (value == "shipping_3" && !cityData) {
            setIsArea(false);
            return;
          } else if (value == "shipping_2" && !cityData) {
            setIsCity(false);
            return;
          } else if (value != "shipping_3" && value != "shipping_2") {
            setIsCity(false);
            setIsArea(false);
          }
          sel = [value.split("_")[1]];
        } else {
          sel = [];
        }
      } else if (name === "posted") {
        setPosted(value);
        if (value) {
          post = [value];
        } else {
          post = [];
        }
      } else if (name === "availabilityFilter") {
        // na = name
        setAvailability(value);
        if (value) {
          avail = [value];
        } else {
          avail = [];
        }
      }
      else if (e === "condition") {
        if (sortValue) {
          selectedConditions = sortValue;
        }
      }
      selectedConditions.length && selectedConditions.map((value, key) => {
        if (value) {
          cat_condition.push(key);
        }
      });
      //   }
      // }
      //else if (e === "category")
      // {
      selectedCategoryFilterValues.length &&
        selectedCategoryFilterValues.map((value, key) => {
          if (value) {
            cat_category.push(key);
          }
        });
      // }

      let filters = {};
      let isFilter = false;

      if (e !== "delivery_pref") {
        if (name === "delivery_pref") {
          filters.delivery_pref_id = selected;
          isFilter = true;
        } else if (deliveryPreferences) {
          let id = [deliveryPreferences.split("_")[1]];
          filters.delivery_pref_id = id;
          isFilter = true;
        }
      }
      if (e !== "ship_pref") {
        if (name === "ship_pref") {
          filters.ship_pref_id = sel;
          isFilter = true;
        } else if (shippingPreferences) {
          let id = [shippingPreferences.split("_")[1]];
          filters.ship_pref_id = id;
          isFilter = true;
        }
      }
      if (e !== "posted") {
        if (name === "posted") {
          filters.posted = post;
          isFilter = true;
        } else if (posted) {
          filters.posted = [posted];
          isFilter = true;
        }
      }
      if (e !== "availabilityFilter") {
        if (name === "availabilityFilter") {
          filters.b_status = avail;
          isFilter = true;
        } else if (availability) {
          filters.b_status = [availability];
          isFilter = true;
        }
      }
      if (cat_condition.length) {
        (filters.condition_id = cat_condition), (isFilter = true);
      }
      if (cat_category.length) {
        (filters.category_id = cat_category), (isFilter = true);
      }

      let sortNumber = 1;
      if (e === "sortValue") {
        sortNumber = sortValue;
      } else if (selectedFilterIndex > 1) {
        sortNumber = selectedFilterIndex;
      }

      if (e === "clear-all") {
        isFilter = false;
      }

      // Removed the condition to send location only when city is selected.
      // if(filters.ship_pref_id == 3){ // CITY
      // if (city) {
      //   cityData = { location: city }
      // }
      //}
      if (filters.ship_pref_id == 3) {
        if (cityData || city) {
          let location = cityData ? cityData.city : city;
          cityData = { location: location };
        }
      }
      if (filters.ship_pref_id == 2) {
        if (cityData?.area || area) {
          let currentCity = cityData ? cityData.city : city;
          let areaNew = cityData
            ? cityData?.area
              ? cityData?.area
              : area
            : area;
          // cityData = { city: currentCity, area: areaNew };
          cityData = { area: areaNew };
        }
      }

      let filter = encodeURIComponent(JSON.stringify(filters));
      if (isFilter) {
        setIsFilter(Object.keys(filters).length > 2);
        setEncodedFilterString(filter);
        setSortFilterString(sortNumber);
        if (cityData) {
          cityData = encodeURIComponent(JSON.stringify(cityData));
        }
        setEncodedCityFilterString(cityData);
        fetchMoreData(filter, sortNumber, cityData);
      } else {
        setEncodedFilterString("");
        setEncodedCityFilterString("");
        setSortFilterString(1);
        setIsFilter(false);
        fetchMoreData(null, 1, null);
      }
    };

  React.useEffect(() => {
    let slug = "books";
    const categorySlug = "books";
    itemApi.getFieldsByCategory(
      categorySlug,
      function (response) {
        if (response) {
          let condition = null;
          response.map((item) => {
            if (item.name === "book_condition") {
              setConditionFilterValues(item.selection_values);
            }
          });
        }
      },
      function (response) { }
    );

    itemApi.getFeaturedItems(handleFeaturedItemsSuccessCallback);
    shippingApi.getShippingPreference(function (resp) {
      let newFilterName = { ...filterName };
      if (resp) {
        setShippingPreference(resp);
        resp.map(
          (category, index) =>
            (newFilterName[`shipping_${category.preference}`] =
              category.description)
        );
        setFilterName(newFilterName);
      }
      shippingApi.getDeliveryPreference(function (response) {
        if (response) {
          setDeliveryPreference(response);
          response.map(
            (category, index) =>
              (newFilterName[`delivery_${category.preference}`] =
                category.description)
          );
          setFilterName(newFilterName);
          setDeliveryPreferences(`delivery_${response[0].preference}`);
        }
      });
    });

    itemApi.getItemsFieldsTrue(slug, function (response) {
      if (response) {
        setConditionFilters(response);
      }
    });
  }, []);

  React.useEffect(() => {
    fetchMoreData(null, 1, null);
  }, [isDetailsPage]);

  const applyfilters = (filter, sortValue, cityFilterData) => {
    setItems([]);
    setEncodedFilterString(filter);
    setSortFilterString(sortValue);
    setEncodedCityFilterString(cityFilterData);
    fetchMoreData(filter, sortValue, cityFilterData);
  },
    [index, setIndex] = React.useState(5),
    [state1, setState1] = React.useState({
      right: false,
      bottom: false,
    }),
    toggleDrawer = (side, open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setState1({ ...state1, [side]: open });
    },
    categoriesMenu = () => {
      return (
        <FormGroup>
          {categories.map((category, index) => {
            return category.status ? (
              <FormControlLabel
                key={category.id}
                control={
                  <Checkbox
                    // onChange={handleCategoriesFilterChange}
                    value={category.name}
                    checked={
                      selectedCategoryFilterValues[category.id] ? true : false
                    }
                    disabled={category.status == 0 ? true : false}
                    name={category.id}
                    id={category.name}
                  />
                }
                label={category.name}
                className={mobileStyles.formControlLabel}
              />
            ) : (
                ""
              );
          })}
        </FormGroup>
      );
    },
    deliveryMenu = () => {
      return (
        <RadioGroup
          aria-label='delivery_pref'
          name='delivery_pref'
          value={deliveryPreferences}
          onChange={handleSelectPreference}
        >
          {/* <FormControlLabel value='delivery_' control={<Radio name="delivery_pref" id="ch" color='primary' />}label={'Any'}/> */}
          {DeliveryPreference.map((category, index) => (
            <FormControlLabel
              value={"delivery_" + category.preference}
              data_delivery={category.description}
              disabled={category.status == 0 ? true : false}
              control={
                <Radio
                  name='delivery_pref'
                  data_desc={category.description}
                  color='primary'
                />
              }
              label={category.description}
              className={mobileStyles.formControlLabel}
            />
          ))}
        </RadioGroup>
      );
    },
    locationMenu = () => {
      return (
        <RadioGroup
          aria-label='ship_pref'
          name='ship_pref'
          value={shippingPreferences}
          onChange={handleSelectPreference}>
          {shippingPreference.map((category, index) => (
            <FormControlLabel
              disabled={category.status == 0 ? true : false}
              control={
                <Radio
                  value={"shipping_" + category.preference}
                  name='ship_pref'
                  color='primary'
                />
              }
              label={category.description}
              className={mobileStyles.formControlLabel}
            />
          ))}
        </RadioGroup>
      );
    },
    conditionFiltersMenu = () => {
      return (
        <FormGroup>
          {conditionFilterValues.map((category, index) => {
            return (
              <FormControlLabel
                key={index}
                disabled={category.value === "Needs Repair" ? true : false}
                control={
                  <Checkbox
                    onChange={handleConditionFilterChange}
                    value={category.value}
                    checked={
                      selectedConditionFilterValues[category.choice_id]
                        ? true
                        : false
                    }
                    name={category.choice_id}
                    id={"id_" + category.choice_id}
                  />
                }
                label={category.value}
                className={mobileStyles.formControlLabel}
              />
            );
          })}
        </FormGroup>
      );
    },
    postedMenu = () => {
      return (
        <RadioGroup
          aria-label='posted'
          name='posted'
          value={posted}
          onChange={handleSelectPreference}
        >
          <FormControlLabel
            control={<Radio value='' name='posted' id='ch' color='primary' />}
            label={"All"}
            className={mobileStyles.formControlLabel}
          />
          <FormControlLabel
            control={
              <Radio value='today' name='posted' id='ch' color='primary' />
            }
            label={"Today"}
            className={mobileStyles.formControlLabel}
          />
          <FormControlLabel
            control={
              <Radio value='week_ago' name='posted' id='ch' color='primary' />
            }
            label={"Last 7 days"}
            className={mobileStyles.formControlLabel}
          />
          <FormControlLabel
            control={
              <Radio value='month_ago' name='posted' id='ch' color='primary' />
            }
            label={"Last 30 days"}
            className={mobileStyles.formControlLabel}
          />
        </RadioGroup>
      );
    },
    availabilityMenu = () => {
      return (
        <RadioGroup
          aria-label='availabilityFilter'
          name='availabilityFilter'
          value={availability}
          onChange={handleSelectPreference}
        >
          <FormControlLabel
            control={
              <Radio value='' name='availabilityFilter' id='ch' color='primary' />
            }
            label={"All"}
            className={mobileStyles.formControlLabel}
          />
          <FormControlLabel
            control={
              <Radio
                value='given'
                name='availabilityFilter'
                id='ch'
                color='primary'
              />
            }
            label={"Gifted"}
            className={mobileStyles.formControlLabel}
          />
          <FormControlLabel
            control={
              <Radio
                value='submitted'
                name='availabilityFilter'
                id='ch'
                color='primary'
              />
            }
            label={"Available"}
            className={mobileStyles.formControlLabel}
          />
        </RadioGroup>
      );
    },
    [expanded, setExpanded] = React.useState([
      false,
      false,
      true,
      true,
      true,
      true,
    ]),
    handleToggleExapnsion = (index) => {
      let newExpanded = [...expanded];
      newExpanded[index] = !newExpanded[index];
      setExpanded(newExpanded);
    },
    [hasMore, setMore] = React.useState(true),
    [paginationKey, setPaginationKey] = React.useState({
      key: { limit: 9 },
    }),
    fetchMoreData = (filter, sortIndex, cityFilterData) => {
      let filterLocal = encodedFilterString;
      let pagination = paginationKey;
      let reset = false;
      let cityFilter = encodedCityFilterString;
      if (filter && filterLocal !== filter) {
        filterLocal = filter;
        pagination = { key: { limit: 9 } };
        setPaginationKey({ key: { limit: 9 } });
        reset = true;
      }

      if (filter === null || !filterLocal) {
        filterLocal = null;
      }

      if (cityFilterData || reset) {
        cityFilter = cityFilterData;
      }

      let sortIndexLocal = sortFilterString;
      if (sortIndex) {
        sortIndexLocal = sortIndex;
      }
      setLoading(true);
      itemApi.getItemsList(
        filterLocal,
        sortIndexLocal,
        pagination,
        cityFilter,
        function (response) {
          if (response && response.data) {
            if (items && items.length > 0) {
              if (reset) {
                setItems(response.data);
              } else {
                let merge = items.concat(response.data);
                setItems(merge);
              }
            } else {
              setItems(response.data);
            }
          }
          if (response.page && response.page && response.page.key) {
            let key = { ...response.page };
            key.limit = 9;
            setPaginationKey({ key });
            setMore(true);
          } else {
            setMore(false);
          }
          setLoading(false);
        },
        function (response, resStatus) {
          setLoading(false);
        }
      );
    },
    selectAllConditionFilter = () => {
      let selected = [];
      conditionFilterValues.map((category) => {
        selected[category.choice_id] = category.value;
      });
      setSelectedConditionFilterValues(selected);
      handleSelectPreference("condition", selected);
    },
    clearConditionFilter = () => {
      setSelectedConditionFilterValues([]);
      handleSelectPreference("condition", []);
    },
    isAnyFilterExist = () => {
      if (
        deliveryPreferences ||
        shippingPreferences ||
        posted ||
        availability ||
        selectedConditionFilterValues ||
        selectedCategoryFilterValues
      ) {
        return true;
      }
      return false;
    },
    createMobileFilterDOM = () => {
      const createMenu = () => {
        const categories = [
          { name: 'Categories', index: 0 },
          { name: 'Shipping', index: 1 },
          { name: 'Availability', index: 5 },
          { name: 'Condition', index: 3 },
          { name: 'Location', index: 2 },
          { name: 'Posted', index: 4 }
        ];

        return categories.map((category) => {
          return (
            <Typography
              className={`${classes.filterItem} ${category.index === index ? classes.activeFilter : ''}`}
              variant='subtitle1'
              onClick={(e) => setIndex(category.index)}
              component='div'>
              {category.name}<div></div>
            </Typography>
          )
        })
      };

      const showActiveMenu = () => {

        const activeMenu = (menu) => {
          return (
            <Box className={mobileStyles.filterSection}>
              <FormControl component='fieldset' className={classes.formControl}>
                {menu()}
              </FormControl>
            </Box>
          );
        };

        const activeMenuWithSearch = (menu) => {
          return (
            <Box className={mobileStyles.filterSection}>

              {/* TODO, when mobile filter design is ready*/}
              {filterName[`${shippingPreferences}`]?.toLowerCase() ==
                "city" && (
                  <Box className={location.cityWrapper}>
                    <Autocomplete
                      id='city-search_id'
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option
                      }
                      filterOptions={(x) => x}
                      options={options}
                      autocomplete='off'
                      includeInputInList
                      disableOpenOnFocus
                      loading={true}
                      loadingText='Loading'
                      name='city'
                      // onClose={updateState}
                      onChange={handleSelectChange}
                      value={city && city.split(",")[0]}
                      style={{
                        width: "100%",
                        marginBottom: "5px",
                        padding: "0px !important",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="City"
                          fullWidth
                          name='city'
                          placeholder='Enter City'
                          autocomplete='off'
                          style={{ padding: "0px !important" }}
                          InputProps={{
                            ...params.InputProps,
                          }}
                          margin='normal'
                          onChange={handleChange}
                        />
                      )}
                      renderOption={(option) => {
                        return (
                          <Grid container alignItems='center'>
                            <Grid item xs>
                              <Typography
                                variant='body2'
                                color='textSecondary'
                              >
                                <span countryCode={option.country_code}>
                                  {option.city}
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      }}
                    />
                    <Box className={classes.filterSearch}>
                      <Button
                        color='primary'
                        className={location.saveBtn}
                        style={{ marginRight: "13px" }}
                        onClick={() => handleCity()}
                        // disabled={city && area ? false : true}
                        disabled={city ? false : true}
                      >
                        Search
                  </Button>
                      <Button
                        color='primary'
                        disabled={!isLoggedIn}
                        className={location.saveBtn}
                        style={{ marginRight: "13px" }}
                        onClick={() => updateProfile()}
                        // disabled={isLoggedIn && city && area ? false : true}
                        disabled={isLoggedIn && city ? false : true}
                      >
                        Set as default
                  </Button>
                    </Box>
                  </Box>
                )}
              {filterName[`${shippingPreferences}`]?.toLowerCase() ==
                "neighborhood" && (
                  <Box className={location.cityWrapper}>
                    <TextField
                      type='text'
                      name='area'
                      placeholder='Enter neighborhood'
                      fullWidth
                      value={area}
                      onChange={(e) => {
                        setArea(e.target.value);
                      }}
                      style={{ marginBottom: "5px" }}
                    />
                    <Box
                      className={classes.filterSearch}
                    >
                      <Button
                        color='primary'
                        className={location.saveBtn}
                        style={{ marginRight: "13px" }}
                        onClick={() => handleArea()}
                        disabled={area ? false : true}
                      >
                        Search
                  </Button>
                      <Button
                        color='primary'
                        className={location.saveBtn}
                        style={{ marginRight: "13px" }}
                        onClick={() => updateProfile()}
                        disabled={isLoggedIn && area ? false : true}
                      >
                        Set as default
                  </Button>
                    </Box>
                  </Box>
                )}

              <FormControl component='fieldset' className={classes.formControl}>
                {menu()}
              </FormControl>
            </Box>
          );
        }

        switch (index) {
          case 0:
            return activeMenu(categoriesMenu)
          case 1:
            return activeMenu(deliveryMenu)
          case 2:
            return activeMenuWithSearch(locationMenu)
          case 3:
            return activeMenu(conditionFiltersMenu)
          case 4:
            return activeMenu(postedMenu)
          case 5:
            return activeMenu(availabilityMenu)
          default:
            return null
        }
      };

      return (
        <Drawer
          className={classes.bottomDrawer}
          anchor='bottom' open={state1.bottom}
          onClose={toggleDrawer("bottom", false)}
        >
          <Box className={mobileStyles.filterHeaderSection}>
            <Typography variant='h3' component='div'>
              Filters
          </Typography>
            <Button variant='subtitle2' component='div' onClick={clearFilter} disabled={!isFilterSet}>
              Clear
          </Button>
          </Box>

          <Box display='flex' height='80%'>
            <Box className={mobileStyles.filterMenuWrapper}>
              {createMenu()}
            </Box>

            {showActiveMenu()}

          </Box>

          <Box className={classes.fixedButtonSection} height='10%'>
            <Button
              variant='outlined'
              onClick={toggleDrawer("bottom", false)}
              color='secondary'
            >
              Close
          </Button>
            <Button
              variant='contained'
              onClick={toggleDrawer("bottom", false)}
              color='primary'
            >
              Apply
          </Button>
          </Box>
        </Drawer>)
    };

  return (
    <>
      {isDetailsPage === true ? (
        displayDetails()
      ) : (
          <FormGroup>
            {createMobileFilterDOM()}
            <Grid container className={classes.container}>
              <Grid item xs={12} className={classes.filterChipsContainer}>
                <>
                  {isAnyFilterExist && (
                    <Grid className={classes.filtersWrapper} item xs={12}>
                      <Grid xs={2} style={{ marginRight: '20px', maxWidth: '15%', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='h4' component='span'>
                          Filters
                          </Typography>
                        <Button
                          color='primary'
                          className={location.saveBtn}
                          onClick={clearFilter}
                          disabled={!isFilterSet}
                          style={{ fontWeight: '700', fontSize: '14px' }}
                        >
                          Clear All
                        </Button>
                      </Grid>
                      <Grid xs={10} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <section className={classes.filters} style={{ display: 'flex', marginLeft: '15px' }}>
                          {selectedCategoryFilterValues &&
                            selectedCategoryFilterValues.map(function (
                              value,
                              key
                            ) {
                              if (value) {
                                return (
                                  <div className={classes.filterChip}>
                                    <Typography
                                      className={classes.filterText}
                                      variant='subtitle2'
                                      style={{
                                        width: "unset",
                                        paddingRight: "5px",
                                      }}
                                      component='div'
                                    >
                                      {value}
                                    </Typography>
                                    {value !== "Books" && (
                                      <Typography
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          border: "1px solid #dedede",
                                        }}
                                        component='span'
                                      >
                                        <svg
                                          width='8px'
                                          height='8px'
                                          viewBox='0 0 8 8'
                                          onClick={(e) => {
                                            value !== "Books"
                                              ? handleRemoveCategoryFilterFromChip(
                                                key
                                              )
                                              : null;
                                          }}
                                        >
                                          <g
                                            id='Archive'
                                            stroke='none'
                                            strokeWidth='1'
                                            fill='none'
                                            fillRule='evenodd'
                                          >
                                            <g
                                              id='SVGs'
                                              transform='translate(-443.000000, -82.000000)'
                                              fill='#717171'
                                            >
                                              <g
                                                id='Icon-/-share-Copy-2'
                                                transform='translate(443.000000, 82.000000)'
                                              >
                                                <path
                                                  d='M8.68005842,0 L10,1.31994158 L6.319,4.999 L10.0000001,8.68005842 L8.68005842,10.0000001 L5,6.319 L1.31994158,10 L-1.77635684e-15,8.68005842 L3.68,5 L8.90101202e-08,1.31994167 L1.31994167,8.90102259e-08 L5,3.68 L8.68005842,0 Z'
                                                  id='x'
                                                ></path>
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                      </Typography>
                                    )}
                                  </div>
                                );
                              }
                            })}
                          {deliveryPreferences && (
                            <div className={classes.filterChip}>
                              <Typography
                                className={classes.filterText}
                                variant='subtitle2'
                                style={{
                                  width: "unset",
                                  paddingRight: "5px",
                                }}
                                component='div'
                              >
                                {filterName[`${deliveryPreferences}`]}
                              </Typography>
                              {/* <CloseIcon style={{ fontSize: '1rem' }} onClick={e=>{setDeliveryPreferences(''); handleSelectPreference('delivery')}} /> */}
                              {filterName[`${deliveryPreferences}`] !==
                                "Pickup" && (
                                  <svg
                                    width='10px'
                                    height='10px'
                                    viewBox='0 0 10 10'
                                    onClick={(e) => {
                                      filterName[`${deliveryPreferences}`] !==
                                        "Pickup"
                                        ? () => {
                                          setDeliveryPreferences("");
                                          handleSelectPreference(
                                            "delivery_pref"
                                          );
                                        }
                                        : null;
                                    }}
                                  >
                                    <g
                                      id='Archive'
                                      stroke='none'
                                      strokeWidth='1'
                                      fill='none'
                                      fillRule='evenodd'
                                    >
                                      <g
                                        id='SVGs'
                                        transform='translate(-443.000000, -82.000000)'
                                        fill='#717171'
                                      >
                                        <g
                                          id='Icon-/-share-Copy-2'
                                          transform='translate(443.000000, 82.000000)'
                                        >
                                          <path
                                            d='M8.68005842,0 L10,1.31994158 L6.319,4.999 L10.0000001,8.68005842 L8.68005842,10.0000001 L5,6.319 L1.31994158,10 L-1.77635684e-15,8.68005842 L3.68,5 L8.90101202e-08,1.31994167 L1.31994167,8.90102259e-08 L5,3.68 L8.68005842,0 Z'
                                            id='x'
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                )}
                            </div>
                          )}
                          {shippingPreferences && ((filterName[`${shippingPreferences}`]?.toLowerCase() == "city" && isCitySet) ||
                            (filterName[`${shippingPreferences}`]?.toLowerCase() == "neighborhood" && isAreaSet) || (filterName[`${shippingPreferences}`]?.toLowerCase() != "city" && filterName[`${shippingPreferences}`]?.toLowerCase() != "neighborhood"))
                            && (
                              <div className={classes.filterChip}>
                                <Typography
                                  className={classes.filterText}
                                  variant='subtitle2'
                                  style={{
                                    width: "unset",
                                    paddingRight: "5px",
                                  }}
                                  component='div'
                                >
                                  {filterName[`${shippingPreferences}`]}
                                </Typography>
                                {/* <CloseIcon style={{ fontSize: '1rem' }} onClick={e=>{setShippingPreferences(''); handleSelectPreference('shipping')}} /> */}
                                <svg
                                  width='10px'
                                  height='10px'
                                  viewBox='0 0 10 10'
                                  onClick={(e) => {
                                    setShippingPreferences("");
                                    handleSelectPreference("ship_pref");
                                  }}
                                >
                                  <g
                                    id='Archive'
                                    stroke='none'
                                    strokeWidth='1'
                                    fill='none'
                                    fillRule='evenodd'
                                  >
                                    <g
                                      id='SVGs'
                                      transform='translate(-443.000000, -82.000000)'
                                      fill='#717171'
                                    >
                                      <g
                                        id='Icon-/-share-Copy-2'
                                        transform='translate(443.000000, 82.000000)'
                                      >
                                        <path
                                          d='M8.68005842,0 L10,1.31994158 L6.319,4.999 L10.0000001,8.68005842 L8.68005842,10.0000001 L5,6.319 L1.31994158,10 L-1.77635684e-15,8.68005842 L3.68,5 L8.90101202e-08,1.31994167 L1.31994167,8.90102259e-08 L5,3.68 L8.68005842,0 Z'
                                          id='x'
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            )}
                          {posted && (
                            <div className={classes.filterChip}>
                              <Typography
                                className={classes.filterText}
                                variant='subtitle2'
                                style={{
                                  width: "unset",
                                  paddingRight: "5px",
                                }}
                                component='div'
                              >
                                {filterName[`posted_${posted}`]}
                              </Typography>
                              {/* <CloseIcon style={{ fontSize: '1rem' }} onClick={e=>{setPosted(''); handleSelectPreference('post')}} /> */}
                              <svg
                                width='10px'
                                height='10px'
                                viewBox='0 0 10 10'
                                onClick={(e) => {
                                  setPosted("");
                                  handleSelectPreference("posted");
                                }}
                              >
                                <g
                                  id='Archive'
                                  stroke='none'
                                  strokeWidth='1'
                                  fill='none'
                                  fillRule='evenodd'
                                >
                                  <g
                                    id='SVGs'
                                    transform='translate(-443.000000, -82.000000)'
                                    fill='#717171'
                                  >
                                    <g
                                      id='Icon-/-share-Copy-2'
                                      transform='translate(443.000000, 82.000000)'
                                    >
                                      <path
                                        d='M8.68005842,0 L10,1.31994158 L6.319,4.999 L10.0000001,8.68005842 L8.68005842,10.0000001 L5,6.319 L1.31994158,10 L-1.77635684e-15,8.68005842 L3.68,5 L8.90101202e-08,1.31994167 L1.31994167,8.90102259e-08 L5,3.68 L8.68005842,0 Z'
                                        id='x'
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          )}
                          {availability && (
                            <div className={classes.filterChip}>
                              <Typography
                                className={classes.filterText}
                                variant='subtitle2'
                                style={{
                                  width: "unset",
                                  paddingRight: "5px",
                                }}
                                component='div'
                              >
                                {filterName[`avail_${availability}`]}
                              </Typography>
                              {/* <CloseIcon style={{ fontSize: '1rem' }} onClick={e=>{setAvailability(''); handleSelectPreference('avail')}} /> */}
                              <svg
                                width='10px'
                                height='10px'
                                viewBox='0 0 10 10'
                                onClick={(e) => {
                                  setAvailability("");
                                  handleSelectPreference("availabilityFilter");
                                }}
                              >
                                <g
                                  id='Archive'
                                  stroke='none'
                                  strokeWidth='1'
                                  fill='none'
                                  fillRule='evenodd'
                                >
                                  <g
                                    id='SVGs'
                                    transform='translate(-443.000000, -82.000000)'
                                    fill='#717171'
                                  >
                                    <g
                                      id='Icon-/-share-Copy-2'
                                      transform='translate(443.000000, 82.000000)'
                                    >
                                      <path
                                        d='M8.68005842,0 L10,1.31994158 L6.319,4.999 L10.0000001,8.68005842 L8.68005842,10.0000001 L5,6.319 L1.31994158,10 L-1.77635684e-15,8.68005842 L3.68,5 L8.90101202e-08,1.31994167 L1.31994167,8.90102259e-08 L5,3.68 L8.68005842,0 Z'
                                        id='x'
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          )}
                          {selectedConditionFilterValues &&
                            selectedConditionFilterValues.map(function (
                              value,
                              key
                            ) {
                              if (value) {
                                return (
                                  <div className={classes.filterChip}>
                                    <Typography
                                      className={classes.filterText}
                                      variant='subtitle2'
                                      style={{
                                        width: "unset",
                                        paddingRight: "5px",
                                      }}
                                      component='div'
                                    >
                                      {value}
                                    </Typography>
                                    <svg
                                      width='10px'
                                      height='10px'
                                      viewBox='0 0 10 10'
                                      onClick={(e) => {
                                        handleRemoveConditionFilterFromChip(
                                          key
                                        );
                                      }}
                                    >
                                      <g
                                        id='Archive'
                                        stroke='none'
                                        strokeWidth='1'
                                        fill='none'
                                        fillRule='evenodd'
                                      >
                                        <g
                                          id='SVGs'
                                          transform='translate(-443.000000, -82.000000)'
                                          fill='#717171'
                                        >
                                          <g
                                            id='Icon-/-share-Copy-2'
                                            transform='translate(443.000000, 82.000000)'
                                          >
                                            <path
                                              d='M8.68005842,0 L10,1.31994158 L6.319,4.999 L10.0000001,8.68005842 L8.68005842,10.0000001 L5,6.319 L1.31994158,10 L-1.77635684e-15,8.68005842 L3.68,5 L8.90101202e-08,1.31994167 L1.31994167,8.90102259e-08 L5,3.68 L8.68005842,0 Z'
                                              id='x'
                                            ></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                  </div>
                                );
                              }
                            })}
                        </section>

                        <div style={{ display: "flex", alignItems: "center", border: '1px solid #dedede', borderRadius: '4px', padding: '0px 0px 1px 8px' }}>
                          <InputLabel id='sort-by-label' className={classes.labelAutoWidth}>
                            <Typography variant='h4' component='div' style={{ fontWeight: '400' }}>
                              Sort by:&nbsp;
                      </Typography>
                          </InputLabel>
                          <FormControl
                            className={classes.selectSortBy}
                            style={{
                              minWidth: "unset",
                              fontWeight: "unset",
                              marginTop: "1.5 rem",
                            }}>
                            <Select
                              labelId='sort-by-label'
                              value={selectedFilterIndex}
                              onChange={handleSortByChange}
                              style={{ minWidth: "unset", margin: "0" }}
                            >
                              <MenuItem value={1} style={{ minWidth: "unset" }}>
                                Newest First
                        </MenuItem>
                              <MenuItem value={2} style={{ minWidth: "unset" }}>
                                Oldest First
                        </MenuItem>
                              {/* <MenuItem value={30} disabled={true} >User Rating (High to Low)</MenuItem>
                                    <MenuItem value={40} disabled={true} >User Rating (Low to High)</MenuItem>
                                    <MenuItem value={50} disabled={true} >Most Popular</MenuItem> */}
                            </Select>
                          </FormControl>
                        </div>


                      </Grid>


                    </Grid>
                  )}
                </>
              </Grid>

              <Grid style={{ display: "flex", minHeight: "400px" }} item xs={12}>
                <Grid item xs={2} style={{ marginRight: '20px', maxWidth: '15%' }}>
                  <ExpansionPanel
                    defaultExpanded={expanded[0]}
                    style={{ boxShadow: "none" }}
                    onChange={() => handleToggleExapnsion(0)}>
                    <ExpansionPanelSummary
                      expandIcon={
                        expanded[0] ? (
                          <RemoveIcon onClick={() => handleToggleExapnsion(0)} />
                        ) : (
                            <AddIcon onClick={() => handleToggleExapnsion(0)} />
                          )
                      }>
                      <Typography variant='h4' component='div'>
                        Categories{expanded[0]}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <FormControl
                        component='fieldset'
                        className={classes.formControl}>
                        {categoriesMenu()}
                      </FormControl>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    defaultExpanded={expanded[1]}
                    style={{ boxShadow: "none" }}
                    onChange={() => handleToggleExapnsion(1)}>
                    <ExpansionPanelSummary
                      expandIcon={
                        expanded[1] ? (
                          <RemoveIcon onClick={() => handleToggleExapnsion(1)} />
                        ) : (
                            <AddIcon onClick={() => handleToggleExapnsion(1)} />
                          )
                      }>
                      <Typography variant='h4' component='div'>
                        Shipping
                    </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <FormControl
                        component='fieldset'
                        className={classes.formControl}>
                        {deliveryMenu()}
                      </FormControl>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    defaultExpanded={expanded[5]}
                    style={{ boxShadow: "none" }}
                    onChange={() => handleToggleExapnsion(5)}>
                    <ExpansionPanelSummary
                      expandIcon={
                        expanded[5] ? (
                          <RemoveIcon onClick={() => handleToggleExapnsion(5)} />
                        ) : (
                            <AddIcon onClick={() => handleToggleExapnsion(4)} />
                          )
                      }>
                      <Typography variant='h4' component='div'>
                        Availability
                    </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <FormControl
                        component='fieldset'
                        className={classes.formControl}>
                        {availabilityMenu()}
                      </FormControl>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    defaultExpanded={expanded[3]}
                    style={{ boxShadow: "none" }}
                    onChange={() => handleToggleExapnsion(3)}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        expanded[3] ? (
                          <RemoveIcon onClick={() => handleToggleExapnsion(3)} />
                        ) : (
                            <AddIcon onClick={() => handleToggleExapnsion(3)} />
                          )
                      }>
                      <Typography variant='h4' component='div'>
                        Condition
                    </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "0 0 5px",
                        }}>
                        <Button
                          // color='primary'
                          className={location.saveBtn}
                          onClick={() => selectAllConditionFilter()}>
                          Select All
                      </Button>
                        <Button
                          color='primary'
                          className={location.saveBtn}
                          onClick={() => clearConditionFilter()}
                          disabled={!selectedConditionFilterValues?.some(val => val)}
                        >
                          Clear
                      </Button>
                      </div>
                      <FormControl
                        component='fieldset'
                        className={classes.formControl}>
                        {conditionFiltersMenu()}
                      </FormControl>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    defaultExpanded={expanded[2]}
                    style={{ boxShadow: "none" }}
                    onChange={() => handleToggleExapnsion(2)}>
                    <ExpansionPanelSummary
                      expandIcon={
                        expanded[2] ? (
                          <RemoveIcon onClick={() => handleToggleExapnsion(2)} />
                        ) : (
                            <AddIcon onClick={() => handleToggleExapnsion(2)} />
                          )
                      }>
                      <Typography variant='h4' component='div'>
                        Location
                      <HtmlTooltip
                          title={"Enter city and neighborhood to search."}>
                          <InfoOutlinedIcon
                            style={{ color: "#22B11F", padding: "2px", fontSize: "20px", position: "relative", top: "5px", left: "2px" }} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
                          ></InfoOutlinedIcon>
                        </HtmlTooltip>
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      style={{ display: "flex", flexDirection: "column" }}>
                      {filterName[`${shippingPreferences}`]?.toLowerCase() ==
                        "city" && (
                          <Box className={location.cityWrapper}>
                            <Autocomplete
                              id='city-search_id'
                              getOptionLabel={(option) =>
                                typeof option === "string" ? option : option
                              }
                              filterOptions={(x) => x}
                              options={options}
                              autocomplete='off'
                              includeInputInList
                              disableOpenOnFocus
                              loading={true}
                              loadingText='Loading'
                              name='city'
                              onChange={handleSelectChange}
                              value={city && city.split(",")[0]}
                              style={{
                                width: "100%",
                                marginBottom: "5px",
                                padding: "0px !important",
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  name='city'
                                  placeholder='Enter City'
                                  autocomplete='off'
                                  style={{ padding: "0px !important" }}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                  margin='normal'
                                  onChange={handleChange}
                                />
                              )}
                              renderOption={(option) => {
                                return (
                                  <Grid container alignItems='center'>
                                    <Grid item xs>
                                      <Typography
                                        variant='body2'
                                        color='textSecondary'
                                      >
                                        <span countryCode={option.country_code}>
                                          {option.city}
                                        </span>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                );
                              }}
                            />
                            <Box
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-around",
                              }}
                            >
                              <Button
                                color='primary'
                                className={location.saveBtn}
                                style={{ marginRight: "47px" }}
                                onClick={() => handleCity()}
                                // disabled={city && area ? false : true}
                                disabled={city ? false : true}>
                                Search
                          </Button>
                              <Button
                                color='primary'
                                disabled={!isLoggedIn}
                                className={location.saveBtn}
                                onClick={() => updateProfile()}
                                // disabled={isLoggedIn && city && area ? false : true}
                                disabled={isLoggedIn && city ? false : true}>
                                Set as default
                          </Button>
                            </Box>
                          </Box>
                        )}
                      {filterName[`${shippingPreferences}`]?.toLowerCase() ==
                        "neighborhood" && (
                          <Box className={location.cityWrapper}>
                            <TextField
                              type='text'
                              name='area'
                              placeholder='Enter neighborhood'
                              fullWidth
                              value={area}
                              onChange={(e) => {
                                setArea(e.target.value);
                              }}
                              style={{ marginBottom: "5px" }}
                            />
                            <Box
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-around",
                              }}
                            >
                              <Button
                                color='primary'
                                className={location.saveBtn}
                                style={{ marginRight: "13px" }}
                                onClick={() => handleArea()}
                                disabled={area ? false : true}
                              >
                                Search
                          </Button>
                              <Button
                                color='primary'
                                className={location.saveBtn}
                                style={{ marginRight: "13px" }}
                                onClick={() => updateProfile()}
                                disabled={isLoggedIn && area ? false : true}
                              >
                                Set as default
                          </Button>
                            </Box>
                          </Box>
                        )}
                      <FormControl
                        component='fieldset'
                        className={classes.formControl}
                      >
                        {locationMenu()}
                      </FormControl>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    defaultExpanded={expanded[4]}
                    style={{ boxShadow: "none" }}
                    onChange={() => handleToggleExapnsion(4)}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        expanded[4] ? (
                          <RemoveIcon onClick={() => handleToggleExapnsion(4)} />
                        ) : (
                            <AddIcon onClick={() => handleToggleExapnsion(3)} />
                          )
                      }
                    >
                      <Typography variant='h4' component='div'>
                        Posted
                    </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <FormControl
                        component='fieldset'
                        className={classes.formControl}
                      >
                        {postedMenu()}
                      </FormControl>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  {isChecked === true
                    ? conditionFilters &&
                    conditionFilters.map((filter, index) => {
                      let newFilter = { ...filter, selectionVal: [] };
                      if (filter && filter.selection_values) {
                        newFilter.selection_values.map(function (selVal, i) {
                          newFilter.selectionVal[i] = selVal.value;
                        });
                        return (
                          <ExpansionPanel
                            style={{ boxShadow: "none" }}
                            onChange={() => handleToggleExapnsion(6)}
                          >
                            <ExpansionPanelSummary
                              expandIcon={
                                expanded[6] ? (
                                  <RemoveIcon
                                    onClick={() => handleToggleExapnsion(6)}
                                  />
                                ) : (
                                    <AddIcon
                                      onClick={() => handleToggleExapnsion(5)}
                                    />
                                  )
                              }
                            >
                              <Typography variant='h4' component='div'>
                                {newFilter.label}
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <FormControl
                                component='fieldset'
                                className={classes.formControl}
                              >
                                <FormGroup>
                                  {/*newFilter.selectionVal.map((val,i)=>{*/}
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={newFilter.selectionVal[0]}
                                        name='categoryFilter'
                                        id='ch'
                                      />
                                    }
                                    label={newFilter.selectionVal[0]}
                                  />
                                  {/*})*/}
                                </FormGroup>
                              </FormControl>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        );
                      }
                    })
                    : ""}
                </Grid>
                <Grid item xs={10}>
                  <div className={mobileStyles.filterContainer}>
                    <div
                      className={mobileStyles.filterLabelWrapper}
                      onClick={toggleDrawer("bottom", true)}>
                      <img src='/assets/images/icon_filter.svg' alt='Filter' />
                      <Typography
                        variant='subtitle2'
                        component='div'
                        className={mobileStyles.filterLabel}>
                        Filter
                    </Typography>
                    </div>
                    <div className={mobileStyles.filterLabelWrapper}>
                      <SortMenuItems
                        mobileStyles={mobileStyles}
                        selectedFilterIndex={selectedFilterIndex}
                        handleSortByChange={handleSortByChange}
                      />
                    </div>
                  </div>
                  {/* {loading ? <LoadingIcon loadingMsg="Loading ..."/> : ''} */}
                  <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={hasMore}>
                    <section className={classes.productList}>
                      {items && items.length == 0 && loading == false ? (
                        <div
                          style={{
                            fontSize: "18px",
                            paddingTop: "50px",
                            width: "100%",
                            textAlign: "center",
                          }}>
                          <Typography
                            className={classes.noDataMsg}
                            component='div'
                            variant='body2'>
                            {isLoggedIn
                              ? `Sorry, we could not find any items. Please refine the
                          filters and try again.`
                              : "Please register or sign-in to see results."}
                          </Typography>
                        </div>
                      ) : ("")}
                      {items &&
                        items.map(function (item, index) {
                          let nemItem = {
                            ...item,
                            itemTitle: [],
                            itemImage: [],
                            category: [],
                            condition: [],
                            id: [],
                          };
                          if (item && item.title) {
                            let keys = Object.keys(item.title);
                            keys.map(function (itemTitle, i) {
                              nemItem.id[i] = itemTitle;
                              nemItem.itemTitle[i] = item.title[itemTitle];
                              let img = item.images[itemTitle].startsWith("http")
                                ? item.images[itemTitle]
                                : appSettings.MEDIA_URL + item.images[itemTitle];
                              nemItem.itemImage[i] = img;
                              nemItem.itemImage[i] = nemItem.itemImage[i].replace(
                                "\\",
                                "/"
                              );
                              nemItem.itemImage[i] = nemItem.itemImage[i].replace(
                                "\\",
                                "/"
                              );
                              let cat_condition = item.cat_condition[
                                itemTitle
                              ].split("_");
                              nemItem.category[i] = cat_condition[0];
                              nemItem.condition[i] = cat_condition[1];
                            });
                            if (nemItem.itemTitle.length < 1) return;
                            return (
                              <DataContext.Provider value={nemItem.item}>
                                <GetItem
                                  item={nemItem}
                                  user={storeUser}
                                  key={nemItem.bundle_id}
                                  itemClicked={itemDetailsClicked}
                                  setItem={setItem}
                                  conditionFilterValues={conditionFilterValues}
                                />
                              </DataContext.Provider>
                            );
                          } else {
                            return;
                          }
                        })}
                      {loading &&
                        skeletonNumbers.map(el => <div className={classes.cardSkeleton} key={el} >
                          <Skeleton variant="rect" width={289} height={206} animation="wave" />
                          <Skeleton variant="rect" style={{ margin: '8px' }} width={141} height={21} animation="wave" />
                          <Box display='flex' m={1} justifyContent='space-between'>
                            <Skeleton variant="rect" width={94} height={14} animation="wave" />
                            <Skeleton variant="rect" width={58} height={14} animation="wave" />
                          </Box>
                          <Box display='flex' m={1} mt={2} justifyContent='space-between' alignItems='center'>
                            <Box display='flex' alignItems='center'>
                              <Skeleton variant="circle" width={40} height={40} animation="wave" />
                              <Box ml={1} alignItems='center'>
                                <Skeleton variant="rect" width={58} height={14} animation="wave" />
                                <Skeleton variant="text" width={47} animation="wave" />
                              </Box>
                            </Box>
                            <Box display='flex' flexDirection='column' alignItems='flex-end'>
                              <Skeleton variant="rect" width={58} height={14} style={{ float: 'right' }} animation="wave" />
                              <Skeleton variant="text" width={68} style={{ float: 'right' }} animation="wave" />
                            </Box>
                          </Box>
                        </div>)
                      }
                    </section>
                  </InfiniteScroll>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <HorizontalAds></HorizontalAds>
              </Grid>
            </Grid>
          </FormGroup>
        )
      }
    </>
  );
};
