import { makeStyles } from '@material-ui/core/styles';

const NotificationStyle = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  bottomDrawer: {
    display: 'block',
    '& .MuiListItem-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '10px',
    },
    '& .MuiChip-sizeSmall': {
      height: '18px',
      fontSize: '11px',
      color: '#ffffff',
      marginBottom: '3px',
    },
    '& ..MuiAvatar-colorDefault': {
      backgroundColor: 'none',
    },
    '& .MuiAvatar-root': {
      height: '30px',
      width: '30px',
    },
    '& .MuiListItemAvatar-root': {
      minWidth: '40px',
    },
    '& .MuiDrawer-paperAnchorLeft': {
      left: 'auto',
      right: 0,
      top: '76px',
      width: '80%',
      height: '90%',
      maxWidth: '380px',
      [theme.breakpoints.down('sm')]: {
        top: '57px',
      },
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    letterSpacing: '0.11px',
    margin: '0',
  },
}));
export default NotificationStyle;
