import React from 'react';
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText,
  Box,
  Chip,
  Avatar,
  ListItemAvatar,
  Typography,
  makeStyles,
  Link,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { itemApi } from './../../api/itemApi';
import { useStoreState } from './../../hooks';
import LoadingIcon from '../ui/loadingIcon';
import Rating from '@material-ui/lab/Rating';
import NotificationStyle from './../../styles/notification.style';
import { navigate } from '@reach/router';
import moment from 'moment';
import { CloseOutlined, DraftsOutlined, MailOutline } from '@material-ui/icons';

import { NotificationContext } from './../../hooks/notificationContext';
import { notificationActions } from '../../constants/notification';
import { userApi } from '../../api';
const useStyles = makeStyles(() => ({
  kindeedsName: {
    fontSize: '16px',
    color: '#22b11f',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
}));

const GetAvatar = (props) => {
  const userObj = {
    name: null,
    user_avatar: null,
    city: null,
    user_rating: 0,
  };
  const [user, setUser] = React.useState(userObj);
  const [isAdmin, setAdmin] = React.useState(false);

  const kindeedsStyle = useStyles();

  React.useEffect(() => {
    let userHash = props.user_hash;
    if (!!userHash) {
      userApi.getUserSummaryByHash(userHash, function (response) {
        if (response) {
          let receiverData = { ...user };
          receiverData.name = response[0].display_name;
          receiverData.city = response[0].u_city;
          receiverData.user_avatar = response[0].user_avatar;
          receiverData.user_rating = response[0].user_rating;
          let isAdmin = response[0].display_name
            ?.toLowerCase()
            .includes('kindeed')
            ? true
            : false;
          setUser(receiverData);
          setAdmin(isAdmin);
        }
      });
    }
  }, []);

  return (
    !!props.user_hash && (
      <>
        <ListItemAvatar>
          <Avatar
            className={kindeedsStyle.kindeedsName}
            src={
              user.user_avatar
                ? user.user_avatar
                : isAdmin
                ? 'assets/avatars/kindeeds-Avtar.svg'
                : ''
            }
          >
            {!isAdmin && user.name && user.name.charAt(0)}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={
            <Typography
              variant='subtitle2'
              component='div'
              style={{
                fontSize: '12px',
              }}
            >
              {user.name}
            </Typography>
          }
          style={{
            display: 'inline-flex',
            fontSize: '11px',
            alignItems: 'center',
          }}
          secondary={
            <Rating
              name='test'
              style={{ marginLeft: '10px', fontSize: '12px' }}
              value={user.user_rating}
              disabled
            />
          }
        />
      </>
    )
  );
};

export { GetAvatar };

const USER_ROLE = {
  GIVER: 'giver',
  RECEIVER: 'receiver',
  USER: 'user',
  ADMIN: 'admin',
};

const NOTIFICATION_STATUS = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export default function Notification(props) {
  const { setUnreadNotificationCount } = React.useContext(NotificationContext);
  const classes = NotificationStyle(),
    [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: true,
      right: false,
    }),
    toggleDrawer = (anchor, open) => (event) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    },
    isLoggedIn = useStoreState((state) => state.auth.isLoggedIn),
    [notifications, setNotifications] = React.useState(null),
    [loading, setLoading] = React.useState(false),
    [notificationFilter, setNotificationFilter] = React.useState({});

  React.useEffect(() => {
    if (isLoggedIn) {
      getNotificationData(notificationFilter);
    }
  }, []);

  const getNotificationData = (notificationFilter) => {
    setLoading(true);
    itemApi.getNotifications(notificationFilter, (response) => {
      console.log(response);
      setNotifications(response);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    getNotificationData(notificationFilter);
  }, [notificationFilter]);

  const updateNotificationById = (notificationId, action) => {
    itemApi.updateNotificationById(notificationId, action, (response) => {
      console.log(response);
      let unreadMsg = null;
      if (action === notificationActions.MARK_AS_READ) {
        notifications.map((notification) => {
          // Change read true
          notification.id === notificationId && (notification.read = true);
          notification.read === false && (unreadMsg = true);
        });
        setNotifications([...notifications], notifications);
      } else if (action === notificationActions.MARK_AS_UNREAD) {
        notifications.map((notification) => {
          notification.id === notificationId && (notification.read = false);
          notification.read === false && (unreadMsg = true);
        });
        setNotifications([...notifications], notifications);
      } else {
        // Need to do other action types
        console.log(action);
      }
      // Need to set exact unread count if we want to show number of notification in notication icon's badge
      unreadMsg === true
        ? setUnreadNotificationCount(1)
        : setUnreadNotificationCount(0);
    });
  };

  const updateNotifications = (action) => {
    itemApi.updateNotifications(action, (response) => {
      console.log(response);
      if (action === notificationActions.MARK_ALL_AS_READ) {
        notifications.map((notification) => (notification.read = true));
        setNotifications([...notifications], notifications);
        setUnreadNotificationCount(0);
      } else if (action === notificationActions.MARK_ALL_AS_UNREAD) {
        notifications.map((notification) => (notification.read = false));
        setNotifications([...notifications], notifications);
        // Need to set exact unread count if we want to show number of notification in notication icon's badge
        setUnreadNotificationCount(1);
      } else {
        // Need to do other action types
        console.log(action);
      }
    });
  };

  const notificationTemplate = {
    create_request_giver: {
      notificationType: 'New Request',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.GIVER,
    },
    create_request_receiver: {
      notificationType: 'Request Sent',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.RECEIVER,
    },
    accept_request_giver: {
      notificationType: 'Request Accepted',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.GIVER,
    },
    accept_request_receiver: {
      notificationType: 'Request Accepted',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.RECEIVER,
    },
    confirm_request_giver: {
      notificationType: 'Request Confirmed',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.GIVER,
    },
    confirm_request_receiver: {
      notificationType: 'Request Confirmed',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.RECEIVER,
    },
    item_given_giver: {
      notificationType: 'Item Given',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.GIVER,
    },
    item_given_receiver: {
      notificationType: 'Item Received',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.RECEIVER,
    },
    item_not_given_giver: {
      notificationType: 'Request Declined',
      status: NOTIFICATION_STATUS.ERROR,
      role: USER_ROLE.GIVER,
    },
    item_not_given_receiver: {
      notificationType: 'Request Declined',
      status: NOTIFICATION_STATUS.ERROR,
      role: USER_ROLE.RECEIVER,
    },

    email_already_confirmed: {
      notificationType: 'Verification',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.USER,
    },
    item_created: {
      notificationType: 'Item Published',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.USER,
    },
    registration: {
      notificationType: 'Registration',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.USER,
    },
    email_confirmation: {
      notificationType: 'Confirmation',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.USER,
    },
    password_change_notification: {
      notificationType: 'Password Changed',
      status: NOTIFICATION_STATUS.SUCCESS,
      role: USER_ROLE.USER,
    },
  };

  return (
    <div>
      <Drawer
        className={classes.bottomDrawer}
        open={state['bottom']}
        onClose={toggleDrawer('bottom', false)}
      >
        <div
          role='presentation'
          // onClick={toggleDrawer("bottom", false)}
          onKeyDown={toggleDrawer('bottom', false)}
        >
          {loading ? (
            <Box margin='0 20px 20px 0'>
              <LoadingIcon />
            </Box>
          ) : (
            <List>
              <Box
                style={{
                  width: '95%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Box style={{ display: 'flex' }}>
                  {/* <ListItem
                key={"Notifications"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              > */}
                  <ListItemText
                    primary={
                      !!notifications
                        ? 'Notifications (' + notifications.length + ')'
                        : 'Notifications'
                    }
                  />

                  {/* </ListItem> */}

                  <CloseOutlined onClick={toggleDrawer('bottom', false)} />
                </Box>

                <Box
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Box style={{ display: 'flex' }}>
                    <Link
                      variant='subtitle2'
                      style={{ fontSize: '12px', cursor: 'pointer' }}
                      onClick={() => setNotificationFilter({ read: false })}
                    >
                      Unread
                    </Link>
                    <Link
                      variant='subtitle2'
                      style={{
                        fontSize: '12px',
                        marginLeft: '5px',
                        cursor: 'pointer',
                      }}
                      onClick={() => setNotificationFilter({})}
                    >
                      View all
                    </Link>
                    {/* <ListItemText primary={<Typography variant='subtitle2' style={{fontSize:'12px'}}></Typography>}/>
                  <ListItemText primary={<Typography variant='subtitle2' style={{fontSize:'12px'}}>View all</Typography>}/> */}
                  </Box>
                  <Box>
                    <Link
                      variant='subtitle2'
                      style={{ fontSize: '12px', cursor: 'pointer' }}
                      onClick={() =>
                        updateNotifications(
                          notificationActions.MARK_ALL_AS_READ
                        )
                      }
                    >
                      Mark all as read
                    </Link>
                    {/* <ListItemText primary={<Typography variant='subtitle2' style={{fontSize:'12px'}}>Mark all as read</Typography> }/> */}
                  </Box>
                </Box>
              </Box>

              <Divider />
              {notifications &&
                notifications.map((notification, index) => (
                  // {['Inbox', 'All'].map((text, index) => (
                  <ListItem
                    button
                    key={notification.id}
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Chip
                          size='small'
                          // icon={<FaceIcon />}
                          label={
                            notificationTemplate[notification.notification_type]
                              ?.notificationType
                          }
                          clickable
                          style={{
                            backgroundColor:
                              notificationTemplate[
                                notification.notification_type
                              ].status === NOTIFICATION_STATUS.SUCCESS
                                ? '#22B11F'
                                : notificationTemplate[
                                    notification.notification_type
                                  ].status === NOTIFICATION_STATUS.ERROR
                                ? '#5352ed'
                                : notificationTemplate[
                                    notification.notification_type
                                  ].status === NOTIFICATION_STATUS.WARNING
                                ? '#ff1744'
                                : '#22B11F',
                          }}
                        />
                        {notification.read ? (
                          <Tooltip title='Mark as unread' arrow>
                            <IconButton
                              aria-label='mark-as-unread'
                              style={{ marginLeft: '10px' }}
                              onClick={() => {
                                updateNotificationById(
                                  notification.id,
                                  notificationActions.MARK_AS_UNREAD
                                );
                              }}
                            >
                              <DraftsOutlined
                                style={{ height: '0.75rem', width: '0.75rem' }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title='Mark as read' arrow>
                            <IconButton
                              aria-label='mark-as-read'
                              style={{ marginLeft: '10px' }}
                              onClick={() => {
                                updateNotificationById(
                                  notification.id,
                                  notificationActions.MARK_AS_READ
                                );
                              }}
                            >
                              <MailOutline
                                style={{ height: '0.75rem', width: '0.75rem' }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>

                      <Typography
                        variant='subtitle2'
                        component='div'
                        style={{
                          fontSize: '11px',
                        }}
                      >
                        {moment(notification.date_created)
                          .startOf('hour')
                          .fromNow()}
                        {/* {new Date(
                          notification.date_created
                        ).toLocaleDateString()} */}
                      </Typography>
                    </Box>
                    {!!notification.url ? (
                      <Link href={notification.url} variant='h3' color='#000'>
                        {notification.title}
                      </Link>
                    ) : (
                      <ListItemText
                        primary={
                          <Typography component='div' variant='h3' color='#000'>
                            {notification.title}
                          </Typography>
                        }
                      />
                    )}
                    {/* <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {notification.body}
                        </Typography>
                      }
                    /> */}
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <GetAvatar
                        user_hash={
                          notificationTemplate[notification.notification_type]
                            .role === USER_ROLE.USER
                            ? notification?.to_user_hash
                            : notificationTemplate[
                                notification.notification_type
                              ].role === USER_ROLE.GIVER
                            ? notification?.data?.receive_user_hash
                            : notificationTemplate[
                                notification.notification_type
                              ].role === USER_ROLE.RECEIVER
                            ? notification?.data?.give_user_hash
                            : notificationTemplate[
                                notification.notification_type
                              ].role === USER_ROLE.ADMIN
                            ? notification?.to_user_hash
                            : null
                        }
                      />
                    </Box>
                  </ListItem>
                ))}
              {notifications && notifications.length === 0 ? (
                <ListItem
                  key={'No result'}
                  style={{ alignItems: 'center' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <ListItemText primary={'No any notifications'} />
                </ListItem>
              ) : null}
            </List>
          )}
        </div>
      </Drawer>
    </div>
  );
}
