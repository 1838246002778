import React, { useEffect } from 'react';
import { GetDetails } from "./GetDetails";
import { itemApi } from "../../api";
import { appSettings } from "../../constants";
import LoadingIcon from "../ui/loadingIcon";
import { navigate } from "@reach/router";


const ExternalItemDetails = (props) => {

    const [bundleObj, setBundleObj] = React.useState("");
    const backClicked = (e) => {
//        window.history.back();
        navigate('/get');
    }
    useEffect(() => {
        const bundleId = props.bundleId;

        itemApi.getItemsByBundle(bundleId, function (response) {
            if (response) {
                let nemItem = { ...response.bundle, itemTitle: [], itemImage: [], category: [], condition: [], id: [] };
                if (response.bundle && response.bundle.title) {
                    let keys = Object.keys(response.bundle.title);
                    keys.map(function (itemTitle, i) {
                        nemItem.id[i] = itemTitle;
                        nemItem.itemTitle[i] = response.bundle.title[itemTitle];
                        let img = response.bundle.images[itemTitle].startsWith('http') ? response.bundle.images[itemTitle] : appSettings.MEDIA_URL + response.bundle.images[itemTitle];
                        nemItem.itemImage[i] = img;
                        let cat_condition = response.bundle.cat_condition[itemTitle].split('_');
                        nemItem.category[i] = cat_condition[0];
                        nemItem.condition[i] = cat_condition[1];
                    });
                    if (nemItem.itemTitle.length < 1) return;
                    setBundleObj(nemItem)
                } else {
                    //  alert ('This Bundle does NOT item');
                    return;
                }
            }
        });
    }, []);
    return (
        <>
            {bundleObj.itemTitle ? <GetDetails item={bundleObj} backClick={backClicked} /> :
                <section id="intro1" style={{ minHeight: '530px', margin: "0 auto" }}>
                    <div className="intro-container1">
                        <LoadingIcon loadingMsg="Loading ..." />
                    </div>
                </section>
            }

        </>
    )
};
export { ExternalItemDetails };
